import React, { PureComponent } from 'react';

class FunctionalDeleteAccount extends PureComponent {
  render() {
    /* eslint-disable */
    return (
      <div>
        Send us an email at <a href="mailto:admin@dorothymemoryapp.com">admin@dorothymemoryapp.com</a> and we will delete your account for Dorothy Memory App. <strong>All</strong> data will be deleted including all your study sets and questions.
      </div>
    );
    /* eslint-enable */
  }
}

export default FunctionalDeleteAccount;
