import gql from 'graphql-tag';

// This isn't currently being used, but it's here for future use. If I don't use it within
// 1 year, I'll delete it:
// https://trello.com/c/kfj7TvgK/951-remove-updateprogress-mutation
export default gql`
  mutation updateProgress(
    $shouldForceUpdate: Boolean
  ) {
    updateProgress(
      shouldForceUpdate: $shouldForceUpdate
    ) {
      completedQuizzesSize
    }
  }
`;
