import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import AlertBanner from 'src/components/AlertBanner';
import NewStudySetForm from 'src/components/forms/onboarding/NewStudySet';

import OnboardingBase from '../Base';
import styles from './styles.module.scss';

function NewStudySet(props) {
  const {
    errors,
    handleSubmit,
    match,
    isActive,
    title,
  } = props;

  return (
    <OnboardingBase>
      <div
        className={ styles.Root }
        data-automated-test="onboarding--new-study-set"
      >
        <p className={ styles.Title }>Welcome to Dorothy! Let’s create your first study set.</p>

        <AlertBanner
          extraClassNames={ styles.AlertBanner }
          isInfo
        >
          <p className={ styles.AlertBannerTitle }>
            Your first study set
          </p>

          <p className={ styles.AlertBannerText }>
            You can create specific study sets, like ”Spanish Vocab I”,
            or broad study sets, like ”Random,” to gather facts you would like
            to remember long-term. And don’t worry, you’ll be able to create many
            more study sets later.
          </p>
        </AlertBanner>

        <div className={ styles.Form }>
          <NewStudySetForm
            errors={ errors }
            handleSubmit={ handleSubmit }
            isActive={ isActive }
            match={ match }
            saveButtonValue={ title ? 'Continue' : 'Create' }
            title={ title }
          />
        </div>
      </div>
    </OnboardingBase>
  );
}

NewStudySet.propTypes = {
  errors       : basePropTypes.errors,
  handleSubmit : PropTypes.func.isRequired,
  isActive     : PropTypes.bool,
  match        : basePropTypes.match.isRequired,
  title        : PropTypes.string,
};

export default NewStudySet;
