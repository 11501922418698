import actionTypes from 'src/constants/actionTypes';
import generalService from 'src/services/general';

export function addErrors(errors) {
  return {
    type    : actionTypes.GENERAL__ADD_ERRORS,
    payload : { errors },
  };
}

export function clearTempState() {
  return { type : actionTypes.GENERAL__CLEAR_TEMP_STATE };
}

export function submitFeedback(values) {
  return dispatch => {
    dispatch({ type : actionTypes.GENERAL__SUBMIT_FEEDBACK_START });

    return generalService
      .submitFeedback(values)
      .then(() => dispatch({
        type : actionTypes.GENERAL__SUBMIT_FEEDBACK_SUCCESS,
      }))
      .catch(errors => dispatch({
        type    : actionTypes.GENERAL__SUBMIT_FEEDBACK_FAILURE,
        payload : { errors },
      }));
  };
}

export function closeBanner() {
  return { type : actionTypes.GENERAL__CLOSE_BANNER };
}
