import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.PROFILE__FETCH_PROGRESS_REPORT_FAILURE:
    case actionTypes.PROFILE__SHOW_FAILURE:
    case actionTypes.PROFILE__UPDATE_NOTIFICATION_SETTING_FAILURE:
      return state.withMutations(map => {
        map.set('isActive', false);
        map.set('isUpdating', false);
      });

    case actionTypes.PROFILE__UPDATE_PROFILE_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isUpdating', false);
      });

    case actionTypes.PROFILE__FETCH_PROGRESS_REPORT_START:
    case actionTypes.PROFILE__SHOW_START:
    case actionTypes.PROFILE__UPDATE_NOTIFICATION_SETTING_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.PROFILE__UPDATE_PROFILE_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isUpdating', true);
      });

    case actionTypes.PROFILE__FETCH_PROGRESS_REPORT_SUCCESS:
      return state.withMutations(map => {
        const { data } = payload;

        map.set('progressReport', Immutable.fromJS(data));
        map.set('isActive', false);
      });

    case actionTypes.PROFILE__SHOW_SUCCESS:
      return state.withMutations(map => {
        const {
          data : {
            maxQuestionAmountPerQuiz,
            notificationSettings,
            studySets,
            user,
          },
        } = payload;

        map.set('accountPlan', Immutable.fromJS(user && user.accountPlan));
        map.set('errors', null);
        map.set('givenFreeAccess', user && user.givenFreeAccess);
        map.set('isActive', false);
        map.set('maxQuestionAmountPerQuiz', maxQuestionAmountPerQuiz);
        map.set('notificationSettings', Immutable.fromJS(notificationSettings));
        map.set('studySets', Immutable.fromJS(studySets));
        map.set('userId', user && user.id);
      });

    case actionTypes.PROFILE__UPDATE_PROFILE_SUCCESS:
      return state.withMutations(map => {
        const {
          data : {
            maxQuestionAmountPerQuiz,
            studySets,
          },
        } = payload;

        map.set('maxQuestionAmountPerQuiz', maxQuestionAmountPerQuiz);
        map.set('studySets', Immutable.fromJS(studySets));
        map.set('isUpdating', false);
      });

    case actionTypes.PROFILE__UPDATE_NOTIFICATION_SETTING_SUCCESS:
      return state.withMutations(map => {
        const { data : { notificationSettings } } = payload;

        map.set('notificationSettings', Immutable.fromJS(notificationSettings));
        map.set('isActive', false);
      });

    default:
      return state;
  }
}
