import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import analytics from 'src/shared/analytics';
import BaseModal from 'src/components/modals/Base';
import ButtonLink from 'src/components/buttons/ButtonLink';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import MAX_QUESTIONS_BEFORE_PAYWALL from 'src/constants/maxQuestionsBeforePaywall';

import styles from './styles.module.scss';

function QuestionLimitModal(props) {
  const {
    closeModal,
    errorMessage,
    heading,
    para,
  } = props;

  useEffect(() => {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Question limit modal opened');
  }, []);

  let headingDisplay = heading || `
    Oh no! It looks like you have reached
    the ${MAX_QUESTIONS_BEFORE_PAYWALL} question limit on your Basic plan.
  `;

  if (errorMessage && errorMessage.includes('adding reversed questions')) {
    headingDisplay = `
      Oh no! By adding reversed questions to your quizzes, it looks like you have
      reached the ${MAX_QUESTIONS_BEFORE_PAYWALL} question limit on your Basic plan.
    `;
  }

  return (
    <BaseModal
      closeModal={ closeModal }
      hasCloseButton
    >
      <h3>
        { headingDisplay }
      </h3>

      <p className={ styles.Para }>
        <Choose>
          <When condition={ para }>
            { para }
          </When>

          <Otherwise>
            You’ll need to upgrade to continue adding questions.
            But don’t worry, it’s really easy!
          </Otherwise>
        </Choose>
      </p>

      <ButtonLink
        linksOut
        handleClick={ closeModal }
        text="Upgrade to Premium"
        to="/profile/account-plan"
      />
    </BaseModal>
  );
}

QuestionLimitModal.propTypes = {
  closeModal   : basePropTypes.closeModal.isRequired,
  errorMessage : basePropTypes.errorMessage,
  heading      : PropTypes.string,
  para         : PropTypes.string,
};

export default QuestionLimitModal;
