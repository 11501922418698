import React from 'react';

import styles from './styles.module.scss';

function Chevron() {
  return (
    <svg
      className={ styles.Root }
      data-automated-test="chevron-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1280"
      x="0px"
      y="0px"
    >
      <path
        className={ styles.Path }
        d="M310.841097 512.169859L767.981657 55.0293A32.170342 32.170342 0 0 0 722.621474 9.347415L265.480915 466.487974a64.340684 64.340684 0 0 0 0 91.042068L722.621474 1014.670601a32.170342 32.170342 0 0 0 45.360183-45.360182z"
      />
    </svg>
  );
}

export default Chevron;
