import { PropTypes } from 'prop-types';
import React from 'react';

import classNames from 'src/components/shared/classNames';
import ErrorIcon from 'src/components/icons/Error';
import InfoIcon from 'src/components/icons/Info';
import WarningIcon from 'src/components/icons/Warning';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function AlertBanner(props) {
  const {
    children,
    extraClassNames,
    isError,
    isInfo,
    isWarning,
  } = props;

  function getRootClassNames() {
    return cx({
      Root : true,
      isError,
      isInfo,
      isWarning,
    }, extraClassNames);
  }

  function getIconClassNames() {
    return cx({
      Icon : true,
      isError,
      isInfo,
      isWarning,
    });
  }

  function getContainerClassNames() {
    return cx({
      Container : true,
      isError,
      isInfo,
      isWarning,
    });
  }

  return (
    <div className={ getRootClassNames() }>
      <div className={ getIconClassNames() }>
        <Choose>
          <When condition={ isError }>
            <ErrorIcon />
          </When>

          <When condition={ isInfo }>
            <InfoIcon />
          </When>

          <When condition={ isWarning }>
            <WarningIcon />
          </When>
        </Choose>
      </div>

      <div className={ getContainerClassNames() }>
        { children }
      </div>
    </div>
  );
}

AlertBanner.propTypes = {
  children        : PropTypes.node.isRequired,
  extraClassNames : PropTypes.string,
  isError         : PropTypes.bool,
  isInfo          : PropTypes.bool,
  isWarning       : PropTypes.bool,
};

export default AlertBanner;
