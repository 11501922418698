import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import * as authenticationActionCreators from 'src/actions/authentication';
import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as generalActionCreators from 'src/actions/general';
import * as profileActionCreators from 'src/actions/profile';

import FeedbackPropsContext from 'src/contexts/FeedbackProps';
import Profile from 'src/components/profile';
import submitFeedback from 'src/containers/shared/submitFeedback';

class FunctionalProfile extends PureComponent {
  static propTypes = {
    accessToken     : authenticationPropTypes.accessToken.isRequired,
    actions         : basePropTypes.actions.isRequired,
    children        : basePropTypes.children.isRequired,
    client          : authenticationPropTypes.client.isRequired,
    errors          : basePropTypes.errors,
    generalIsActive : PropTypes.bool,
    history         : basePropTypes.history.isRequired,
    location        : basePropTypes.location.isRequired,
    uid             : authenticationPropTypes.uid.isRequired,
  };

  state = {
    shouldDisplayThankYouMessage : false,
  }

  componentDidMount() {
    return this.props.actions.profile.show();
  }

  buildFeedbackPropsContext = () => {
    const { errors, generalIsActive } = this.props;

    return {
      errors,
      isActive                     : generalIsActive,
      resetFeedbackModal           : () => this.setState({ shouldDisplayThankYouMessage : false }),
      shouldDisplayThankYouMessage : this.state.shouldDisplayThankYouMessage,
      submitFeedback               : this.submitFeedback,
    };
  }

  handleSignout = () => {
    const { accessToken, actions, client, uid } = this.props;

    return actions
      .authentication
      .signOut({ 'access-token' : accessToken, client, uid })
      .then(this.navigateToSignIn)
      .catch(this.navigateToSignIn);
  }

  navigateToSignIn = () => this.props.history.push('/auth/welcome')

  submitFeedback = values => submitFeedback(values, this)

  render() {
    const { children, location } = this.props;

    return (
      <FeedbackPropsContext.Provider value={ this.buildFeedbackPropsContext() }>
        <Profile
          handleSignout={ this.handleSignout }
          location={ location }
        >
          { children }
        </Profile>
      </FeedbackPropsContext.Provider>
    );
  }
}

function mapStateToProps({ authentication, general }) {
  return {
    accessToken     : authentication.get('accessToken'),
    client          : authentication.get('client'),
    errors          : general.get('errors'),
    generalIsActive : general.get('isActive'),
    name            : authentication.get('name'),
    uid             : authentication.get('uid'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication : bindActionCreators(authenticationActionCreators, dispatch),
      general        : bindActionCreators(generalActionCreators, dispatch),
      profile        : bindActionCreators(profileActionCreators, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalProfile);
