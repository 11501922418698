import { Link } from 'react-router-dom';
import React from 'react';

import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import Card from 'src/components/Card';

import styles from './styles.module.scss';

function PublicStudySets(props) {
  const {
    publicStudySets,
  } = props;

  return (
    <div
      className={ styles.Root }
      data-automated-test="dashboard--public-study-sets-section"
    >
      <h3 className={ styles.Heading }>See what others use Dorothy for:</h3>

      <ul className={ styles.StudySetCardList }>
        <For each="studySet" index="i" of={ publicStudySets }>
          <li
            className={ styles.StudySetCardListItem }
            key={ studySet.get('id') }
          >
            <Card
              dataAutomatedTest=""
              extraClassNames={ styles.StudySetCard }
            >
              <p>
                <Link
                  className={ styles.Link }
                  to={ `/public-study-sets/${studySet.get('id')}` }
                >
                  { studySet.get('title') }
                </Link>
              </p>

              <p>
                { studySet.get('originalQuestionsCount') } questions
              </p>
            </Card>
          </li>
        </For>
      </ul>

      <p className={ styles.ViewMoreLink }>
        <Link
          className={ styles.Link }
          to="/public-study-sets"
        >
          view more
        </Link>
      </p>
    </div>
  );
}

PublicStudySets.propTypes = {
  publicStudySets : studySetPropTypes.studySets.isRequired,
};

export default PublicStudySets;
