import { PropTypes } from 'prop-types';
import React from 'react';

import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as statsPropTypes from 'src/constants/propTypes/stats';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import Logo from 'src/components/images/logos/NameWithFaceAsO';

import ActionContent from './ActionContent';
import NoQuizContent from './NoQuizContent';
import styles from './styles.module.scss';

function OpeningContent(props) {
  const {
    chosenStudySets,
    maxQuestionAmountPerQuiz,
    onboardingStudySetId,
    statsDashboardReport,
    quiz,
  } = props;

  const getOpeningText = () => {
    if (!quiz) return 'Welcome to your dashboard!';

    return 'Welcome back to your dashboard!';
  };

  // TODO: refresh when "take your next quiz early" is available. The button
  // for a study set is appearing.

  // TODO: deal with NoQuizContent
  return (
    <div className={ styles.Root }>
      <div className={ styles.LogoContainer }>
        <Logo defaultSpacing isCentered />
      </div>

      <h3 className={ styles.Heading }>{ getOpeningText() }</h3>

      <Choose>
        <When condition={ quiz }>
          <ActionContent
            chosenStudySets={ chosenStudySets }
            maxQuestionAmountPerQuiz={ maxQuestionAmountPerQuiz }
            quiz={ quiz }
            statsDashboardReport={ statsDashboardReport }
          />
        </When>

        <Otherwise>
          <NoQuizContent onboardingStudySetId={ onboardingStudySetId } />
        </Otherwise>
      </Choose>
    </div>
  );
}

OpeningContent.propTypes = {
  chosenStudySets          : studySetPropTypes.studySets.isRequired,
  maxQuestionAmountPerQuiz : PropTypes.number,
  onboardingStudySetId     : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quiz                     : quizPropTypes.quiz,
  statsDashboardReport     : statsPropTypes.dashboardReport.isRequired,
};

export default OpeningContent;
