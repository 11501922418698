import gql from 'graphql-tag';

export default gql`
  mutation signIn(
    $email: String!
    $isNative: Boolean
  ) {
    signIn(
      email: $email
      isNative: $isNative
    ) {
      email
      id
    }
  }
`;
