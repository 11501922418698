import gql from 'graphql-tag';

import questionFields from 'src/graphql/fragments/questionFields';

export default gql`
  mutation deleteQuestionImage(
    $questionId: ID
  ) {
    deleteQuestionImage(
      questionId: $questionId
    ) {
      ...questionFields
    }
  }
  ${questionFields}
`;
