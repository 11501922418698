import Immutable from 'immutable';

export default Immutable.fromJS({
  errors       : [],
  isActive     : false,
  loaded       : {
    all      : [],
    featured : [],
    show     : {
      questions : [],
    },
  },
});
