import buildUserFriendlyErrorsForMissingValues from './buildUserFriendlyErrorsForMissingValues';

export default function (values, containerThis, signInCallback = null) {
  const { authentication } = containerThis.props.actions;

  const errors = buildUserFriendlyErrorsForMissingValues(
    values,
    { email  : 'Oops! Looks like you forgot to enter your email.' },
  );

  if (errors) return authentication.addErrors(errors);

  return authentication
    .signIn(values)
    .then(data => {
      if (signInCallback) return signInCallback(data);

      const { payload } = data;

      if (payload && payload.errors) return;

      return containerThis.setState({ shouldDisplayVerifyOtp : true });
    });
}
