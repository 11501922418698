// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___e7ta4{margin-bottom:18px}.styles-module__Root___e7ta4.styles-module__isSelected___1d5Of a{font-weight:bold}.styles-module__Root___e7ta4 a{text-decoration:none}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/sorter/SortOption/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,iEACE,gBAAA,CACF,+BACE,oBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin-bottom: 18px; }\n  .Root.isSelected a {\n    font-weight: bold; }\n  .Root a {\n    text-decoration: none; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___e7ta4",
	"isSelected": "styles-module__isSelected___1d5Of"
};
module.exports = exports;
