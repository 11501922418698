import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

export const accountPlan = ImmutablePropTypes.contains({
  periodEnd : PropTypes.string,
  planType  : PropTypes.string.isRequired,
});

export const cancelStripeSubscription = PropTypes.func;
export const isCancelation = PropTypes.bool;
export const isPaymentMethodChange = PropTypes.bool;
export const isResubscription = PropTypes.bool;
export const subscribe = PropTypes.func;
