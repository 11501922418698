// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___24cPy{padding:18px 0;position:relative}.styles-module__Root___24cPy:hover{cursor:pointer}.styles-module__Title___3SyXI{box-shadow:none;color:#c00;text-decoration:none}.styles-module__Title___3SyXI.styles-module__isActive___oEUMH{color:#bdbdbd}.styles-module__Title___3SyXI.styles-module__isPositiveAction___1uVFl{color:#2071aa}.styles-module__Loader___W6CxV{position:absolute;right:12px;top:15px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/modals/Confirmation/SubmitButton/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAA,CACA,iBAAA,CACA,mCACE,cAAA,CAEJ,8BACE,eAAA,CACA,UAAA,CACA,oBAAA,CACA,8DACE,aAAA,CACF,sEACE,aAAA,CAEJ,+BACE,iBAAA,CACA,UAAA,CACA,QAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  padding: 18px 0;\n  position: relative; }\n  .Root:hover {\n    cursor: pointer; }\n\n.Title {\n  box-shadow: none;\n  color: #cc0000;\n  text-decoration: none; }\n  .Title.isActive {\n    color: #bdbdbd; }\n  .Title.isPositiveAction {\n    color: #2071aa; }\n\n.Loader {\n  position: absolute;\n  right: 12px;\n  top: 15px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___24cPy",
	"Title": "styles-module__Title___3SyXI",
	"isActive": "styles-module__isActive___oEUMH",
	"isPositiveAction": "styles-module__isPositiveAction___1uVFl",
	"Loader": "styles-module__Loader___W6CxV"
};
module.exports = exports;
