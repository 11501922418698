import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';
import * as studySetsActions from 'src/actions/studySets';

import StudySetsAll from 'src/components/studySets/All';

class FunctionalAll extends PureComponent {
  static propTypes = {
    actions      : basePropTypes.actions.isRequired,
    isActive     : basePropTypes.isActive,
    location     : basePropTypes.location.isRequired,
    studySets    : studySetPropTypes.studySets.isRequired,
  };

  componentDidMount() {
    return this.props.actions.studySets.getAll();
  }

  render() {
    return (
      <StudySetsAll
        isActive={ this.props.isActive }
        location={ this.props.location }
        studySets={ this.props.studySets }
      />
    );
  }
}

function mapStateToProps({ studySets }) {
  return {
    isActive     : studySets.get('isActive'),
    studySets    : studySets.getIn(['loaded', 'all']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalAll);
