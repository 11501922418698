// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__PulseLoader___23gi8{margin-top:36px}@media screen and (min-width: 1080px){.styles-module__PulseLoader___23gi8{margin-top:0}}.styles-module__Answer___1ZItD{margin-top:18px}.styles-module__EditQuestionLink___1e8Lt{display:inline-block;margin-top:12px}.styles-module__EditQuestionLinkContainer___1tIec{margin-top:36px;text-align:center}.styles-module__DeleteTitle___lWsDL{color:#c00;margin:24px auto 0;opacity:93%;text-align:center;width:-moz-fit-content;width:fit-content}.styles-module__DeleteTitle___lWsDL:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/ShowQuestion/styles.module.scss"],"names":[],"mappings":"AAAA,oCACE,eAAA,CACA,sCACE,oCACE,YAAA,CAAA,CAEN,+BACE,eAAA,CAEF,yCACE,oBAAA,CACA,eAAA,CAEF,kDACE,eAAA,CACA,iBAAA,CAEF,oCACE,UAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,sBAAA,CAAA,iBAAA,CACA,0CACE,cAAA","file":"styles.module.scss","sourcesContent":[".PulseLoader {\n  margin-top: 36px; }\n  @media screen and (min-width: 1080px) {\n    .PulseLoader {\n      margin-top: 0; } }\n\n.Answer {\n  margin-top: 18px; }\n\n.EditQuestionLink {\n  display: inline-block;\n  margin-top: 12px; }\n\n.EditQuestionLinkContainer {\n  margin-top: 36px;\n  text-align: center; }\n\n.DeleteTitle {\n  color: #cc0000;\n  margin: 24px auto 0;\n  opacity: 93%;\n  text-align: center;\n  width: fit-content; }\n  .DeleteTitle:hover {\n    cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"PulseLoader": "styles-module__PulseLoader___23gi8",
	"Answer": "styles-module__Answer___1ZItD",
	"EditQuestionLink": "styles-module__EditQuestionLink___1e8Lt",
	"EditQuestionLinkContainer": "styles-module__EditQuestionLinkContainer___1tIec",
	"DeleteTitle": "styles-module__DeleteTitle___lWsDL"
};
module.exports = exports;
