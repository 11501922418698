import addPublicStudySet from 'src/graphql/mutations/publicStudySets/addPublicStudySet';
import getAllPublicStudySets from 'src/graphql/queries/publicStudySets/getAllPublicStudySets';
import showPublicStudySet from 'src/graphql/queries/publicStudySets/showPublicStudySet';

import post from './shared/post';
import publicPost from './shared/publicPost';

export default {
  add(values) {
    return post(values, addPublicStudySet);
  },

  getAll(values) {
    return publicPost(values, getAllPublicStudySets);
  },

  show(values) {
    return publicPost(values, showPublicStudySet);
  },
};
