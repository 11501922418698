import gql from 'graphql-tag';

import studySetFields from 'src/graphql/fragments/studySetFields';

export default gql`
  mutation createStudySet(
    $description: String
    $questions: [QuestionInput!]
    $reversedCopiesAdded: Boolean!
    $title: String
    $visibilityStatus: String!
  ) {
    createStudySet(
      description: $description
      questions: $questions
      reversedCopiesAdded: $reversedCopiesAdded
      title: $title
      visibilityStatus: $visibilityStatus
    ) {
      ...studySetFields
    }
  }
  ${studySetFields}
`;
