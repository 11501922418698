import gql from 'graphql-tag';

export default gql`
  mutation deleteStudySet(
    $id: ID
    $shouldDestroyAll: Boolean
  ) {
    deleteStudySet(
      id: $id
      shouldDestroyAll: $shouldDestroyAll
    ) {
      id
    }
  }
`;
