import React from 'react';

import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import isQuizReady from 'src/shared/isQuizReady';
import Lever from 'src/components/Lever';
import ResultsList from 'src/components/ResultsList';

import styles from './styles.module.scss';

function QuizQuestionsLever(props) {
  const {
    dueDate,
    questions,
    quiz,
  } = props;

  const quizIsReady = isQuizReady(quiz, dueDate);
  const quizStarted = quiz.get('started');

  function getTitle() {
    if (quizStarted) return 'Peek at the questions left in your quiz';
    if (quizIsReady) return 'Peek at the questions in your quiz';

    return 'Peek at the questions in your next quiz';
  }

  function toggleIsLeverOpenCallback() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Question limit modal opened');
  }

  return (
    <div className={ styles.Root }>
      <Lever
        dataAutomatedTest="question-form-study-sets-dropdown"
        leverTitleClassNames={ styles.LeverTitle }
        shouldHideOverflow
        title={ getTitle() }
        toggleIsLeverOpenCallback={ toggleIsLeverOpenCallback }
      >
        <div className={ styles.ResultsList }>
          <ResultsList
            basePath="/questions"
            resultName="Question"
            results={ questions }
          />
        </div>
      </Lever>
    </div>
  );
}

QuizQuestionsLever.propTypes = {
  dueDate   : quizPropTypes.dueDate.isRequired,
  questions : questionPropTypes.questions.isRequired,
  quiz      : quizPropTypes.quiz.isRequired,
};

export default QuizQuestionsLever;
