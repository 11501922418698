import actionTypes from 'src/constants/actionTypes';
import pushNotificationsService from 'src/services/pushNotifications';

function handleCommonPushNotificationAction(actionPrefix, serviceAction, values) {
  return dispatch => {
    dispatch({ type : actionTypes[`PUSH_NOTIFICATION__${actionPrefix}_START`] });

    return pushNotificationsService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`PUSH_NOTIFICATION__${actionPrefix}_SUCCESS`],
          payload : { pushNotifications : { ...data[key] } },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`PUSH_NOTIFICATION__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function createPushNotification(values) { // eslint-disable-line import/prefer-default-export
  return handleCommonPushNotificationAction(
    'CREATE',
    'createPushNotification',
    values,
  );
}
