// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1b6VC{display:flex;flex-direction:column;grid-gap:24px;gap:24px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/profile/Notifications/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,aAAA,CAAA,QAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: column;\n  gap: 24px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1b6VC"
};
module.exports = exports;
