import React, { useState } from 'react';

import analytics from 'src/shared/analytics';
import CommentIcon from 'src/components/icons/Comment';
import FeedbackModal from 'src/components/modals/Feedback';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';

import styles from './styles.module.scss';

function FeedbackWidget() {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  function openFeedbackModal() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Submit feedback clicked');

    setIsFeedbackModalOpen(true);
  }

  function closeFeedbackModal() { setIsFeedbackModalOpen(false); }

  return (
    <div>
      <If condition={ isFeedbackModalOpen }>
        <FeedbackModal closeModal={ closeFeedbackModal } />
      </If>

      <div
        className={ styles.Container }
        data-automated-test="feedback-widget"
        onClick={ openFeedbackModal }
      >
        <div className={ styles.Icon }>
          <CommentIcon />
        </div>

        <p className={ styles.Para }>
          Feedback
        </p>
      </div>
    </div>
  );
}

export default FeedbackWidget;
