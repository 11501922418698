import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as authenticationActions from 'src/actions/authentication';
import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

import signIn from 'src/containers/shared/signIn';
import Welcome from 'src/components/authentication/Welcome';

import verifyOtp from './shared/verifyOtp';

class FunctionalWelcome extends PureComponent {
  static propTypes = {
    actions     : basePropTypes.actions.isRequired,
    emailSentTo : authenticationPropTypes.emailSentTo,
    errors      : basePropTypes.errors,
    location    : basePropTypes.location.isRequired,
  }

  constructor() {
    super();
    this.checkLastEmailStatusTimeout = null;
  }

  state = {
    shouldDisplayVerifyOtp : false,
    shouldFireConfetti     : false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.shouldDisplayVerifyOtp &&
      this.state.shouldDisplayVerifyOtp
    ) {
      this.checkLastEmailStatus();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.checkLastEmailStatusTimeout);
  }

  checkLastEmailStatus = (attemptNumber = 0) => {
    const { actions: { authentication }, emailSentTo } = this.props;

    if (attemptNumber > 6) return;

    return authentication
      .checkLastEmailStatus({ emailSentTo })
      .then(({ payload : { errors, userInfo } }) => {
        if (errors) return;

        const { checkLastEmailStatus : { status } } = userInfo;

        if (status === 'not found' || status === 'accepted') {
          this.checkLastEmailStatusTimeout = setTimeout(() => {
            this.checkLastEmailStatus(attemptNumber + 1);
          }, 15000);
        }
      });
  }

  findPathFrom() {
    const { location : { state } } = this.props;
    return state && state.pathFrom;
  }

  goBackFromVerifyOtp = () => {
    this.setState({ shouldDisplayVerifyOtp : false });
  }

  handleSendOtp = values => signIn(values, this)

  handleVerifyOtp = values => verifyOtp(values, this)

  render() {
    return (
      <Welcome
        goBackFromVerifyOtp={ this.goBackFromVerifyOtp }
        handleSendOtp={ this.handleSendOtp }
        handleVerifyOtp={ this.handleVerifyOtp }
        shouldDisplayVerifyOtp={ this.state.shouldDisplayVerifyOtp }
        pathFrom={ this.findPathFrom() }
        shouldFireConfetti={ this.state.shouldFireConfetti }
        { ...this.props }
      />
    );
  }
}

function mapStateToProps({ authentication }) {
  return {
    emailSentTo       : authentication.get('emailSentTo'),
    errors            : authentication.get('errors'),
    isActive          : authentication.get('isActive'),
    isFirstSignIn     : authentication.get('isFirstSignIn'),
    justSignedOut     : authentication.get('justSignedOut'),
    sentEmailMessage  : authentication.get('sentEmailMessage'),
    sentEmailSeverity : authentication.get('sentEmailSeverity'),
    sentEmailStatus   : authentication.get('sentEmailStatus'),
    userId            : authentication.get('id'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication : bindActionCreators(authenticationActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalWelcome);
