// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___31D6m{margin:0 24px 6px 0;min-width:89px}.styles-module__Root___31D6m a{text-decoration:none}@media screen and (min-width: 768px){.styles-module__Root___31D6m{margin:0 0 6px 0}}.styles-module__Title___39BAe{color:rgba(0,0,0,.65)}.styles-module__Title___39BAe.styles-module__isActive___2vBbz{color:#262322;font-weight:bold}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/profile/Links/LinkItem/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,mBAAA,CACA,cAAA,CACA,+BACE,oBAAA,CACF,qCACE,6BACE,gBAAA,CAAA,CAEN,8BACE,qBAAA,CACA,8DACE,aAAA,CACA,gBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin: 0 24px 6px 0;\n  min-width: 89px; }\n  .Root a {\n    text-decoration: none; }\n  @media screen and (min-width: 768px) {\n    .Root {\n      margin: 0 0 6px 0; } }\n\n.Title {\n  color: rgba(0, 0, 0, 0.65); }\n  .Title.isActive {\n    color: #262322;\n    font-weight: bold; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___31D6m",
	"Title": "styles-module__Title___39BAe",
	"isActive": "styles-module__isActive___2vBbz"
};
module.exports = exports;
