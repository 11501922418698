import React from 'react';

import styles from './styles.module.scss';

function TrashCan() {
  return (
    <svg
      className={ styles.Root }
      data-automated-test="trash-can-icon"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 640"
      xmlSpace="preserve"
    >
      <g>
        <path d="M256,388.9c5,0,9-4,9-9V250.6c0-5-4-9-9-9s-9,4-9,9v129.3C247,384.9,251,388.9,256,388.9z" />
        <path d="M292.5,25h-73.1C197.9,25,180,42.3,180,63.8V70H98.1C82.1,70,69,83.4,69,99.4v54   c0,16,13.1,28.6,29.1,28.6h6.6l0.5,256c0.1,27,22,49,49,49h203.5c27,0,48.9-22,49-49l0.5-256h6.6c16,0,29.1-12.6,29.1-28.6v-54   c0-16-13.1-29.4-29.1-29.4H332v-6.2C332,42.3,314.1,25,292.5,25z M198,63.8c0-11.6,9.9-20.8,21.5-20.8h73.1   c11.6,0,21.5,9.2,21.5,20.8V70H198V63.8z M388.7,438c0,17.1-13.9,31-31,31H154.3c-17.1,0-31-14-31-31l-0.5-256h266.5L388.7,438z    M425,153c0,6.1-4.9,11-11,11H98c-6.1,0-11-4.9-11-11V99c0-6.1,4.9-11,11-11h316c6.1,0,11,4.9,11,11V153z" />
        <path d="M187,388.9c5,0,9-4,9-9V250.6c0-5-4-9-9-9s-9,4-9,9v129.3C178,384.9,182,388.9,187,388.9z" />
        <path d="M325,388.9c5,0,9-4,9-9V250.6c0-5-4-9-9-9s-9,4-9,9v129.3C316,384.9,320,388.9,325,388.9z" />
      </g>
    </svg>
  );
}

export default TrashCan;
