import gql from 'graphql-tag';

export default gql`
  fragment studySetFields on StudySetType {
    description
    id
    isUndesignated
    originalQuestions {
      answer {
        audioUrl
        body
        id
        imageUrl
      }
      audioUrl
      forgottenByDate
      id
      imageUrl
      isReversedCopyActive
      title
    }
    reversedCopiesAdded
    title
    visibilityStatus
    userId
  }
`;
