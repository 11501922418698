import { Controller } from 'react-hook-form';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildOptions from 'src/components/shared/buildOptions';
import Lever from 'src/components/Lever';
import SelectField from 'src/components/fields/SelectField';

import styles from './styles.module.scss';

function Options({ control, studySet, studySets }) {
  const options = buildOptions(studySets);

  return (
    <div className={ styles.Root }>
      <Lever
        dataAutomatedTest="question-form-study-sets-dropdown"
        title="options"
      >
        <div className={ styles.Container }>
          <Controller
            control={ control }
            defaultValue={ (studySet && studySet.get('id')) || '' }
            name="studySetId"
            render={ ({ onChange, value }) => (
              <SelectField
                heading="Study set"
                options={ options || [] }
                value={ value }
                onChange={ onChange }
              />
            ) }
          />
        </div>
      </Lever>
    </div>
  );
}

Options.propTypes = {
  control   : basePropTypes.control.isRequired,
  studySet  : studySetPropTypes.studySet,
  studySets : studySetPropTypes.studySets.isRequired,
};

export default Options;
