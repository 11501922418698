import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import SignInViaExternalSource from 'src/components/authentication/SignInViaExternalSource';

import * as authenticationActions from 'src/actions/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as quizzesActions from 'src/actions/quizzes';

class FunctionalSignInViaExternalSource extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    location : basePropTypes.location.isRequired,
  }

  state = {
    isLoading : true,
    pathTo    : null,
  }

  componentDidMount() {
    const { actions, location: { search } } = this.props;

    try {
      const {
        accessToken,
        client,
        expiry,
        pathTo,
        uid,
      } = this.decodeUri(search);

      actions.authentication.updateUser({
        accessToken,
        client,
        email : uid,
        expiry,
        uid,
      });

      if (pathTo === '/quiz/configuration') {
        return actions
          .quizzes
          .fetchNewest()
          .then(() => this.updateState({ pathTo }));
      }

      return this.updateState({ pathTo });
    } catch (e) {
      return this.updateState({ pathTo : '/auth/welcome' });
    }
  }

  decodeUri(search) {
    let accessToken;
    let client;
    let expiry;
    let pathTo;
    let uid;

    /* eslint-disable prefer-destructuring */
    try {
      const decoded = decodeURIComponent(search);

      accessToken = decoded.split('access-token=')[1].split('&client')[0];
      client = decoded.split('client=')[1].split('&expiry')[0];
      uid = decodeURIComponent(decoded.split('&uid=')[1].split('=#<O')[0]);

      // Order matters here. It's okay if pathTo is not set.
      // expiry should be set but if not, also okay.
      expiry = decoded.split('expiry=')[1].split('&redirect-path')[0];

      pathTo = decodeURIComponent(decoded.split('&redirect-path=')[1].split('&uid=')[0]);
    } catch (_) {} // eslint-disable-line no-empty
    /* eslint-enable prefer-destructuring */

    return {
      accessToken,
      client,
      expiry,
      pathTo,
      uid,
    };
  }

  updateState({ pathTo }) {
    return this.setState({
      isLoading : false,
      pathTo    : pathTo || '/',
    });
  }

  render() {
    const {
      isLoading,
      pathTo,
    } = this.state;

    return (
      <SignInViaExternalSource
        isLoading={ isLoading }
        pathFrom={ this.props.location.pathname }
        pathTo={ pathTo }
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication : bindActionCreators(authenticationActions, dispatch),
      quizzes        : bindActionCreators(quizzesActions, dispatch),
    },
  };
}

export default connect(null, mapDispatchToProps)(FunctionalSignInViaExternalSource);
