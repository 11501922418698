import React from 'react';

import styles from './styles.module.scss';

function Library() {
  return (
    <svg
      className={ styles.Root }
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
    >
       <g transform="translate(0,-952.36218)">
         <path d="m 23.092245,969.37359 a 1.9968554,2.0030276 1.0759778 0 0 -1.718599,1.46849 L 6.0624938,1027.5822 a 1.9968554,2.0030276 1.0759778 0 0 1.4373735,2.4684 l 19.6857667,5.2178 a 1.9968554,2.0030276 1.0759778 0 0 2.437286,-1.4059 l 12.37391,-45.86713 0,45.36713 a 2.0000237,1.9998541 0 0 0 1.999824,1.9997 l 23.997887,0 23.997888,0 a 2.0000237,1.9998541 0 0 0 1.999824,-1.9997 l 0,-61.98926 a 2.0000237,1.9998541 0 0 0 -1.812341,-1.99965 2.0000237,1.9998541 0 0 0 -0.187483,0 l -23.997888,0 -23.997887,0 a 2.0000237,1.9998541 0 0 0 -1.999824,1.99965 l 0,2.90575 -18.154651,-4.84291 a 1.9968554,2.0030276 1.0759778 0 0 -0.749934,-0.0625 z m 22.904233,3.99931 19.99824,0 0,47.9917 -19.99824,0 0,-47.9917 z m 23.997887,0 19.99824,0 0,47.9917 -19.99824,0 0,-47.9917 z m -45.277263,0.43742 15.842355,4.21802 -11.373999,42.05516 -15.811108,-4.1554 11.342752,-42.11778 z m -3.812165,36.52488 a 2.0000239,1.9998543 0 0 0 -0.406214,3.9681 l 3.999648,0.9999 a 2.0008002,2.0006305 0 1 0 0.999912,-3.8744 l -3.999648,-0.9998 a 2.0000239,1.9998543 0 0 0 -0.593698,-0.094 z m 31.903441,3.0308 a 2.0020198,2.00185 0 1 0 0.187484,3.9993 l 5.999472,0 a 2.0000239,1.9998543 0 1 0 0,-3.9993 l -5.999472,0 a 2.0000239,1.9998543 0 0 0 -0.187484,0 z m 23.997888,0 a 2.0020198,2.00185 0 1 0 0.187483,3.9993 l 5.999472,0 a 2.0000239,1.9998543 0 1 0 0,-3.9993 l -5.999472,0 a 2.0000239,1.9998543 0 0 0 -0.187483,0 z m -64.463075,6.3739 15.811108,4.1868 -1.874835,6.9675 -15.811108,-4.218 1.874835,-6.9363 z m 33.653287,5.624 19.99824,0 0,5.999 -19.99824,0 0,-5.999 z m 23.997887,0 19.99824,0 0,5.999 -19.99824,0 0,-5.999 z" />
       </g>
    </svg>
  );
}

export default Library;
