import React from 'react';

function Blob() {
  return (
    <svg width="375" height="589" viewBox="0 0 375 589">
      <path d="M-16.9477 565.21C-73.9455 523.863 -97.4984 420.891 -76.3265 344.859C-55.6326 268.38 10.209 218.809 58.225 162.12C106.241 105.43 136.808 41.0808 188.345 14.6302C240.268 -12.3737 312.72 -1.436 371.29 42.8044C429.474 87.5875 473.757 165.163 478.538 245.302C483.318 325.441 449.487 408.091 399.476 461.908C349.833 515.213 284.111 540.77 206.667 563.603C129.178 585.947 39.6272 606.62 -16.9385 565.21" />
    </svg>

  );
}

export default Blob;
