import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import Logo from 'src/components/quiz/Congratulations/Logo';

import styles from './styles.module.scss';

function Transition({ handleClick }) {
  return (
    <div
      className={ styles.Root }
      data-automated-test="quiz-congratulations-transitioner"
      onClick={ handleClick }
    >
      <Logo shouldAnimate />
    </div>
  );
}

Transition.propTypes = {
  handleClick : basePropTypes.handleClick.isRequired,
};

export default Transition;
