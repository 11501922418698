import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import ButtonLink from 'src/components/buttons/ButtonLink';

import LetUsKnowModal from 'src/components/Dashboard/OpeningContent/LetUsKnowModal';
import styles from './styles.module.scss';

function NoQuizContent(props) {
  const { onboardingStudySetId } = props;

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  function openFeedbackModal() { setIsFeedbackModalOpen(true); }
  function closeFeedbackModal() { setIsFeedbackModalOpen(false); }

  return (
    <div>
      <If condition={ isFeedbackModalOpen }>
        <LetUsKnowModal closeModal={ closeFeedbackModal } />
      </If>

      <Choose>
        <When condition={ onboardingStudySetId }>
          <p className={ styles.Para }>
            We’re so excited to have you here.
            Let’s add some questions to the study set you just created and
            we’ll get your very first quiz set up for tomorrow.
            If you have any questions, please{ ' ' }

            <span
              className={ styles.LetUsKnowLink }
              data-automated-test="dashboard--opening-para--let-us-know-span"
              onClick={ openFeedbackModal }
            >let us know</span>.
          </p>

          <ButtonLink
            extraClassNames={ styles.ButtonLink }
            text="Let's add some questions"
            to={ `/study-sets/${onboardingStudySetId}/edit?purpose=onboarding` }
          />
        </When>

        <Otherwise>
          <p className={ styles.Para }>
            We’re so excited to have you here. Add your first study set and we’ll get your
            very first quiz set up for tomorrow.
            If you have any questions, please{ ' ' }

            <span
              className={ styles.LetUsKnowLink }
              data-automated-test="dashboard--opening-para--let-us-know-span"
              onClick={ openFeedbackModal }
            >let us know</span>.
          </p>

          <ButtonLink
            extraClassNames={ `${styles.ButtonLink} ${styles.shouldReallyDrawFocus}` }
            isPulsing
            text="Let's add a study set"
            to="/study-sets/new"
          />
        </Otherwise>
      </Choose>
    </div>
  );
}

NoQuizContent.propTypes = {
  onboardingStudySetId : PropTypes.number,
};

export default NoQuizContent;
