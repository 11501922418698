import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import autoFocusError from 'src/components/forms/StudySet/shared/autoFocusError';
import buildStudySetQuestionsFromCSV
from 'src/components/forms/StudySet/shared/buildStudySetQuestionsFromCSV';
import ButtonDiv from 'src/components/buttons/ButtonDiv';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import classNames from 'src/components/shared/classNames';
import debounce from 'src/components/shared/debounce';
import FieldErrors from 'src/components/fields/FieldErrors';
import handleSkipLinkClick from 'src/components/shared/handleSkipLinkClick';
import Header from 'src/components/navigation/Header';
import HomeLink from 'src/components/navigation/HomeLink';
import HorizontalLine from 'src/components/HorizontalLine';
import LimitModal from 'src/components/modals/QuestionLimitModal';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import TextField from 'src/components/fields/TextField';

import CsvImportModal from './CsvImportModal';
import Options from './Options';
import Question from './Question';
import QuestionsOptions from './QuestionsOptions';
import ShowMoreContainer from './ShowMoreContainer';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function StudySet(props) {
  const {
    addQuestion,
    bulkAddQuestions,
    deleteAnswerAudio,
    deleteAnswerImage,
    deleteQuestionAudio,
    deleteQuestionImage,
    errors : errorsMap,
    extraClassNames,
    handleSubmit,
    heading,
    history,
    isActive,
    isOnboarding,
    isSaving,
    location,
    match,
    noBackButton,
    refreshData,
    removeQuestion,
    saveButtonValue,
    studySet,
    titleAutoFocusDelayInMs,
    undoRemoveQuestion,
    updateValueInAutosaved,
  } = props;

  const errors = errorsMap && errorsMap.get && errorsMap;

  const { handleSubmit: formSubmit, register, setValue, unregister } = useForm();

  const onSubmit = useCallback(
    values => formSubmit(handleSubmit)(values),
    [formSubmit, handleSubmit],
  );

  const id = studySet.get('id');
  const visibilityStatus = studySet.get('visibilityStatus');
  const isUndesignated = studySet.get('isUndesignated');
  const reversedCopiesAdded = studySet.get('reversedCopiesAdded');
  const title = studySet.get('title');
  const description = studySet.get('description');
  const questions = studySet.get('questions');
  const isPublicErrors = errors && errors.get('ispublic');
  const isNewStudySet = !id;

  const [questionKeys, setQuestionKeys] = useState([]);
  const lastKey = questionKeys.size && questionKeys.last();

  const [addedNewQuestionFromLocationSearch, setAddedNewQuestionFromLocationSearch] = useState(false); // eslint-disable-line max-len
  const [canScrollSome, setCanScrollSome] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isCSVImportModalOpen, setIsCSVImportModalOpen] = useState(false);
  const [isLoadingAllQuestions, setIsLoadingAllQuestions] = useState(false);
  const [isQuestionsOptionsContainerOpen, setIsQuestionsOptionsContainerOpen] = useState(false);
  const [paywallLimitErrorMessage, setPaywallLimitErrorMessage] = useState(null);
  const [reversedQuestionsOptionsSelected, setReversedQuestionsOptionsSelected] = useState(
    reversedCopiesAdded,
  );
  const [shouldAutoFocusLastQuestion, setShouldAutoFocusLastQuestion] = useState(false);
  const [shouldLoadAllQuestions, setShouldLoadAllQuestions] = useState(false);
  const [shouldTempHideHint, setShouldTempHideHint] = useState(false);

  const goToBottomOfPage = () => {
    window.scrollTo({ top : document.body.scrollHeight + 600, behavior : 'smooth' });
  };

  function handleResize() {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.pageYOffset;

    const canScrollSomeNow = windowHeight < documentHeight;
    const pageLocation = scrollPosition + windowHeight;

    const isAtBottomNow = pageLocation >= documentHeight - 96;

    setCanScrollSome(canScrollSomeNow);
    setIsAtBottom(isAtBottomNow);
  }

  function handleRemoveQuestion(key) {
    removeQuestion(key);
    setTimeout(() => handleResize(), 130);
  }

  const addAnotherQuestion = useCallback(
    () => {
      setShouldTempHideHint(true);

      // Default to zero just in case the user deletes all questions
      if (questions.size === 0) return addQuestion('0');
      const key = (Number(lastKey) + 1) || 0;
      addQuestion(key.toString());

      // This small timeout is to give the page a chance to render the new question
      setTimeout(() => {
        goToBottomOfPage();
        setShouldAutoFocusLastQuestion(true);
      }, 125);

      setTimeout(() => setShouldTempHideHint(false), 750);
    },
    [addQuestion, lastKey],
  );

  useEffect(() => {
    let newQuestionKeys = questions && questions
      .keySeq()
      .map(k => Number(k))
      .sort()
      .map(k => k.toString());

    if (isNewStudySet) return setQuestionKeys(newQuestionKeys);

    if (shouldLoadAllQuestions) {
      setQuestionKeys(newQuestionKeys);
      setIsLoadingAllQuestions(true);
      // This is an arbitrary amount of time. I just want to the user to wait
      // a little bit for all the input fields to be drawn on the page.
      const ms = newQuestionKeys * 5;
      setTimeout(() => setIsLoadingAllQuestions(false), ms);
      return;
    }

    newQuestionKeys = newQuestionKeys.slice(-100);

    setQuestionKeys(newQuestionKeys);
  }, [questions.size, shouldLoadAllQuestions]);

  useEffect(() => {
    setReversedQuestionsOptionsSelected(reversedCopiesAdded);
  }, [reversedCopiesAdded]);

  useEffect(() => {
    if (addedNewQuestionFromLocationSearch) return;
    if (!location) return;

    const { search } = location;

    if (!search) return;
    if (!search.includes('purpose=add-new-question')) return;

    if (shouldAutoFocusLastQuestion) return;

    if (!questions) return;
    if (!questionKeys) return;
    if (questionKeys.length === 0) return;
    if (questions.size === 0) return;

    setAddedNewQuestionFromLocationSearch(true);

    if (questions.getIn([questionKeys.last(), 'title'])) {
      addAnotherQuestion();
      return;
    }

    setTimeout(() => {
      goToBottomOfPage();
      setShouldAutoFocusLastQuestion(true);
    }, 125);
  }, [questions, questionKeys]);

  useEffect(() => {
    if (!isOnboarding) return;
    if (!questions) return;
    if (questions.size > 0) return setShouldAutoFocusLastQuestion(true);

    addAnotherQuestion();

    setTimeout(() => {
      setShouldAutoFocusLastQuestion(true);
    }, 125);
  }, [isOnboarding]);

  useEffect(
    () => {
      if (!errors) return setPaywallLimitErrorMessage(null);

      const errorKeys = errors.keySeq();
      const limitErrorKey = errorKeys && errorKeys.find(key => key.includes('limit'));

      if (limitErrorKey) {
        const message = errors.get(limitErrorKey).first();

        return setPaywallLimitErrorMessage(message);
      }

      autoFocusError(errorKeys);
    },
    [errors],
  );

  function handleKeyDown(event) {
    if (!event.metaKey && !event.ctrlKey) return;
    if (event.code !== 'Enter') return;

    addAnotherQuestion();
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', handleResize);
    };
  }, [addAnotherQuestion]);

  const debouncedCheckScroll = debounce(handleResize, 100);

  function addInitialDebounce() {
    if (!location) return debouncedCheckScroll();

    const { search } = location;

    if (!search) return debouncedCheckScroll();
    if (search.includes('purpose=add-new-question')) return;

    debouncedCheckScroll();
  }

  useEffect(() => {
    addInitialDebounce();
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('scroll', debouncedCheckScroll);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('scroll', debouncedCheckScroll);
    };
  }, []);

  const handleFieldChange = useCallback((extraKeys, value) => {
    const page = id || 'new';
    const keys = ['autosaved', page];

    for (let i = 0; i < extraKeys.length; i += 1) {
      keys.push(extraKeys[i]);
    }

    updateValueInAutosaved(keys, value);
  }, [id, updateValueInAutosaved]);

  function handleTitleChange({ target : { value } }) {
    handleFieldChange(['title'], value);
  }

  function handleDescriptionChange({ target : { value } }) {
    handleFieldChange(['description'], value);
  }

  function addCSVQuestionsToStudySet(csvQuestions) {
    const newQuestions = buildStudySetQuestionsFromCSV(csvQuestions, questions);

    setShouldLoadAllQuestions(true);
    bulkAddQuestions(newQuestions);

    // This small timeout is to give the page a chance to render the new questions
    setTimeout(goToBottomOfPage, 125);
  }

  function handleContainerClick() {
    if (isQuestionsOptionsContainerOpen) setIsQuestionsOptionsContainerOpen(false);
  }

  const addQuestionButtonShouldHover = canScrollSome &&
    !isAtBottom &&
    questions &&
    questions.size > 1;

  let questionHeading = 'New questions';

  if (!isNewStudySet) questionHeading = `${questions.size} questions`;

  return (
    <div
      className={ `${styles.Root} ${extraClassNames}` }
      onClick={ handleContainerClick }
    >
      <form onSubmit={ onSubmit }>
        <Header
          history={ history }
          isActive={ isActive }
          isCloserToSun
          isSaving={ isSaving }
          match={ match }
          noBackButton={ noBackButton }
          saveButtonIsSecondary
          saveButtonValue={ saveButtonValue || 'Create' }
          withSaveButton
        />

        <Choose>
          <When condition={ !isOnboarding }>
            <HomeLink
              disabled={ isActive }
              match={ match }
            />
          </When>

          <Otherwise>
            <div className={ styles.SkipLinkContainer }>
              <Link
                className={ styles.SkipLink }
                onClick={ () => handleSkipLinkClick(match) }
                to={ '/' }
              >
                skip
              </Link>
            </div>
          </Otherwise>
        </Choose>

        <h3>{ heading }</h3>

        <If condition={ isPublicErrors }>
          <FieldErrors errorMessages={ isPublicErrors } />
        </If>

        <div className={ styles.Container }>
          <div className={ styles.Title }>
            <TextField
              autoFocus={ isNewStudySet }
              autoFocusDelayInMs={ titleAutoFocusDelayInMs }
              defaultValue={ title }
              disabled={ isActive }
              errorMessages={ errors && errors.get('title') }
              heading="What will your study set be called?"
              htmlFor="title"
              name="title"
              onChange={ handleTitleChange }
              register={ register }
            />
          </div>

          <div className={ styles.Description }>
            <TextField
              defaultValue={ description }
              disabled={ isUndesignated || isActive }
              errorMessages={ errors && errors.get('description') }
              heading="Description (optional)"
              htmlFor="description"
              name="description"
              onChange={ handleDescriptionChange }
              register={ register }
            />
          </div>
        </div>

        <Options
          isOnFormPage
          isVisibilityStatusDisabled={ isUndesignated || isActive }
          register={ register }
          reversedCopiesAdded={ reversedCopiesAdded }
          reversedCopiesAddedDisabled={ isActive }
          setReversedQuestionsOptionsSelected={ setReversedQuestionsOptionsSelected }
          visibilityStatus={ visibilityStatus }
        />

        <div className={ styles.HorizontalLine }>
          <HorizontalLine />
        </div>

        <div className={ styles.QuestionsTitleAndOptions }>
          <h3 className={ styles.QuestionHeading }>{ questionHeading }</h3>

          <QuestionsOptions
            addCSVQuestionsToStudySet={ addCSVQuestionsToStudySet }
            id={ id }
            isActive={ isActive }
            isQuestionsOptionsContainerOpen={ isQuestionsOptionsContainerOpen }
            openModal={ () => setIsCSVImportModalOpen(true) }
            refreshData={ refreshData }
            setIsQuestionsOptionsContainerOpen={ setIsQuestionsOptionsContainerOpen }
          />
        </div>

        <If condition={ id && questions.size > 100 && !shouldLoadAllQuestions }>
          <ShowMoreContainer
            isActive={ isActive }
            loadAllQuestions={ () => setShouldLoadAllQuestions(true) }
          />
        </If>

        <If condition={ isLoadingAllQuestions }>
          <div className={ styles.PulseLoader }>
            <PulseLoadingIndicator isLoading={ true }/>
          </div>
        </If>

        <If condition={ reversedCopiesAdded !== undefined }>
          <ul>
            <For each="key" index="i" of={ questionKeys }>
              <Question
                deleteAnswerAudio={ deleteAnswerAudio }
                deleteAnswerImage={ deleteAnswerImage }
                deleteQuestionAudio={ deleteQuestionAudio }
                deleteQuestionImage={ deleteQuestionImage }
                errors={ errors }
                handleFieldChange={ handleFieldChange }
                key={ `question-${key}` }
                index={ Number(key) }
                isActive={ isActive }
                isNewStudySet={ isNewStudySet }
                isUndesignated={ isUndesignated }
                question={ questions.get(key) }
                questionKey={ key }
                register={ register }
                removeQuestion={ handleRemoveQuestion }
                reversedCopiesAdded={ reversedCopiesAdded }
                reversedQuestionsOptionsSelected={ reversedQuestionsOptionsSelected }
                setValue={ setValue }
                shouldAutoFocus={ shouldAutoFocusLastQuestion && i === questionKeys.size - 1 }
                updateValueInAutosaved={ updateValueInAutosaved }
                undoRemoveQuestion={ undoRemoveQuestion }
                unregister={ unregister }
              />
            </For>
          </ul>
        </If>

        <div className={ styles.BottomButtons }>
          <div
            className={ cx({
              AddQuestionButton : true,
              shouldHover       : addQuestionButtonShouldHover,
            }) }
          >
            <ButtonDiv
              dataAutomatedTest="study-set-add-question-button"
              handleClick={ addAnotherQuestion }
              hint={ shouldTempHideHint ? null : 'ctrl + enter/cmd + enter' }
              disabled={ isActive }
              iconIsOnLeftSide
              value="Add another question"
              withPlusSign
            />
          </div>

          <div
            className={ cx({
              BottomSubmitButton    : true,
              otherButtonIsHovering : addQuestionButtonShouldHover,
            }) }
          >
            <ButtonSubmit
              dataAutomatedTest="study-set--bottom-submit-button"
              disabled={ isActive }
              isActive={ isActive }
              isSecondary
              type="submit"
              value={ saveButtonValue || 'Create' }
            />
          </div>
        </div>
      </form>

      <If condition={ Boolean(paywallLimitErrorMessage) }>
        <LimitModal
          closeModal={ () => setPaywallLimitErrorMessage(null) }
          errorMessage={ paywallLimitErrorMessage }
        />
      </If>

      <If condition={ isCSVImportModalOpen }>
        <CsvImportModal
          addCSVQuestionsToStudySet={ addCSVQuestionsToStudySet }
          closeModal={ () => setIsCSVImportModalOpen(false) }
          savedCsv={ studySet.get('csv') }
          studySetId={ id }
          updateValueInAutosaved={ updateValueInAutosaved }
        />
      </If>
    </div>
  );
}

StudySet.propTypes = {
  addQuestion             : studySetPropTypes.addQuestion.isRequired,
  bulkAddQuestions        : studySetPropTypes.bulkAddQuestions.isRequired,
  deleteAnswerAudio       : questionPropTypes.deleteAudio,
  deleteAnswerImage       : questionPropTypes.deleteImage,
  deleteQuestionAudio     : questionPropTypes.deleteAudio,
  deleteQuestionImage     : questionPropTypes.deleteImage,
  errors                  : basePropTypes.errors,
  extraClassNames         : PropTypes.string,
  handleSubmit            : basePropTypes.handleSubmit.isRequired,
  heading                 : basePropTypes.heading.isRequired,
  history                 : basePropTypes.history.isRequired,
  isActive                : basePropTypes.isActive.isRequired,
  isOnboarding            : PropTypes.bool,
  isSaving                : basePropTypes.isSaving,
  location                : basePropTypes.location,
  match                   : basePropTypes.match.isRequired,
  noBackButton            : basePropTypes.noBackButton,
  removeQuestion          : studySetPropTypes.removeQuestion.isRequired,
  refreshData             : PropTypes.func.isRequired,
  saveButtonValue         : basePropTypes.saveButtonValue,
  studySet                : studySetPropTypes.autosavedStudySet.isRequired,
  titleAutoFocusDelayInMs : basePropTypes.titleAutoFocusDelayInMs,
  undoRemoveQuestion      : studySetPropTypes.undoRemoveQuestion,
  updateValueInAutosaved  : studySetPropTypes.updateValueInAutosaved,
};

export default memo(StudySet);
