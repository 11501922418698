import * as recharts from 'recharts';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import CustomTooltip from '../Tooltip';

const {
  Bar : RechartsBar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} = recharts;

function Bar(props) {
  const {
    data,
  } = props;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={ data }
        margin={ { top : 20 } }
      >
        <CartesianGrid strokeDasharray="3 3" vertical={ false } />
        <XAxis dataKey="xAxisName" />

        <YAxis type="number" />

        <Tooltip
          content={ <CustomTooltip /> }
          cursor={ { fill : '#e9e9e9' } }
          wrapperStyle={ { outline : 'none' } }
        />

        <RechartsBar
          dataKey="yValue"
          fill="#f4793e"
          maxBarSize={ 60 }
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

Bar.propTypes = {
  data : statsPropTypes.chartData.isRequired,
};

export default Bar;
