import React from 'react';

import LinkOutIcon from 'src/components/icons/LinkOut';

import styles from './styles.module.scss';

function EarlyQuizWarningPara() {
  return (
    <div className={ styles.Root }>
      <span>
        <strong>Note:</strong> you are taking this quiz early.
        That means some of your questions may be easier than usual.
        This will reduce your efficiency.
        Furthermore, we won’t give you a question you’ve already
        been quizzed on today.
        <br />
        But don’t let any of that worry you. You’re studying and that’s what’s important!
      </span>

      <p className={ styles.SubPara }>
        Learn more{ ' ' }

        <a
          href="https://dorothymemoryapp.com/spaced-repetition/"
          rel="noreferrer"
          target="_blank"
        >
          here<LinkOutIcon />
        </a>.
      </p>
    </div>
  );
}

EarlyQuizWarningPara.propTypes = {};

export default EarlyQuizWarningPara;
