import gql from 'graphql-tag';

export default gql`
  mutation submitFeedback(
    $feedback: String!
  ) {
    submitFeedback(
      feedback: $feedback
    ) {
      feedback
    }
  }
`;
