import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import AuthenticationModal from 'src/components/modals/Authentication';
import ButtonDiv from 'src/components/buttons/ButtonDiv';
import Logo from 'src/components/images/logos/FaceOnly';

import LinkItem from '../LinkItem';
import styles from './styles.module.scss';

function Unauthed({ pathname }) {
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [choseSignUp, setChoseSignUp] = useState(false);

  function openSignInModal() {
    setIsSignInModalOpen(true);
  }

  function closeSignInModal() {
    setIsSignInModalOpen(false);
  }

  function signUp() {
    setChoseSignUp(true);
    openSignInModal();
  }

  function logIn() {
    setChoseSignUp(false);
    openSignInModal();
  }

  let unauthedHeading = 'Hi! Let’s log you in.';

  if (choseSignUp) unauthedHeading = 'Hi! Let’s get you signed up.';

  return (
    <>
      <If condition={ isSignInModalOpen }>
        <AuthenticationModal
          unauthedHeading={ unauthedHeading }
          closeModal={ closeSignInModal }
        />
      </If>

      <ul className={ styles.LeftSideContainer }>
        <li className={ styles.Logo }>
          <a
            href="https://dorothymemoryapp.com/"
            target="_blank" rel="noreferrer"
          >
            <Logo dataAutomatedTest="home-logo-desktop-link" isLeft />
          </a>
        </li>

        <li>
          <LinkItem
            isActive={ pathname.includes('/public-study-sets') }
            title="Public study sets"
            to="/public/public-study-sets"
          />
        </li>
      </ul>

      <div
        className={ styles.RightSideContainer }
        data-automated-test="site-wide-links--unauthed-profile"
      >
        <ButtonDiv
          dataAutomatedTest="site-wide-links--unauthed-profile--log-in-link"
          extraClassNames={ styles.LogInButton }
          handleClick={ logIn }
          isSecondary
          value="Log in"
        />

        <ButtonDiv
          dataAutomatedTest="site-wide-links--unauthed-profile--sign-up-link"
          extraClassNames={ styles.SignUpButton }
          handleClick={ signUp }
          value="Sign up"
        />
      </div>
    </>
  );
}

Unauthed.propTypes = {
  isCloserToSun : PropTypes.bool,
  pathname      : basePropTypes.pathname.isRequired,
};

export default Unauthed;
