// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__HomeLink___1XmTS{height:100%;margin:0 0;margin:0 initial;text-align:right;width:64px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/navigation/Header/RightSide/styles.module.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,UAAA,CAAA,gBAAA,CACA,gBAAA,CACA,UAAA","file":"styles.module.scss","sourcesContent":[".HomeLink {\n  height: 100%;\n  margin: 0 initial;\n  text-align: right;\n  width: 64px; }\n"]}]);
// Exports
exports.locals = {
	"HomeLink": "styles-module__HomeLink___1XmTS"
};
module.exports = exports;
