import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.STUDY_SET__ADD_QUESTION_TO_EDIT:
      return state.setIn(['autosaved', payload.id, 'questions', payload.key], Immutable.fromJS({}));

    case actionTypes.STUDY_SET__ADD_QUESTION_TO_NEW:
      return state.setIn(['autosaved', 'new', 'questions', payload.key], Immutable.fromJS({}));

    case actionTypes.STUDY_SET__BULK_ADD_QUESTIONS_TO_NEW:
      return state.withMutations(map => {
        payload.newQuestions.map(newQuestion => map.setIn(
          ['autosaved', 'new', 'questions', newQuestion.key],
          Immutable.fromJS(newQuestion.question),
        ));
      });

    case actionTypes.STUDY_SET__BULK_ADD_QUESTIONS_TO_EDIT:
      return state.withMutations(map => {
        payload.newQuestions.map(newQuestion => map.setIn(
          ['autosaved', payload.id, 'questions', newQuestion.key],
          Immutable.fromJS(newQuestion.question),
        ));
      });

    case actionTypes.STUDY_SET__CLEAR_AUTOSAVED_EDIT:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
        map.deleteIn(['autosaved', payload.id]);
      });

    case actionTypes.STUDY_SET__CLEAR_AUTOSAVED_NEW:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
        map.setIn(['autosaved', 'new'], Immutable.fromJS({
          description         : null,
          isUndesignated      : false,
          questions           : { 0 : { answer : {} } },
          reversedCopiesAdded : false,
          title               : null,
          visibilityStatus    : 'is_private',
        }));
      });

    case actionTypes.STUDY_SET__CLEAR_TEMP_STATE:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
        map.set('isSuccessful', null);
      });

    case actionTypes.STUDY_SET__CREATE_FAILURE:
    case actionTypes.STUDY_SET__DELETE_FAILURE:
    case actionTypes.STUDY_SET__UPDATE_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.set('isSaving', false);
      });

    case actionTypes.STUDY_SET__CREATE_START:
    case actionTypes.STUDY_SET__UPDATE_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
        map.set('isSaving', true);
      });

    case actionTypes.STUDY_SET__DELETE_START:
    case actionTypes.STUDY_SET__GET_ALL_START:
    case actionTypes.STUDY_SET__SHOW_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.STUDY_SET__CREATE_SUCCESS:
    case actionTypes.STUDY_SET__UPDATE_SUCCESS:
      return state.withMutations(map => {
        const { data: studySet } = payload;

        studySet.questions = studySet.originalQuestions;
        delete studySet.originalQuestions;

        map.setIn(['loaded', 'show'], Immutable.fromJS(studySet));
        map.set('isActive', false);
        map.set('isSaving', false);
        map.set('isSuccessful', true);
      });

    case actionTypes.STUDY_SET__DELETE_SUCCESS:
      return state.withMutations(map => {
        map.set('isActive', false);
        map.setIn(['loaded', 'show', Immutable.fromJS({})]);
      });

    case actionTypes.STUDY_SET__GET_ALL_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.setIn(['loaded', 'all'], Immutable.fromJS([]));
      });

    case actionTypes.STUDY_SET__GET_ALL_SUCCESS:
      return state.withMutations(map => {
        const { data: studySets } = payload;

        map.setIn(['loaded', 'all'], Immutable.fromJS(studySets));
        map.set('isActive', false);
      });

    case actionTypes.STUDY_SET__REMOVE_ANSWER_AUDIO:
      return state.deleteIn([
        'autosaved',
        payload.id,
        'questions',
        payload.key,
        'answer',
        'audioUrl',
      ]);

    case actionTypes.STUDY_SET__REMOVE_ANSWER_IMAGE:
      return state.deleteIn([
        'autosaved',
        payload.id,
        'questions',
        payload.key,
        'answer',
        'imageUrl',
      ]);

    case actionTypes.STUDY_SET__REMOVE_QUESTION_AUDIO:
      return state.deleteIn(['autosaved', payload.id, 'questions', payload.key, 'audioUrl']);

    case actionTypes.STUDY_SET__REMOVE_QUESTION_FROM_EDIT:
      return state.setIn(['autosaved', payload.id, 'questions', payload.key, 'isRemoved'], true);

    case actionTypes.STUDY_SET__REMOVE_QUESTION_FROM_NEW:
      return state.deleteIn(['autosaved', 'new', 'questions', payload.key]);

    case actionTypes.STUDY_SET__REMOVE_QUESTION_IMAGE:
      return state.deleteIn(['autosaved', payload.id, 'questions', payload.key, 'imageUrl']);

    case actionTypes.STUDY_SET__SET_AUTOSAVED_EDIT_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.STUDY_SET__SET_AUTOSAVED_EDIT_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.deleteIn(['autosaved', payload.id]);
      });

    case actionTypes.STUDY_SET__SET_AUTOSAVED_EDIT_SUCCESS:
      return state.withMutations(map => {
        const { data: studySet } = payload;

        const questions = studySet.originalQuestions.reduce((acc, question, i) => {
          acc[i] = question;
          return acc;
        }, {});

        map.setIn(['autosaved', studySet.id], Immutable.fromJS(studySet));
        map.setIn(['autosaved', studySet.id, 'questions'], Immutable.fromJS(questions));
        map.set('isActive', false);
      });

    case actionTypes.STUDY_SET__SHOW_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.setIn(['loaded', 'show'], Immutable.fromJS({}));
      });

    case actionTypes.STUDY_SET__SHOW_SUCCESS:
      return state.withMutations(map => {
        const { data: studySet } = payload;

        studySet.questions = studySet.originalQuestions;
        delete studySet.originalQuestions;

        map.setIn(['loaded', 'show'], Immutable.fromJS(studySet));
        map.set('isActive', false);
      });

    case actionTypes.STUDY_SET__START_SAVE:
      return state.set('isActive', true);

    case actionTypes.STUDY_SET__UNDO_REMOVE_QUESTION_FROM_EDIT:
      return state.setIn(['autosaved', payload.id, 'questions', payload.key, 'isRemoved'], false);

    case actionTypes.STUDY_SET__UPDATE_VALUE_IN_AUTOSAVED:
      return state.setIn(payload.keys, Immutable.fromJS(payload.value));

    default:
      return state;
  }
}
