import gql from 'graphql-tag';

export default gql`
  query showPublicStudySet(
    $id: ID!
  ) {
    showPublicStudySet(
      id: $id
    ) {
      description
      id
      originalQuestions {
        answer {
          audioUrl
          body
          id
          imageUrl
        }
        audioUrl
        id
        imageUrl
        title
      }
      title
      visibilityStatus
    }
  }
`;
