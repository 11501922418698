// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___21_OU{align-items:flex-start;display:flex;flex-direction:column;grid-gap:24px;gap:24px;padding:20px}.styles-module__Title___MCjtG{font-size:24px;font-weight:600;line-height:normal;line-height:initial}.styles-module__AlertBanner___2ueBm{box-sizing:border-box;padding:8px 8px 10px;width:100%}.styles-module__AlertBanner___2ueBm>div:first-child{margin-right:16px}.styles-module__AlertBanner___2ueBm svg{height:32px;width:32px}.styles-module__AlertBannerTitle___1j5O9{font-weight:bold}.styles-module__AlertBannerText___2ZwE8{line-height:normal;line-height:initial;margin-top:4px}.styles-module__Form___2Dc1Z{width:100%}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/onboarding/NewStudySet/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,sBAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,YAAA,CAEF,8BACE,cAAA,CACA,eAAA,CACA,kBAAA,CAAA,mBAAA,CAEF,oCACE,qBAAA,CACA,oBAAA,CACA,UAAA,CACA,oDACE,iBAAA,CACF,wCACE,WAAA,CACA,UAAA,CAEJ,yCACE,gBAAA,CAEF,wCACE,kBAAA,CAAA,mBAAA,CACA,cAAA,CAEF,6BACE,UAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 20px; }\n\n.Title {\n  font-size: 24px;\n  font-weight: 600;\n  line-height: initial; }\n\n.AlertBanner {\n  box-sizing: border-box;\n  padding: 8px 8px 10px;\n  width: 100%; }\n  .AlertBanner > div:first-child {\n    margin-right: 16px; }\n  .AlertBanner svg {\n    height: 32px;\n    width: 32px; }\n\n.AlertBannerTitle {\n  font-weight: bold; }\n\n.AlertBannerText {\n  line-height: initial;\n  margin-top: 4px; }\n\n.Form {\n  width: 100%; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___21_OU",
	"Title": "styles-module__Title___MCjtG",
	"AlertBanner": "styles-module__AlertBanner___2ueBm",
	"AlertBannerTitle": "styles-module__AlertBannerTitle___1j5O9",
	"AlertBannerText": "styles-module__AlertBannerText___2ZwE8",
	"Form": "styles-module__Form___2Dc1Z"
};
module.exports = exports;
