import loadDataUrlAndCall from 'src/shared/loadDataUrlAndCall';

async function readAudioFile(fileList) {
  if (!fileList || !fileList.length) return null;

  const { audioDurationInSeconds, io } = await loadDataUrlAndCall(fileList);
  const { name, size, type } = fileList[0];

  return { audioDurationInSeconds, io, name, size, type };
}

async function readImageFile(fileList) {
  if (!fileList || !fileList.length) return null;

  const { io } = await loadDataUrlAndCall(fileList);
  const { name, size, type } = fileList[0];

  return { io, name, size, type };
}

function getAudioFile(audioFile, values) {
  if (audioFile) return audioFile;

  const autosaved = values.autosavedAudio;

  if (autosaved) return autosaved;

  return null;
}

function getImageFile(imageFile, values) {
  if (imageFile) return imageFile;

  const autosaved = values.autosavedImage;

  if (autosaved) return autosaved;

  return null;
}

async function buildMediaValues(values) {
  const answerAudioValue = await readAudioFile(values.answer.audioFile);
  const questionAudioValue = await readAudioFile(values.audioFile);

  const answerImageFile = await readImageFile(values.answer.imageFile);
  const questionImageFile = await readImageFile(values.imageFile);

  const { answer } = values;

  return {
    audioFile : getAudioFile(questionAudioValue, values),
    imageFile : getImageFile(questionImageFile, values),

    answer : {
      audioFile : getAudioFile(answerAudioValue, answer),
      imageFile : getImageFile(answerImageFile, answer),
    },
  };
}

async function buildValuesFromElementsWithFiles(values) {
  const mediaValues = await buildMediaValues(values);

  const { answer } = values;

  delete values.autosavedAudio;
  delete values.autosavedImage;
  delete answer.autosavedAudio;
  delete answer.autosavedImage;

  return {
    ...values,
    ...mediaValues,

    answer : {
      ...answer,
      ...mediaValues.answer,
    },
  };
}

export default buildValuesFromElementsWithFiles;
