import React from 'react';

import styles from './styles.module.scss';

function Info() {
  return (
    <svg
      className={ styles.Root }
      width="700pt"
      height="700pt"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m350 420c-10.5 0-17.5-7-17.5-17.5v-157.5c0-10.5 7-17.5 17.5-17.5s17.5 7 17.5 17.5v157.5c0 10.5-7 17.5-17.5 17.5zm0-227.5c-10.5 0-17.5-7-17.5-17.5v-35c0-10.5 7-17.5 17.5-17.5s17.5 7 17.5 17.5v35c0 10.5-7 17.5-17.5 17.5z"/>
        <path d="m350 542.5c-145.25 0-262.5-117.25-262.5-262.5s117.25-262.5 262.5-262.5 262.5 117.25 262.5 262.5-117.25 262.5-262.5 262.5zm0-490c-126 0-227.5 101.5-227.5 227.5s101.5 227.5 227.5 227.5 227.5-101.5 227.5-227.5-101.5-227.5-227.5-227.5z"/>
      </g>
    </svg>
  );
}

export default Info;
