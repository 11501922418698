import React from 'react';

function CommentIcon() {
  return (
    <svg
      width="700pt"
      height="700pt"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m566.72 71.121h-433.44c-12.879 0-23.52 10.641-23.52 23.52v267.68c0 12.879 10.641 23.52 23.52 23.52h112c7.2812 0 14 3.3594 18.48 8.9609l67.762 85.121c9.5195 11.762 27.441 11.762 36.398 0l67.762-85.121c4.4805-5.6016 11.199-8.9609 18.48-8.9609h112c12.879 0 23.52-10.641 23.52-23.52l-0.003906-267.68c0.55859-12.879-10.078-23.52-22.961-23.52zm-314.16 197.68c-19.039 0-34.719-15.68-34.719-34.719 0-19.039 15.68-34.719 34.719-34.719 19.039 0 34.719 15.68 34.719 34.719-0.55859 19.598-15.676 34.719-34.719 34.719zm97.441 0c-19.039 0-34.719-15.68-34.719-34.719 0-19.039 15.68-34.719 34.719-34.719s34.719 15.68 34.719 34.719c0 19.598-15.68 34.719-34.719 34.719zm96.879 0c-19.039 0-34.719-15.68-34.719-34.719 0-19.039 15.68-34.719 34.719-34.719 19.039 0 34.719 15.68 34.719 34.719 0.003906 19.598-15.676 34.719-34.719 34.719z"/>
      </g>
    </svg>
  );
}

export default CommentIcon;
