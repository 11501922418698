// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1_h_F>div{padding:0}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Stats/Card/styles.module.scss"],"names":[],"mappings":"AAAA,iCACE,SAAA","file":"styles.module.scss","sourcesContent":[".Root > div {\n  padding: 0; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1_h_F"
};
module.exports = exports;
