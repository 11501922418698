import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';

export default function handleSkipLinkClick(match) {
  if (match) {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track(`Skip link clicked on ${match.path}`);
  }
}
