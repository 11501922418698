import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function FooterItem(props) {
  const {
    children,
    dataAutomatedTest,
    extraClassNames,
    handleClick,
    isActive,
    title,
    to,
  } = props;

  function getLinkClassNames() {
    return cx({
      Link : true,
      isActive,
    });
  }

  function getTitleClassNames() {
    return cx({
      Title : true,
      isActive,
    });
  }

  return (
    <li className={ `${styles.Root} ${extraClassNames}` }>
      <Choose>
        <When condition={ handleClick && !title }>
          <div
            className={ styles.NoTitleLink }
            data-automated-test={ dataAutomatedTest }
            onClick={ handleClick }
          >
            { children }
          </div>
        </When>

        <When condition={ handleClick }>
          <div
            className={ getLinkClassNames() }
            data-automated-test={ dataAutomatedTest }
            onClick={ handleClick }
          >
            { children }
            <p className={ getTitleClassNames() } >{ title }</p>
          </div>
        </When>

        <When condition={ to && to.includes('https') }>
          <a
            className={ getLinkClassNames() }
            data-automated-test={ dataAutomatedTest }
            href={ to }
          >
            { children }
            <p className={ getTitleClassNames() } >{ title }</p>
          </a>
        </When>

        <Otherwise>
          <Link
            className={ getLinkClassNames() }
            data-automated-test={ dataAutomatedTest }
            to={ to }
          >
            { children }
            <p className={ getTitleClassNames() } >{ title }</p>
          </Link>

          <If condition={ isActive }>
            <div className={ styles.IsActiveBottomBar } />
          </If>
        </Otherwise>
      </Choose>
    </li>
  );
}

FooterItem.propTypes = {
  children          : basePropTypes.children,
  dataAutomatedTest : basePropTypes.dataAutomatedTest,
  extraClassNames   : PropTypes.string,
  handleClick       : PropTypes.func,
  isActive          : basePropTypes.isActive,
  title             : basePropTypes.title,
  to                : basePropTypes.to,
};

export default FooterItem;
