import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as generalActionCreators from 'src/actions/general';
import * as profileActionCreators from 'src/actions/profile';
import * as profilePropTypes from 'src/constants/propTypes/profile';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as pushNotificationsActions from 'src/actions/pushNotifications';
import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as quizzesActions from 'src/actions/quizzes';
import * as statsActions from 'src/actions/stats';

import analytics from 'src/shared/analytics';
import Dashboard from 'src/components/Dashboard';
import FeedbackPropsContext from 'src/contexts/FeedbackProps';
import requestPushPermission from 'src/shared/serviceWorker/requestPushPermission';
import submitFeedback from 'src/containers/shared/submitFeedback';

class FunctionalDashboard extends PureComponent {
  static propTypes = {
    actions              : basePropTypes.actions.isRequired,
    errors               : basePropTypes.errors,
    generalIsActive      : PropTypes.bool,
    notificationSettings : profilePropTypes.notificationSettings.isRequired,
    quiz                 : quizPropTypes.quiz,
    userId               : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  state = {
    shouldDisplayThankYouMessage : false,
  }

  componentDidMount() {
    const {
      actions: {
        general,
        profile,
        publicStudySets,
        stats,
      },
      quiz,
      userId,
    } = this.props;

    general.clearTempState();
    profile.show();
    stats.fetchDashboardReport();

    if (userId) analytics.identify(userId);
    if (!quiz) publicStudySets.getAllFeatured();

    this.fetchNewestQuiz();
  }

  fetchNewestQuiz = () => {
    const { actions: { quizzes } } = this.props;

    let promisedData;

    return quizzes
      .fetchNewest()
      .then(data => {
        promisedData = data;

        // TODO: temp
        // const { payload : { quiz : { completedAt, id, isSampleQuiz } } } = data;
        const { payload : { quiz : { id } } } = data;

        quizzes.addToBeAnsweredQuestions(id);

        // TODO: temp
        // return this.requestPushPermission(completedAt, isSampleQuiz);
      })
      .catch(err => {
        if (
          ['denied', 'No Push API Support!'].includes(err.message)
        ) return;

        Sentry.captureException(err, scope => {
          const string = JSON.stringify(Object.getOwnPropertyNames(promisedData.payload));
          scope.setTag('payload keys', string);
          scope.setTag('payload type', promisedData.type);
          return scope;
        });
      });
  }

  requestPushPermission = (completedAt, isSampleQuiz) => {
    const {
      actions: { pushNotifications },
      notificationSettings,
    } = this.props;

    const pushSetting = notificationSettings &&
      notificationSettings.find(ns => ns.get('medium') === 'push');

    if (pushSetting && !pushSetting.get('optedOut')) return;
    if (isSampleQuiz && !completedAt) return;

    return requestPushPermission(pushNotifications.createPushNotification);
  }

  buildLetUsKnowPropsContext = () => {
    const { errors, generalIsActive } = this.props;

    return {
      errors,
      isActive                     : generalIsActive,
      resetFeedbackModal           : () => this.setState({ shouldDisplayThankYouMessage : false }),
      shouldDisplayThankYouMessage : this.state.shouldDisplayThankYouMessage,
      submitFeedback               : values => submitFeedback(values, this),
    };
  }

  render() {
    return (
      <FeedbackPropsContext.Provider value={ this.buildLetUsKnowPropsContext() }>
        <Dashboard { ...this.props } />
      </FeedbackPropsContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  const {
    authentication,
    general,
    onboarding,
    profile,
    publicStudySets,
    quizzes,
    stats,
  } = state;

  return {
    chosenStudySets          : profile.get('studySets'),
    errors                   : general.get('errors'),
    expiry                   : authentication.get('expiry'),
    featuredPublicStudySets  : publicStudySets.getIn(['loaded', 'featured']),
    generalIsActive          : general.get('isActive'),
    isActive                 : quizzes.get('isActive'),
    isBannerClosed           : general.get('isBannerClosed'),
    isLoadingStats           : stats.get('isActive'),
    maxQuestionAmountPerQuiz : profile.get('maxQuestionAmountPerQuiz'),
    name                     : authentication.get('name'),
    notificationSettings     : profile.get('notificationSettings'),
    onboardingStudySetId     : onboarding.getIn(['studySet', 'id']),
    statsDashboardReport     : stats.get('dashboardReport'),
    quiz                     : quizzes.getIn(['loaded', 'newest']),
    userId                   : authentication.get('id'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      general           : bindActionCreators(generalActionCreators, dispatch),
      profile           : bindActionCreators(profileActionCreators, dispatch),
      publicStudySets   : bindActionCreators(publicStudySetsActions, dispatch),
      pushNotifications : bindActionCreators(pushNotificationsActions, dispatch),
      quizzes           : bindActionCreators(quizzesActions, dispatch),
      stats             : bindActionCreators(statsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalDashboard);
