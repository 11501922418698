import { PropTypes } from 'prop-types';
import React from 'react';

import ButtonDiv from 'src/components/buttons/ButtonDiv';
import classNames from 'src/components/shared/classNames';
import FaceLogoBlue from 'src/assets/face-logo--blue.svg';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Plan(props) {
  const {
    buttonIsPrimary,
    children,
    cost,
    costSubTitle,
    dataAutomatedTest,
    handleUpgrade,
    isActive,
    name,
    needsGooglePlayBilling,
    shouldHideButton,
  } = props;

  return (
    <div
      className={ styles.Root }
      data-automated-test={ dataAutomatedTest }
    >
      <h3
        className={ styles.Name }
        data-automated-test="profile--account-plan--name"
      >{ name }</h3>

      <div className={ styles.Container }>
        <div className={ styles.ContainerContent }>
          <p className={ styles.Cost }>{ cost }</p>
          <p className={ styles.SubTitle }>{ costSubTitle }</p>

          <div className={ styles.Logo }>
            <img src={ FaceLogoBlue } />
          </div>

          { children }
        </div>

        <If condition={ !shouldHideButton }>
          <ButtonDiv
            dataAutomatedTest="profile--account-plans--upgrade-button"
            disabled={ isActive }
            extraClassNames={ cx({ Button : !isActive, buttonIsPrimary }) }
            handleClick={ handleUpgrade }
            shouldNotRebuildClick={ needsGooglePlayBilling }
            value="Upgrade"
          />
        </If>
      </div>
    </div>
  );
}

Plan.propTypes = {
  buttonIsPrimary   : PropTypes.bool,
  children          : PropTypes.node.isRequired,
  cost              : PropTypes.string.isRequired,
  costSubTitle      : PropTypes.string.isRequired,
  dataAutomatedTest : PropTypes.string,
  handleUpgrade     : PropTypes.func,
  isActive          : PropTypes.bool,
  name              : PropTypes.string.isRequired,
};

export default Plan;
