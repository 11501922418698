import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as statsActions from 'src/actions/stats';
import * as statsPropTypes from 'src/constants/propTypes/stats';

import analytics from 'src/shared/analytics';
import getUrlParameter from 'src/shared/getUrlParameter';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import Stats from 'src/components/Stats';

class FunctionalStats extends PureComponent {
  static propTypes = {
    actions         : basePropTypes.actions.isRequired,
    history         : basePropTypes.history.isRequired,
    isActive        : PropTypes.bool,
    location        : basePropTypes.location.isRequired,
    statsFullReport : statsPropTypes.fullReport,
  };

  state = {
    isLoading : true,
  }

  componentDidMount() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Stats page visited');

    this.fetchFullReport();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchFullReport();
    }
  }

  fetchFullReport = () => {
    let params = { timeFrame : 'last_7_days' };

    const urlParameter = getUrlParameter('time_frame');

    if (urlParameter) params = { timeFrame : urlParameter };

    this.props.actions.stats.fetchFullReport(params).then(() => {
      this.setState({ isLoading : false });
    });
  }

  render() {
    const {
      history,
      isActive,
      location,
      statsFullReport,
    } = this.props;

    return (
      <Stats
        fullReport={ statsFullReport }
        history={ history }
        isActive={ isActive || this.state.isLoading }
        location={ location }
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    stats,
  } = state;

  return {
    isActive        : stats.get('isActive'),
    statsFullReport : stats.get('fullReport'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      stats : bindActionCreators(statsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalStats);
