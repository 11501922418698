// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Para___uaMa9{margin-top:12px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/modals/QuestionLimitModal/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA","file":"styles.module.scss","sourcesContent":[".Para {\n  margin-top: 12px; }\n"]}]);
// Exports
exports.locals = {
	"Para": "styles-module__Para___uaMa9"
};
module.exports = exports;
