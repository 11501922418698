import gql from 'graphql-tag';

export default gql`
  fragment dataPointFields on StatsFullReportDataPointType {
    tooltip {
      label
      value
    }
    xAxisName
    yValue
  }
`;
