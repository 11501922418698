function buildStudySetQuestionsFromCSV(csvQuestions, questions) {
  let startingSize = questions.size;

  if (
    startingSize === 1 &&
    !questions.getIn(['0', 'title']) &&
    !questions.getIn(['0', 'answer', 'body'])
  ) {
    startingSize = 0;
  }

  return csvQuestions.map((question, i) => ({
    key : (startingSize + i).toString(),
    question,
  }));
}

export default buildStudySetQuestionsFromCSV;
