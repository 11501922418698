import React from 'react';
import { Redirect } from 'react-router-dom';

import * as basePropTypes from 'src/constants/propTypes/base';

import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';

import styles from './styles.module.scss';

function SignInViaExternalSource(props) {
  const {
    isLoading,
    pathFrom,
    pathTo,
  } = props;

  return (
    <Choose>
      <When condition={ isLoading }>
        <div className={ styles.Root }>
          <div className={ styles.PulseLoader }>
            <PulseLoadingIndicator isLoading={ isLoading }/>
          </div>
        </div>
      </When>

      <Otherwise>
        <Redirect
          to={ {
            pathname : pathTo,
            state    : { pathFrom },
          } }
        />
      </Otherwise>
    </Choose>
  );
}

SignInViaExternalSource.propTypes = {
  isLoading : basePropTypes.isLoading,
  pathFrom  : basePropTypes.pathFrom,
  pathTo    : basePropTypes.pathTo,
};

export default SignInViaExternalSource;
