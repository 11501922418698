import acknowledgeGooglePlayPurchase from 'src/graphql/mutations/payments/acknowledgeGooglePlayPurchase';
import startStripeCheckout from 'src/graphql/mutations/payments/startStripeCheckout';

import post from './shared/post';

export default {
  acknowledgeGooglePlayPurchase(values) {
    return post(values, acknowledgeGooglePlayPurchase);
  },

  startStripeCheckout(values) {
    return post(values, startStripeCheckout);
  },
};
