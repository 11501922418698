import moment from 'moment';

import isQuizReady from 'src/shared/isQuizReady';

// This will only happen if the user has chosen study set(s)
// to study in their preferences and those study set(s) do not
// actually have any questions.
function getQuestionsAvailable(chosenStudySets) {
  return chosenStudySets && chosenStudySets.reduce((acc, css) => {
    const count = css.get('questionsCount');
    const countCorrected = count && count > 0 ? count : 0;
    return countCorrected + acc;
  }, 0);
}

function getSampleQuiz(prevQuiz, quiz) {
  if (quiz.get('isSampleQuiz')) return quiz;
  if (!prevQuiz) return null;
  if (prevQuiz.get('isSampleQuiz')) return prevQuiz;

  return null;
}

export default function (quiz, chosenStudySets, defaultDueDate) {
  const dueDate = defaultDueDate ||
    (quiz.get('dueDate') && new Date(quiz.get('dueDate').replace(/-/g, '/')));

  const quizCompleted = Boolean(quiz.get('completedAt'));
  const quizIsReady = isQuizReady(quiz, dueDate);
  const quizStarted = quiz.get('started');

  const prevQuiz = quiz.get('previousQuiz');
  const prevQuizCompleted = prevQuiz && Boolean(prevQuiz.get('completedAt'));

  const prevQuizWasRecentlyCompleted = prevQuizCompleted &&
    moment(prevQuiz.get('completedAt')) > moment().startOf('day');

  const questionsAvailable = getQuestionsAvailable(chosenStudySets);
  const quizIsDueAtLeastTwoDaysFromNow = moment(dueDate).subtract(2, 'days') > new Date();

  const noQuestionsInQuizzes = chosenStudySets &&
    chosenStudySets.size > 0 &&
    questionsAvailable === 0;

  const isOnFirstQuizAndNotStarted = !prevQuiz && !quizCompleted;
  const isSampleQuiz = quiz.get('isSampleQuiz');

  const sampleQuiz = getSampleQuiz(prevQuiz, quiz);
  const completedSampleQuiz = sampleQuiz && Boolean(sampleQuiz.get('completedAt'));
  const completedSampleQuizToday = completedSampleQuiz &&
    moment(sampleQuiz.get('completedAt')) > moment().startOf('day');

  return {
    completedSampleQuizToday,
    isOnFirstQuizAndNotStarted,
    isSampleQuiz,
    noQuestionsInQuizzes,
    prevQuizWasRecentlyCompleted,
    quizCompleted,
    quizStarted,
    quizIsDueAtLeastTwoDaysFromNow,
    quizIsDueTodayAndNotStarted    : !quizCompleted && quizIsReady && !quizStarted,
    quizIsDueTodayAndStarted       : !quizCompleted && quizIsReady && quizStarted,
    quizIsDueInFutureAndNotStarted : !quizCompleted && !quizIsReady && !quizStarted,
    quizIsDueInFutureAndStarted    : !quizCompleted && !quizIsReady && quizStarted,
  };
}
