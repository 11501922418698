function navigateOrWait(props, pathname = '/', state = null) {
  const { errors, history } = props;

  if (!errors) {
    return history.push({
      pathname,
      state,
    });
  }

  return null;
}

export default navigateOrWait;
