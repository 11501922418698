// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3vx1w textarea{min-height:100px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/forms/Feedback/styles.module.scss"],"names":[],"mappings":"AAAA,sCACE,gBAAA","file":"styles.module.scss","sourcesContent":[".Root textarea {\n  min-height: 100px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3vx1w"
};
module.exports = exports;
