import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as statsPropTypes from 'src/constants/propTypes/stats';

import QuizCongratulations from 'src/components/quiz/Congratulations';
import QuizCongratulationsContext from 'src/contexts/QuizCongratulations';

class FunctionalQuizCongratulations extends PureComponent {
  static propTypes = {
    dashboardReport : statsPropTypes.dashboardReport.isRequired,
    quiz            : quizPropTypes.quiz.isRequired,
  };

  buildQuizCongratulationsContext() {
    const { dashboardReport, quiz } = this.props;

    return {
      dashboardReport,
      studySetIdFromQuiz : quiz.get('studySetId'),
    };
  }

  render() {
    return (
      <QuizCongratulationsContext.Provider value={ this.buildQuizCongratulationsContext() }>
        <QuizCongratulations { ...this.props } />
      </QuizCongratulationsContext.Provider>
    );
  }
}

function mapStateToProps({ quizzes, stats }, { match : { params : { id } } }) {
  return {
    dashboardReport           : stats.get('dashboardReport'),
    isActive                  : quizzes.get('isActive'),
    questionsToBeAnsweredSize : quizzes.get('questionsToBeAnswered').size,
    quiz                      : quizzes.getIn(['loaded', id]),
  };
}

export default connect(mapStateToProps, null)(FunctionalQuizCongratulations);
