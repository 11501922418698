import gql from 'graphql-tag';

import questionFields from 'src/graphql/fragments/questionFields';

export default gql`
  mutation updateQuestion(
    $answer: AnswerInput!
    $audioFile: UploadInput
    $id: ID!
    $imageFile: UploadInput
    $studySetId: ID
    $title: String
  ) {
    updateQuestion(
      answer: $answer
      audioFile: $audioFile
      id: $id
      imageFile: $imageFile
      studySetId: $studySetId
      title: $title
    ) {
      ...questionFields
    }
  }
  ${questionFields}
`;
