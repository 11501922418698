import React, { useEffect } from 'react';

import AlertBanner from 'src/components/AlertBanner';
import analytics from 'src/shared/analytics';
import AuthenticationForm from 'src/components/forms/Authentication';
import GoogleAuth from 'src/components/forms/GoogleAuth';
import OrLinesContainer from 'src/components/OrLinesContainer';

import Shared from './Shared';
import styles from './styles.module.scss';

const shared = new Shared();

function SignIn(props) {
  const {
    errors,
    handleSubmit,
    isActive,
    justSignedOut,
  } = props;

  shared.setClassProperties({ props });

  useEffect(() => {
    analytics.track('Sign in page visit');
  }, []);

  const alertBannerHeadingText = shared.chooseAlertBannerHeadingText();

  function isOnWebBrowserAndOnAndroidPhone() {
    if (typeof navigator !== 'undefined' && navigator.userAgent) {
      return /Android/i.test(navigator.userAgent);
    }

    return false;
  }

  return (
    <div
      className={ styles.Root }
      data-automated-test="authentication--sign-in"
    >
      <div className={ styles.Container }>
        <If condition={ alertBannerHeadingText }>
          <AlertBanner isInfo>
            <p className={ styles.AlertBannerInfoPara }>
              { alertBannerHeadingText }
            </p>

            <If condition={ !justSignedOut }>
              <p className={ styles.ContactUsPara }>
                If you need help, please contact us at{ ' ' }
                <a href="mailto:admin@dorothymemoryapp.com">admin@dorothymemoryapp.com</a>{ ' ' }
                and we’d be happy to assist.
              </p>
            </If>
          </AlertBanner>
        </If>

        <h2 className={ styles.Title }>
          Log in or sign up
        </h2>

        <If condition={ isOnWebBrowserAndOnAndroidPhone() }>
          <div className={ styles.PlayStoreBadgeContainer }>
            <a href='https://play.google.com/store/apps/details?id=com.dorothymemoryapp.my.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'> { /* eslint-disable-line max-len */ }
              <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/> { /* eslint-disable-line max-len */ }
            </a>

            <OrLinesContainer noMargins />
          </div>
        </If>

        <GoogleAuth isActive={ isActive } />

        <OrLinesContainer noMargins />

        <div className={ styles.EmailSection }>
          <p className={ styles.ParagraphContent }>
            Enter your email to receive a one time password.
          </p>

          <AuthenticationForm
            errors={ errors }
            handleSubmit={ handleSubmit }
            isActive={ isActive }
          />
        </div>
      </div>
    </div>
  );
}

SignIn.propTypes = shared.propTypes;

export default SignIn;
