import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import BaseModal from 'src/components/modals/Base';
import HorizontalLine from 'src/components/HorizontalLine';

import styles from './styles.module.scss';
import SubmitButton from './SubmitButton';

function ConfirmationModal(props) {
  const {
    children,
    closeModal,
    isActive,
    isPositiveAction,
    submitAction,
    submitActionName,
    title,
  } = props;

  return (
    <BaseModal
      closeModal={ closeModal }
      dataAutomatedTest="confirmation-modal"
      extraClasses={ { container : styles.Container } }
    >
      <h3 className={ styles.Title }>{ title || 'Are you sure?' }</h3>

      <p className={ styles.Para }>
        { children }
      </p>

      <div className={ styles.Buttons }>
        <div className={ styles.HorizontalLine }><HorizontalLine /></div>

        <SubmitButton
          isActive={ isActive }
          isPositiveAction={ isPositiveAction }
          submitAction={ submitAction }
          submitActionName={ submitActionName }
        />

        <div className={ styles.HorizontalLine }><HorizontalLine /></div>

        <div
          className={ styles.CancelButton }
          onClick={ closeModal }
        >
          <p>Cancel</p>
        </div>
      </div>
    </BaseModal>
  );
}

ConfirmationModal.propTypes = {
  children         : basePropTypes.children.isRequired,
  closeModal       : basePropTypes.closeModal.isRequired,
  isActive         : basePropTypes.isActive,
  isPositiveAction : basePropTypes.isPositiveAction,
  submitAction     : basePropTypes.submitAction.isRequired,
  submitActionName : basePropTypes.submitActionName.isRequired,
  title            : basePropTypes.title,
};

export default ConfirmationModal;
