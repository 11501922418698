import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.PUBLIC_STUDY_SET__ADD_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
      });

    case actionTypes.PUBLIC_STUDY_SET__ADD_START:
    case actionTypes.PUBLIC_STUDY_SET__GET_ALL_START:
    case actionTypes.PUBLIC_STUDY_SET__GET_ALL_FEATURED_START:
    case actionTypes.PUBLIC_STUDY_SET__SHOW_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
        map.set('isSuccessful', false);
      });

    case actionTypes.PUBLIC_STUDY_SET__ADD_SUCCESS:
      return state.withMutations(map => {
        map.set('isActive', false);
        map.set('isSuccessful', true);
      });

    case actionTypes.PUBLIC_STUDY_SET__CLEAR_TEMP_STATE:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
        map.set('isSuccessful', null);
      });

    case actionTypes.PUBLIC_STUDY_SET__GET_ALL_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.setIn(['loaded', 'all'], Immutable.fromJS([]));
      });

    case actionTypes.PUBLIC_STUDY_SET__GET_ALL_SUCCESS:
      return state.withMutations(map => {
        const { data: publicStudySets } = payload;

        map.setIn(['loaded', 'all'], Immutable.fromJS(publicStudySets));
        map.set('isActive', false);
      });

    case actionTypes.PUBLIC_STUDY_SET__GET_ALL_FEATURED_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.setIn(['loaded', 'featured'], Immutable.fromJS([]));
      });

    case actionTypes.PUBLIC_STUDY_SET__GET_ALL_FEATURED_SUCCESS:
      return state.withMutations(map => {
        const { data: publicStudySets } = payload;

        map.setIn(['loaded', 'featured'], Immutable.fromJS(publicStudySets));
        map.set('isActive', false);
      });

    case actionTypes.PUBLIC_STUDY_SET__SHOW_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.setIn(['loaded', 'show'], Immutable.fromJS({}));
      });

    case actionTypes.PUBLIC_STUDY_SET__SHOW_SUCCESS:
      return state.withMutations(map => {
        const { data: publicStudySet } = payload;

        publicStudySet.questions = publicStudySet.originalQuestions;
        delete publicStudySet.originalQuestions;

        map.setIn(['loaded', 'show'], Immutable.fromJS(publicStudySet));
        map.set('isActive', false);
      });

    default:
      return state;
  }
}
