import { PropTypes } from 'prop-types';
import React from 'react';

import * as quizPropTypes from 'src/constants/propTypes/quiz';

import analytics from 'src/shared/analytics';
import ButtonDiv from 'src/components/buttons/ButtonDiv';
import ButtonLink from 'src/components/buttons/ButtonLink';
import classNames from 'src/components/shared/classNames';
import HorizontalLine from 'src/components/HorizontalLine';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function QuizOptions(props) {
  const {
    newestQuiz,
    startFlashStudySession,
  } = props;

  function handleTakeSampleQuizClick() {
    analytics.identify(newestQuiz.get('userId'));
    analytics.track('Take sample quiz clicked', { quizId : newestQuiz.get('id') });
  }

  const shouldShowSampleQuiz = newestQuiz &&
    newestQuiz.get('isSampleQuiz') &&
    !newestQuiz.get('completedAt');

  return (
    <div>
      <Choose>
        <When condition={ shouldShowSampleQuiz }>
          <div className={ styles.SampleQuizLink }>
            <ButtonLink
              handleClick={ handleTakeSampleQuizClick }
              text="Take a sample quiz →"
              to={ `/quiz/${newestQuiz.get('id')}/explanation?purpose=sample` }
            />
          </div>
        </When>

        <Otherwise>
          <ButtonDiv
            dataAutomatedTest="show-study-set--flash-study-session"
            extraClassNames={ cx({
              FlashStudySessionButton                 : true,
              FlashStudySessionButtonExtraSpecificity : true,
            }) }
            handleClick={ startFlashStudySession }
            iconIsOnLeftSide
            isTertiary
            value="Flash study session →"
            withBolt
          />
        </Otherwise>
      </Choose>

      <div className={ styles.HorizontalLine }>
        <HorizontalLine />
      </div>
    </div>
  );
}

QuizOptions.propTypes = {
  newestQuiz             : quizPropTypes.quiz.isRequired,
  startFlashStudySession : PropTypes.func,
};

export default QuizOptions;
