import gql from 'graphql-tag';

export default gql`
  mutation createPushNotification(
    $auth: String!
    $endpoint: String!
    $p256dh: String!
  ) {
    createPushNotification(
      auth: $auth
      endpoint: $endpoint
      p256dh: $p256dh
    ) {
      id
    }
  }
`;
