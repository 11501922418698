import { PropTypes } from 'prop-types';
import React from 'react';

import TextBadge from 'src/components/TextBadge';
import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function TextBadges(props) {
  const {
    hasDescription,
    isPublic,
    isUnlisted,
    reversedCopiesAdded,
  } = props;

  return (
    <div
      className={ cx({
        Root : true,
        hasDescription,
      }) }
    >
      <If condition={ isPublic }>
        <div className={ styles.TextBadge }>
          <TextBadge
            icon="public"
            text="Publicly available"
          />
        </div>
      </If>

      <If condition={ isUnlisted }>
        <div className={ styles.TextBadge }>
          <TextBadge
            icon="unlisted"
            text="Unlisted"
          />
        </div>
      </If>

      <If condition={ reversedCopiesAdded }>
        <div className={ styles.TextBadge }>
          <TextBadge
            icon="reversedCopies"
            text="Reversed copies added"
          />
        </div>
      </If>
    </div>
  );
}

TextBadges.propTypes = {
  hasDescription      : PropTypes.bool,
  isPublic            : PropTypes.bool,
  isUnlisted          : PropTypes.bool,
  reversedCopiesAdded : PropTypes.bool,
};

export default TextBadges;
