import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as authenticationActions from 'src/actions/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

class FunctionalSignOut extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
  }

  componentDidMount() {
    this.props.actions.authentication.signOut();
  }

  render() {
    return <Redirect to={ { pathname : '/auth/welcome' } } />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication : bindActionCreators(authenticationActions, dispatch),
    },
  };
}

export default connect(null, mapDispatchToProps)(FunctionalSignOut);
