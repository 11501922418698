// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__DeleteAllTitle___Jin-w,.styles-module__DeleteTitle___6dJef{margin:0 auto;text-align:center;width:-moz-fit-content;width:fit-content}.styles-module__DeleteAllTitle___Jin-w:hover,.styles-module__DeleteTitle___6dJef:hover{cursor:pointer}.styles-module__SelectDeleteButton___2orxV>p{color:#c00;opacity:93%}.styles-module__SelectDeleteAllButton___r7mkN>p{color:maroon;opacity:80%}.styles-module__Container___1Q9t2{left:16px;padding:0;width:calc(100% - 32px)}@media screen and (min-width: 568px){.styles-module__Container___1Q9t2{left:calc(50% - 150px);width:300px}}.styles-module__CancelButton___3xXaS{padding:18px 0}.styles-module__CancelButton___3xXaS:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/studySets/Show/DeletionModalAndLinks/styles.module.scss"],"names":[],"mappings":"AAAA,2EAEE,aAAA,CACA,iBAAA,CACA,sBAAA,CAAA,iBAAA,CACA,uFAEE,cAAA,CAEJ,6CACE,UAAA,CACA,WAAA,CAEF,gDACE,YAAA,CACA,WAAA,CAEF,kCACE,SAAA,CACA,SAAA,CACA,uBAAA,CACA,qCACE,kCACE,sBAAA,CACA,WAAA,CAAA,CAEN,qCACE,cAAA,CACA,2CACE,cAAA","file":"styles.module.scss","sourcesContent":[".DeleteAllTitle,\n.DeleteTitle {\n  margin: 0 auto;\n  text-align: center;\n  width: fit-content; }\n  .DeleteAllTitle:hover,\n  .DeleteTitle:hover {\n    cursor: pointer; }\n\n.SelectDeleteButton > p {\n  color: #cc0000;\n  opacity: 93%; }\n\n.SelectDeleteAllButton > p {\n  color: #800000;\n  opacity: 80%; }\n\n.Container {\n  left: 16px;\n  padding: 0;\n  width: calc(100% - 32px); }\n  @media screen and (min-width: 568px) {\n    .Container {\n      left: calc(50% - 150px);\n      width: 300px; } }\n\n.CancelButton {\n  padding: 18px 0; }\n  .CancelButton:hover {\n    cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"DeleteAllTitle": "styles-module__DeleteAllTitle___Jin-w",
	"DeleteTitle": "styles-module__DeleteTitle___6dJef",
	"SelectDeleteButton": "styles-module__SelectDeleteButton___2orxV",
	"SelectDeleteAllButton": "styles-module__SelectDeleteAllButton___r7mkN",
	"Container": "styles-module__Container___1Q9t2",
	"CancelButton": "styles-module__CancelButton___3xXaS"
};
module.exports = exports;
