import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildOptions from 'src/components/shared/buildOptions';
import SwitchSlider from 'src/components/SwitchSlider';

import Form from './Form';
import styles from './styles.module.scss';

function ChooseStudySets(props) {
  const {
    allStudySets,
    chosenStudySets,
    handleSubmit,
    isUpdating,
  } = props;

  const chosenSize = chosenStudySets && chosenStudySets.size;

  const [isEditing, setIsEditing] = useState(false);
  const [isChecked, setIsChecked] = useState(chosenSize === 0);

  useEffect(() => {
    if (chosenSize > 0) setIsChecked(false);
  }, [chosenSize, setIsChecked]);

  function closeEditing() { setIsEditing(false); }
  function openEditing() { setIsEditing(true); }

  const { control, handleSubmit: formSubmit, reset } = useForm();

  const onSubmit = formSubmit(data => handleSubmit(data)
    .then(() => {
      if (data.studySets.length === 0) setIsChecked(true);
      closeEditing();
    }));

  function onChecked() {
    closeEditing();
    setIsChecked(true);
    handleSubmit({ studySets : [] });
  }

  function onUnchecked() {
    openEditing();
    setIsChecked(false);
  }

  const defaultMultiSelectValue = chosenSize && buildOptions(chosenStudySets);

  function onCancel() {
    reset(defaultMultiSelectValue);
    closeEditing();
    if (chosenSize === 0) setIsChecked(true);
  }

  return (
    <div className={ styles.Root }>
      <div>
        <div className={ styles.SliderContainer }>
          <p>Study all of your questions</p>

          <SwitchSlider
            defaultChecked={ isChecked }
            name="preferences__study-all-questions"
            onChecked={ onChecked }
            onUnchecked={ onUnchecked }
          />
        </div>

        <p className={ styles.TopSectionSubText }>
          You can choose to have all your questions appear in your
          quizzes (when they’re ready/due). Or you can have only
          the questions from certain study sets appear in your
          quizzes instead.
        </p>
      </div>

      <If condition={ !isChecked }>
        <div className={ styles.FormContainer }>
          <Choose>
            <When condition={ !isEditing }>
              <p
                className={ styles.Link }
                onClick={ openEditing }
              >edit</p>
            </When>

            <Otherwise>
              <p
                className={ styles.Link }
                onClick={ onCancel }
              >cancel</p>
            </Otherwise>
          </Choose>

          <Form
            allStudySets={ allStudySets }
            control={ control }
            defaultMultiSelectValue={ defaultMultiSelectValue }
            isEditing={ isEditing }
            isUpdating={ isUpdating }
            onSubmit={ onSubmit }
          />
        </div>
      </If>
    </div>
  );
}

ChooseStudySets.propTypes = {
  allStudySets    : studySetPropTypes.studySets.isRequired,
  chosenStudySets : studySetPropTypes.studySets.isRequired,
  handleSubmit    : basePropTypes.handleSubmit.isRequired,
  isUpdating      : basePropTypes.isUpdating,
};

export default ChooseStudySets;
