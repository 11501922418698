import { PropTypes } from 'prop-types';
import React, { memo, useState } from 'react';

import AuthenticationModal from 'src/components/modals/Authentication';
import Footer from 'src/components/navigation/Footer';
import HomeIcon from 'src/components/icons/Home';
import LibraryIcon from 'src/components/icons/Library';
import ProfileIcon from 'src/components/icons/Profile';

import Desktop from '../Desktop';
import FooterItem from '../FooterItem';

import styles from './styles.module.scss';

function Unauthed(props) {
  const {
    isCloserToSun,
    pathname,
  } = props;

  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  function openSignInModal() {
    setIsSignInModalOpen(true);
  }

  function closeSignInModal() {
    setIsSignInModalOpen(false);
  }

  return (
    <>
      <If condition={ isSignInModalOpen }>
        <AuthenticationModal closeModal={ closeSignInModal } />
      </If>

      <div className={ styles.Header }>
        <Desktop
          isUnauthed={ true }
          isCloserToSun={ isCloserToSun }
          pathname={ pathname }
        />
      </div>

      <div className={ styles.Footer }>
        <Footer>
          <FooterItem
            title="Dorothy"
            to="https://dorothymemoryapp.com/"
          >
            <div className={ styles.HomeIcon }>
              <HomeIcon color="#262322" />
            </div>
          </FooterItem>

          <FooterItem
            extraClassNames={ styles.PssFooterItem }
            isActive={ pathname.includes('/public/public-study-sets') }
            title="Public study sets"
            to="/public/public-study-sets"
          >
            <div className={ styles.LibraryIcon }>
              <LibraryIcon />
            </div>
          </FooterItem>

          <FooterItem
            dataAutomatedTest="site-wide-links--unauthed-profile"
            handleClick={ openSignInModal }
            title="Profile"
          >
            <div className={ styles.ProfileIcon }>
              <ProfileIcon color="#262322" />
            </div>
          </FooterItem>
        </Footer>
      </div>
    </>
  );
}

Unauthed.propTypes = {
  isCloserToSun : PropTypes.bool,
  pathname      : PropTypes.string.isRequired,
};

export default memo(Unauthed);
