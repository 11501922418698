import React from 'react';

import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import ShowPublicStudySet from 'src/components/publicStudySets/Show';

function ShowPublicPublicStudySet(props) {
  const { studySet } = props;

  const questionsSize = (
    studySet &&
    studySet.get('questions') &&
    studySet.get('questions').size
  ) || 0;

  /* eslint-disable max-len */
  function getUnauthedHeadingForAddToMyStudySets() {
    if (questionsSize < 61) return 'Hi there! Let’s get that study set added for you.';

    return 'Hi! Let’s sign you up or log you in before adding this study set.';
  }

  function getUnauthedParaForAddToMyStudySets() {
    if (questionsSize < 61) return 'It’s easy. Just enter your email and we’ll send you a one time password. After you enter that password, you’ll have an account and access to your very first study set.';

    return null;
  }
  /* eslint-enable max-len */

  return (
    <ShowPublicStudySet
      isUnauthed
      unauthedHeading={ getUnauthedHeadingForAddToMyStudySets() }
      unauthedPara={ getUnauthedParaForAddToMyStudySets() }
      { ...props }
    />
  );
}

ShowPublicPublicStudySet.propTypes = {
  studySet : studySetPropTypes.studySet.isRequired,
};

export default ShowPublicPublicStudySet;
