// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___15l-i{padding:16px 16px 103px}@media screen and (min-width: 768px){.styles-module__Root___15l-i{margin:0 auto;padding:16px 0 103px;width:464px}}@media screen and (min-width: 768px){.styles-module__Root___15l-i{min-width:730px}}@media screen and (min-width: 980px){.styles-module__Root___15l-i{padding-top:107px}}.styles-module__ResultsContainer___2r994{margin-top:-18px}@media screen and (min-width: 980px){.styles-module__ResultsContainer___2r994{margin-top:0}}.styles-module__PublicStudySetsLink___23DWF{color:#2071aa;cursor:pointer;text-decoration:none}.styles-module__PublicStudySetsLink___23DWF:hover{cursor:pointer}.styles-module__PublicStudySetsLink___23DWF *:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/studySets/All/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,uBAAA,CACA,qCACE,6BACE,aAAA,CACA,oBAAA,CACA,WAAA,CAAA,CACJ,qCACE,6BACE,eAAA,CAAA,CACJ,qCACE,6BACE,iBAAA,CAAA,CAEN,yCACE,gBAAA,CACA,qCACE,yCACE,YAAA,CAAA,CAEN,4CACE,aAAA,CACA,cAAA,CACA,oBAAA,CACA,kDACE,cAAA,CACF,oDACE,cAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  padding: 16px 16px 103px; }\n  @media screen and (min-width: 768px) {\n    .Root {\n      margin: 0 auto;\n      padding: 16px 0 103px;\n      width: 464px; } }\n  @media screen and (min-width: 768px) {\n    .Root {\n      min-width: 730px; } }\n  @media screen and (min-width: 980px) {\n    .Root {\n      padding-top: 107px; } }\n\n.ResultsContainer {\n  margin-top: -18px; }\n  @media screen and (min-width: 980px) {\n    .ResultsContainer {\n      margin-top: 0; } }\n\n.PublicStudySetsLink {\n  color: #2071aa;\n  cursor: pointer;\n  text-decoration: none; }\n  .PublicStudySetsLink:hover {\n    cursor: pointer; }\n  .PublicStudySetsLink *:hover {\n    cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___15l-i",
	"ResultsContainer": "styles-module__ResultsContainer___2r994",
	"PublicStudySetsLink": "styles-module__PublicStudySetsLink___23DWF"
};
module.exports = exports;
