// The purpose of this function is to prevent graphql errors such as
// `Variable title of type String! was provided invalid value.
// Expected value to not be null.`
// From making it to the UI. We can't really customize these errors
// on the backend nor should we. Furthermore, the convention does
// seem to be to intercept form errors during the submission and
// show errors before the save is finished.

function reduce(values, requiredValues) {
  return Object.keys(requiredValues).reduce((accumulator, key) => {
    if (values[key] && typeof values[key] !== 'object') return accumulator;

    if (typeof values[key] === 'object') {
      const nested = reduce(values[key], requiredValues[key]);
      if (Object.keys(nested).length > 0) accumulator[key] = nested;
    }

    if (values[key]) return accumulator;

    if (!accumulator[key]) accumulator[key] = [];

    accumulator[key].push(requiredValues[key]);

    return accumulator;
  }, {});
}

function buildUserFriendlyErrorsForMissingValues(values, requiredValues) {
  const errorsHash = reduce(values, requiredValues);

  if (Object.keys(errorsHash).length === 0) return null;

  return errorsHash;
}

export default buildUserFriendlyErrorsForMissingValues;
