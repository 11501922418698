import moment from 'moment';

export default function isQuizReady(quiz, defaultDueDate = null) {
  let dueDate = defaultDueDate;

  if (!dueDate) dueDate = quiz.get('dueDate') && new Date(quiz.get('dueDate').replace(/-/g, '/'));
  if (!dueDate) return false;
  if (moment(dueDate).subtract(1, 'day') > new Date()) return false;

  const questions = quiz.get('questions');

  return questions && questions.size > 0 && !quiz.get('completedAt');
}
