import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as profilePropTypes from 'src/constants/propTypes/profile';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import ChooseStudySets from './ChooseStudySets';
import MaxQuestionAmount from './MaxQuestionAmount';

function Preferences(props) {
  const {
    allStudySets,
    chosenStudySets,
    errors,
    handleChooseStudySetsSave,
    handleMaxQuestionAmountSave,
    isUpdating,
    maxQuestionAmountPerQuiz,
  } = props;

  return (
    <div>
      <MaxQuestionAmount
        errors={ errors }
        handleSubmit={ handleMaxQuestionAmountSave }
        isUpdating={ isUpdating }
        maxQuestionAmountPerQuiz={ maxQuestionAmountPerQuiz }
      />

      <ChooseStudySets
        allStudySets={ allStudySets }
        chosenStudySets={ chosenStudySets }
        handleSubmit={ handleChooseStudySetsSave }
        isUpdating={ isUpdating }
      />
    </div>
  );
}

Preferences.propTypes = {
  allStudySets                : studySetPropTypes.studySets.isRequired,
  chosenStudySets             : studySetPropTypes.studySets.isRequired,
  errors                      : basePropTypes.errors,
  handleChooseStudySetsSave   : profilePropTypes.handleChooseStudySetsSave.isRequired,
  handleMaxQuestionAmountSave : profilePropTypes.handleMaxQuestionAmountSave.isRequired,
  isUpdating                  : basePropTypes.isUpdating,
  maxQuestionAmountPerQuiz    : profilePropTypes.maxQuestionAmountPerQuiz.isRequired,
};

export default Preferences;
