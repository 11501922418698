import React from 'react';

import styles from './styles.module.scss';

function InstallApp() {
  return (
    <div className={ styles.Root }>
      <div className={ styles.ImageContainer }>
        { /* eslint-disable-next-line max-len */ }
        <a href='https://play.google.com/store/apps/details?id=com.dorothymemoryapp.my.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
      </div>

      <p className={ styles.Para }>
        Unfortunately at this time, we are not on the Apple Store.
        However, <strong>everything works the same in your browser as it would in an app</strong>.
        You should have no issues using Dorothy on your phone or tablet.
      </p>
    </div>
  );
}

InstallApp.propTypes = {};

export default InstallApp;
