// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___Li9Ag path{fill:#2071aa}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/icons/PlusCircle/styles.module.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA","file":"styles.module.scss","sourcesContent":[".Root path {\n  fill: #2071aa; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___Li9Ag"
};
module.exports = exports;
