import React from 'react';

import Logo from 'src/assets/logo-1.png';

import Shared from './Shared';
import styles from './styles.module.scss';

const shared = new Shared();

function NameWithFaceAsO(props) {
  const { dataAutomatedTest } = props;

  shared.setClassProperties({ props });

  return (
    <div
      className={ shared.getRootClassNames() }
      data-automated-test={ dataAutomatedTest }
    >
      <img className={ styles.Image } src={ Logo } />
    </div>
  );
}

NameWithFaceAsO.propTypes = shared.propTypes;

export default NameWithFaceAsO;
