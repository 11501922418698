import Immutable from 'immutable';

export default Immutable.fromJS({
  autosaved : {
    new : {
      csv                 : '',
      description         : null,
      isUndesignated      : false,
      questions           : { 0 : { answer : {} } },
      reversedCopiesAdded : false,
      title               : null,
      visibilityStatus    : 'is_private',
    },
  },
  errors       : [],
  isActive     : false,
  isSaving     : false,
  isSuccessful : null,
  loaded       : {
    all  : [],
    show : {
      questions : [],
    },
  },
});
