import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import analytics from 'src/shared/analytics';
import Card from 'src/components/Card';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import Header from 'src/components/navigation/Header';
import HomeLink from 'src/components/navigation/HomeLink';
import LimitModal from 'src/components/modals/QuestionLimitModal';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import TextField from 'src/components/fields/TextField';

import Field from './Field';
import Options from './Options';

import styles from './styles.module.scss';

function Question(props) {
  const {
    deleteAnswerAudio,
    deleteAnswerImage,
    deleteQuestionAudio,
    deleteQuestionImage,
    errors,
    fromEdit,
    handleSubmit,
    heading,
    isActive,
    question,
    studySets,
  } = props;

  const { control, handleSubmit: formSubmit, getValues, register, setValue } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function closeModal() { setIsModalOpen(false); }
  function openModal() { setIsModalOpen(true); }

  function submit(values) { return handleSubmit(values); }
  const onSubmit = formSubmit(submit);

  const title = question && question.get('title');
  const answer = question && question.getIn(['answer', 'body']);
  const answerAudio = question && question.getIn(['answer', 'audioUrl']);
  const answerImage = question && question.getIn(['answer', 'imageUrl']);
  const questionAudio = question && question.get('audioUrl');
  const questionImage = question && question.get('imageUrl');
  const studySet = question && question.get('studySet');

  const getAnswerImageFileList = () => {
    const currentValues = getValues();
    return currentValues.answer && currentValues.answer.imageFile;
  };
  const getQuestionImageFileList = () => getValues().imageFile;

  useEffect(
    () => {
      if (errors && errors.get('limit')) return openModal();
      return closeModal();
    },
    [errors],
  );

  function handleHomeLinkClick() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Home link clicked on /questions/:id/edit');
  }

  return (
    <Card
      isFullScreenOnDesktop
      isFullScreenOnMobile
      isSoloCardOnScreen
      navLocationOnMobile="header"
    >
      <Choose>
        <When condition={ !question && fromEdit }>
          <div className={ styles.PulseLoader }>
            <PulseLoadingIndicator isLoading={ true }/>
          </div>
        </When>

        <Otherwise>
          <form onSubmit={ onSubmit }>
            <Header withSaveButton { ...props } />

            <HomeLink
              disabled={ isActive }
              handleClick={ handleHomeLinkClick }
            />

            <h3 className={ styles.Heading }>{ heading }</h3>

            <div className={ styles.Fields }>
              <Field
                audioFileInputName={ 'audioFile' }
                deleteAudio={ deleteQuestionAudio }
                deleteImage={ deleteQuestionImage }
                getFileList={ getQuestionImageFileList }
                imageFileInputName={ 'imageFile' }
                register={ register }
                savedAudioUrl={ questionAudio }
                savedImageUrl={ questionImage }
                setValue={ setValue }
              >
                <TextField
                  autoFocus={ !title && !answer }
                  defaultValue={ title }
                  disabled={ isActive }
                  errorMessages={ errors && errors.get('title') }
                  heading="Question"
                  htmlFor="title"
                  isTextArea
                  name="title"
                  register={ register }
                />
              </Field>

              <div className={ styles.Answer }>
                <Field
                  audioFileInputName={ "answer['audioFile']" }
                  deleteAudio={ deleteAnswerAudio }
                  deleteImage={ deleteAnswerImage }
                  getFileList={ getAnswerImageFileList }
                  imageFileInputName={ "answer['imageFile']" }
                  register={ register }
                  savedAudioUrl={ answerAudio }
                  savedImageUrl={ answerImage }
                  setValue={ setValue }
                >
                  <TextField
                    defaultValue={ answer }
                    disabled={ isActive }
                    errorMessages={ errors && errors.get('body') }
                    heading="Answer"
                    htmlFor="answer['body']"
                    isTextArea
                    name="answer['body']"
                    register={ register }
                  />
                </Field>
              </div>

              <Options
                control={ control }
                studySet={ studySet }
                studySets={ studySets }
              />
            </div>
          </form>
        </Otherwise>
      </Choose>

      <If condition={ isModalOpen }>
        <LimitModal closeModal={ closeModal } />
      </If>
    </Card>
  );
}

Question.propTypes = {
  deleteAnswerAudio   : questionPropTypes.deleteAudio,
  deleteQuestionAudio : questionPropTypes.deleteAudio,
  deleteAnswerImage   : questionPropTypes.deleteImage,
  deleteQuestionImage : questionPropTypes.deleteImage,
  errors              : basePropTypes.errors,
  fromEdit            : PropTypes.bool,
  handleSubmit        : authenticationPropTypes.handleSubmit.isRequired,
  heading             : questionPropTypes.heading.isRequired,
  isActive            : basePropTypes.isActive.isRequired,
  question            : questionPropTypes.question,
  studySets           : studySetPropTypes.studySets.isRequired,
};

export default Question;
