import moment from 'moment';
import React, { memo } from 'react';
import * as Sentry from '@sentry/browser';

import * as quizPropTypes from 'src/constants/propTypes/quiz';

function NextQuizDueDate({ dueDate, quiz }) {
  const nextQuizDueInWords = quiz.get('nextQuizDueInWords');
  const time = moment.parseZone(dueDate).add(1, 'minute').format('LT');
  const date = moment(dueDate).utc().subtract(1, 'day').format('dddd MMM Do');

  let zone;

  if ([time, date].includes('Invalid date')) {
    // NOTE: Will remove in the future
    if (window.location.hostname === 'my.dorothymemoryapp.com') {
      Sentry.captureMessage(
        'the moment due date was invalid',
        {
          extra : {
            dueDate,
            quiz : quiz.toJS(),
          },
        },
      );
    }

    return (
      <span>
        Your next quiz will be ready in { nextQuizDueInWords }.
      </span>
    );
  }

  try {
    const shortName = new Date().toLocaleTimeString('en-us', { timeZoneName : 'short' });
    zone = ` ${shortName.split(' ')[2]}`;
  } catch (_) {} // eslint-disable-line no-empty

  return (
    <span>
      { nextQuizDueInWords } at
      <strong> { time }{ zone } on { date }</strong>.
    </span>
  );
}

NextQuizDueDate.propTypes = {
  dueDate : quizPropTypes.dueDate.isRequired,
  quiz    : quizPropTypes.quiz.isRequired,
};

export default memo(NextQuizDueDate);
