// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Para___2XlWv{margin-top:18px}.styles-module__ButtonLink___Ax6MJ.styles-module__shouldReallyDrawFocus___1xpR4 a{margin:36px auto 24px}@media screen and (min-width: 568px){.styles-module__ButtonLink___Ax6MJ a{margin:36px auto 18px;width:auto;width:initial}.styles-module__ButtonLink___Ax6MJ.styles-module__shouldReallyDrawFocus___1xpR4 a{margin:45px auto 36px}}.styles-module__LetUsKnowLink___1G2Ed{color:#2071aa;cursor:pointer;text-decoration:none}.styles-module__LetUsKnowLink___1G2Ed:hover{cursor:pointer}.styles-module__LetUsKnowLink___1G2Ed *:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Dashboard/OpeningContent/NoQuizContent/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CAEF,kFACE,qBAAA,CAEF,qCACE,qCACE,qBAAA,CACA,UAAA,CAAA,aAAA,CACF,kFACE,qBAAA,CAAA,CAEJ,sCACE,aAAA,CACA,cAAA,CACA,oBAAA,CACA,4CACE,cAAA,CACF,8CACE,cAAA","file":"styles.module.scss","sourcesContent":[".Para {\n  margin-top: 18px; }\n\n.ButtonLink.shouldReallyDrawFocus a {\n  margin: 36px auto 24px; }\n\n@media screen and (min-width: 568px) {\n  .ButtonLink a {\n    margin: 36px auto 18px;\n    width: initial; }\n  .ButtonLink.shouldReallyDrawFocus a {\n    margin: 45px auto 36px; } }\n\n.LetUsKnowLink {\n  color: #2071aa;\n  cursor: pointer;\n  text-decoration: none; }\n  .LetUsKnowLink:hover {\n    cursor: pointer; }\n  .LetUsKnowLink *:hover {\n    cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"Para": "styles-module__Para___2XlWv",
	"ButtonLink": "styles-module__ButtonLink___Ax6MJ",
	"shouldReallyDrawFocus": "styles-module__shouldReallyDrawFocus___1xpR4",
	"LetUsKnowLink": "styles-module__LetUsKnowLink___1G2Ed"
};
module.exports = exports;
