import { PropTypes } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function InfoText(props) {
  const {
    children,
  } = props;

  return (
    <div
      className={ styles.Root }
      data-automated-test="charts--info-text"
    >
      { children }
    </div>
  );
}

InfoText.propTypes = {
  children : PropTypes.node.isRequired,
};

export default InfoText;
