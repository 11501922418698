import React from 'react';

import ShowPublicStudySetQuestion from 'src/components/publicStudySets/ShowQuestion';

function ShowPublicPublicStudySetQuestion(props) {
  return (
    <ShowPublicStudySetQuestion
      isUnauthed
      { ...props }
    />
  );
}

export default ShowPublicPublicStudySetQuestion;
