import React, { useContext, useEffect, useState } from 'react';

import * as quizPropTypes from 'src/constants/propTypes/quiz';

import classNames from 'src/components/shared/classNames';
import QuizCongratulationsContext from 'src/contexts/QuizCongratulations';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Paragraph({ isAnimatingCongratulations }) {
  const [title, setTitle] = useState('');

  function getTitle() {
    const random = Math.random();

    if (random > 0.33) return 'Congratulations!';

    return 'Way to go!';
  }

  useEffect(() => setTitle(getTitle()), []);

  const { dashboardReport, studySetIdFromQuiz } = useContext(QuizCongratulationsContext);

  const completedQuizzesSize = dashboardReport && dashboardReport.get('completedQuizzesSize');
  const lastQuizWasStacked = dashboardReport && dashboardReport.get('lastQuizWasStacked');
  const quizStreak = dashboardReport && dashboardReport.get('quizStreak');

  function getParaClassNames() {
    return cx({
      Para          : true,
      shouldAnimate : isAnimatingCongratulations,
    });
  }

  function getTitleClassNames() {
    return cx({
      Title         : true,
      shouldAnimate : isAnimatingCongratulations,
    });
  }

  function getNumberSuffix() {
    const n = completedQuizzesSize;

    const tenModulo = n % 10;
    const oneHundredModulo = n % 100;

    if (tenModulo === 1 && oneHundredModulo !== 11) return `${n}st`;
    if (tenModulo === 2 && oneHundredModulo !== 12) return `${n}nd`;
    if (tenModulo === 3 && oneHundredModulo !== 13) return `${n}rd`;

    return `${n}th`;
  }

  return (
    <div>
      <h3 className={ getTitleClassNames() }>
        { title }
      </h3>

      <div className={ getParaClassNames() }>
        <Choose>
          <When condition={ studySetIdFromQuiz }>
            You completed this flash study session.
            We know you must be preparing for something important and so we want to wish
            you good luck! We know you’ll do great.
          </When>

          <When condition={ completedQuizzesSize && completedQuizzesSize === 1 }>
            You have completed your very first quiz.
            We know the first one can be tough so give yourself a pat on the back.
          </When>

          <When condition={ !lastQuizWasStacked && quizStreak && quizStreak > 1 }>
            You’re on a <strong>{ quizStreak }-day streak</strong> of not missing a quiz!
            That means for { quizStreak } quizzes in a row,
            you completed each quiz on the day it was due.
            You are remembering more!
          </When>

          <When condition={ completedQuizzesSize }>
            For the { getNumberSuffix() } time,
            you took a quiz and pushed yourself to recall your answers. You’re getting smarter!
          </When>

          <Otherwise>
            You completed your quiz and attempted to recall every answer. Well done!
          </Otherwise>
        </Choose>
      </div>
    </div>
  );
}

Paragraph.propTypes = {
  isAnimatingCongratulations : quizPropTypes.isAnimatingCongratulations,
};

export default Paragraph;
