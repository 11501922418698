// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (min-width: 980px){.styles-module__Root___2jBgI{margin:67px auto 16px;width:calc(100% - 32px)}}@media screen and (min-width: 1080px){.styles-module__Root___2jBgI{margin:0;margin:initial;padding:79px 0 36px;width:auto;width:initial}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/studySets/Edit/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,6BACE,qBAAA,CACA,uBAAA,CAAA,CAEJ,sCACE,6BACE,QAAA,CAAA,cAAA,CACA,mBAAA,CACA,UAAA,CAAA,aAAA,CAAA","file":"styles.module.scss","sourcesContent":["@media screen and (min-width: 980px) {\n  .Root {\n    margin: 67px auto 16px;\n    width: calc(100% - 32px); } }\n\n@media screen and (min-width: 1080px) {\n  .Root {\n    margin: initial;\n    padding: 79px 0 36px;\n    width: initial; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2jBgI"
};
module.exports = exports;
