import { PropTypes } from 'prop-types';
import React from 'react';

import GraduationCapInCircleIcon from 'src/components/icons/GraduationCapInCircle';

import styles from './styles.module.scss';

function StatsPlaceholder(props) {
  const {
    titleNoun = 'stats',
  } = props;

  return (
    <div
      className={ styles.Root }
      data-automated-test="stats-placeholder"
    >
      <div className={ styles.IconContainer }>
        <GraduationCapInCircleIcon />
      </div>

      <p className={ styles.ComingSoonTitle }>Your { titleNoun } will appear here soon</p>

      <p className={ styles.ComingSoonPara }>
        Hint: all you have to do is take a quiz
      </p>
    </div>
  );
}

StatsPlaceholder.propTypes = {
  titleNoun : PropTypes.string,
};

export default StatsPlaceholder;
