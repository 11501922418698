import { PropTypes } from 'prop-types';
import React from 'react';

import classNames from 'src/components/shared/classNames';

import * as basePropTypes from 'src/constants/propTypes/base';

import HomeLink from 'src/components/navigation/HomeLink';

import BackButton from './BackButton';
import RightSide from './RightSide';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Header(props) {
  const {
    children,
    disabled,
    exitLinkPath,
    exitOverrideAction,
    history,
    isActive,
    isCloserToSun,
    isSaving,
    match,
    middleSection,
    noBackButton,
    noPadding,
    rightSideHasHomeLink,
    saveButtonIsSecondary,
    saveButtonValue,
    withSaveButton,
  } = props;

  function getRootClassNames() {
    return cx({
      Root : true,
      isCloserToSun,
      noPadding,
    });
  }

  const state = history && history.location && history.location.state;

  const cameViaExternalSource = state &&
    state.pathFrom &&
    state.pathFrom.includes('/auth/sign-in-via-external-source');

  return (
    <div className={ getRootClassNames() }>
      <Choose>
        <When condition={ children }>
          { children }
        </When>

        <Otherwise>
          <Choose>
            <When condition={ cameViaExternalSource }>
              <div />
            </When>

            <When condition={ noBackButton }>
              <div className={ styles.HomeLink }>
                <HomeLink
                  disabled={ isActive }
                  match={ match }
                />
              </div>
            </When>

            <Otherwise>
              <BackButton
                history={ history }
                match={ match }
              />
            </Otherwise>
          </Choose>

          <If condition={ middleSection }>
            <div className={ styles.NumberTracker }>
              { middleSection }
            </div>
          </If>

          <RightSide
            disabled={ disabled }
            exitLinkPath={ exitLinkPath }
            exitOverrideAction={ exitOverrideAction }
            isActive={ isActive }
            isSaving={ isSaving }
            rightSideHasHomeLink={ rightSideHasHomeLink }
            saveButtonIsSecondary={ saveButtonIsSecondary }
            saveButtonValue={ saveButtonValue }
            withSaveButton={ withSaveButton }
          />
        </Otherwise>
      </Choose>
    </div>
  );
}

Header.propTypes = {
  children              : basePropTypes.children,
  disabled              : basePropTypes.disabled,
  exitLinkPath          : PropTypes.string,
  exitOverrideAction    : PropTypes.func,
  history               : basePropTypes.history,
  isActive              : basePropTypes.isActive,
  isCloserToSun         : PropTypes.bool,
  isSaving              : basePropTypes.isSaving,
  match                 : basePropTypes.match,
  noBackButton          : basePropTypes.noBackButton,
  noPadding             : basePropTypes.noPadding,
  middleSection         : PropTypes.node,
  rightSideHasHomeLink  : basePropTypes.rightSideHasHomeLink,
  saveButtonIsSecondary : PropTypes.bool,
  saveButtonValue       : basePropTypes.saveButtonValue,
  withSaveButton        : basePropTypes.withSaveButton,
};

export default Header;
