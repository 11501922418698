import React, { useRef, useState } from 'react';
import Select from 'react-select';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function SelectField(props) {
  const {
    heading,
    onChange,
    options,
    value,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => setIsFocused(false);
  const handleFocus = () => setIsFocused(true);

  const inputRef = useRef(null);

  function setUpRef(el) { inputRef.current = el; }

  function getTitleClassNames() {
    return cx({
      Title    : true,
      hasValue : Boolean(value),
      isFocused,
    });
  }

  const handleTitleClick = () => inputRef.current.focus();

  return (
    <div className={ styles.Root }>
      <div
        className={ getTitleClassNames() }
        onClick={ handleTitleClick }
      >
        { heading }
      </div>

      <Select
        backspaceRemovesValue
        className={ styles.Select }
        classNamePrefix="Select"
        isClearable
        onBlur={ handleBlur }
        onFocus={ handleFocus }
        options={ options }
        onChange={ val => onChange(val && val.value) }
        placeholder=""
        ref={ setUpRef }
        value={ options.find(c => c.value === value) }
      />
    </div>
  );
}

SelectField.propTypes = {
  heading  : basePropTypes.heading.isRequired,
  onChange : basePropTypes.onChange.isRequired,
  options  : basePropTypes.options.isRequired,
  value    : basePropTypes.value,
};

export default SelectField;
