import statsDashboardReport from 'src/graphql/queries/stats/dashboardReport';
import statsFullReport from 'src/graphql/queries/stats/fullReport';

import post from './shared/post';

export default {
  fetchDashboardReport() {
    return post(null, statsDashboardReport);
  },

  fetchFullReport(values) {
    return post(values, statsFullReport);
  },
};
