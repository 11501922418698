import { Link } from 'react-router-dom';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function LinkItem({ isActive, title, to }) {
  function getTitleClassNames() {
    return cx({
      Title : true,
      isActive,
    });
  }

  return (
    <li className={ styles.Root }>
      <Link to={ to }>
        <p className={ getTitleClassNames() }>{ title }</p>
      </Link>
    </li>
  );
}

LinkItem.propTypes = {
  isActive : basePropTypes.isActive,
  title    : basePropTypes.title.isRequired,
  to       : basePropTypes.to.isRequired,
};

export default LinkItem;
