// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2k2_4{align-items:flex-start;display:flex;flex-direction:column;grid-gap:24px;gap:24px;padding:20px}.styles-module__Title___dpS8v{font-size:24px;font-weight:600;line-height:normal;line-height:initial;margin-bottom:-10px}.styles-module__Title___dpS8v span{font-size:24px}.styles-module__BottomButtons___iFLq7{display:flex;flex-direction:row;justify-content:space-between;width:100%}.styles-module__ButtonLink___1OifW{max-width:-moz-max-content;max-width:max-content;min-width:190px}.styles-module__ButtonLink___1OifW>a{margin-top:0;width:100%}.styles-module__SkipLink___2ifR6{align-items:center;color:#2071aa;display:flex;text-decoration:underline}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/onboarding/SampleQuiz/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,sBAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,YAAA,CAEF,8BACE,cAAA,CACA,eAAA,CACA,kBAAA,CAAA,mBAAA,CACA,mBAAA,CACA,mCACE,cAAA,CAEJ,sCACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CAEF,mCACE,0BAAA,CAAA,qBAAA,CACA,eAAA,CACA,qCACE,YAAA,CACA,UAAA,CAEJ,iCACE,kBAAA,CACA,aAAA,CACA,YAAA,CACA,yBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 20px; }\n\n.Title {\n  font-size: 24px;\n  font-weight: 600;\n  line-height: initial;\n  margin-bottom: -10px; }\n  .Title span {\n    font-size: 24px; }\n\n.BottomButtons {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 100%; }\n\n.ButtonLink {\n  max-width: max-content;\n  min-width: 190px; }\n  .ButtonLink > a {\n    margin-top: 0;\n    width: 100%; }\n\n.SkipLink {\n  align-items: center;\n  color: #2071aa;\n  display: flex;\n  text-decoration: underline; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2k2_4",
	"Title": "styles-module__Title___dpS8v",
	"BottomButtons": "styles-module__BottomButtons___iFLq7",
	"ButtonLink": "styles-module__ButtonLink___1OifW",
	"SkipLink": "styles-module__SkipLink___2ifR6"
};
module.exports = exports;
