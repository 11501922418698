import gql from 'graphql-tag';

export default gql`
  query progressReport {
    progressReport {
      answersBeingStudiedMemorized
      answersRecalled
      completedQuizzesSize
      lastQuizWasStacked
      questionsCurrentlyBeingStudied
      quizStreak
      totalAnswersCurrentlyMemorized
      totalTimeSpent
    }
  }
`;
