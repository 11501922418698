import gql from 'graphql-tag';

export default gql`
  query showQuestion(
    $id: ID!
  ) {
    showQuestion(
      id: $id
    ) {
      answer {
        audioUrl
        body
        id
        imageUrl
      }
      audioUrl
      forgottenByDate
      id
      imageUrl
      studySet {
        id
        title
      }
      title
      userId
    }
  }
`;
