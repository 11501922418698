import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.SEARCH__CLEAR_RESULTS:
      return state.withMutations(map => {
        map.set('images', Immutable.fromJS([]));
        map.set('isActive', false);
      });

    case actionTypes.SEARCH__CLEAR_TEMP_STATE:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
      });

    case actionTypes.SEARCH__USER_QUESTIONS_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.SEARCH__USER_QUESTIONS_SUCCESS:
      return state.withMutations(map => {
        const { questions: { results } } = payload;

        map.set('questions', Immutable.fromJS(results));
        map.set('isActive', false);
      });

    case actionTypes.SEARCH__USER_QUESTIONS_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.set('questions', Immutable.fromJS([]));
      });

    default:
      return state;
  }
}
