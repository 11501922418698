import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as statsPropTypes from 'src/constants/propTypes/stats';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import analytics from 'src/shared/analytics';
import ButtonLink from 'src/components/buttons/ButtonLink';
import classNames from 'src/components/shared/classNames';
import getQuizStages from 'src/shared/getQuizStages';

import OpeningPara from './OpeningPara';
import QuizQuestionsLever from './QuizQuestionsLever';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ActionContent(props) {
  const {
    chosenStudySets,
    maxQuestionAmountPerQuiz,
    statsDashboardReport,
    quiz,
  } = props;

  const [quizStages, setQuizStages] = useState({});

  const dueDate = quiz.get('dueDate') && new Date(quiz.get('dueDate').replace(/-/g, '/'));

  const questions = quiz.get('questions');
  const quizId = quiz.get('id');

  useEffect(() => {
    const stages = getQuizStages(quiz, chosenStudySets, dueDate);
    setQuizStages(stages);
  }, [quiz]);

  const {
    completedSampleQuizToday,
    isOnFirstQuizAndNotStarted,
    isSampleQuiz,
    quizIsDueTodayAndNotStarted,
    quizIsDueTodayAndStarted,
    quizIsDueInFutureAndNotStarted,
    quizIsDueInFutureAndStarted,
    quizStarted,
  } = quizStages;

  function handleTakeSampleQuizClick() {
    analytics.identify(quiz.get('userId'));
    analytics.track('Onboarding -- Sample quiz started', { quizId });
  }

  // NOTE: using isSampleQuiz here arbitrarily. It can be any value from
  // quizStages. I just want to wait until something got defined.
  return (
    <div className={ cx({ Root : true, isActive : isSampleQuiz === undefined }) }>
      <OpeningPara
        dueDate={ dueDate }
        maxQuestionAmountPerQuiz={ maxQuestionAmountPerQuiz }
        quiz={ quiz }
        statsDashboardReport={ statsDashboardReport }
        { ...quizStages }
      />

      <Choose>
        <When condition={ quizIsDueTodayAndNotStarted }>
          <ButtonLink
            extraClassNames={ styles.ButtonLink }
            text="Let's get started"
            to={ `/quiz/${quizId}/explanation` }
          />
        </When>

        <When condition={ quizIsDueTodayAndStarted }>
          <ButtonLink
            extraClassNames={ styles.ButtonLink }
            text="Let's continue"
            to={ `/quiz/${quizId}/questions/1` }
          />
        </When>

        <When condition={ isOnFirstQuizAndNotStarted && isSampleQuiz && quizStarted }>
          <div>
            <ButtonLink
              text="Let’s continue the sample quiz →"
              to={ `/quiz/${quizId}/explanation?purpose=sample` }
            />

            <div className={ styles.SecondLink }>
              <ButtonLink
                isSecondary
                text="Add a study set"
                to="/study-sets/new"
              />
            </div>
          </div>
        </When>

        <When condition={ isOnFirstQuizAndNotStarted && isSampleQuiz }>
          <div>
            <ButtonLink
              extraClassNames={ `${styles.ButtonLink} ${styles.shouldReallyDrawFocus}` }
              handleClick={ handleTakeSampleQuizClick }
              isPulsing
              text="Let’s take the sample quiz →"
              to={ `/quiz/${quizId}/explanation?purpose=sample` }
            />

            <div className={ styles.SecondLink }>
              <ButtonLink
                isSecondary
                text="Add a study set"
                to="/study-sets/new"
              />
            </div>
          </div>
        </When>

        <When condition={ completedSampleQuizToday }>
          <ButtonLink
            text="Add a study set"
            to="/study-sets/new"
          />
        </When>

        <When condition={ isOnFirstQuizAndNotStarted }>
          <ButtonLink
            extraClassNames={ `${styles.ButtonLink} ${styles.isTertiary}` }
            isTertiary
            text="Take your first quiz early →"
            to={ `/quiz/${quizId}/explanation` }
          />
        </When>

        <When condition={ quizIsDueInFutureAndNotStarted }>
          <ButtonLink
            extraClassNames={ `${styles.ButtonLink} ${styles.isTertiary}` }
            isTertiary
            text="Take your next quiz early →"
            to={ `/quiz/${quizId}/explanation` }
          />
        </When>

        <When condition={ quizIsDueInFutureAndStarted }>
          <ButtonLink
            extraClassNames={ styles.ButtonLink }
            isSecondary
            text="Let's continue"
            to={ `/quiz/${quizId}/questions/1` }
          />
        </When>

        <Otherwise>
          <ButtonLink
            extraClassNames={ `${styles.ButtonLink} ${styles.shouldReallyDrawFocus}` }
            isPulsing
            text="Add a study set"
            to="/study-sets/new"
          />
        </Otherwise>
      </Choose>

      <If
        condition={ questions &&
          questions.size > 0 &&
          !isOnFirstQuizAndNotStarted &&
          !isSampleQuiz }
      >
        <QuizQuestionsLever
          dueDate={ dueDate }
          questions={ questions }
          quiz={ quiz }
        />
      </If>
    </div>
  );
}

ActionContent.propTypes = {
  chosenStudySets          : studySetPropTypes.studySets.isRequired,
  maxQuestionAmountPerQuiz : PropTypes.number,
  statsDashboardReport     : statsPropTypes.dashboardReport.isRequired,
  quiz                     : quizPropTypes.quiz.isRequired,
};

export default ActionContent;
