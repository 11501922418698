import React from 'react';

import styles from './styles.module.scss';

function AndMoreEllipses() {
  return (
    <svg
      className={ styles.Root }
      x="700pt"
      y="700pt"
      viewBox="0 0 700 700"
    >
      <path d="m350 35c-64.977 0-127.29 25.812-173.24 71.758-45.945 45.949-71.758 108.27-71.758 173.24s25.812 127.29 71.758 173.24c45.949 45.945 108.27 71.758 173.24 71.758s127.29-25.812 173.24-71.758c45.945-45.949 71.758-108.27 71.758-173.24 0-43.008-11.32-85.254-32.824-122.5-21.504-37.246-52.43-68.172-89.676-89.676-37.246-21.504-79.492-32.824-122.5-32.824zm0 455c-55.695 0-109.11-22.125-148.49-61.508-39.383-39.383-61.508-92.797-61.508-148.49s22.125-109.11 61.508-148.49c39.383-39.383 92.797-61.508 148.49-61.508s109.11 22.125 148.49 61.508c39.383 39.383 61.508 92.797 61.508 148.49s-22.125 109.11-61.508 148.49c-39.383 39.383-92.797 61.508-148.49 61.508zm-70-210c0 9.2812-3.6875 18.184-10.25 24.75-6.5664 6.5625-15.469 10.25-24.75 10.25s-18.184-3.6875-24.75-10.25c-6.5625-6.5664-10.25-15.469-10.25-24.75s3.6875-18.184 10.25-24.75c6.5664-6.5625 15.469-10.25 24.75-10.25s18.184 3.6875 24.75 10.25c6.5625 6.5664 10.25 15.469 10.25 24.75zm105 0c0 9.2812-3.6875 18.184-10.25 24.75-6.5664 6.5625-15.469 10.25-24.75 10.25s-18.184-3.6875-24.75-10.25c-6.5625-6.5664-10.25-15.469-10.25-24.75s3.6875-18.184 10.25-24.75c6.5664-6.5625 15.469-10.25 24.75-10.25s18.184 3.6875 24.75 10.25c6.5625 6.5664 10.25 15.469 10.25 24.75zm105 0c0 9.2812-3.6875 18.184-10.25 24.75-6.5664 6.5625-15.469 10.25-24.75 10.25s-18.184-3.6875-24.75-10.25c-6.5625-6.5664-10.25-15.469-10.25-24.75s3.6875-18.184 10.25-24.75c6.5664-6.5625 15.469-10.25 24.75-10.25s18.184 3.6875 24.75 10.25c6.5625 6.5664 10.25 15.469 10.25 24.75z"/>
    </svg>
  );
}

export default AndMoreEllipses;
