import React from 'react';

function Warning() {
  return (
    <svg
      width="700pt"
      height="700pt"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m609.28 444.64-218.4-376.32c-8.957-14.559-24.078-23.52-40.879-23.52s-31.922 8.9609-40.879 23.52l-218.4 376.32c-8.3984 14.559-8.3984 32.48 0 47.039 8.3984 14.559 23.52 23.52 40.879 23.52h437.92c16.801 0 32.48-8.9609 40.879-23.52 8.4023-14.559 7.8398-32.48-1.1172-47.039zm-259.28-281.12c19.039 0 34.719 16.238 33.602 35.281l-6.7188 129.92c-0.55859 14.559-12.32 25.762-26.879 25.762s-26.32-11.199-26.879-25.762l-6.7266-129.92c-1.1172-19.602 14.562-35.281 33.602-35.281zm0 286.16c-19.602 0-35.281-15.68-35.281-35.281 0-19.602 15.68-35.281 35.281-35.281s35.281 15.68 35.281 35.281c0 19.602-15.68 35.281-35.281 35.281z"/>
      </g>
    </svg>
  );
}

export default Warning;
