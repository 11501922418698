import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

// To show and hide content
function Lever(props) {
  // NOTE: If you pass shouldHideOverflow, then
  // the root container of your children cannot have
  // a margin-top value. That's because the overflow hidden
  // styles of Lever will cause some serious jumping
  // janky-ness. Use padding-top instead.
  const {
    children,
    dataAutomatedTest,
    isOpenByDefault,
    leverTitleClassNames,
    shouldHideOverflow,
    title,
    toggleIsLeverOpenCallback,
  } = props;

  const [isLeverOpen, setIsLeverOpen] = useState(isOpenByDefault);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  function toggleIsLeverOpen() {
    setIsLeverOpen(!isLeverOpen);

    if (toggleIsLeverOpenCallback) toggleIsLeverOpenCallback();
  }

  useEffect(() => {
    if (!hasBeenOpened && isLeverOpen) {
      setHasBeenOpened(true);
    }
  }, [hasBeenOpened, isLeverOpen]);

  useEffect(() => {
    if (isOpenByDefault) setIsLeverOpen(true);
  }, [isOpenByDefault]);

  function getLeverContentClassNames() {
    return cx({
      LeverContent  : true,
      isLeverOpen,
      isLeverClosed : !isLeverOpen,
      hasBeenOpened,
      shouldHideOverflow,
    });
  }

  return (
    <div>
      <div className={ styles.LeverTitleContainer }>
        <p
          className={ `${leverTitleClassNames} ${styles.LeverTitle}` }
          data-automated-test={ dataAutomatedTest }
          onClick={ toggleIsLeverOpen }
        >
          { title } <strong>{ isLeverOpen ? '-' : '+' }</strong>
        </p>
      </div>

      <div className={ styles.LeverContainer }>
        <If condition={ !isLeverOpen }>
          <div className={ styles.HiddenOverlay } />
        </If>

        <div className={ getLeverContentClassNames() }>
          { children }
        </div>
      </div>
    </div>
  );
}

Lever.propTypes = {
  children                  : basePropTypes.children.isRequired,
  dataAutomatedTest         : basePropTypes.dataAutomatedTest,
  isOpenByDefault           : PropTypes.bool,
  leverTitleClassNames      : basePropTypes.leverTitleClassNames,
  shouldHideOverflow        : basePropTypes.shouldHideOverflow,
  title                     : basePropTypes.title.isRequired,
  toggleIsLeverOpenCallback : PropTypes.func,
};

export default Lever;
