import { PropTypes } from 'prop-types';
import React, { useRef, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';
import SegmentedToggle from 'src/components/toggles/SegmentedToggle';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function SegmentedButton(props) {
  const {
    disabled,
    initialSelectedValue,
    name,
    options,
    register,
  } = props;

  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);

  const inputRef = useRef(null);

  function setUpRef(el) {
    if (register) {
      register(el);
      inputRef.current = el;
    }
  }

  const hiddenInputClassNames = cx({
    HiddenInput : true,
    isDisabled  : disabled,
  });

  function handleClick({ value }) {
    if (disabled) return;
    inputRef.current.value = value;
    setSelectedValue(value);
  }

  return (
    <label name={ name }>
      <input
        className={ hiddenInputClassNames }
        defaultValue={ initialSelectedValue }
        disabled={ disabled }
        name={ name }
        readOnly
        ref={ setUpRef }
        type="string"
      />

      <SegmentedToggle
        disabled={ disabled }
        handleClick={ handleClick }
        options={ options }
        selectedValue={ selectedValue }
      />
    </label>
  );
}

SegmentedButton.propTypes = {
  disabled             : PropTypes.bool,
  initialSelectedValue : PropTypes.string.isRequired,
  name                 : basePropTypes.name.isRequired,
  options              : PropTypes.arrayOf(PropTypes.shape({
    hint  : PropTypes.string,
    title : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
  })).isRequired,
  register       : basePropTypes.register.isRequired,
};

export default SegmentedButton;
