// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1vKfd,div.styles-module__Root___1vKfd.styles-module__RootExtraSpecificity___26tzy>div,div.styles-module__Root___1vKfd p{color:rgba(0,0,0,.65);height:100%;text-align:right;text-decoration:none;width:64px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/navigation/Header/RightSide/ExitLink/styles.module.scss"],"names":[],"mappings":"AAAA,+IAGE,qBAAA,CACA,WAAA,CACA,gBAAA,CACA,oBAAA,CACA,UAAA","file":"styles.module.scss","sourcesContent":[".Root,\ndiv.Root.RootExtraSpecificity > div,\ndiv.Root p {\n  color: rgba(0, 0, 0, 0.65);\n  height: 100%;\n  text-align: right;\n  text-decoration: none;\n  width: 64px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1vKfd",
	"RootExtraSpecificity": "styles-module__RootExtraSpecificity___26tzy"
};
module.exports = exports;
