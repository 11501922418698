import { PropTypes } from 'prop-types';
import React from 'react';

import Card from 'src/components/Card';

import styles from './styles.module.scss';

function ChartCard(props) {
  const {
    children,
  } = props;

  return (
    <Card extraClassNames={ styles.Root }>
      { children }
    </Card>
  );
}

ChartCard.propTypes = {
  children : PropTypes.node.isRequired,
};

export default ChartCard;
