import { PropTypes } from 'prop-types';
import React from 'react';

import DoubleArrowsReversedDirectionsIcon from 'src/components/icons/DoubleArrowsReversedDirections';
import OpenedLockIcon from 'src/components/icons/OpenedLock';
import ThreePeopleIcon from 'src/components/icons/ThreePeople';

import styles from './styles.module.scss';

function TextBadge({ icon, text }) {
  return (
    <div
      className={ styles.Root }
      data-automated-test="text-badge"
    >
      <Choose>
        <When condition={ icon === 'reversedCopies' }>
          <div className={ styles.ReversedCopiesIcon }>
            <DoubleArrowsReversedDirectionsIcon />
          </div>
        </When>

        <When condition={ icon === 'public' }>
          <div className={ styles.PublicIcon }>
            <ThreePeopleIcon />
          </div>
        </When>

        <When condition={ icon === 'unlisted' }>
          <div className={ styles.UnlistedIcon }>
            <OpenedLockIcon />
          </div>
        </When>
      </Choose>

      <p>{ text }</p>
    </div>
  );
}

TextBadge.propTypes = {
  icon : PropTypes.string,
  text : PropTypes.string.isRequired,
};

export default TextBadge;
