import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';

import AuthenticationForm from 'src/components/forms/Authentication';
import BaseModal from 'src/components/modals/Base';
import GoogleAuth from 'src/components/forms/GoogleAuth';
import OrLinesContainer from 'src/components/OrLinesContainer';
import OtpConfirmation from 'src/components/forms/OtpConfirmation';
import SignInPropsContext from 'src/contexts/SignInProps';

import styles from './styles.module.scss';

function AuthenticationModal(props) {
  const {
    closeModal,
    shouldUseVerifyOtpWithCallback,
    unauthedHeading,
    unauthedPara,
  } = props;

  const {
    emailSentTo,
    errors,
    isActive,
    shouldDisplayVerifyOtp,
    shouldFireConfetti,
    signIn,
    verifyOtp,
    verifyOtpWithCallback,
  } = useContext(SignInPropsContext);

  function handleOtpConfirmationSubmit(values) {
    if (shouldUseVerifyOtpWithCallback) return verifyOtpWithCallback(values);

    return verifyOtp(values);
  }

  return (
    <BaseModal
      closeModal={ closeModal }
      extraClasses={ { container : styles.Modal } }
      hasCloseButton
    >
      <Choose>
        <When condition={ shouldDisplayVerifyOtp }>
          <div>
            <p className={ styles.ParagraphContent }>
              Great!{ ' ' }

              <strong>
                We’ve emailed a one time password to { emailSentTo }
              </strong>.

              You’re almost there. Just enter what we sent below.
            </p>

            <OtpConfirmation
              errors={ errors }
              handleSubmit={ handleOtpConfirmationSubmit }
              isActive={ isActive }
              shouldFireConfetti={ shouldFireConfetti }
            />
          </div>
        </When>

        <Otherwise>
          <div>
            <h3 className={ styles.Title }>
              <Choose>
                <When condition={ unauthedHeading }>
                  { unauthedHeading }
                </When>

                <Otherwise>
                  Hi! Let’s sign you up or log you in before going there.
                </Otherwise>
              </Choose>
            </h3>

            <GoogleAuth
              fullWidth
              isActive={ isActive }
              shouldRedirectToThisPage
            />

            <OrLinesContainer />

            <p className={ styles.ParagraphContent }>
              <Choose>
                <When condition={ unauthedPara }>
                  { unauthedPara }
                </When>

                <Otherwise>
                  Enter your email and we’ll send you a one time password.
                  After you enter that password, you will be logged in
                  and will have full access to Dorothy.
                </Otherwise>
              </Choose>
            </p>

            <AuthenticationForm
              errors={ errors }
              fullWidth
              handleSubmit={ signIn }
              isActive={ isActive }
            />
          </div>
        </Otherwise>
      </Choose>
    </BaseModal>
  );
}

AuthenticationModal.propTypes = {
  closeModal                     : PropTypes.func.isRequired,
  shouldUseVerifyOtpWithCallback : PropTypes.bool,
  unauthedHeading                : PropTypes.string,
  unauthedPara                   : PropTypes.string,
};

export default AuthenticationModal;
