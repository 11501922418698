import React from 'react';

import AllDevicesLogo from 'src/components/icons/AllDevices';
import BrainInArrowsCircleLogo from 'src/components/icons/BrainInArrowsCircle';
import FaceOnlyLogo from 'src/components/icons/LogoFaceOnly';

import Benefit from './Benefit';
import CircleSection from './CircleSection';
import styles from './styles.module.scss';

function InfoSection() {
  const circlesText = [
    'Learn something',
    'Add it to Dorothy',
    'Take your quizzes',
    'Let Dorothy do its thing',
  ];

  return (
    <div className={ styles.Root }>
      <div className={ styles.Mobile }>
        <div className={ styles.HeadingContainer }>
          <h2 className={ styles.Heading }>Welcome to Dorothy.</h2>

          <h2 className={ styles.HeadingPara }>
            Log in or sign up to get started.
          </h2>
        </div>
      </div>

      <div className={ styles.Desktop }>
        <div className={ styles.HeadingContainer }>
          <h1 className={ styles.Heading }>Welcome to <span>Dorothy</span></h1>

          <p className={ styles.HeadingPara }>
            A spaced repetition tool that sends you a daily quiz with anything
            you’re studying and about to forget.
          </p>
        </div>

        <div className={ styles.CirclesContainer }>
          <For each="text" index="i" of={ circlesText }>
            <CircleSection index={ i } key={ text } text={ text } />
          </For>
        </div>

        <div className={ styles.BenefitsContainer }>
          <h2 className={ styles.BenefitsTitle }>Benefits of Dorothy</h2>

          <div className={ styles.Benefits }>
            <Benefit
              logo={ <FaceOnlyLogo /> }
              title="Take the passive approach"
            >
              Never ask yourself “what should I study today?” or
              “when should I study this again?”. Dorothy takes care
              of all of that for you.
            </Benefit>

            <Benefit
              logo={ <BrainInArrowsCircleLogo /> }
              title="Deeper cognitive processing"
            >
              Dorothy predicts, <strong>to the day</strong>, when you’ll forget
              an answer and quizzes you the day before to keep it in your memory.
              This allows you to memorize faster and form stronger neural connections.
            </Benefit>

            <Benefit
              logo={ <AllDevicesLogo /> }
              title="Available on all devices"
            >
              Whether that’s desktop, tablet, or mobile, Dorothy will
              help you retain anything you’re learning in the most
              effective and efficient way possible.
            </Benefit>
          </div>
        </div>
      </div>
    </div>
  );
}

InfoSection.propTypes = {
};

export default InfoSection;
