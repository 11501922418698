import { Link } from 'react-router-dom';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import StatsPlaceholder from 'src/components/StatsPlaceholder';
import Stat from 'src/components/Stat';

import buildReadableStats from './buildReadableStats';
import styles from './styles.module.scss';

function Stats(props) {
  const {
    statsDashboardReport,
  } = props;

  const stats = buildReadableStats(statsDashboardReport);

  function handleViewMoreClick() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('View more stats link clicked');
  }

  return (
    <div className={ styles.Root }>
      <h3 className={ styles.Heading }>Stats</h3>

      <Choose>
        <When condition={ !!stats }>
          <div className={ styles.Container }>
            <div
              className={ styles.StatsContainer }
              data-automated-test="dashboard--stats"
            >
              <Stat noHint stat={ stats.totalAnswersMemorized } />
              <Stat noHint stat={ stats.averageFutureRetention } />
              <Stat noHint stat={ stats.quizStreak } />
            </div>

            <Link
              className={ styles.ViewMoreLink }
              onClick={ handleViewMoreClick }
              to="/stats"
            >view more</Link>
          </div>
        </When>

        <Otherwise>
          <div className={ styles.StatsPlaceholderContainer }>
            <StatsPlaceholder />
          </div>
        </Otherwise>
      </Choose>
    </div>
  );
}

Stats.propTypes = {
  statsDashboardReport : statsPropTypes.dashboardReport.isRequired,
};

export default Stats;
