import { PropTypes } from 'prop-types';
import React, { memo, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import BarChartIcon from 'src/components/icons/BarChart';
import EllipsesIcon from 'src/components/icons/Ellipses';
import Footer from 'src/components/navigation/Footer';
import HomeIcon from 'src/components/icons/Home';
import LibraryIcon from 'src/components/icons/Library';
import PlusCircleIcon from 'src/components/icons/PlusCircle';

import Desktop from './Desktop';
import ExtraLinks from './ExtraLinks';
import FooterItem from './FooterItem';
import Unauthed from './Unauthed';

import styles from './styles.module.scss';

function SiteWideLinks(props) {
  const {
    closeBanner,
    isBannerClosed,
    isCloserToSun,
    isUnauthed,
    location : { pathname },
  } = props;

  const [shouldShowExtraLinks, setShouldShowExtraLinks] = useState(false);

  return (
    <Choose>
      <When condition={ isUnauthed }>
        <Unauthed
          isCloserToSun={ isCloserToSun }
          pathname={ pathname }
        />
      </When>

      <Otherwise>
        <div className={ styles.Header }>
          <Desktop
            closeBanner={ closeBanner }
            isBannerClosed={ isBannerClosed }
            isCloserToSun={ isCloserToSun }
            pathname={ pathname }
          />
        </div>

        <div className={ styles.Footer }>
          <If condition={ shouldShowExtraLinks }>
            <ExtraLinks />
          </If>

          <Footer>
            <FooterItem
              isActive={ pathname === '/' }
              title="Home"
              to="/"
            >
              <div className={ styles.HomeIcon }>
                <HomeIcon color="#262322" />
              </div>
            </FooterItem>

            <FooterItem
              dataAutomatedTest="site-wide-links--stats"
              isActive={ pathname === '/stats' }
              title="Stats"
              to="/stats"
            >
              <div className={ styles.BarChartIcon }>
                <BarChartIcon />
              </div>
            </FooterItem>

            <FooterItem
              isActive={ pathname === '/study-sets/new' }
              title={ window.innerWidth > 366 ? 'Add study set' : 'Add' }
              to="/study-sets/new"
            >
              <div className={ styles.PlusCircleIcon }>
                <PlusCircleIcon color="#262322" />
              </div>
            </FooterItem>

            <FooterItem
              dataAutomatedTest="site-wide-links--study-sets"
              isActive={ pathname === '/study-sets' }
              title="Study sets"
              to="/study-sets"
            >
              <div className={ styles.LibraryIcon }>
                <LibraryIcon />
              </div>
            </FooterItem>

            <FooterItem
              dataAutomatedTest="site-wide-links--ellipses"
              handleClick={ () => setShouldShowExtraLinks(!shouldShowExtraLinks) }
            >
              <div className={ styles.EllipsesIcon }>
                <EllipsesIcon />
              </div>
            </FooterItem>
          </Footer>
        </div>
      </Otherwise>
    </Choose>
  );
}

SiteWideLinks.propTypes = {
  isCloserToSun : PropTypes.bool,
  isUnauthed    : PropTypes.bool,
  location      : basePropTypes.location.isRequired,
};

export default memo(SiteWideLinks);
