import React, { useEffect, useState } from 'react';

import * as profilePropTypes from 'src/constants/propTypes/profile';

import Notification from './Notification';
import styles from './styles.module.scss';

function Notifications(props) {
  const {
    handleNotificationSettingUpdate,
    notificationSettings,
  } = props;

  const notificationSetting = notificationSettings
    .find(setting => setting.get('medium') === 'email');

  // TODO: temp
  return (
    <div className={ styles.Root }>
      <Notification
        handleNotificationSettingUpdate={ handleNotificationSettingUpdate }
        notificationSetting={ notificationSetting }
      />
    </div>
  );

  // eslint-disable-next-line no-unreachable
  const [isOnAppleDevice, setIsOnAppleDevice] = useState(false);
  const [isOnSafari, setIsOnSafari] = useState(false);
  const [isServiceWorkerRegistered, setIsServiceWorkerRegistered] = useState(true);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations()
        .then(registrations => setIsServiceWorkerRegistered(!!registrations.length));
    } else {
      setIsServiceWorkerRegistered(false);
    }

    setIsOnAppleDevice(['iPad', 'iPhone', 'iPod'].includes(navigator.platform));
    setIsOnSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);

  // eslint-disable-next-line no-unreachable
  return (
    <div className={ styles.Root }>
      <For each="notificationSetting" of={ notificationSettings }>
        <Notification
          handleNotificationSettingUpdate={ handleNotificationSettingUpdate }
          isOnAppleDevice={ isOnAppleDevice }
          isOnSafari={ isOnSafari }
          isServiceWorkerRegistered={ isServiceWorkerRegistered }
          key={ notificationSetting.get('id') }
          notificationSetting={ notificationSetting }
        />
      </For>
    </div>
  );
}

Notifications.propTypes = {
  handleNotificationSettingUpdate : profilePropTypes.handleNotificationSettingUpdate.isRequired,
  notificationSettings            : profilePropTypes.notificationSettings.isRequired,
};

export default Notifications;
