import ClipLoader from 'react-spinners/ClipLoader';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function SubmitButton(props) {
  const {
    extraClassNames,
    isActive,
    isPositiveAction,
    submitAction,
    submitActionName,
  } = props;

  function getTitleClassNames() {
    return cx({ Title : true, isActive, isPositiveAction });
  }

  function handleSubmit() {
    if (isActive) return;

    return submitAction();
  }

  return (
    <div
      className={ `${styles.Root} ${extraClassNames}` }
      data-automated-test="confirmation-modal__submit-click"
      onClick={ handleSubmit }
    >
      <p className={ getTitleClassNames() }>{ submitActionName }</p>

      <If condition={ isActive }>
        <div className={ styles.Loader }>
          <ClipLoader
            color="#bdbdbd"
            isLoading={ isActive }
            size={ 15 }
          />
        </div>
      </If>
    </div>
  );
}

SubmitButton.propTypes = {
  extraClassNames  : PropTypes.string,
  isActive         : basePropTypes.isActive,
  isPositiveAction : basePropTypes.isPositiveAction,
  submitAction     : basePropTypes.submitAction.isRequired,
  submitActionName : basePropTypes.submitActionName.isRequired,
};

export default SubmitButton;
