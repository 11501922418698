import immutableTransform from 'redux-persist-transform-immutable';
import { persistReducer, persistStore } from 'redux-persist';

import reducers from 'src/reducers';

class Shared {
  constructor(storage) {
    this.storage = storage;

    this.setStore();
    this.persistor = persistStore(this.store);
  }

  getPersistor() {
    return this.persistor;
  }

  getStore() {
    return this.store;
  }

  clearStore() {
    return this.persistor.flush();
  }

  // private

  _buildPersistedReducer() {
    return persistReducer(
      {
        key        : 'root',
        storage    : this.storage,
        transforms : [immutableTransform()],
      },
      reducers,
    );
  }
}

export default Shared;
