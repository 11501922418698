import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildOptions from 'src/components/shared/buildOptions';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';

import styles from './styles.module.scss';

function Form(props) {
  const {
    allStudySets,
    control,
    defaultMultiSelectValue,
    isEditing,
    isUpdating,
    onSubmit,
  } = props;

  const options = buildOptions(allStudySets);

  return (
    <form onSubmit={ onSubmit }>
      <Choose>
        <When condition={ !isEditing }>
          <p className={ styles.FormDescription }>
            You currently have <strong>only</strong> the
            questions from these study sets appearing in your quizzes:
          </p>
        </When>

        <Otherwise>
          <p className={ styles.FormDescription }>
            Choose which study sets will have their questions
            appear in your quizzes
          </p>
        </Otherwise>
      </Choose>

      <div className={ styles.SelectContainer }>
        <Controller
          control={ control }
          defaultValue={ defaultMultiSelectValue || [] }
          name="studySets"
          render={ ({ onChange, value }) => (
            <Select
              backspaceRemovesValue
              classNamePrefix="Select"
              isDisabled={ !isEditing }
              isClearable
              isMulti
              onChange={ onChange }
              options={ options || [] }
              placeholder="Study sets"
              value={ value }
            />
          ) }
        />
      </div>

      <If condition={ isEditing }>
        <ButtonSubmit
          disabled={ isUpdating }
          isActive={ isUpdating }
          isBelowInput
          type="submit"
          value="Save"
        />
      </If>
    </form>
  );
}

Form.propTypes = {
  allStudySets            : studySetPropTypes.studySets.isRequired,
  control                 : basePropTypes.control.isRequired,
  defaultMultiSelectValue : basePropTypes.defaultMultiSelectValue.isRequired,
  isEditing               : basePropTypes.isEditing,
  isUpdating              : basePropTypes.isUpdating,
  onSubmit                : basePropTypes.onSubmit.isRequired,
};

export default Form;
