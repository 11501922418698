import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import BaseModal from 'src/components/modals/Base';
import ConfirmationModal from 'src/components/modals/Confirmation';
import HorizontalLine from 'src/components/HorizontalLine';
import SubmitButton from 'src/components/modals/Confirmation/SubmitButton';

import styles from './styles.module.scss';

function DeletionModalAndLinks(props) {
  const {
    closeInitialModal,
    deleteStudySet,
    isInitialModalOpen,
    questionsSize,
  } = props;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);

  function closeDeleteModal() { setIsDeleteModalOpen(false); }
  function openDeleteModal() { setIsDeleteModalOpen(true); }
  function closeDeleteAllModal() { setIsDeleteAllModalOpen(false); }
  function openDeleteAllModal() { setIsDeleteAllModalOpen(true); }
  function deleteAll() { deleteStudySet({ shouldDestroyAll : true }); }

  function selectDeleteAll() {
    closeDeleteModal();
    closeInitialModal();
    openDeleteAllModal();
  }

  function selectDelete() {
    closeDeleteAllModal();
    closeInitialModal();
    openDeleteModal();
  }

  return (
    <>
      <If condition={ isInitialModalOpen }>
        <BaseModal
          closeModal={ closeInitialModal }
          extraClasses={ { container : styles.Container } }
        >
          <SubmitButton
            extraClassNames={ styles.SelectDeleteButton }
            submitAction={ selectDelete }
            submitActionName="Delete study set"
          />

          <HorizontalLine />

          <If condition={ questionsSize && questionsSize > 0 }>
            <SubmitButton
              extraClassNames={ styles.SelectDeleteAllButton }
              submitAction={ selectDeleteAll }
              submitActionName="Delete study set and all of its questions"
            />

            <HorizontalLine />
          </If>

          <div
            className={ styles.CancelButton }
            onClick={ closeInitialModal }
          >
            <p>Cancel</p>
          </div>
        </BaseModal>
      </If>

      <If condition={ isDeleteModalOpen }>
        <ConfirmationModal
          closeModal={ closeDeleteModal }
          submitAction={ deleteStudySet }
          submitActionName="Delete study set"
        >
          You’ll keep all of your questions but your
          study set will be erased forever. Again, your
          questions will NOT be erased. But they will no
          longer be associated with a study set.
        </ConfirmationModal>
      </If>

      <If condition={ isDeleteAllModalOpen }>
        <ConfirmationModal
          closeModal={ closeDeleteAllModal }
          submitAction={ deleteAll }
          submitActionName="Delete all"
        >
          You will permanently lose all of your questions
          in this study set and the study set itself.
          This is not reversible. The interval history
          used by our algorithms to determine your
          intervals for these questions will be lost forever.
        </ConfirmationModal>
      </If>
    </>
  );
}

DeletionModalAndLinks.propTypes = {
  closeInitialModal  : PropTypes.func.isRequired,
  deleteStudySet     : PropTypes.func.isRequired,
  isInitialModalOpen : PropTypes.bool.isRequired,
  questionsSize      : PropTypes.number.isRequired,
};

export default DeletionModalAndLinks;
