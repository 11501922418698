// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (min-width: 768px){.styles-module__Fields___3oYe3{margin-top:36px}}@media screen and (min-width: 1080px){.styles-module__Fields___3oYe3{width:100%}}.styles-module__Heading___2N6Xd{margin-bottom:36px}.styles-module__Answer___167NQ{margin-top:24px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/forms/Question/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,+BACE,eAAA,CAAA,CAEJ,sCACE,+BACE,UAAA,CAAA,CAEJ,gCACE,kBAAA,CAEF,+BACE,eAAA","file":"styles.module.scss","sourcesContent":["@media screen and (min-width: 768px) {\n  .Fields {\n    margin-top: 36px; } }\n\n@media screen and (min-width: 1080px) {\n  .Fields {\n    width: 100%; } }\n\n.Heading {\n  margin-bottom: 36px; }\n\n.Answer {\n  margin-top: 24px; }\n"]}]);
// Exports
exports.locals = {
	"Fields": "styles-module__Fields___3oYe3",
	"Heading": "styles-module__Heading___2N6Xd",
	"Answer": "styles-module__Answer___167NQ"
};
module.exports = exports;
