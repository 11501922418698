import gql from 'graphql-tag';

export default gql`
  query statsDashboardReport {
    statsDashboardReport {
      avgFutureRetention
      completedQuizzesSize
      lastQuizWasStacked
      quizStreak
      totalAnswersMemorized
    }
  }
`;
