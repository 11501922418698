import React from 'react';

import styles from './styles.module.scss';

function Close() {
  return (
    <svg
      className={ styles.Root }
      viewBox="0 0 24 30"
      x="0px"
      y="0px"
    >
        <path d="M12,1 C12.5128358,1 12.9355072,1.4718269 12.9932723,2.07968529 L13,2.22222222 L13,11 L21.7777778,11 C22.4527925,11 23,11.4477153 23,12 C23,12.5128358 22.5281731,12.9355072 21.9203147,12.9932723 L21.7777778,13 L13,13 L13,21.7777778 C13,22.4527925 12.5522847,23 12,23 C11.4871642,23 11.0644928,22.5281731 11.0067277,21.9203147 L11,21.7777778 L11,13 L2.22222222,13 C1.54720753,13 1,12.5522847 1,12 C1,11.4871642 1.4718269,11.0644928 2.07968529,11.0067277 L2.22222222,11 L11,11 L11,2.22222222 C11,1.54720753 11.4477153,1 12,1 Z" fillRule="nonzero" transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) "/>
    </svg>
  );
}

export default Close;
