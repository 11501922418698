import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';

export default function ({ path }) {
  const userId = getUserIdFromStore();

  if (userId) analytics.identify(userId);

  analytics.track(`Back link clicked on ${path}`);
}
