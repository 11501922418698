// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__FormDescription___2fdsk{line-height:16px;max-width:85%}.styles-module__FormDescription___2fdsk strong{line-height:16px}.styles-module__SelectContainer___1Nnxv{margin-top:12px;max-width:85%}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/profile/Preferences/ChooseStudySets/Form/styles.module.scss"],"names":[],"mappings":"AAAA,wCACE,gBAAA,CACA,aAAA,CACA,+CACE,gBAAA,CAEJ,wCACE,eAAA,CACA,aAAA","file":"styles.module.scss","sourcesContent":[".FormDescription {\n  line-height: 16px;\n  max-width: 85%; }\n  .FormDescription strong {\n    line-height: 16px; }\n\n.SelectContainer {\n  margin-top: 12px;\n  max-width: 85%; }\n"]}]);
// Exports
exports.locals = {
	"FormDescription": "styles-module__FormDescription___2fdsk",
	"SelectContainer": "styles-module__SelectContainer___1Nnxv"
};
module.exports = exports;
