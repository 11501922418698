import { Link } from 'react-router-dom';
import React from 'react';

import HorizontalLine from 'src/components/HorizontalLine';
import LinkToPageIcon from 'src/components/icons/LinkToPage';

import styles from './styles.module.scss';

function ExtraLinks() {
  const links = [
    {
      title : 'All questions',
      path  : '/questions',
    },
    {
      title : 'Public study sets',
      path  : '/public-study-sets',
    },
    {
      title : 'Profile',
      path  : '/profile',
    },
  ];

  return (
    <div className={ styles.Root }>
      <ul className={ styles.Links }>
        <For each="link" of={ links }>
          <li key={ link.path }>
            <Link className={ styles.Link } to={ link.path }>
              { link.title }
            </Link>
          </li>
        </For>

        <li className={ styles.HorizontalLine }>
          <HorizontalLine />
        </li>

        <li className={ styles.LogOut }>
          <Link className={ styles.Link } to={ '/auth/sign-out' }>
            <LinkToPageIcon />
            <p>Log out</p>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default ExtraLinks;
