import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

function Tooltip({ target : { dataset : { rh } } }) {
  return (
    <div className={ styles.Root }>
      { rh }
    </div>
  );
}

Tooltip.propTypes = {
  target : basePropTypes.target.isRequired,
};

export default Tooltip;
