import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import Logo from 'src/assets/face-logo.png';

import styles from './styles.module.scss';

function FaceOnly({ dataAutomatedTest }) {
  return (
    <div
      className={ styles.Root }
      data-automated-test={ dataAutomatedTest }
    >
      <img className={ styles.Image } src={ Logo } />
    </div>
  );
}

FaceOnly.propTypes = {
  dataAutomatedTest : basePropTypes.dataAutomatedTest,
};

export default FaceOnly;
