import actionTypes from 'src/constants/actionTypes';
import questionsService from 'src/services/questions';

function handleCommonQuestionAnswerAction(actionPrefix, serviceAction, values) {
  return dispatch => {
    dispatch({ type : actionTypes[`QUESTION__${actionPrefix}_START`] });

    return questionsService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`QUESTION__${actionPrefix}_SUCCESS`],
          payload : { answer : { ...data[key] } },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`QUESTION__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

function handleCommonQuestionAction(actionPrefix, serviceAction, values) {
  return dispatch => {
    dispatch({ type : actionTypes[`QUESTION__${actionPrefix}_START`] });

    return questionsService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`QUESTION__${actionPrefix}_SUCCESS`],
          payload : { question : { ...data[key] } },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`QUESTION__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function addErrors(errors) {
  return {
    type    : actionTypes.QUESTION__ADD_ERRORS,
    payload : { errors },
  };
}

export function clearTempState() {
  return { type : actionTypes.QUESTION__CLEAR_TEMP_STATE };
}

export function create(values) {
  return handleCommonQuestionAction('CREATE', 'create', values);
}

export function deleteAnswerAudio(values) {
  return handleCommonQuestionAnswerAction('DELETE_ANSWER_AUDIO', 'deleteAnswerAudio', values);
}

export function deleteAnswerImage(values) {
  return handleCommonQuestionAnswerAction('DELETE_ANSWER_IMAGE', 'deleteAnswerImage', values);
}

export function deleteAudio(values) {
  return handleCommonQuestionAction('DELETE_AUDIO', 'deleteAudio', values);
}

export function deleteImage(values) {
  return handleCommonQuestionAction('DELETE_IMAGE', 'deleteImage', values);
}

export function deleteQuestion(values) {
  return handleCommonQuestionAction('DELETE', 'deleteQuestion', values);
}

export function show(values) {
  return handleCommonQuestionAction('SHOW', 'show', values);
}

export function update(values) {
  return handleCommonQuestionAction('UPDATE', 'update', values);
}
