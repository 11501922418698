import React from 'react';

import styles from './styles.module.scss';

function Home() {
  return (
    <svg
      className={ styles.Root }
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      xmlSpace="preserve"
    >
      <path
        d="M11.2,49.6c-0.1-0.3-0.2-0.7-0.2-1c0-0.7,0.3-1.4,1-2.1l36.4-32.5c0.5-0.4,1-0.6,1.4-0.6l1.4,0.6l36.3,32.5  c0.7,0.6,1,1.3,1,2.1c0,0.3-0.1,0.7-0.2,1c-0.4,1.1-1.4,1.7-2.8,1.7h-5.8V84H58V64.3c0-1.1-0.9-1.9-1.9-1.9H43.6  c-1.1,0-1.9,0.9-1.9,1.9V84H19.8V51.3H14C12.6,51.3,11.7,50.7,11.2,49.6z"
      />
    </svg>
  );
}

export default Home;
