import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from 'src/reducers/questions/initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.QUESTION__CLEAR_TEMP_STATE:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
        map.set('isSuccessful', null);
      });

    case actionTypes.QUESTION__ADD_ERRORS:
    case actionTypes.QUESTION__CREATE_FAILURE:
    case actionTypes.QUESTION__DELETE_ANSWER_AUDIO_FAILURE:
    case actionTypes.QUESTION__DELETE_ANSWER_IMAGE_FAILURE:
    case actionTypes.QUESTION__DELETE_AUDIO_FAILURE:
    case actionTypes.QUESTION__DELETE_FAILURE:
    case actionTypes.QUESTION__DELETE_IMAGE_FAILURE:
    case actionTypes.QUESTION__SHOW_FAILURE:
    case actionTypes.QUESTION__UPDATE_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
        map.set('isSaving', false);
      });

    case actionTypes.QUESTION__CREATE_START:
    case actionTypes.QUESTION__UPDATE_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
        map.set('isSaving', true);
      });

    case actionTypes.QUESTION__DELETE_ANSWER_AUDIO_START:
    case actionTypes.QUESTION__DELETE_ANSWER_IMAGE_START:
    case actionTypes.QUESTION__DELETE_AUDIO_START:
    case actionTypes.QUESTION__DELETE_IMAGE_START:
    case actionTypes.QUESTION__DELETE_START:
    case actionTypes.QUESTION__SHOW_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.QUESTION__DELETE_SUCCESS:
      return state.withMutations(map => {
        const { question } = payload;

        map.set('isActive', false);
        map.deleteIn(['loaded', 'questions', question.id]);
      });

    case actionTypes.QUESTION__CREATE_SUCCESS:
    case actionTypes.QUESTION__DELETE_AUDIO_SUCCESS:
    case actionTypes.QUESTION__DELETE_IMAGE_SUCCESS:
    case actionTypes.QUESTION__UPDATE_SUCCESS:
      return state.withMutations(map => {
        const { question } = payload;

        map.set('isActive', false);
        map.set('isSaving', false);
        map.set('isSuccessful', true);
        map.setIn(['loaded', 'questions', question.id], Immutable.fromJS(question));
      });

    case actionTypes.QUESTION__DELETE_ANSWER_AUDIO_SUCCESS:
    case actionTypes.QUESTION__DELETE_ANSWER_IMAGE_SUCCESS:
      return state.withMutations(map => {
        const { answer } = payload;

        map.set('isActive', false);
        map.set('isSuccessful', true);
        map.setIn(['loaded', 'questions', answer.questionId, 'answer'], Immutable.fromJS(answer));
      });

    case actionTypes.QUESTION__SHOW_SUCCESS:
      return state.withMutations(map => {
        const { question } = payload;

        map.set('isActive', false);
        map.setIn(['loaded', 'questions', question.id], Immutable.fromJS(question));
      });

    default:
      return state;
  }
}
