import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import HomeLink from 'src/components/navigation/HomeLink';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import styles from './styles.module.scss';

function NotFound(props) {
  return (
    <div className={ styles.Root }>
      <HomeLink />

      <h3>
        Oh no! It looks like we may have sent you to the wrong page.
        We can’t seem to find it anywhere!
      </h3>

      <p>
        No worries. Even though this page wasn’t found, nothing crashed and
        all of your data is intact. Feel free to click the home link to
        return to your dashboard.
      </p>

      <SiteWideLinks location={ props.location } />
    </div>
  );
}

NotFound.propTypes = {
  location : basePropTypes.location.isRequired,
};

export default NotFound;
