import React from 'react';

import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import FormattedQuestionContent from 'src/components/FormattedQuestionContent';

import styles from './styles.module.scss';

function QuestionContent({ hasQuizStarted, question, questionNumber }) {
  const title = question.get('title');

  return (
    <div>
      <input
        data-automated-test="quiz-question-title-input"
        type="hidden"
        value={ title }
      />

      <div className={ styles.FormattedQuestionContent }>
        <FormattedQuestionContent
          audioSrc={ question.get('audioUrl') }
          imageSrc={ question.get('imageUrl') }
          value={ title }
        />
      </div>

      <If condition={ !hasQuizStarted && !question.get('timeTaken') && questionNumber === '1' }>
        <div className={ styles.RevealAnswerSubText }>
          Give yourself some time to try and recall the answer before revealing it
        </div>
      </If>
    </div>
  );
}

QuestionContent.propTypes = {
  hasQuizStarted : quizPropTypes.hasQuizStarted,
  question       : questionPropTypes.question.isRequired,
  questionNumber : quizPropTypes.questionNumber.isRequired,
};

export default QuestionContent;
