import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as questionsActions from 'src/actions/questions';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetsActions from 'src/actions/studySets';

import buildValuesFromElementsWithFiles from 'src/containers/shared/buildValuesFromElementsWithFiles';
import navigateOrWait from 'src/containers/shared/navigateOrWait';
import QuestionForm from 'src/components/forms/Question';

class FunctionalNew extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    history  : basePropTypes.history.isRequired,
    isActive : basePropTypes.isActive,
  };

  constructor() {
    super();
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.questions.clearTempState();
    actions.studySets.getAll();
  }

  async handleSave(values) {
    const { questions } = this.props.actions;

    const params = await buildValuesFromElementsWithFiles(values);

    return questions
      .create(params)
      .then(data => {
        if (window.gtag) {
          window.gtag('event', 'conversion_in_product_site', { value : 0 });
        }

        return this.navigateOrWait(data);
      })
      .catch(() => null);
  }

  navigateOrWait = ({ payload : { question : { id } } }) => navigateOrWait(
    this.props,
    `/questions/${id}`,
    { pathFrom : '/questions/new' },
  )

  render() {
    return (
      <QuestionForm
        handleSubmit={ this.handleSave }
        heading="New Question"
        { ...this.props }
      />
    );
  }
}

function mapStateToProps({ questions, studySets }) {
  return {
    errors       : questions.get('errors'),
    isActive     : questions.get('isActive'),
    isSaving     : questions.get('isSaving'),
    isSuccessful : questions.get('isSuccessful'),
    studySets    : studySets.getIn(['loaded', 'all']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      questions : bindActionCreators(questionsActions, dispatch),
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalNew);
