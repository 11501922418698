import React from 'react';

import styles from './styles.module.scss';

function Profile() {
  return (
    <svg
      className={ styles.Root }
      x="0px"
      y="0px"
      viewBox="0 0 64 80"
    >
      <circle cx="32" cy="21.3" r="10.7" />
      <path d="M59.6,20.3c-1.5-3.6-3.7-6.8-6.4-9.5c-2.8-2.8-6-4.9-9.5-6.4C40,2.8,36,2,32,2c-4,0-8,0.8-11.7,2.4  c-3.6,1.5-6.8,3.7-9.5,6.4c-2.8,2.8-4.9,6-6.4,9.5C2.8,24,2,28,2,32c0,3.8,0.7,7.4,2,10.9c1.3,3.4,3.2,6.4,5.6,9.1  c1.4,1.5,2.9,2.9,4.6,4.2c1.7,1.2,3.5,2.3,5.3,3.2c1.9,0.9,3.9,1.5,6,2c2.1,0.5,4.3,0.7,6.5,0.7c2.2,0,4.4-0.2,6.5-0.7  c2.1-0.5,4.1-1.1,6-2c1.9-0.9,3.7-1.9,5.3-3.2c1.7-1.2,3.2-2.6,4.6-4.2c2.4-2.7,4.3-5.8,5.6-9.1c1.4-3.5,2-7.1,2-10.9  C62,28,61.2,24,59.6,20.3z M53,49.2c-1.5-7.6-7-13.8-14.3-16.1c-2,1.1-4.3,1.8-6.7,1.8s-4.7-0.6-6.7-1.8C18,35.4,12.5,41.6,11,49.2  C7,44.3,4.9,38.3,4.9,32C4.9,17,17,4.9,32,4.9S59.1,17,59.1,32C59.1,38.3,57,44.3,53,49.2z" />
    </svg>

  );
}

export default Profile;
