import gql from 'graphql-tag';

import answerFields from './answerFields';

export default gql`
  fragment questionFields on QuestionType {
    answer { ...answerFields }
    audioUrl
    id
    imageUrl
    studySet {
      id
      title
    }
    title
  }
  ${answerFields}
`;
