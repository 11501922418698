import gql from 'graphql-tag';

export default gql`
  mutation deleteQuestion(
    $id: ID
  ) {
    deleteQuestion(
      id: $id
    ) {
      id
    }
  }
`;
