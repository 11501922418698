import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import analytics from 'src/shared/analytics';
import Breadcrumbs from 'src/components/navigation/Breadcrumbs';

import styles from './styles.module.scss';

function Navigation(props) {
  const {
    history,
    location,
    studySet,
    title,
    userId,
  } = props;

  function handleBackClick() {
    analytics.identify(userId);
    analytics.track('Back link clicked on /questions/:id');
    history.goBack();
  }

  const studySetsLabel = window.innerWidth > 768 ? 'Your study sets' : 'Study sets';

  return (
    <Choose>
      <When condition={ studySet && location.pathname.includes('/study-sets') }>
        <div className={ styles.LinkContainer }>
          <Breadcrumbs
            links={ [
              { label : studySetsLabel, path : '/study-sets' },
              { label : studySet.get('title'), path : `/study-sets/${studySet.get('id')}` },
              { label : title, isCurrent : true },
            ] }
          />
        </div>
      </When>

      <Otherwise>
        <div className={ styles.BackLinkContainer }>
          <p onClick={ handleBackClick }>back</p>
        </div>
      </Otherwise>
    </Choose>
  );
}

Navigation.propTypes = {
  history  : basePropTypes.history.isRequired,
  location : basePropTypes.location.isRequired,
  studySet : studySetPropTypes.studySet,
  title    : PropTypes.string,
  userId   : PropTypes.string,
};

export default Navigation;
