import actionTypes from 'src/constants/actionTypes';
import studySetsService from 'src/services/studySets';

function handleCommonStudySetAction(actionPrefix, serviceAction, values = null) {
  return dispatch => {
    dispatch({
      type    : actionTypes[`STUDY_SET__${actionPrefix}_START`],
      payload : { id : values && values.id },
    });

    return studySetsService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`STUDY_SET__${actionPrefix}_SUCCESS`],
          payload : { data : data[key] },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`STUDY_SET__${actionPrefix}_FAILURE`],
        payload : { errors, id : values && values.id },
      }));
  };
}

export function addQuestionToEdit(key, id) {
  return {
    type    : actionTypes.STUDY_SET__ADD_QUESTION_TO_EDIT,
    payload : { key, id },
  };
}

export function addQuestionToNew(key) {
  return {
    type    : actionTypes.STUDY_SET__ADD_QUESTION_TO_NEW,
    payload : { key },
  };
}

export function bulkAddQuestionsToEdit(newQuestions, id) {
  return {
    type    : actionTypes.STUDY_SET__BULK_ADD_QUESTIONS_TO_EDIT,
    payload : { newQuestions, id },
  };
}

export function bulkAddQuestionsToNew(newQuestions) {
  return {
    type    : actionTypes.STUDY_SET__BULK_ADD_QUESTIONS_TO_NEW,
    payload : { newQuestions },
  };
}

export function clearAutosavedEdit(id) {
  return {
    type    : actionTypes.STUDY_SET__CLEAR_AUTOSAVED_EDIT,
    payload : { id },
  };
}

export function clearAutosavedNew() {
  return { type : actionTypes.STUDY_SET__CLEAR_AUTOSAVED_NEW };
}

export function clearTempState() {
  return { type : actionTypes.STUDY_SET__CLEAR_TEMP_STATE };
}

export function create(values) {
  return handleCommonStudySetAction('CREATE', 'create', values);
}

export function deleteStudySet(values) {
  return handleCommonStudySetAction('DELETE', 'deleteStudySet', values);
}

export function getAll() {
  return handleCommonStudySetAction('GET_ALL', 'getAll');
}

export function removeAnswerAudio(key, id) {
  return {
    type    : actionTypes.STUDY_SET__REMOVE_ANSWER_AUDIO,
    payload : { key, id },
  };
}

export function removeAnswerImage(key, id) {
  return {
    type    : actionTypes.STUDY_SET__REMOVE_ANSWER_IMAGE,
    payload : { key, id },
  };
}

export function removeQuestionAudio(key, id) {
  return {
    type    : actionTypes.STUDY_SET__REMOVE_QUESTION_AUDIO,
    payload : { key, id },
  };
}

export function removeQuestionFromEdit(key, id) {
  return {
    type    : actionTypes.STUDY_SET__REMOVE_QUESTION_FROM_EDIT,
    payload : { key, id },
  };
}

export function removeQuestionFromNew(key) {
  return {
    type    : actionTypes.STUDY_SET__REMOVE_QUESTION_FROM_NEW,
    payload : { key },
  };
}

export function removeQuestionImage(key, id) {
  return {
    type    : actionTypes.STUDY_SET__REMOVE_QUESTION_IMAGE,
    payload : { key, id },
  };
}

export function setAutosavedEdit(values) {
  return handleCommonStudySetAction('SET_AUTOSAVED_EDIT', 'show', values);
}

export function show(values) {
  return handleCommonStudySetAction('SHOW', 'show', values);
}

export function startSave() {
  return { type : actionTypes.STUDY_SET__START_SAVE };
}

export function undoRemoveQuestionFromEdit(key, id) {
  return {
    type    : actionTypes.STUDY_SET__UNDO_REMOVE_QUESTION_FROM_EDIT,
    payload : { key, id },
  };
}

export function update(values) {
  return handleCommonStudySetAction('UPDATE', 'update', values);
}

export function updateValueInAutosaved(keys, value) {
  return {
    type    : actionTypes.STUDY_SET__UPDATE_VALUE_IN_AUTOSAVED,
    payload : { keys, value },
  };
}
