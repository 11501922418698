// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1_OU2{height:20px;width:20px}.styles-module__Root___1_OU2.styles-module__disabled___16eMa .styles-module__Path___3Ji2_{cursor:not-allowed;fill:#bdbdbd}.styles-module__Path___3Ji2_{fill:#2071aa}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/icons/AudioUpload/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,UAAA,CACA,0FACE,kBAAA,CACA,YAAA,CAEJ,6BACE,YAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  height: 20px;\n  width: 20px; }\n  .Root.disabled .Path {\n    cursor: not-allowed;\n    fill: #bdbdbd; }\n\n.Path {\n  fill: #2071aa; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1_OU2",
	"disabled": "styles-module__disabled___16eMa",
	"Path": "styles-module__Path___3Ji2_"
};
module.exports = exports;
