import gql from 'graphql-tag';

export default gql`
  query checkLastEmailStatus(
    $emailSentTo: String!
  ) {
    checkLastEmailStatus(
      emailSentTo: $emailSentTo
    ) {
      message
      severity
      status
    }
  }
`;
