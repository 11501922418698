import gql from 'graphql-tag';

export default gql`
  fragment answerFields on AnswerType {
    audioUrl
    body
    id
    imageUrl
    questionId
  }
`;
