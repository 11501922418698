// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Para___cWias{color:rgba(0,0,0,.65);font-weight:600;margin-top:12px;opacity:1;text-align:center}.styles-module__Para___cWias.styles-module__shouldAnimate___2liTc{animation:styles-module__show___2AMK4 5.5s}@keyframes styles-module__show___2AMK4{0%{opacity:0}73%{opacity:0}100%{opacity:1}}.styles-module__Para___cWias strong{color:#0a3959;font-weight:bold}.styles-module__Title___2AOQp{color:#0a3959;margin-top:45px;opacity:1;text-align:center}.styles-module__Title___2AOQp.styles-module__shouldAnimate___2liTc{animation:styles-module__show___2AMK4 3s}@keyframes styles-module__show___2AMK4{0%{opacity:0}67%{opacity:0}100%{opacity:1}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/quiz/Congratulations/Paragraph/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,qBAAA,CACA,eAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,kEACE,0CAAA,CAEJ,uCACE,GACE,SAAA,CACF,IACE,SAAA,CACF,KACE,SAAA,CAAA,CACF,oCACE,aAAA,CACA,gBAAA,CAEJ,8BACE,aAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,mEACE,wCAAA,CAEJ,uCACE,GACE,SAAA,CACF,IACE,SAAA,CACF,KACE,SAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Para {\n  color: rgba(0, 0, 0, 0.65);\n  font-weight: 600;\n  margin-top: 12px;\n  opacity: 1;\n  text-align: center; }\n  .Para.shouldAnimate {\n    animation: show 5.5s; }\n\n@keyframes show {\n  0% {\n    opacity: 0; }\n  73% {\n    opacity: 0; }\n  100% {\n    opacity: 1; } }\n  .Para strong {\n    color: #0a3959;\n    font-weight: bold; }\n\n.Title {\n  color: #0a3959;\n  margin-top: 45px;\n  opacity: 1;\n  text-align: center; }\n  .Title.shouldAnimate {\n    animation: show 3s; }\n\n@keyframes show {\n  0% {\n    opacity: 0; }\n  67% {\n    opacity: 0; }\n  100% {\n    opacity: 1; } }\n"]}]);
// Exports
exports.locals = {
	"Para": "styles-module__Para___cWias",
	"shouldAnimate": "styles-module__shouldAnimate___2liTc",
	"show": "styles-module__show___2AMK4",
	"Title": "styles-module__Title___2AOQp"
};
module.exports = exports;
