import actionTypes from 'src/constants/actionTypes';
import searchService from 'src/services/search';

export function clearResults() {
  return { type : actionTypes.SEARCH__CLEAR_RESULTS };
}

export function clearTempState() {
  return { type : actionTypes.SEARCH__CLEAR_TEMP_STATE };
}

export function searchForUserQuestions(values) {
  return dispatch => {
    dispatch({ type : actionTypes.SEARCH__USER_QUESTIONS_START });

    return searchService
      .searchForUserQuestions(values)
      .then(({ searchForUserQuestions : questions }) => dispatch({
        type    : actionTypes.SEARCH__USER_QUESTIONS_SUCCESS,
        payload : { questions },
      }))
      .catch(errors => dispatch({
        type    : actionTypes.SEARCH__USER_QUESTIONS_FAILURE,
        payload : { errors },
      }));
  };
}
