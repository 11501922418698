// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__SubmitButton___3TupT{width:100%}.styles-module__SubmitButton___3TupT>input{box-shadow:0 2px 4px 1px rgba(38,35,34,.08);width:100%}.styles-module__SubmitButton___3TupT.styles-module__isActive___dMWDV>input{box-shadow:none}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/forms/OtpConfirmation/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,UAAA,CACA,2CACE,2CAAA,CACA,UAAA,CACF,2EACE,eAAA","file":"styles.module.scss","sourcesContent":[".SubmitButton {\n  width: 100%; }\n  .SubmitButton > input {\n    box-shadow: 0 2px 4px 1px rgba(38, 35, 34, 0.08);\n    width: 100%; }\n  .SubmitButton.isActive > input {\n    box-shadow: none; }\n"]}]);
// Exports
exports.locals = {
	"SubmitButton": "styles-module__SubmitButton___3TupT",
	"isActive": "styles-module__isActive___dMWDV"
};
module.exports = exports;
