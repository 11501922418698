import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

export const addCSVQuestionsToStudySet = PropTypes.func;
export const addQuestion = PropTypes.func;
export const addStudySet = PropTypes.func;

export const autosavedQuestion = ImmutablePropTypes.mapContains({
  answer : ImmutablePropTypes.mapContains({
    body : PropTypes.string,
  }),
  id    : PropTypes.string,
  title : PropTypes.string,
});

export const autosavedStudySet = ImmutablePropTypes.mapContains({
  description : PropTypes.string,
  id          : PropTypes.string,
  title       : PropTypes.string,
});

export const bulkAddQuestions = PropTypes.func;
export const deleteStudySet = PropTypes.func;
export const isPublic = PropTypes.bool;
export const isPublicDisabled = PropTypes.bool;
export const isQuestionsOptionsContainerOpen = PropTypes.bool;
export const isUndesignated = PropTypes.bool;

export const newStudySet = ImmutablePropTypes.mapContains({
  description    : PropTypes.string,
  id             : PropTypes.string,
  questionsCount : PropTypes.number,
  title          : PropTypes.string,
});

export const questionKey = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
export const removeQuestion = PropTypes.func;
export const reversedCopiesAdded = PropTypes.bool;
export const reversedCopiesAddedDisabled = PropTypes.bool;
export const setIsQuestionsOptionsContainerOpen = PropTypes.func;

export const studySet = ImmutablePropTypes.mapContains({
  description    : PropTypes.string,
  id             : PropTypes.string.isRequired,
  questionsCount : PropTypes.number,
  title          : PropTypes.string.isRequired,
});

export const studySets = ImmutablePropTypes.listOf(studySet);
export const undoRemoveQuestion = PropTypes.func;
export const updateValueInAutosaved = PropTypes.func;
