import actionTypes from 'src/constants/actionTypes';
import statsService from 'src/services/stats';

function handleCommonStatsAction(actionPrefix, serviceAction, values) {
  return dispatch => {
    dispatch({ type : actionTypes[`STATS__${actionPrefix}_START`] });

    return statsService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`STATS__${actionPrefix}_SUCCESS`],
          payload : { data : { ...data[key] } },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`STATS__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function fetchDashboardReport() {
  return handleCommonStatsAction('FETCH_DASHBOARD_REPORT', 'fetchDashboardReport', null);
}

export function fetchFullReport(values) {
  return handleCommonStatsAction('FETCH_FULL_REPORT', 'fetchFullReport', values);
}
