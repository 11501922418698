import gql from 'graphql-tag';

import quizFields from 'src/graphql/fragments/quizFields';

export default gql`
  mutation resetFlashStudySession(
    $quizId: ID!
    $studySetId: ID!
  ) {
    resetFlashStudySession(
      quizId: $quizId
      studySetId: $studySetId
    ) {
      ...quizFields
    }
  }
  ${quizFields}
`;
