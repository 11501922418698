import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';

import analytics from 'src/shared/analytics';
import FeedbackModal from 'src/components/modals/Feedback';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';

import styles from './styles.module.scss';

function LetUsKnowModal(props) {
  const {
    closeModal,
  } = props;

  useEffect(() => {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Let us know opened on Dashboard');
  }, []);

  return (
    <FeedbackModal
      closeModal={ closeModal }
      thankYouMessage="We've received your questions. We'll get back to you as soon as we can!"
    >
      <p className={ styles.LetUsKnowParagraphContent }>
        <strong>Please let us know how we can help.</strong>
        { ' ' }What questions do you have?
      </p>
    </FeedbackModal>
  );
}

LetUsKnowModal.propTypes = {
  closeModal : PropTypes.func.isRequired,
};

export default LetUsKnowModal;
