import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import Button from './Button';
import EvaluationExplanationOne from './EvaluationExplanationOne';
import styles from './styles.module.scss';

class RecallEvaluation extends PureComponent {
  static propTypes = {
    handleNext                       : PropTypes.func.isRequired,
    hasQuizStarted                   : quizPropTypes.hasQuizStarted,
    isActive                         : basePropTypes.isActive,
    isFirstQuiz                      : PropTypes.bool,
    isTransitioningToCongratulations : quizPropTypes.isTransitioningToCongratulations,
    question                         : questionPropTypes.question.isRequired,
    questionNumber                   : quizPropTypes.questionNumber.isRequired,
    updateQuestionToBeAnswered       : quizPropTypes.updateQuestionToBeAnswered.isRequired,
  }

  state = {
    recallEvaluation : null,
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  selectEasyYesEvaluation = () => this.updateQuestionToBeAnswered(3)

  selectHardYesEvaluation = () => this.updateQuestionToBeAnswered(2)

  selectAlmostEvaluation = () => this.updateQuestionToBeAnswered(1)

  selectNoEvaluation = () => this.updateQuestionToBeAnswered(0);

  updateQuestionToBeAnswered = recallEvaluation => {
    const {
      handleNext,
      isActive,
      updateQuestionToBeAnswered : propsUpdateQuestionToBeAnswered,
    } = this.props;

    if (isActive) return;

    this.setState({ recallEvaluation });
    propsUpdateQuestionToBeAnswered({ recallEvaluation });
    // To knock it to the back of the JS loop
    setTimeout(handleNext, 0);
  }

  handleKeyDown = event => {
    const { isActive, isTransitioningToCongratulations } = this.props;

    if (isActive || isTransitioningToCongratulations) return;

    if (event.key === '4') return this.selectEasyYesEvaluation();
    if (event.key === '3') return this.selectHardYesEvaluation();
    if (event.key === '2') return this.selectAlmostEvaluation();
    if (event.key === '1') return this.selectNoEvaluation();
  }

  render() {
    const {
      hasQuizStarted,
      isActive,
      isFirstQuiz,
      isTransitioningToCongratulations,
      question,
      questionNumber,
    } = this.props;

    const disabled = isActive || isTransitioningToCongratulations;
    const recallEvaluation = question.get('recallEvaluation');

    return (
      <div className={ styles.Root }>
        <div className={ styles.LineSeparator } />

        <EvaluationExplanationOne
          hasQuizStarted={ hasQuizStarted }
          isFirstQuiz={ isFirstQuiz }
          questionNumber={ questionNumber }
        />

        <div className={ styles.ButtonContainer }>
          <Button
            disabled={ disabled }
            isSelected={ recallEvaluation === 3 }
            handleClick={ () => this.selectEasyYesEvaluation() }
            text="Yes and it was easy"
          />

          <Button
            disabled={ disabled }
            isSelected={ recallEvaluation === 2 }
            handleClick={ () => this.selectHardYesEvaluation() }
            text="Yes and it was difficult"
          />

          <Button
            disabled={ disabled }
            isSelected={ recallEvaluation === 1 }
            handleClick={ () => this.selectAlmostEvaluation() }
            text="Almost"
          />

          <Button
            disabled={ disabled }
            isSelected={ recallEvaluation === 0 }
            handleClick={ () => this.selectNoEvaluation() }
            text="No"
          />
        </div>
      </div>
    );
  }
}

export default RecallEvaluation;
