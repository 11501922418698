import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import * as questionPropTypes from 'src/constants/propTypes/question';

import classNames from 'src/components/shared/classNames';
import CountdownTimer from 'src/components/CountdownTimer';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function AnswerRevealBox(props) {
  const {
    isResetting,
    question,
    scrollToAnswerContent,
    secondsToReveal,
    updateQuestionToBeAnswered,
  } = props;

  const readingTimeTimeout = useRef(null);
  const idealStruggleTimeTimeout = useRef(null);

  // The default setting of 3 and 32 are to deal with a bug where
  // the times might be null
  const estimatedReadingTime = question.get('estimatedReadingTime') || 3;
  const idealStruggleTime = question.get('idealStruggleTime') || 32;

  const [isDisabled, setIsDisabled] = useState(true);
  const [timeStarted, setTimeStarted] = useState(null);

  function startAutoRevealTimer() {
    const timeTaken = idealStruggleTime + estimatedReadingTime;
    const callback = () => updateQuestionToBeAnswered({ timeTaken });

    idealStruggleTimeTimeout.current = setTimeout(callback, idealStruggleTime * 1000);
  }

  function runReadingTimeTimeout() {
    const callback = () => {
      setIsDisabled(false);
      startAutoRevealTimer();
    };

    readingTimeTimeout.current = setTimeout(callback, estimatedReadingTime * 1000);
  }

  function resetTimerFunctionality() {
    setTimeStarted(new Date().getTime());
    runReadingTimeTimeout();
  }

  function clearTimeouts() {
    clearTimeout(readingTimeTimeout.current);
    clearTimeout(idealStruggleTimeTimeout.current);
  }

  function handleRevealAnswer(event) {
    if (isDisabled) return;

    event.preventDefault();
    clearTimeout(idealStruggleTimeTimeout.current);

    const timeTaken = Number(((new Date().getTime() - timeStarted) / 1000).toFixed(3));

    updateQuestionToBeAnswered({ timeTaken });
    scrollToAnswerContent();
  }

  function reset() {
    clearTimeouts();

    setIsDisabled(true);
    readingTimeTimeout.current = null;
    idealStruggleTimeTimeout.current = null;

    setTimeStarted(null);

    resetTimerFunctionality();
  }

  function handleKeyDown(event) {
    if (event.key !== 'Enter') return;

    handleRevealAnswer(event);
  }

  useEffect(() => {
    if (isDisabled) return;

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isDisabled]);

  useEffect(() => {
    resetTimerFunctionality();

    return clearTimeouts;
  }, []);

  useEffect(() => {
    if (isResetting) reset();
  }, [isResetting]);

  const actionRevealText = window.innerWidth < 768 ? 'Tap' : 'Click';

  return (
    <div
      className={ styles.Root }
      data-automated-test="quiz-question--reveal-answer-box"
    >
      <div
        className={ cx({ Container : true, isDisabled }) }
        onClick={ handleRevealAnswer }
      >
        <p className={ styles.TopText }>
          ANSWER
        </p>

          <p className={ cx({ BottomText : true, isDisabled }) }>
            { actionRevealText } anywhere to reveal
          </p>

        <div className={ styles.CountdownTimer }>
          <CountdownTimer
            isStopped={ false }
            seconds={ secondsToReveal }
            shouldReset={ false }
          />
        </div>
      </div>
    </div>
  );
}

AnswerRevealBox.propTypes = {
  isResetting                : PropTypes.bool,
  question                   : questionPropTypes.question.isRequired,
  scrollToAnswerContent      : PropTypes.func.isRequired,
  secondsToReveal            : PropTypes.number.isRequired,
  updateQuestionToBeAnswered : PropTypes.func.isRequired,
};

export default AnswerRevealBox;
