import { PropTypes } from 'prop-types';
import React from 'react';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function SegmentedToggle(props) {
  const {
    disabled,
    handleClick,
    options,
    selectedValue,
  } = props;

  function getOptionClassNames(option) {
    return cx({
      Option     : true,
      isDisabled : disabled,
      isSelected : option.value === selectedValue,
    });
  }

  return (
    <div className={ styles.Root }>
      <If condition={ options }>
        <For each="option" of={ options }>
          <div
            className={ getOptionClassNames(option) }
            data-automated-test="segmented-toggle--option"
            data-automated-test-selected={ selectedValue === option.value }
            data-rh={ option.hint }
            key={ option.value }
            onClick={ () => handleClick(option) }
          >
            { option.title }
          </div>
        </For>
      </If>
    </div>
  );
}

SegmentedToggle.propTypes = {
  disabled    : PropTypes.bool,
  handleClick : PropTypes.func,
  options     : PropTypes.arrayOf(PropTypes.shape({
    hint  : PropTypes.string,
    title : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
  })),
  selectedValue : PropTypes.string,
};

export default SegmentedToggle;
