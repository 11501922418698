import { PropTypes } from 'prop-types';
import React from 'react';

import ButtonDiv from 'src/components/buttons/ButtonDiv';
import ButtonLink from 'src/components/buttons/ButtonLink';

import styles from './styles.module.scss';

function FlashStudySession(props) {
  const {
    hasQuestionsWrongFromFssTakenToday,
    quizId,
    resetFlashStudySession,
    started,
    studyOnlyWrongQuestions,
  } = props;

  return (
    <div className={ styles.Root }>
      <Choose>
        <When condition={ started }>
          <p>
            You can continue your study session or you can start from the beginning. If you
            choose to start from the beginning, <strong>we’ll begin the study session with any
            questions you got ”wrong” the last time</strong>. ”Wrong” means any questions to
            which you self-evaluated as a ”Almost” or ”No”.
          </p>

          <div className={ styles.ContinueButton }>
            <ButtonLink
              text="Continue flash study session"
              to={ `/quiz/${quizId}/questions/1` }
              withArrow
            />
          </div>

          <div className={ styles.StartOverButton }>
            <ButtonDiv
              dataAutomatedTest="quiz-explanation--start-over-fss"
              handleClick={ resetFlashStudySession }
              isSecondary
              value="Start from the beginning"
            />
          </div>
        </When>

        <Otherwise>
          <p>
            <strong>What is this for?</strong> A flash study session is for those times
            when you need to cram some information. While Dorothy is primarily for long-term
            learning, we recognize that you may need to quickly study for an exam, a
            presentation, etc.
          </p>

          <p className={ styles.SecondPara }>
            <strong>How does it work?</strong> We will quiz you on all of your
            questions from this study set. <strong>They are in order of when we expect you to forget
            the answer</strong>. You can exit at anytime.
          </p>

          <ButtonLink
            text="Begin"
            to={ `/quiz/${quizId}/questions/1` }
          />

          <If condition={ hasQuestionsWrongFromFssTakenToday }>
            <div className={ styles.WrongQuestionsButtonContainer }>
              <ButtonDiv
                dataAutomatedTest="quiz-explanation--study-only-wrong-questions"
                handleClick={ studyOnlyWrongQuestions }
                isSecondary
                value="Only study questions you got wrong last time"
              />

              <p className={ styles.WrongQuestionsSubPara }>
                (these are questions you self-evaluated with a ”Almost” or ”No” in the last
                flash study session you did today)
              </p>
            </div>
          </If>
        </Otherwise>
      </Choose>
    </div>
  );
}

FlashStudySession.propTypes = {
  hasQuestionsWrongFromFssTakenToday : PropTypes.bool,
  quizId                             : PropTypes.string.isRequired,
  resetFlashStudySession             : PropTypes.func,
  started                            : PropTypes.bool,
  studyOnlyWrongQuestions            : PropTypes.func,
};

export default FlashStudySession;
