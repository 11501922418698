import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import GroupOne from './GroupOne';
import GroupTwo from './GroupTwo';
import styles from './styles.module.scss';

function Charts(props) {
  const {
    fullReport,
  } = props;

  const isEmpty = fullReport.get('isEmpty');

  return (
    <div
      className={ styles.Root }
      data-automated-test="stats-page"
    >
      <GroupOne
        answersMemorized={ fullReport.get('answersMemorized') }
        isEmpty={ isEmpty }
        questionsStudied={ fullReport.get('questionsStudied') }
        totals={ fullReport.get('totals') }
      />

      <GroupTwo
        avgFutureRetention={ fullReport.get('avgFutureRetention') }
        avgStudyTime={ fullReport.get('avgStudyTime') }
        isEmpty={ isEmpty }
        totals={ fullReport.get('totals') }
      />
    </div>
  );
}

Charts.propTypes = {
  fullReport : statsPropTypes.fullReport.isRequired,
};

export default Charts;
