import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import AndMoreEllipses from 'src/components/icons/AndMoreEllipses';
import classNames from 'src/components/shared/classNames';
import ConfirmationModal from 'src/components/modals/Confirmation';
import RefreshIcon from 'src/components/icons/Refresh';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function QuestionsOptions(props) {
  const {
    id,
    isActive,
    isQuestionsOptionsContainerOpen,
    openModal,
    refreshData,
    setIsQuestionsOptionsContainerOpen,
  } = props;

  const updateTitle = id ? 'Refresh data' : 'Clear data';

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  function handleAndMoreEllipsesClick() {
    setIsQuestionsOptionsContainerOpen(current => !current);
  }

  function handleRefreshData() {
    refreshData();
    setIsQuestionsOptionsContainerOpen(false);
    setIsConfirmationModalOpen(false);
  }

  return (
    <div className={ cx({ Root : true, isConfirmationModalOpen }) }>
      <div
        className={ cx({
          AndMoreEllipses : true,
          isDisabled      : isActive,
        }) }
        data-automated-test="questions-options--handle-and-more-ellipsis-icon"
        onClick={ isActive ? undefined : handleAndMoreEllipsesClick }
      >
        <AndMoreEllipses />
      </div>

      <If condition={ isConfirmationModalOpen }>
        <ConfirmationModal
          closeModal={ () => setIsConfirmationModalOpen(false) }
          isPositiveAction
          submitAction={ handleRefreshData }
          submitActionName="Yes"
        >
          <Choose>
            <When condition={ id }>
              This will refresh your data here with what we have in the database
              (what we have on this{ ' ' }
              <Link className={ styles.ModalLink } to={ `/study-sets/${id}` }>page</Link>{ ' ' }
              ). You’ll lose any unsaved changes.
            </When>

            <Otherwise>
              This will clear out your data here. You’ll lose all changes.
            </Otherwise>
          </Choose>
        </ConfirmationModal>
      </If>

      <If condition={ isQuestionsOptionsContainerOpen }>
        <div className={ styles.OptionsContainer }>
          <div className={ styles.Options }>
            <a
              href="https://dorothymemoryapp.com/chrome-extension/"
              target="_blank" rel="noreferrer"
            >
              <div className={ styles.Option }>
                <i className="fas fa-brain" />
                <p className={ styles.OptionPara }>Generate Questions with AI</p>
              </div>
            </a>

            <div
              className={ styles.Option }
              data-automated-test="questions-options--import-a-csv-modal-link"
              onClick={ openModal }
            >
              <i className="fas fa-plus" />
              <p className={ styles.OptionPara }>Import a CSV</p>
            </div>

            <div
              className={ styles.Option }
              data-automated-test="questions-options--refresh-data-link"
              onClick={ () => setIsConfirmationModalOpen(true) }
            >
              <div className={ styles.Icon }>
                <RefreshIcon />
              </div>

              <p
                className={ styles.OptionPara }
                data-rh={ window.innerWidth > 1024 ?
                  'Don’t worry, we’ll confirm with you before taking this action.' :
                  null }
              >
                { updateTitle }
              </p>
            </div>
          </div>
        </div>
      </If>
    </div>
  );
}

QuestionsOptions.propTypes = {
  id                                 : PropTypes.string,
  isActive                           : basePropTypes.isActive,
  isQuestionsOptionsContainerOpen    : studySetPropTypes.isQuestionsOptionsContainerOpen.isRequired,
  openModal                          : PropTypes.func.isRequired,
  refreshData                        : PropTypes.func.isRequired,
  setIsQuestionsOptionsContainerOpen : studySetPropTypes.setIsQuestionsOptionsContainerOpen.isRequired, // eslint-disable-line max-len
};

export default QuestionsOptions;
