import actionTypes from 'src/constants/actionTypes';
import paymentsService from 'src/services/payments';

function handleCommonPaymentAction(actionPrefix, serviceAction, values) {
  return dispatch => {
    dispatch({ type : actionTypes[`PAYMENT__${actionPrefix}_START`] });

    return paymentsService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`PAYMENT__${actionPrefix}_SUCCESS`],
          payload : { payments : { ...data[key] } },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`PAYMENT__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function acknowledgeGooglePlayPurchase(values) {
  return handleCommonPaymentAction(
    'ACKNOWLEDGE_GOOGLE_PLAY_PURCHASE',
    'acknowledgeGooglePlayPurchase',
    values,
  );
}

export function startStripeCheckout(values) {
  return handleCommonPaymentAction(
    'START_STRIPE_CHECKOUT',
    'startStripeCheckout',
    values,
  );
}
