import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

function onboarding(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.ONBOARDING__ADD_FIRST_STUDY_SET:
      return state.set('studySet', Immutable.fromJS(payload.studySet));

    case actionTypes.ONBOARDING__CLEAR_ONBOARDING:
      return state.set('studySet', Immutable.fromJS({ id : null, title : null }));

    default:
      return state;
  }
}

export default onboarding;
