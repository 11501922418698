import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import Card from 'src/components/Card';
import Header from 'src/components/navigation/Header';

import Content from './Content';
import styles from './styles.module.scss';

function Explanation(props) {
  const {
    estimatedTimeToCompleteInMinutes : estimation,
    history,
    isActive,
    isSample,
    match,
    questions,
    questionsSize,
    quiz,
    resetFlashStudySession,
    studyOnlyWrongQuestions,
  } = props;

  // A hacky way to preload all of our files:
  // https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
  useEffect(() => {
    const audioUrls = [];
    const imageUrls = [];

    questions.forEach(question => {
      const questionAudio = question.get('audioUrl');
      const answerAudio = question.getIn(['answer', 'audioUrl']);
      const questionImage = question.get('imageUrl');
      const answerImage = question.getIn(['answer', 'imageUrl']);

      if (questionAudio) audioUrls.push(questionAudio);
      if (answerAudio) audioUrls.push(answerAudio);
      if (questionImage) imageUrls.push(questionImage);
      if (answerImage) imageUrls.push(answerImage);
    });

    audioUrls.forEach(url => {
      const audio = new Audio();
      audio.src = url;
    });

    imageUrls.forEach(url => {
      const audio = new Image();
      audio.src = url;
    });
  }, [questions]);

  function handleKeyDown(event) {
    if (event.key !== 'Enter') return;

    history.push(`/quiz/${quiz.get('id')}/questions/1`);
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const studySetIdFromQuiz = quiz.get('studySetId');
  const exitLinkPath = studySetIdFromQuiz && `/study-sets/${studySetIdFromQuiz}`;

  return (
    <div className={ styles.Root }>
      <Header
        exitLinkPath={ exitLinkPath }
        history={ history }
        match={ match }
      />

      <div className={ styles.DesktopContainer }>
        <Card isSoloCardOnScreen>
          <Content
            estimation={ estimation }
            isActive={ isActive }
            isSample={ isSample }
            questionsSize={ questionsSize }
            quiz={ quiz }
            resetFlashStudySession={ resetFlashStudySession }
            studyOnlyWrongQuestions={ studyOnlyWrongQuestions }
            studySetIdFromQuiz={ studySetIdFromQuiz }
          />
        </Card>
      </div>

      <div className={ styles.MobileContainer }>
        <Content
          estimation={ estimation }
          isActive={ isActive }
          isSample={ isSample }
          questionsSize={ questionsSize }
          quiz={ quiz }
          resetFlashStudySession={ resetFlashStudySession }
          studyOnlyWrongQuestions={ studyOnlyWrongQuestions }
          studySetIdFromQuiz={ studySetIdFromQuiz }
        />
      </div>
    </div>
  );
}

Explanation.propTypes = {
  estimatedTimeToCompleteInMinutes : quizPropTypes.estimatedTimeToCompleteInMinutes.isRequired,
  history                          : basePropTypes.history.isRequired,
  isActive                         : PropTypes.bool,
  isFlashStudySession              : PropTypes.bool,
  isSample                         : quizPropTypes.isSample,
  match                            : basePropTypes.match.isRequired,
  questions                        : questionPropTypes.questions.isRequired,
  questionsSize                    : questionPropTypes.questionsSize.isRequired,
  quiz                             : quizPropTypes.quiz.isRequired,
  resetFlashStudySession           : PropTypes.func,
  studyOnlyWrongQuestions          : PropTypes.func,
};

export default Explanation;
