// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (max-width: 568px){.styles-module__Modal___2TA8M{box-sizing:border-box;left:0;top:0;width:100%}}\n.styles-module__Title___Htmsh{margin-bottom:18px;text-align:left}\n.styles-module__ParagraphContent___3-3Sb{margin-bottom:18px;text-align:left}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/modals/Authentication/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,8BACE,qBAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CAAA;AAEJ,8BACE,kBAAA,CACA,eAAA;AAEF,yCACE,kBAAA,CACA,eAAA","file":"styles.module.scss","sourcesContent":["@media screen and (max-width: 568px) {\n  .Modal {\n    box-sizing: border-box;\n    left: 0;\n    top: 0;\n    width: 100%; } }\n\n.Title {\n  margin-bottom: 18px;\n  text-align: left; }\n\n.ParagraphContent {\n  margin-bottom: 18px;\n  text-align: left; }\n"]}]);
// Exports
exports.locals = {
	"Modal": "styles-module__Modal___2TA8M",
	"Title": "styles-module__Title___Htmsh",
	"ParagraphContent": "styles-module__ParagraphContent___3-3Sb"
};
module.exports = exports;
