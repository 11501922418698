import gql from 'graphql-tag';

export default gql`
  mutation startStripeCheckout(
    $planType: Int!
  ) {
    startStripeCheckout(
      planType: $planType
    ) {
      sessionId
    }
  }
`;
