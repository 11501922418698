// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__BottomButtons___3G4Xh{display:flex;flex-direction:row;justify-content:space-between;margin-top:24px}.styles-module__SubmitButton___vPDsb>div>div{margin-top:0;max-width:-moz-max-content;max-width:max-content;min-width:190px}.styles-module__SubmitButton___vPDsb>div>div input{width:100%}.styles-module__SkipLink___8PHgv{align-items:center;color:#2071aa;display:flex;text-decoration:underline}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/forms/onboarding/NewStudySet/styles.module.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CAEF,6CACE,YAAA,CACA,0BAAA,CAAA,qBAAA,CACA,eAAA,CACA,mDACE,UAAA,CAEJ,iCACE,kBAAA,CACA,aAAA,CACA,YAAA,CACA,yBAAA","file":"styles.module.scss","sourcesContent":[".BottomButtons {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-top: 24px; }\n\n.SubmitButton > div > div {\n  margin-top: 0;\n  max-width: max-content;\n  min-width: 190px; }\n  .SubmitButton > div > div input {\n    width: 100%; }\n\n.SkipLink {\n  align-items: center;\n  color: #2071aa;\n  display: flex;\n  text-decoration: underline; }\n"]}]);
// Exports
exports.locals = {
	"BottomButtons": "styles-module__BottomButtons___3G4Xh",
	"SubmitButton": "styles-module__SubmitButton___vPDsb",
	"SkipLink": "styles-module__SkipLink___8PHgv"
};
module.exports = exports;
