import * as Sentry from '@sentry/browser';

function handleError(err) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(err); // eslint-disable-line no-console
  }

  Sentry.captureException(err, scope => {
    scope.setTag('error caught in register', true);
    return scope;
  });
}

if (navigator.serviceWorker) {
  try {
    navigator.serviceWorker.register('/service-worker.js', { scope : '/' })
      .then(() => navigator.serviceWorker.ready)
      .then(registration => {
        if ('SyncManager' in window) {
          registration.sync.register('sync-forms');
        } else {
          throw new Error('This browser does not support background sync.');
        }
      })
      .catch(handleError);
  } catch (err) {
    handleError(err);
  }
}

// TODOs:
// * Will the user receive a push notification even if they don't have it installed?
