import { PropTypes } from 'prop-types';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

class Shared {
  propTypes = {
    errors       : basePropTypes.errors,
    fullWidth    : PropTypes.bool,
    handleSubmit : authenticationPropTypes.handleSubmit.isRequired,
    isActive     : basePropTypes.isActive.isRequired,
  };
}

export default Shared;
