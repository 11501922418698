import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

export const attempt = ImmutablePropTypes.mapContains({ // eslint-disable-line import/prefer-default-export, max-len
  id       : PropTypes.string.isRequired,
  question : ImmutablePropTypes.mapContains({
    dueDate : PropTypes.string,
    id      : PropTypes.string.isRequired,
  }),
  recallEvaluation : PropTypes.number.isRequired,
});
