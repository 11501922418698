import axios from 'axios';

import {
  handleServerError as handleServerErrorAction,
  signOut as signOutAction,
  updateToken,
} from 'src/actions/authentication';
import snakeCaseParams from 'src/services/shared/snakeCaseParams';
import storeService from 'src/services/store';

function addAuthenticationHeaders(config) {
  const updatedConfig = config;
  const { authentication } = storeService.getStore().getState();

  const tokenEl = document.querySelector('meta[name="csrf-token"]');
  const token = tokenEl && tokenEl.content;

  if (authentication) {
    const headers = snakeCaseParams({
      accessToken : authentication.get('accessToken'),
      client      : authentication.get('client'),
      name        : authentication.get('name'),
      uid         : authentication.get('uid'),
    });

    const date = new Date();
    date.setFullYear(date.getFullYear() + 100); // Set the date 100 years into the future.

    /* eslint-disable max-len */
    document.cookie = `accessToken=${headers.access_token}; expires=${date.toUTCString()}; path=/`;
    document.cookie = `client=${headers.client}; expires=${date.toUTCString()}; path=/`;
    document.cookie = `name=${headers.name}; expires=${date.toUTCString()}; path=/`;
    document.cookie = `uid=${headers.uid}; expires=${date.toUTCString()}; path=/`;
    document.cookie = `xCsrfToken=${token}; expires=${date.toUTCString()}; path=/`;
    /* eslint-enable max-len */

    updatedConfig.headers = Object.assign(
      updatedConfig.headers,
      headers,
      { 'X-CSRF-Token' : token },
    );
  }

  return updatedConfig;
}

function updateStoredToken(response) {
  const {
    headers : {
      'access-token' : accessToken,
      expiry,
    },
  } = response;

  const store = storeService.getStore();

  if (accessToken) {
    store.dispatch(updateToken({ accessToken, expiry }));
  }

  return response;
}

function signOut(store) {
  const { authentication } = store.getState();
  const uid = authentication.get('uid');

  const headers = {
    'access-token' : authentication.get('accessToken'),
    client         : authentication.get('client'),
    uid,
  };

  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);

  document.cookie = `accessToken=${authentication.get('accessToken')}; expires=${date.toUTCString()}; path=/`; /* eslint-disable-line max-len */

  store.dispatch(signOutAction(headers));
}

function handleError(error) {
  const store = storeService.getStore();

  try {
    const { request, response } = error;

    if (!response && !request) {
      return store.dispatch(handleServerErrorAction());
    }

    const { data: { errors }, status } = response;
    const isOtpError = errors && Object.keys(errors).includes('otp');

    if (status === 401 && !isOtpError) return signOut(store);
    if (status === 500) return store.dispatch(handleServerErrorAction());

    updateStoredToken(error.response);
  } catch (_) {
    return store.dispatch(signOutAction());
  }

  throw error;
}

export default function (baseURL) {
  const api = axios.create({
    baseURL,
  });

  api.interceptors.request.use(addAuthenticationHeaders);
  api.interceptors.response.use(updateStoredToken, handleError);

  return api;
}
