// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___dF5DU{display:flex;flex-direction:row;justify-content:space-between;background-color:#fff;bottom:0;box-shadow:0 2px 4px #bdbdbd;left:0;list-style-type:none;padding:12px 0;position:fixed;width:100%}.styles-module__Root___dF5DU.styles-module__shouldDisplayScrollMoreBlur___23Vs1::after{background:linear-gradient(180deg, transparent 10%, #edf2f7 100%);content:\"\";height:60px;left:0;position:absolute;right:0;top:-60px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/navigation/Footer/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,qBAAA,CACA,QAAA,CACA,4BAAA,CACA,MAAA,CACA,oBAAA,CACA,cAAA,CACA,cAAA,CACA,UAAA,CACA,uFACE,iEAAA,CACA,UAAA,CACA,WAAA,CACA,MAAA,CACA,iBAAA,CACA,OAAA,CACA,SAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background-color: #ffffff;\n  bottom: 0;\n  box-shadow: 0 2px 4px #bdbdbd;\n  left: 0;\n  list-style-type: none;\n  padding: 12px 0;\n  position: fixed;\n  width: 100%; }\n  .Root.shouldDisplayScrollMoreBlur::after {\n    background: linear-gradient(180deg, transparent 10%, #edf2f7 100%);\n    content: \"\";\n    height: 60px;\n    left: 0;\n    position: absolute;\n    right: 0;\n    top: -60px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___dF5DU",
	"shouldDisplayScrollMoreBlur": "styles-module__shouldDisplayScrollMoreBlur___23Vs1"
};
module.exports = exports;
