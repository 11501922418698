import React from 'react';

import Lever from 'src/components/Lever';

import styles from './styles.module.scss';

function KeyboardShortcuts() {
  const shortcuts = [
    {
      key         : '4',
      description : 'Select "Yes and it was easy"',
    },
    {
      key         : '3',
      description : 'Select "Yes and it was difficult"',
    },
    {
      key         : '2',
      description : 'Select "Almost"',
    },
    {
      key         : '1',
      description : 'Select "No"',
    },
    {
      key         : 'enter/return',
      description : 'Reveal answer',
    },
  ];

  return (
    <Lever
      leverTitleClassNames={ styles.LeverTitle }
      title="keyboard shortcuts"
    >
      <div className={ styles.Root }>
        <ul className={ styles.Container }>
          <For each="shortcut" of={ shortcuts }>
            <li
              className={ styles.Shortcut }
              key={ shortcut.key }
            >
              <p className={ styles.Key }>{ shortcut.key }</p>
              <p className={ styles.Description }>{ shortcut.description }</p>
            </li>
          </For>
        </ul>
      </div>
    </Lever>
  );
}

export default KeyboardShortcuts;
