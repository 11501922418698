import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';

import buildUserFriendlyErrorsForMissingValues from './buildUserFriendlyErrorsForMissingValues';

export default function (values, containerThis) {
  const { general } = containerThis.props.actions;

  const errors = buildUserFriendlyErrorsForMissingValues(
    values,
    { feedback  : 'Oops! Looks like you forgot to enter your feedback.' },
  );

  if (errors) return general.addErrors(errors);

  return general
    .submitFeedback(values)
    .then(() => {
      const userId = getUserIdFromStore();

      if (userId) analytics.identify(userId);

      analytics.track('Feedback submitted', { feedback : values.feedback });

      return containerThis.setState({ shouldDisplayThankYouMessage : true });
    });
}
