// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2KGx4{width:100%}@media screen and (min-width: 980px){.styles-module__Root___2KGx4{display:flex;flex-direction:row;justify-content:space-between}.styles-module__Root___2KGx4 label,.styles-module__Root___2KGx4 .styles-module__Answer___P2686,.styles-module__Root___2KGx4 .styles-module__Question___33Rxx{width:calc(50% - 16px)}.styles-module__Root___2KGx4 label label,.styles-module__Root___2KGx4 .styles-module__Answer___P2686 label,.styles-module__Root___2KGx4 .styles-module__Question___33Rxx label{width:100%}}.styles-module__Answer___P2686{margin-top:9px}@media screen and (min-width: 980px){.styles-module__Answer___P2686{margin-top:0;margin-top:initial}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/forms/StudySet/Question/Fields/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,UAAA,CACA,qCACE,6BACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,6JAGE,sBAAA,CACA,+KAGE,UAAA,CAAA,CAEV,+BACE,cAAA,CACA,qCACE,+BACE,YAAA,CAAA,kBAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  width: 100%; }\n  @media screen and (min-width: 980px) {\n    .Root {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between; }\n      .Root label,\n      .Root .Answer,\n      .Root .Question {\n        width: calc(50% - 16px); }\n        .Root label label,\n        .Root .Answer label,\n        .Root .Question label {\n          width: 100%; } }\n\n.Answer {\n  margin-top: 9px; }\n  @media screen and (min-width: 980px) {\n    .Answer {\n      margin-top: initial; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2KGx4",
	"Answer": "styles-module__Answer___P2686",
	"Question": "styles-module__Question___33Rxx"
};
module.exports = exports;
