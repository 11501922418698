import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as authenticationActions from 'src/actions/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildSignInPropsContext from 'src/containers/public/shared/buildSignInPropsContext';
import ShowPublicPublicStudySet from 'src/components/public/publicStudySets/Show';
import SignInPropsContext from 'src/contexts/SignInProps';

class FunctionalShow extends PureComponent {
  static propTypes = {
    actions    : basePropTypes.actions.isRequired,
    authErrors : basePropTypes.errors,
    errors     : basePropTypes.errors,
    history    : basePropTypes.history.isRequired,
    isActive   : basePropTypes.isActive,
    isAuthed   : PropTypes.bool,
    location   : basePropTypes.location.isRequired,
    match      : basePropTypes.match.isRequired,
    studySet   : studySetPropTypes.studySet.isRequired,
  };

  state = {
    isVerifyingOtp         : false,
    shouldDisplayVerifyOtp : false,
    shouldFireConfetti     : false,
  }

  componentDidMount() {
    const { actions, match } = this.props;

    actions.publicStudySets.show({ id : match.params.id });
  }

  addStudySet = () => {
    const { actions: { publicStudySets }, history, studySet } = this.props;

    return publicStudySets
      .add({ id : studySet.get('id') })
      .then(data => {
        if (window.gtag) {
          window.gtag('event', 'conversion_in_product_site', { value : 0 });
        }

        const isRailsEnvTest = process.env.RAILS_ENV === 'test';

        if (!isRailsEnvTest) this.setState({ shouldFireConfetti : true });

        const { payload } = data;

        if (payload && payload.errors) {
          return this.setState({ isVerifyingOtp : false });
        }

        const { data : { id } } = payload;

        return history.push({
          pathname : `/study-sets/${id}`,
          state    : { pathFrom : `/public-study-sets/${this.props.studySet.get('id')}` },
        });
      })
      .catch(() => {
        this.setState({ isVerifyingOtp : false });
      });
  }

  buildSignInPropsContext = () => buildSignInPropsContext(this, this.addStudySet)

  render() {
    if (this.props.isAuthed && !this.state.isVerifyingOtp) {
      let { pathname } = this.props.location;

      pathname = pathname.replace('/public/', '/');

      return <Redirect to={ { pathname } } />;
    }

    return (
      <SignInPropsContext.Provider value={ this.buildSignInPropsContext() }>
        <ShowPublicPublicStudySet
          addStudySet={ this.addStudySet }
          errors={ this.props.errors }
          history={ this.props.history }
          isActive={ this.props.isActive }
          location={ this.props.location }
          match={ this.props.match }
          studySet={ this.props.studySet }
        />
      </SignInPropsContext.Provider>
    );
  }
}

function mapStateToProps({ authentication, publicStudySets }) {
  return {
    authIsActive : authentication.get('isActive'),
    authErrors   : authentication.get('errors'),
    emailSentTo  : authentication.get('emailSentTo'),
    errors       : publicStudySets.get('errors'),
    isActive     : publicStudySets.get('isActive'),
    isAuthed     : !!(authentication.get('email') && authentication.get('accessToken')),
    studySet     : publicStudySets.getIn(['loaded', 'show']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication  : bindActionCreators(authenticationActions, dispatch),
      publicStudySets : bindActionCreators(publicStudySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalShow);
