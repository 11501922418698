import { PropTypes } from 'prop-types';
import React from 'react';

import ButtonDiv from 'src/components/buttons/ButtonDiv';

import styles from './styles.module.scss';

function ShowMoreContainer(props) {
  const {
    isActive,
    loadAllQuestions,
  } = props;

  return (
    <div className={ styles.Root }>
      <p className={ styles.Para }>
        (showing the last 100 questions)
      </p>

      <div className={ styles.Button }>
        <ButtonDiv
          dataAutomatedTest="study-set--form--load-all-questions-button"
          disabled={ isActive }
          handleClick={ loadAllQuestions }
          isSecondary
          value="Load all questions"
        />
      </div>
    </div>
  );
}

ShowMoreContainer.propTypes = {
  isActive         : PropTypes.bool,
  loadAllQuestions : PropTypes.func.isRequired,
};

export default ShowMoreContainer;
