import React from 'react';

import styles from './styles.module.scss';

function Search() {
  return (
    <svg
      className={ styles.Root }
      data-automated-test="trash-can-icon"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g transform="translate(0,-952.36218)">
        <path d="m 42.998529,966.36219 c -15.97982,0 -28.99854,13.01861 -28.99854,28.99854 0,15.97987 13.0187,28.99857 28.99854,28.99857 6.91114,0 13.25932,-2.4431 18.24908,-6.4997 l 19.62402,19.624 c 1.17141,1.1714 3.0783,1.1715 4.24978,0 1.17147,-1.1715 1.17147,-3.0471 0,-4.2185 l -19.62401,-19.624 c 4.06479,-4.9923 6.49967,-11.3615 6.49967,-18.28037 0,-15.97993 -13.01871,-28.99854 -28.99854,-28.99854 z m 0,5.9997 c 12.73733,0 22.99884,10.26142 22.99884,22.99884 0,12.73727 -10.26153,22.99887 -22.99884,22.99887 -12.73731,0 -22.99884,-10.2616 -22.99884,-22.99887 0,-12.73742 10.26151,-22.99884 22.99884,-22.99884 z" />
      </g>
    </svg>
  );
}

export default Search;
