import { PropTypes } from 'prop-types';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class Shared {
  propTypes = {
    children     : basePropTypes.children,
    disabled     : basePropTypes.disabled,
    floatRight   : basePropTypes.floatRight,
    fullWidth    : PropTypes.bool,
    handleClick  : basePropTypes.handleClick,
    isActive     : basePropTypes.isActive,
    isBelowInput : basePropTypes.isBelowInput,
    isInFooter   : basePropTypes.isInFooter,
    isInHeader   : basePropTypes.isInHeader,
    isSecondary  : basePropTypes.isSecondary,
    isTertiary   : basePropTypes.isTertiary,
    value        : basePropTypes.value,
  };

  getRootClassNames() {
    const {
      floatRight,
      isBelowInput,
      isInFooter,
      isInHeader,
    } = this.props;

    return cx({
      Root : true,
      floatRight,
      isBelowInput,
      isInFooter,
      isInHeader,
    });
  }

  getContainerClassNames() {
    const {
      fullWidth,
      floatRight,
      isBelowInput,
      isInFooter,
      isInHeader,
    } = this.props;

    return cx({
      Container : true,
      fullWidth,
      floatRight,
      isBelowInput,
      isInFooter,
      isInHeader,
    });
  }

  getButtonClassNames() {
    const {
      disabled,
      floatRight,
      fullWidth,
      isActive,
      isInFooter,
      isInHeader,
      isPulsing,
      isSecondary,
      isTertiary,
    } = this.props;

    return cx({
      Button     : true,
      isDisabled : disabled,
      floatRight,
      fullWidth,
      isActive,
      isInFooter,
      isInHeader,
      isNative   : this.isNative,
      isPulsing  : isPulsing && !disabled,
      isPrimary  : !isSecondary && !isTertiary,
      isSecondary,
      isTertiary,
    });
  }

  getLoaderClassNames() {
    const {
      disabled,
      isInHeader,
      isSecondary,
    } = this.props;

    return cx({
      Loader     : true,
      isDisabled : disabled,
      isInHeader,
      isNative   : this.isNative,
      isSecondary,
    });
  }

  setClassProperties({ props, isNative }) {
    this.props = props;
    this.isNative = isNative;
  }
}

export default Shared;
