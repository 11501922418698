// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__LeverTitle___27kzg{color:#828282}.styles-module__LeverTitle___27kzg strong{color:#828282}.styles-module__Root___1Xuds{padding-top:18px}.styles-module__Container___3FJEV{background-color:#262322;border-radius:6px;box-sizing:border-box;opacity:.7;padding:18px}.styles-module__Shortcut___1nGOu{display:flex;flex-direction:row;justify-content:flex-start;margin-top:4px}.styles-module__Key___1tFJI{color:#a9d2ef;margin-right:12px;min-width:84px}.styles-module__Description___1hBlb{color:#fff}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/quiz/Explanation/KeyboardShortcuts/styles.module.scss"],"names":[],"mappings":"AAAA,mCACE,aAAA,CACA,0CACE,aAAA,CAEJ,6BACE,gBAAA,CAEF,kCACE,wBAAA,CACA,iBAAA,CACA,qBAAA,CACA,UAAA,CACA,YAAA,CAEF,iCACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,cAAA,CAEF,4BACE,aAAA,CACA,iBAAA,CACA,cAAA,CAEF,oCACE,UAAA","file":"styles.module.scss","sourcesContent":[".LeverTitle {\n  color: #828282; }\n  .LeverTitle strong {\n    color: #828282; }\n\n.Root {\n  padding-top: 18px; }\n\n.Container {\n  background-color: #262322;\n  border-radius: 6px;\n  box-sizing: border-box;\n  opacity: 0.7;\n  padding: 18px; }\n\n.Shortcut {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  margin-top: 4px; }\n\n.Key {\n  color: #a9d2ef;\n  margin-right: 12px;\n  min-width: 84px; }\n\n.Description {\n  color: #ffffff; }\n"]}]);
// Exports
exports.locals = {
	"LeverTitle": "styles-module__LeverTitle___27kzg",
	"Root": "styles-module__Root___1Xuds",
	"Container": "styles-module__Container___3FJEV",
	"Shortcut": "styles-module__Shortcut___1nGOu",
	"Key": "styles-module__Key___1tFJI",
	"Description": "styles-module__Description___1hBlb"
};
module.exports = exports;
