import getUrlParameter from 'src/shared/getUrlParameter';

function getFilter(filterParam) {
  if (filterParam) return filterParam;
  // For example, if the filter was just cleared
  if (filterParam === '' && getUrlParameter('filter')) return null;

  return getUrlParameter('filter');
}

export default function buildRoute(filterParam, sortParam, basePath = '/questions') {
  const filter = getFilter(filterParam);
  const sort = sortParam || getUrlParameter('sort');

  if (!filter && !sort) return basePath;
  if (!sort) return `${basePath}?filter=${filter}`;
  if (!filter) return `${basePath}?sort=${sort}`;

  return `${basePath}?sort=${sort}&filter=${filter}`;
}
