import { PropTypes } from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import AuthenticationModal from 'src/components/modals/Authentication';
import Breadcrumbs from 'src/components/navigation/Breadcrumbs';
import buildLink from 'src/components/shared/buildLink';
import ButtonDiv from 'src/components/buttons/ButtonDiv';
import ConfirmationModal from 'src/components/modals/Confirmation';
import LimitModal from 'src/components/modals/QuestionLimitModal';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import QuestionResult from 'src/components/ResultsList/QuestionResult';
import ResultsList from 'src/components/ResultsList';
import ShareableLink from 'src/components/ShareableLink';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import styles from './styles.module.scss';

function ShowPublicStudySet(props) {
  const {
    addStudySet,
    errors,
    isActive,
    isUnauthed,
    location,
    studySet,
    unauthedHeading,
    unauthedPara,
  } = props;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  function openSignInModal() { setIsSignInModalOpen(true); }
  function closeSignInModal() { setIsSignInModalOpen(false); }

  function closeLimitModal() { setIsLimitModalOpen(false); }
  function openLimitModal() { setIsLimitModalOpen(true); }

  function closeConfirmationModal() { setIsConfirmationModalOpen(false); }
  function openConfirmationModal() { setIsConfirmationModalOpen(true); }

  useEffect(
    () => {
      const errorKeys = errors && errors.keySeq();
      const limitError = errorKeys && errorKeys.find(key => key.includes('limit'));

      if (limitError) return openLimitModal();
      return closeLimitModal();
    },
    [errors],
  );

  const questions = studySet.get('questions');
  const title = studySet.get('title');

  const publicPublicStudySetPath = `/#/public/public-study-sets/${studySet.get('id')}`;
  const publicPublicStudySetLink = buildLink(publicPublicStudySetPath);

  let questionsBasePath = `/public-study-sets/${studySet.get('id')}/questions`;

  if (isUnauthed) {
    questionsBasePath = `/public/public-study-sets/${studySet.get('id')}/questions`;
  }

  function handleAddToMyStudySetsClick() {
    if (isUnauthed) { return openSignInModal(); }

    return openConfirmationModal();
  }

  return (
    <div className={ styles.Root }>
      <div className={ styles.Container }>
        <Choose>
          <When condition={ !studySet || isActive }>
            <div className={ styles.PulseLoader }>
              <PulseLoadingIndicator isLoading={ isActive }/>
            </div>
          </When>

          <Otherwise>
            <If condition={ !isUnauthed }>
              <div className={ styles.BreadcrumbsContainer }>
                <Breadcrumbs
                  links={ [
                    { label : 'Study sets', path : '/study-sets' },
                    { label : 'Public study sets', path : '/public-study-sets' },
                    { label : title, isCurrent : true },
                  ] }
                />
              </div>
            </If>

            <div className={ styles.ShareableLinkContainer }>
              <ShareableLink
                isSecondary
                link={ publicPublicStudySetLink }
              />
            </div>

            <div className={ styles.TopSection }>
              <div className={ styles.TitleSection }>
                <h3>{ title }</h3>
                <p>{ studySet.get('description') }</p>
              </div>
            </div>

            <div className={ styles.ButtonContainer }>
              <ButtonDiv
                dataAutomatedTest="public-study-sets-show-add-button"
                handleClick={ handleAddToMyStudySetsClick }
                iconIsOnLeftSide
                isSecondary={ !isUnauthed }
                value="Add to my study sets"
                withPlusSign
              />
            </div>

            <If condition={ questions }>
              <ResultsList
                hasQuietTitle
                resultName="Question"
                results={ questions }
              >
                <ul>
                  <For each="question" index="i" of={ questions }>
                    <QuestionResult
                      basePath={ questionsBasePath }
                      key={ question.get('id') }
                      question={ question }
                    />
                  </For>
                </ul>
              </ResultsList>
            </If>

            <If condition={ questions && questions.size > 9 }>
              <div className={ styles.BottomButtonContainer }>
                <ButtonDiv
                  dataAutomatedTest="public-study-sets-show-add-button"
                  handleClick={ handleAddToMyStudySetsClick }
                  isSecondary={ !isUnauthed }
                  value="Add to my study sets"
                  withPlusSign
                />
              </div>
            </If>

            <If condition={ isSignInModalOpen }>
              <AuthenticationModal
                closeModal={ closeSignInModal }
                shouldUseVerifyOtpWithCallback
                unauthedHeading={ unauthedHeading }
                unauthedPara={ unauthedPara }
              />
            </If>

            <If condition={ isConfirmationModalOpen }>
              <ConfirmationModal
                closeModal={ closeConfirmationModal }
                isPositiveAction
                submitAction={ addStudySet }
                submitActionName="Yes!"
                title={ `Add ${title} to your study sets?` }
              />
            </If>

            <If condition={ isLimitModalOpen }>
              <LimitModal
                closeModal={ closeLimitModal }
                heading={ `
                    Oh no! It looks like adding this study set will cause you to go over
                    the 60 question limit on your Basic plan.
                  ` }
                para={ `
                    You’ll need to upgrade to add this study set.
                    But don’t worry, it’s really easy!
                  ` }
              />
            </If>
          </Otherwise>
        </Choose>

        <SiteWideLinks
          isUnauthed={ isUnauthed }
          location={ location }
        />
      </div>
    </div>
  );
}

ShowPublicStudySet.propTypes = {
  addStudySet     : studySetPropTypes.addStudySet.isRequired,
  errors          : basePropTypes.errors,
  isActive        : basePropTypes.isActive,
  isUnauthed      : PropTypes.bool,
  location        : basePropTypes.location.isRequired,
  studySet        : studySetPropTypes.studySet.isRequired,
  unauthedHeading : PropTypes.string,
  unauthedPara    : PropTypes.string,
};

export default memo(ShowPublicStudySet);
