import { bindActionCreators } from 'redux';
import React from 'react';
import * as Sentry from '@sentry/browser';

import * as authenticationActions from 'src/actions/authentication';
import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as profileActionCreators from 'src/actions/profile';
import * as pushNotificationsActions from 'src/actions/pushNotifications';
import * as questionsActions from 'src/actions/questions';

import { signOut } from 'src/actions/authentication';

import storeService from 'src/services/store';

import updateNotificationSettingsOnChange from './shared/updateNotificationSettingsOnChange';

class Shared {
  propTypes = {
    accessToken : authenticationPropTypes.accessToken,
    actions     : basePropTypes.actions.isRequired,
    email       : authenticationPropTypes.email,
    errors      : basePropTypes.errors,
  };

  clearTempState() {
    // We don't want isActive, errors, etc to persist after a refresh
    this.props.actions.authentication.clearTempState();
    this.props.actions.questions.clearTempState();
  }

  componentDidMount() {
    this.clearTempState();
    updateNotificationSettingsOnChange(this.props);
  }

  // This is to deal with a strange edge case where for
  // some reason, errors gets set as a plain object in
  // my state. It's happened twice but I can't track
  // down why. It should not because I'm using `fromJS`
  // in my reducer.
  //
  // However, it does. And when it does, it breaks
  // everything and a refresh cannot save it since
  // I'm persisting storage. I could put this in
  // componentWillMount but that's going away in
  // React17 so I won't.
  //
  // I believe this to be poor code and architecture but
  // it's a small price to pay to move on and not break
  // the user flow. If it comes up again, I'll investigate
  // further. If it doesn't, I'll delete it.
  /* eslint-disable */
  handleStrangeErrorsObjectIssue(error, callback = () => {}) {
    const {
      actions,
      authenticationErrors,
      questionErrors,
    } = this.props;

    if (
      (authenticationErrors instanceof Object &&
      Object.keys(authenticationErrors).length === 0) ||
      (questionErrors instanceof Object &&
      Object.keys(questionErrors).length === 0)
    ) {
      try {
        console.log(this.props);
        console.error(error);
      } catch(_) {}

      Sentry.captureMessage('Your errors were set as a plain object again and not a Map (from handleStrangeErrorsObjectIssue)');

      return storeService
        .clearStore()
        .then(() => {
          storeService.getStore().dispatch(signOut());
          callback();
        });
    }
  }
  /* eslint-enable */

  mapDispatchToProps(dispatch) {
    return {
      actions : {
        authentication    : bindActionCreators(authenticationActions, dispatch),
        questions         : bindActionCreators(questionsActions, dispatch),
        profile           : bindActionCreators(profileActionCreators, dispatch),
        pushNotifications : bindActionCreators(pushNotificationsActions, dispatch),
      },
    };
  }

  mapStateToProps({ authentication, profile, questions }) {
    return {
      accessToken             : authentication.get('accessToken'),
      authenticationErrors    : authentication.get('errors'), // TODO: May not need in future
      client                  : authentication.get('client'),
      email                   : authentication.get('email'),
      experiencingServerError : authentication.get('experiencingServerError'),
      notificationSettings    : profile.get('notificationSettings'),
      questionErrors          : questions.get('errors'), // TODO: May not need in future
      uid                     : authentication.get('uid'),
    };
  }

  redirectToSignIn = ({ location : { pathname } }, Redirect) => (
    <Redirect
      to={ {
        pathname : '/auth/welcome',
        state    : { pathFrom : pathname },
      } }
    />
  )

  setClassProperties(classProperties) {
    const { props } = classProperties;

    this.props = props;
  }
}

export default Shared;
