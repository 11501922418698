import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionsActions from 'src/actions/questions';
import * as questionPropTypes from 'src/constants/propTypes/question';

import navigateOrWait from 'src/containers/shared/navigateOrWait';
import ShowQuestion from 'src/components/ShowQuestion';

class FunctionalShow extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    match    : basePropTypes.match.isRequired,
    question : questionPropTypes.question.isRequired,
  };

  componentDidMount() {
    const { actions, match } = this.props;

    actions.questions.show({ id : match.params.id });
  }

  deleteQuestion = () => {
    const { actions, question } = this.props;

    return actions
      .questions
      .deleteQuestion({ id : question.get('id') })
      .then(this.navigateOrWait)
      .catch(this.navigateOrWait);
  }

  navigateOrWait = () => navigateOrWait(this.props, '/questions')

  render() {
    return (
      <ShowQuestion
        deleteQuestion={ this.deleteQuestion }
        { ...this.props }
      />
    );
  }
}

function mapStateToProps({ questions }, { match : { params } }) {
  return {
    errors       : questions.get('errors'),
    isActive     : questions.get('isActive'),
    isSuccessful : questions.get('isSuccessful'),
    question     : questions.getIn(['loaded', 'questions', params.id]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      questions : bindActionCreators(questionsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalShow);
