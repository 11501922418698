import { PropTypes } from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildLink from 'src/components/shared/buildLink';
import ButtonLink from 'src/components/buttons/ButtonLink';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import QuestionResult from 'src/components/ResultsList/QuestionResult';
import ResultsList from 'src/components/ResultsList';
import ShareableLink from 'src/components/ShareableLink';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import QuizOptions from './QuizOptions';
import TextBadges from './TextBadges';
import UpdateOptions from './UpdateOptions';
import styles from './styles.module.scss';

function ShowStudySet(props) {
  const {
    deleteStudySet,
    history,
    isActive,
    isSuccessful,
    location,
    newestQuiz,
    startFlashStudySession,
    studySet,
  } = props;

  // TODO: handle study set not being present for whatever reason
  if (!studySet) return null;

  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [isUpdateOptionsContainerOpen, setIsUpdateOptionsContainerOpen] = useState(false);

  const { state } = history.location;
  const pathFrom = state && state.pathFrom;

  const fromStudySets = pathFrom && pathFrom.includes('study-sets');
  const fromEdit = fromStudySets && pathFrom.includes('edit');
  const fromNew = fromStudySets && pathFrom.includes('new');
  const fromPublicStudySets = fromStudySets && pathFrom.includes('public-study-sets');

  const questions = studySet.get('questions');
  const questionsSize = questions && questions.size;
  const results = questions;
  const isUndesignated = studySet.get('isUndesignated');
  const description = studySet.get('description');
  const visibilityStatus = studySet.get('visibilityStatus');

  const isUnlisted = visibilityStatus === 'is_unlisted';
  const isPublic = visibilityStatus === 'is_public';

  const publicPublicStudySetPath = `/#/public/public-study-sets/${studySet.get('id')}`;
  const publicPublicStudySetLink = buildLink(publicPublicStudySetPath);

  function handleContainerClick() {
    if (isUpdateOptionsContainerOpen) setIsUpdateOptionsContainerOpen(false);
  }

  useEffect(() => {
    if (fromNew || fromEdit) {
      window.scrollTo({ top : 0, behavior : 'smooth' });
    }
  }, []);

  return (
    <div
      className={ styles.Root }
      onClick={ handleContainerClick }
    >
      <div className={ styles.Container }>
        <Choose>
          <When condition={ isActive || isDownloadingPDF }>
            <div className={ styles.PulseLoader }>
              <PulseLoadingIndicator isLoading={ true }/>
            </div>

            <If condition={ isDownloadingPDF }>
              <p className={ styles.SuccessMessage }>
                Please don’t refresh the page. We’re creating your PDF(s) right now.
                We’ll create them with 20 questions at a time to avoid any issues.
              </p>

              <p className={ styles.SuccessMessage }>
                It may take a little bit of time so just hold tight and we’ll have
                this ready for you shortly.
              </p>
            </If>
          </When>

          <Otherwise>
            <If condition={ isSuccessful }>
              <Choose>
                <When condition={ fromNew }>
                  <p className={ styles.SuccessMessage }>
                    Your study set was successfully created!
                  </p>
                </When>

                <When condition={ fromEdit }>
                  <p className={ styles.SuccessMessage }>
                    Your study set was successfully saved!
                  </p>
                </When>

                <When condition={ fromPublicStudySets }>
                  <p className={ styles.SuccessMessage }>
                    This study set was successfully added to your study sets!
                  </p>
                </When>
              </Choose>
            </If>

            <div className={ styles.TopSection }>
              <If condition={ questionsSize && questionsSize > 0 }>
                <div className={ styles.AboveTitleSection }>
                  <QuizOptions
                    newestQuiz={ newestQuiz }
                    startFlashStudySession={ startFlashStudySession }
                  />
                </div>
              </If>

              <If condition={ isPublic || isUnlisted }>
                <div className={ styles.ShareableLinkContainer }>
                  <ShareableLink link={ publicPublicStudySetLink } />
                </div>
              </If>

              <div className={ styles.TitleSection }>
                <div className={ styles.TitleContainer }>
                  <h3>{ studySet.get('title') }</h3>
                  <p>{ description }</p>
                </div>

                <UpdateOptions
                  deleteStudySet={ deleteStudySet }
                  isUndesignated={ isUndesignated }
                  isUpdateOptionsContainerOpen={ isUpdateOptionsContainerOpen }
                  questions={ questions }
                  setIsDownloadingPDF={ setIsDownloadingPDF }
                  setIsUpdateOptionsContainerOpen={ setIsUpdateOptionsContainerOpen }
                  studySetId={ studySet.get('id') }
                  title={ studySet.get('title') }
                />
              </div>

              <TextBadges
                hasDescription={ description || isUndesignated }
                isPublic={ isPublic }
                isUnlisted={ isUnlisted }
                reversedCopiesAdded={ studySet.get('reversedCopiesAdded') }
              />
            </div>

            <If condition={ results }>
              <ResultsList
                hasQuietTitle
                isLoading={ isActive }
                resultName="Question"
                results={ results }
              >
                <ul>
                  <For each="question" index="i" of={ results }>
                    <QuestionResult
                      basePath={ `/study-sets/${studySet.get('id')}/questions` }
                      key={ question.get('id') }
                      question={ question }
                    />
                  </For>
                </ul>
              </ResultsList>
            </If>

            <ButtonLink
              dataAutomatedTest="study-set-show--add-new-question-link-at-bottom"
              extraClassNames={ styles.AddNewQuestionLinkAtBottom }
              isSecondary
              text="Add new question"
              to={ `/study-sets/${studySet.get('id')}/edit?purpose=add-new-question` }
              withPlusSign
            />
          </Otherwise>
        </Choose>

        <If condition={ !isDownloadingPDF }>
          <SiteWideLinks location={ location } />
        </If>
      </div>
    </div>
  );
}

ShowStudySet.propTypes = {
  deleteStudySet         : studySetPropTypes.deleteStudySet.isRequired,
  history                : basePropTypes.history.isRequired,
  isActive               : basePropTypes.isActive,
  location               : basePropTypes.location.isRequired,
  isSuccessful           : basePropTypes.isSuccessful,
  newestQuiz             : quizPropTypes.quiz.isRequired,
  startFlashStudySession : PropTypes.func.isRequired,
  studySet               : studySetPropTypes.studySet.isRequired,
};

export default memo(ShowStudySet);
