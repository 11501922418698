// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__SecondPara___1BT8x{margin-top:18px}.styles-module__StartOverButton___3H95E{margin-top:24px}.styles-module__WrongQuestionsButtonContainer___1Lour{margin-top:24px}.styles-module__WrongQuestionsSubPara___1aXCT{color:rgba(0,0,0,.65);font-size:14px;margin:0 auto;max-width:360px;text-align:center}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/quiz/Explanation/FlashStudySession/styles.module.scss"],"names":[],"mappings":"AAAA,mCACE,eAAA,CAEF,wCACE,eAAA,CAEF,sDACE,eAAA,CAEF,8CACE,qBAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CACA,iBAAA","file":"styles.module.scss","sourcesContent":[".SecondPara {\n  margin-top: 18px; }\n\n.StartOverButton {\n  margin-top: 24px; }\n\n.WrongQuestionsButtonContainer {\n  margin-top: 24px; }\n\n.WrongQuestionsSubPara {\n  color: rgba(0, 0, 0, 0.65);\n  font-size: 14px;\n  margin: 0 auto;\n  max-width: 360px;\n  text-align: center; }\n"]}]);
// Exports
exports.locals = {
	"SecondPara": "styles-module__SecondPara___1BT8x",
	"StartOverButton": "styles-module__StartOverButton___3H95E",
	"WrongQuestionsButtonContainer": "styles-module__WrongQuestionsButtonContainer___1Lour",
	"WrongQuestionsSubPara": "styles-module__WrongQuestionsSubPara___1aXCT"
};
module.exports = exports;
