import gql from 'graphql-tag';

export default gql`
  mutation updateProfile(
    $maxQuestionAmountPerQuiz: Int
    $studySetIds: [ID!]
  ) {
    updateProfile(
      maxQuestionAmountPerQuiz: $maxQuestionAmountPerQuiz
      studySetIds: $studySetIds
    ) {
      maxQuestionAmountPerQuiz
      studySets {
        id
        questionsCount
        title
      }
    }
  }
`;
