import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import analytics from 'src/shared/analytics';
import ButtonLink from 'src/components/buttons/ButtonLink';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import handleSkipLinkClick from 'src/components/shared/handleSkipLinkClick';

import OnboardingBase from '../Base';
import styles from './styles.module.scss';

function SampleQuiz(props) {
  const {
    isActive,
    match,
    newest,
  } = props;

  function handleGetStartedClick() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Onboarding -- Sample quiz started');
  }

  return (
    <OnboardingBase isActive={ isActive }>
      <div
        className={ styles.Root }
        data-automated-test="onboarding--sample-quiz"
      >
        <p className={ styles.Title }>
          Well done! Now, let’s take a short sample quiz.
          <span> (about 1 minute)</span>
        </p>

        <p className={ styles.Para }>
          We’ll quiz you on 1-2 questions from the study set you just added.
        </p>

        <div className={ styles.BottomButtons }>
          <Choose>
            <When condition={ newest }>
              <ButtonLink
                extraClassNames={ styles.ButtonLink }
                handleClick={ handleGetStartedClick }
                text="Let's get started"
                to={ `/quiz/${newest.get('id')}/questions/1` }
              />
            </When>

            <Otherwise>
              <div />
            </Otherwise>
          </Choose>

          <Link
            className={ styles.SkipLink }
            onClick={ () => handleSkipLinkClick(match) }
            to={ '/' }
          >
            skip
          </Link>
        </div>
      </div>
    </OnboardingBase>
  );
}

SampleQuiz.propTypes = {
  isActive : PropTypes.bool,
  match    : basePropTypes.match.isRequired,
  newest   : quizPropTypes.quiz.isRequired,
};

export default SampleQuiz;
