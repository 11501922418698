import confetti from 'canvas-confetti';

export default function fireConfetti(duration = 2000) {
  let count = 35;
  let startVelocity = 15;
  let interval = 100;

  const animationEnd = Date.now() + duration;

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  if (window.innerWidth >= 768) {
    count = 75;
    interval = 150;
    startVelocity = 30;
  }

  const defaults = {
    colors : ['#2071aa', '#b2dfdb', '#f4793e'],
    spread : 360,
    startVelocity,
    ticks  : 140,
    zIndex : 10,
  };

  function fire(particleCount, opts) {
    confetti({ ...defaults, ...opts, particleCount });
  }

  setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = count * (timeLeft / duration);

    fire(particleCount, {
      origin : {
        x : randomInRange(0.1, 0.45),
        // since particles fall down, start a bit higher than random
        y : Math.random() - 0.2,
      },
    });

    fire(particleCount, {
      origin : {
        x : randomInRange(0.55, 0.9),
        y : Math.random() - 0.2,
      },
    });
  }, interval);
}
