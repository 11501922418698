import { PropTypes } from 'prop-types';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';

class Shared {
  // TODO: add
  propTypes = {
    emailSentTo        : authenticationPropTypes.emailSentTo,
    handleSubmit       : PropTypes.func.isRequired,
    isActive           : PropTypes.bool,
    shouldFireConfetti : PropTypes.bool,
  };
}

export default Shared;
