import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/// ////////////
// Containers
/// ////////////

import Dashboard from 'src/containers/Dashboard';
import NotFound from 'src/containers/NotFound';

import OnboardingNewStudySet from 'src/containers/onboarding/NewStudySet';
import OnboardingSampleQuiz from 'src/containers/onboarding/SampleQuiz';

import EditQuestion from 'src/containers/questions/Edit';
import NewQuestion from 'src/containers/questions/New';
import Questions from 'src/containers/questions/All';
import ShowQuestion from 'src/containers/questions/Show';

import QuizCongratulations from 'src/containers/quiz/Congratulations';
import QuizExplanation from 'src/containers/quiz/Explanation';
import QuizQuestion from 'src/containers/quiz/Question';

import Stats from 'src/containers/Stats';

import EditStudySet from 'src/containers/studySets/Edit';
import NewStudySet from 'src/containers/studySets/New';
import ShowStudySet from 'src/containers/studySets/Show';
import ShowStudySetQuestion from 'src/containers/studySets/ShowQuestion';
import StudySets from 'src/containers/studySets/All';

import AccountPlan from 'src/containers/profile/AccountPlan';
import ContactUs from 'src/containers/profile/ContactUs';
import DeleteAccount from 'src/containers/profile/DeleteAccount';
import InstallApp from 'src/containers/profile/InstallApp';
import Notifications from 'src/containers/profile/Notifications';
import Profile from 'src/containers/profile/Index';
import Preferences from 'src/containers/profile/Preferences';
import UserDetails from 'src/containers/profile/UserDetails';

function AuthenticatedRoutes() {
  /* eslint-disable max-len */
  return (
    <Switch>
      <Route path="/" component={ Dashboard } exact />

      <Route path="/onboarding/new-study-set" component={ OnboardingNewStudySet } exact />
      <Route path="/onboarding/sample-quiz" component={ OnboardingSampleQuiz } exact />

      <Route path="/questions" component={ Questions } exact />
      <Route path="/questions/new" component={ NewQuestion } exact />
      <Route path="/questions/:id" component={ ShowQuestion } exact />
      <Route path="/questions/:id/edit" component={ EditQuestion } exact />

      <Route path="/quiz/:id/congratulations" component={ QuizCongratulations } exact />
      <Route path="/quiz/:id/explanation" component={ QuizExplanation } exact />
      <Route path="/quiz/:id/explanation?purpose=email_quiz" component={ QuizExplanation } exact />
      <Route path="/quiz/:id/questions/:questionNumber" component={ QuizQuestion } exact />

      <Route path="/stats" component={ Stats } exact />

      <Route path="/study-sets" component={ StudySets } exact />
      <Route path="/study-sets/new" component={ NewStudySet } exact />
      <Route path="/study-sets/:id" component={ ShowStudySet } exact />
      <Route path="/study-sets/:id/questions/:questionId" component={ ShowStudySetQuestion } exact />
      <Route path="/study-sets/:id/edit" component={ EditStudySet } exact />
      <Route path="/study-sets/:id/edit?purpose=onboarding" component={ EditStudySet } exact />

      <Route
        path="/profile"
        render={ profileProps => (
          <Profile { ...profileProps }>
            <Switch>
              <Redirect from="/profile" to="/profile/user-details" exact />
              <Route path="/profile/user-details" component={ UserDetails } exact />
              <Route path="/profile/preferences" component={ Preferences } exact />
              <Route path="/profile/notifications" component={ Notifications } exact />
              <Route path="/profile/account-plan" component={ AccountPlan } exact />
              <Route path="/profile/install-app" component={ InstallApp } exact />
              <Route path="/profile/contact-us" component={ ContactUs } exact />
              <Route path="/profile/delete-account" component={ DeleteAccount } exact />
            </Switch>
          </Profile>
        ) }
      />

      <Route component={ NotFound } />
    </Switch>
  );
  /* eslint-enable max-len */
}

export default memo(AuthenticatedRoutes);
