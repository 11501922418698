import gql from 'graphql-tag';

import questionFields from 'src/graphql/fragments/questionFields';

export default gql`
  mutation createQuestion(
    $answer: AnswerInput!
    $audioFile: UploadInput
    $imageFile: UploadInput
    $studySetId: ID
    $title: String
  ) {
    createQuestion(
      answer: $answer
      audioFile: $audioFile
      imageFile: $imageFile
      studySetId: $studySetId
      title: $title
    ) {
      ...questionFields
    }
  }
  ${questionFields}
`;
