function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+') // eslint-disable-line no-useless-escape
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

function subscribePushManager(registration) {
  if (!('PushManager' in window)) {
    throw new Error('No Push API Support!');
  }

  const convertedVapidKey = urlBase64ToUint8Array(process.env.VAPID_PUBLIC_KEY);

  return registration
    .pushManager
    .subscribe({
      userVisibleOnly      : true,
      applicationServerKey : convertedVapidKey,
    });
}

export default function createSubscription(createPushNotification) {
  return navigator.serviceWorker.ready
    .then(subscribePushManager)
    .then(subscription => {
      const { endpoint, keys : { auth, p256dh } } = subscription.toJSON();

      return createPushNotification({ auth, endpoint, p256dh });
    });
}
