import gql from 'graphql-tag';

export default gql`
  mutation updateNotificationSetting(
    $id: ID
    $medium: String!
    $optedOut: Boolean!
  ) {
    updateNotificationSetting(
      id: $id
      medium: $medium
      optedOut: $optedOut
    ) {
      notificationSettings {
        id
        medium
        optedOut
      }
    }
  }
`;
