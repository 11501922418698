import { createContext } from 'react';

const SignInProps = createContext({
  errors                       : null,
  isActive                     : false,
  shouldDisplayThankYouMessage : false,
  submitFeedback               : null,
});

export default SignInProps;
