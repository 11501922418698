import { PropTypes } from 'prop-types';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import InfoText from 'src/components/Stats/Charts/InfoText';
import LineChart from 'src/components/Stats/Charts/LineChart';
import TextStats from 'src/components/Stats/Charts/TextStats';

import styles from './styles.module.scss';

function Totals(props) {
  const {
    isEmpty,
    report,
  } = props;

  const total = report.get('currentlyMemorized');

  const stats = [
    {
      dataAutomatedTest : 'answers-memorized--totals--total-currently-memorized',
      title             : 'Total currently memorized',
      value             : total,
    },
  ];

  return (
    <div className={ styles.Root }>
      <InfoText>
        An answer is considered memorized if:

        <ul>
          <li>
            The future due date of its question has not passed.
            If you created the question within the last 24 hours,
            its due date will not have passed.
          </li>

          <li>
            You did not select “No” when asked “Did you recall the correct answer?”
            the last time you were quizzed on it.
          </li>
        </ul>
      </InfoText>

      <TextStats stats={ stats } />
      <LineChart isEmpty={ isEmpty } report={ report } />
    </div>
  );
}

Totals.propTypes = {
  isEmpty : PropTypes.bool,
  report  : statsPropTypes.answersMemorizedTotals.isRequired,
};

export default Totals;
