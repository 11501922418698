import { applyMiddleware, compose, createStore } from 'redux';
import localforage from 'localforage';
import reduxStorage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import Shared from './Shared';

class Store extends Shared {
  setStore() {
    const persistedReducer = this._buildPersistedReducer();
    const middlewares = [applyMiddleware(thunk)];

    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }
    /* eslint-enable no-underscore-dangle */

    this.store = createStore(
      persistedReducer,
      {}, // initial state
      compose(...middlewares),
    );
  }
}

const isTestEnv = process.env.RAILS_ENV === 'test';

if (isTestEnv && process.env.SHOULD_USE_LOCALFORAGE === 'true') {
  window.localforage = localforage;
}

let storage = localforage;

if (isTestEnv && process.env.SHOULD_USE_LOCALFORAGE !== 'true') {
  // We're using reduxStorage in test env because Capybara doesn't
  // clean out all the things localforage uses. So we end up with
  // some awful flakiness. But we have an rspec script just for
  // localforage testing.
  storage = reduxStorage;
}

export default new Store(storage);
