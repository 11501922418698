// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__StatsPlaceholder___1n83P{margin-top:12px}.styles-module__Chart___1QQez{height:200px;width:100%}.styles-module__Chart___1QQez>div{margin-left:-25px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Stats/Charts/LineChart/styles.module.scss"],"names":[],"mappings":"AAAA,yCACE,eAAA,CAEF,8BACE,YAAA,CACA,UAAA,CACA,kCACE,iBAAA","file":"styles.module.scss","sourcesContent":[".StatsPlaceholder {\n  margin-top: 12px; }\n\n.Chart {\n  height: 200px;\n  width: 100%; }\n  .Chart > div {\n    margin-left: -25px; }\n"]}]);
// Exports
exports.locals = {
	"StatsPlaceholder": "styles-module__StatsPlaceholder___1n83P",
	"Chart": "styles-module__Chart___1QQez"
};
module.exports = exports;
