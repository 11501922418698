import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import Breadcrumbs from 'src/components/navigation/Breadcrumbs';
import Card from 'src/components/Card';
import Preview from 'src/components/ShowQuestion/Preview';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import styles from './styles.module.scss';

function ShowPublicStudySetQuestion(props) {
  const {
    history,
    isActive,
    isUnauthed,
    question,
    studySet,
  } = props;

  // TODO: Change to loading component
  if (!question || isActive) return null;

  const basePath = isUnauthed ?
    '/public/public-study-sets' :
    '/public-study-sets';

  const studySetId = studySet.get('id');
  const studySetTitle = studySet.get('title');
  const title = question.get('title') || '(no title)';

  const links = [
    { label : 'Public study sets', path : '/public-study-sets' },
    { label : studySetTitle, path : `/public-study-sets/${studySetId}` },
    { label : title, isCurrent : true },
  ];

  return (
    <div>
      <Card
        isSoloCardOnScreen
        isFullScreenOnDesktop
        isFullScreenOnMobile
        navLocationOnMobile="footer"
      >
        <div className={ styles.LinkContainer }>
          <div className={ styles.DesktopContainer }>
            <Breadcrumbs links={ links } />
          </div>

          <div className={ styles.MobileContainer }>
            <Choose>
              <When condition={ isUnauthed }>
                <Breadcrumbs links={ links } />
              </When>

              <Otherwise>
                <Breadcrumbs
                  links={ [
                    { label : 'Study sets', path : '/study-sets' },
                    { label : 'Public study sets', path : '/public-study-sets' },
                    { label : studySetTitle, path : `/public-study-sets/${studySetId}` },
                    { label : title, isCurrent : true },
                  ] }
                />
              </Otherwise>
            </Choose>
          </div>
        </div>

        <Preview
          basePath={ basePath }
          question={ question }
          studySet={ studySet }
        />
      </Card>

      <SiteWideLinks
        isUnauthed={ isUnauthed }
        location={ history.location }
      />
    </div>
  );
}

ShowPublicStudySetQuestion.propTypes = {
  history    : basePropTypes.history.isRequired,
  isActive   : basePropTypes.isActive,
  isUnauthed : PropTypes.bool,
  question   : questionPropTypes.question.isRequired,
  studySet   : studySetPropTypes.studySet.isRequired,
};

export default ShowPublicStudySetQuestion;
