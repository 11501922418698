import React from 'react';

function GraduationCapInCircle() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44">
      <ellipse cx="21.8172" cy="22" rx="21.8172" ry="22" />

      <g clipPath="url(#clip0_146_2377)">
        <path d="M14.8755 23.18V27.18L21.8173 31L28.7591 27.18V23.18L21.8173 27L14.8755 23.18ZM21.8173 13L10.9087 19L21.8173 25L30.7425 20.09V27H32.7259V19L21.8173 13Z"/>
      </g>

      <defs>
        <clipPath id="clip0_146_2377">
          <rect x="9.91699" y="10" width="23.8006" height="24" rx="11.9003" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default GraduationCapInCircle;
