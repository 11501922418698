import Immutable from 'immutable';

export default Immutable.fromJS({
  accountPlan          : {},
  hasPremiumAccess     : false,
  isActive             : false,
  notificationSettings : [],
  progressReport       : null,
  stripeAccount        : {
    paymentMethods     : [],
    stripeSubscription : null,
  },
  studySets            : [],
});
