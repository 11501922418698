import { PropTypes } from 'prop-types';
import React from 'react';

import Plan from './Plan';
import styles from './styles.module.scss';

function Plans(props) {
  const {
    isActive,
    needsGooglePlayBilling,
    planType,
    upgradeAccount,
    upgradeTo45DaysWithGooglePlayBilling,
    upgradeToYearlyWithGooglePlayBilling,
    upgradeToLifetimeWithGooglePlayBilling,
  } = props;

  // Account plan type matches:
  // 1 - Premium (1 Year)
  // 2 - Premium (Lifetime)
  // 3 - Premium (45 Days)

  return (
    <div className={ styles.Root }>
      <Plan
        cost="Free"
        costSubTitle="No payment or credit card required"
        dataAutomatedTest="profile--account-plan"
        name="Basic"
        shouldHideButton
      >
        <p className={ styles.SubTitle }>
          Dorothy is completely free for your first <strong>60</strong> questions.
        </p>

        <p className={ styles.SubTitle }>
          Many people never need to add more than 60 questions and if that’s you,
          that’s perfectly fine. You can use Dorothy for free as long as you’d like.
        </p>
      </Plan>

      <Plan
        cost="$8"
        costSubTitle="45 Days"
        dataAutomatedTest="profile--account-plan"
        handleUpgrade={ needsGooglePlayBilling ?
          upgradeTo45DaysWithGooglePlayBilling :
          () => upgradeAccount(3) }
        isActive={ isActive }
        name="Premium (45 Days)"
        needsGooglePlayBilling={ needsGooglePlayBilling }
        shouldHideButton={ [1, 2, 3].includes(planType) }
      >
        <p className={ styles.SubTitle }>
          Perhaps you have a big exam (or multiple) coming up and
          you only need to study for a short time period.
        </p>

        <p className={ styles.SubTitle }>
          With a Premium plan,
          you’ll be able to add <strong>unlimited</strong> questions for that time.
        </p>

        <p className={ styles.SubTitle }>
          This is <strong>not</strong> an auto-renewing subscription.
        </p>

        <p className={ styles.SubTitle }>
          At the end of those 45 days, you can choose to manually add more time, or not.
          Even if you don’t, you will always have access to all of your data.
        </p>
      </Plan>

      <Plan
        cost="$45"
        costSubTitle="1 Year"
        dataAutomatedTest="profile--account-plan"
        handleUpgrade={ needsGooglePlayBilling ?
          upgradeToYearlyWithGooglePlayBilling :
          () => upgradeAccount(1) }
        isActive={ isActive }
        name="Premium (1 Year)"
        needsGooglePlayBilling={ needsGooglePlayBilling }
        shouldHideButton={ [1, 2].includes(planType) }
      >
        <p className={ styles.SubTitle }>
          With a Premium plan,
          you’ll be able to add <strong>unlimited</strong> questions.
        </p>

        <p className={ styles.SubTitle }>
          This is <strong>not</strong> an auto-renewing subscription.
        </p>

        <p className={ styles.SubTitle }>
          At the end of that year, you can choose to manually add more time, or not.
          Even if you don’t, you will always have access to all of your data.
        </p>
      </Plan>

      <Plan
        cost="$150"
        costSubTitle="Lifetime access"
        dataAutomatedTest="profile--account-plan"
        handleUpgrade={ needsGooglePlayBilling ?
          upgradeToLifetimeWithGooglePlayBilling :
          () => upgradeAccount(2) }
        isActive={ isActive }
        name="Premium (Lifetime)"
        needsGooglePlayBilling={ needsGooglePlayBilling }
        shouldHideButton={ planType === 2 }
      >
        <p className={ styles.SubTitle }>
          If you believe in Dorothy and would rather not worry about manually renewing,
          you can sign up for lifetime access.
          It would mean so much to us as we are still a young startup.
        </p>

        <p className={ styles.SubTitle }>
          With the Premium (Lifetime) plan,
          you’ll be able to add <strong>unlimited</strong> questions for life.
        </p>
      </Plan>
    </div>
  );
}

Plans.propTypes = {
  handleUpgrade : PropTypes.func.isRequired,
  isActive      : PropTypes.bool,
  planType      : PropTypes.number.isRequired,
};

export default Plans;
