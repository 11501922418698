import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import * as basePropTypes from 'src/constants/propTypes/base';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import handleSkipLinkClick from 'src/components/shared/handleSkipLinkClick';
import isSmallMobile from 'src/components/shared/isSmallMobile';
import TextField from 'src/components/fields/TextField';

import styles from './styles.module.scss';

function NewStudySet(props) {
  const {
    errors,
    handleSubmit,
    isActive,
    match,
    saveButtonValue,
    title,
  } = props;

  const { handleSubmit: formSubmit, register } = useForm();

  const onSubmit = useCallback(
    values => formSubmit(handleSubmit)(values),
    [formSubmit, handleSubmit],
  );

  const isOnSmallMobile = isSmallMobile();

  return (
    <form onSubmit={ onSubmit }>
      <div className={ styles.Title }>
        <TextField
          autoFocus={ !isOnSmallMobile }
          autoFocusDelayInMs={ isOnSmallMobile ? null : 3000 }
          defaultValue={ title }
          disabled={ isActive }
          errorMessages={ errors && errors.get('title') }
          heading="What will your study set be called?"
          htmlFor="title"
          name="title"
          register={ register }
        />
      </div>

      <div className={ styles.BottomButtons }>
        <div
          className={ styles.SubmitButton }
        >
          <ButtonSubmit
            dataAutomatedTest="study-set--bottom-submit-button"
            disabled={ isActive }
            isActive={ isActive }
            type="submit"
            value={ saveButtonValue || 'Create' }
          />
        </div>

        <Link
          className={ styles.SkipLink }
          onClick={ () => handleSkipLinkClick(match) }
          to={ '/' }
        >
          skip
        </Link>
      </div>
    </form>
  );
}

NewStudySet.propTypes = {
  errors          : basePropTypes.errors,
  handleSubmit    : PropTypes.func.isRequired,
  isActive        : PropTypes.bool,
  match           : basePropTypes.match.isRequired,
  saveButtonValue : PropTypes.string,
  title           : PropTypes.string,
};

export default NewStudySet;
