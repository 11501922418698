import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as profileActionCreators from 'src/actions/profile';
import * as profilePropTypes from 'src/constants/propTypes/profile';
import * as pushNotificationsActions from 'src/actions/pushNotifications';

import Notifications from 'src/components/profile/Notifications';
import requestPushPermission from 'src/shared/serviceWorker/requestPushPermission';

class FunctionalNotifications extends PureComponent {
  static propTypes = {
    actions              : basePropTypes.actions.isRequired,
    notificationSettings : profilePropTypes.notificationSettings.isRequired,
  };

  handleNotificationSettingUpdate = (notificationSetting, optedOut) => {
    const medium = notificationSetting.get('medium');
    const id = notificationSetting && notificationSetting.get('id');

    const params = {
      id,
      medium,
      optedOut,
    };

    return this.props.actions.profile.updateNotificationSetting(params)
      .then(() => {
        if (medium === 'push' && !optedOut) {
          return this.handleOptingInToPushNotifications(id, medium);
        }
      });
  }

  handleOptingInToPushNotifications = (id, medium) => {
    const { actions : { profile, pushNotifications } } = this.props;

    return requestPushPermission(pushNotifications.createPushNotification, true)
      .catch(err => profile.updateNotificationSetting({ id, medium, optedOut : true })
        .then(() => {
          if (err.message === 'denied') {
            return alert('Oh no! It looks like you turned off notifications in your browser settings. You\'ll need to manually turn them back on.'); // eslint-disable-line no-alert, max-len
          }

          if (err.message === 'No Push API Support!') {
            return alert('Oh no! It doesn\'t look like your browser supports push notifications.'); // eslint-disable-line no-alert, max-len
          }

          alert('Oh no! It doesn\'t appear that you will be able to receive push notifications on this browser and phone.'); // eslint-disable-line no-alert, max-len
        }));
  }

  render() {
    return (
      <Notifications
        handleNotificationSettingUpdate={ this.handleNotificationSettingUpdate }
        notificationSettings={ this.props.notificationSettings }
      />
    );
  }
}

function mapStateToProps({ profile }) {
  return {
    notificationSettings : profile.get('notificationSettings'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      profile           : bindActionCreators(profileActionCreators, dispatch),
      pushNotifications : bindActionCreators(pushNotificationsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalNotifications);
