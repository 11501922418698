import { Link } from 'react-router-dom';
import React from 'react';

import * as quizPropTypes from 'src/constants/propTypes/quiz';

import classNames from 'src/components/shared/classNames';
import CongratulationsParagraph from 'src/components/quiz/Congratulations/Paragraph';
import Logo from 'src/components/quiz/Congratulations/Logo';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function PostTransition({ isAnimatingCongratulations }) {
  function getLogoClassNames() {
    return cx({
      Logo          : true,
      shouldAnimate : isAnimatingCongratulations,
    });
  }

  return (
    <div>
      <div className={ getLogoClassNames() }>
        <Link to={ '/' }>
          <Logo
            dataAutomatedTest="post-transition-logo"
            shouldAnimate={ false }
          />
        </Link>
      </div>

      <CongratulationsParagraph isAnimatingCongratulations={ isAnimatingCongratulations } />
    </div>
  );
}

PostTransition.propTypes = {
  isAnimatingCongratulations : quizPropTypes.isAnimatingCongratulations,
};

export default PostTransition;
