import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import buildTotalDaysMonthsText from 'src/components/shared/buildTotalDaysMonthsText';
import HorizontalLine from 'src/components/HorizontalLine';
import StatsCard from 'src/components/Stats/Card';

import AvgFutureRetention from '../AvgFutureRetention';
import AvgStudyTime from '../AvgStudyTime';
import Tabs from '../Tabs';
import styles from './styles.module.scss';

function GroupOne(props) {
  const {
    avgFutureRetention,
    avgStudyTime,
    isEmpty,
    totals,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const seconds = totals.get('avgStudyTime');
  const minutes = (seconds / 60).toFixed(1);

  const tabs = [
    {
      dataAutomatedTest : 'totals--avg-future-retention',
      label             : 'Average future retention',
      index             : 0,
      value             : buildTotalDaysMonthsText(totals.get('avgFutureRetention')),
    },
    {
      dataAutomatedTest : 'totals--avg-study-time',
      label             : 'Average study time',
      index             : 1,
      value             : `${minutes} minutes/day`,
    },
  ];

  return (
    <StatsCard>
      <div className={ styles.Root }>
        <Tabs
          activeIndex={ activeIndex }
          setActiveIndex={ setActiveIndex }
          tabs={ tabs }
        />

        <div className={ styles.HorizontalLine }>
          <HorizontalLine />
        </div>

        <Choose>
          <When condition={ activeIndex === 0 }>
            <AvgFutureRetention isEmpty={ isEmpty } report={ avgFutureRetention } />
          </When>

          <Otherwise>
            <AvgStudyTime isEmpty={ isEmpty } report={ avgStudyTime } />
          </Otherwise>
        </Choose>
      </div>
    </StatsCard>
  );
}

GroupOne.propTypes = {
  avgFutureRetention : statsPropTypes.avgFutureRetention.isRequired,
  avgStudyTime       : statsPropTypes.avgStudyTime.isRequired,
  isEmpty            : PropTypes.bool,
  totals             : statsPropTypes.totals.isRequired,
};

export default GroupOne;
