import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as onboardingActions from 'src/actions/onboarding';
import * as studySetsActions from 'src/actions/studySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import navigateOrWait from 'src/containers/shared/navigateOrWait';
import OnboardingNewStudySet from 'src/components/onboarding/NewStudySet';

class FunctionalNewStudySet extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    errors   : basePropTypes.errors,
    isActive : PropTypes.bool,
    match    : basePropTypes.match.isRequired,
    studySet : studySetPropTypes.autosavedStudySet,
  };

  componentDidMount() {
    this.props.actions.studySets.clearTempState();
  }

  handleCreate = async values => {
    const {
      actions : { onboarding, studySets },
    } = this.props;

    studySets.startSave();

    const params = {
      ...values,
      reversedCopiesAdded : false,
      visibilityStatus    : 'is_private',
    };

    return studySets
      .create(params)
      .then(({ payload : { data : studySet } }) => {
        if (window.gtag) {
          window.gtag('event', 'conversion_in_product_site', { value : 0 });
        }

        const id = studySet && studySet.id;

        if (!id) return;

        onboarding.addFirstStudySet(studySet);
        this.navigateOrWait(id);

        const userId = getUserIdFromStore();

        if (userId) analytics.identify(userId);

        analytics.track('Onboarding -- Study set created with title');
      })
      .catch(() => null);
  }

  handleUpdate = async values => {
    const { actions: { studySets }, studySet } = this.props;
    const id = studySet.get('id');

    studySets.updateValueInAutosaved(['autosaved', id, 'title'], values.title);

    return this.navigateOrWait(id);
  }

  navigateOrWait = id => navigateOrWait(
    this.props,
    `/study-sets/${id}/edit?purpose=onboarding`,
  )

  render() {
    const { isActive, match, studySet } = this.props;

    const id = studySet && studySet.get('id');
    const title = studySet && studySet.get('title');
    const handleSubmit = id ? this.handleUpdate : this.handleCreate;

    return (
      <OnboardingNewStudySet
        errors={ this.props.errors }
        handleSubmit={ handleSubmit }
        match={ match }
        isActive={ isActive }
        title={ title }
      />
    );
  }
}

function mapStateToProps({ onboarding, studySets }) {
  const id = onboarding.getIn(['studySet', 'id']);

  return {
    errors   : studySets.get('errors'),
    isActive : studySets.get('isActive'),
    studySet : studySets.getIn(['autosaved', id]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      onboarding : bindActionCreators(onboardingActions, dispatch),
      studySets  : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalNewStudySet);
