import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

import * as questionPropTypes from './question';

export const attempted = PropTypes.bool;
export const completedSampleQuiz = PropTypes.bool;
export const dueDate = PropTypes.instanceOf(Date);
export const estimatedTimeToCompleteInMinutes = PropTypes.number;

export const handleNext = PropTypes.func;
export const hasQuizStarted = PropTypes.bool;
export const isAnimatingCongratulations = PropTypes.bool;
export const isOnFirstQuizAndNotStarted = PropTypes.bool;
export const isReady = PropTypes.bool;
export const isReadyForNextQuestion = PropTypes.bool;
export const isResetting = PropTypes.bool;
export const isSample = PropTypes.bool;
export const isTransitioningToCongratulations = PropTypes.bool;
export const navigateToCongratulations = PropTypes.func;
export const nextButtonRef = PropTypes.shape({ current : PropTypes.instanceOf(Element) });
export const noPrevQuiz = PropTypes.bool;
export const noQuestionsInQuizzes = PropTypes.bool;
export const prevQuizWasRecentlyCompleted = PropTypes.bool;
export const questionNumber = PropTypes.string;
export const questionsToBeAnsweredSize = PropTypes.number;

export const quiz = ImmutablePropTypes.mapContains({
  completedAt  : PropTypes.string,
  dueDate      : PropTypes.string.isRequired,
  id           : PropTypes.string.isRequired,
  isSampleQuiz : PropTypes.bool,
  previousQuiz : ImmutablePropTypes.mapContains({
    completedAt  : PropTypes.string,
    dueDate      : PropTypes.string.isRequired,
    isSampleQuiz : PropTypes.bool,
  }),
  questions    : questionPropTypes.questions.isRequired,
  started      : PropTypes.bool.isRequired,
});

export const quizCompleted = PropTypes.bool;
export const quizIsDueTodayAndNotStarted = PropTypes.bool;
export const quizIsDueTodayAndStarted = PropTypes.bool;
export const quizIsDueInFutureAndNotStarted = PropTypes.bool;
export const quizIsDueInFutureAndStarted = PropTypes.bool;
export const quizStarted = PropTypes.bool;
export const secondsToReveal = PropTypes.number;
export const scrollToAnswerContent = PropTypes.func;
export const tonightAtMidnight = PropTypes.string;
export const transitionToCongratulations = PropTypes.func;
export const updateQuestionToBeAnswered = PropTypes.func;
