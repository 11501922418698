import Immutable from 'immutable';

export default Immutable.fromJS({
  accessToken               : null,
  client                    : null,
  email                     : null,
  emailSentTo               : null,
  errors                    : null,
  experiencingServerError   : false,
  expiry                    : null,
  id                        : null,
  isActive                  : false,
  justSignedOut             : false,
  lastAccessTokenFromSignIn : null,
  name                      : null,
  uid                       : null,
});
