import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import classNames from 'src/components/shared/classNames';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function LinkItem(props) {
  const {
    children,
    dataAutomatedTest,
    handleClickOverride,
    iconClassName,
    isActive,
    title,
    to,
  } = props;

  function getTitleClassNames() {
    return cx({
      Title : true,
      isActive,
    });
  }

  return (
    <Choose>
      <When condition={ handleClickOverride }>
        <div
          className={ styles.Root }
          data-automated-test={ dataAutomatedTest }
          onClick={ handleClickOverride }
        >
          <p className={ getTitleClassNames() } >{ title }</p>

          <If condition={ isActive }>
            <div className={ styles.IsActiveBottomBar } />
          </If>
        </div>
      </When>

      <Otherwise>
        <Link
          className={ styles.Link }
          data-automated-test={ dataAutomatedTest }
          to={ to }
        >
          <div className={ styles.Root }>
            <Choose>
              <When condition={ children }>
                { children }
              </When>

              <Otherwise>
                <If condition={ iconClassName }>
                  <i className={ `${styles.Icon} fas fa-${iconClassName}` } />
                </If>

                <If condition={ title }>
                  <p className={ getTitleClassNames() } >{ title }</p>
                </If>
              </Otherwise>
            </Choose>

            <If condition={ isActive }>
              <div className={ styles.IsActiveBottomBar } />
            </If>
          </div>
        </Link>
      </Otherwise>
    </Choose>
  );
}

LinkItem.propTypes = {
  children            : basePropTypes.children,
  dataAutomatedTest   : basePropTypes.dataAutomatedTest,
  handleClickOverride : PropTypes.func,
  iconClassName       : basePropTypes.iconClassName,
  isActive            : basePropTypes.isActive,
  title               : basePropTypes.title,
  to                  : basePropTypes.to.isRequired,
};

export default LinkItem;
