import gql from 'graphql-tag';

export default gql`
  mutation acknowledgeGooglePlayPurchase(
    $planType: Int!
    $purchaseToken: String!
    $skuItemId: String!
  ) {
    acknowledgeGooglePlayPurchase(
      planType: $planType
      purchaseToken: $purchaseToken
      skuItemId: $skuItemId
    ) {
      id
    }
  }
`;
