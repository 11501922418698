import { PropTypes } from 'prop-types';
import React from 'react';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function OrLinesContainer(props) {
  const {
    noMargins,
  } = props;

  return (
    <div className={ cx({ Root : true, noMargins }) }>
      <div className={ styles.OrContainerLine } />
      <p>or</p>
      <div className={ styles.OrContainerLine } />
    </div>
  );
}

OrLinesContainer.propTypes = {
  noMargins : PropTypes.bool,
};

export default OrLinesContainer;
