import React, { useContext, useEffect } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import ButtonLink from 'src/components/buttons/ButtonLink';
import Footer from 'src/components/navigation/Footer';
import QuizCongratulationsContext from 'src/contexts/QuizCongratulations';

import styles from './styles.module.scss';

function Finish({ history }) {
  const { studySetIdFromQuiz } = useContext(QuizCongratulationsContext);

  const exitQuizPath = studySetIdFromQuiz ? `/study-sets/${studySetIdFromQuiz}` : '/';

  function handleKeyDown(event) {
    if (event.key !== 'Enter') return;

    history.push(exitQuizPath);
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className={ styles.Root }>
      <div className={ styles.InsideContainer }>
        <div className={ styles.ButtonContainer }>
          <div className={ styles.Button }>
            <ButtonLink
              isInFooter
              text="Finish"
              to={ exitQuizPath }
            />
          </div>
        </div>
      </div>

      <div className={ styles.Footer }>
        <Footer>
          <ButtonLink
            isInFooter
            text="Finish"
            to={ exitQuizPath }
          />
        </Footer>
      </div>
    </div>
  );
}

Finish.propTypes = {
  history : basePropTypes.history.isRequired,
};

export default Finish;
