import { Link } from 'react-router-dom';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import analytics from 'src/shared/analytics';
import classNames from 'src/components/shared/classNames';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import HomeIcon from 'src/components/icons/Home';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function HomeLink({ disabled, extraClasses, handleClick, match }) {
  const extraRootClass = extraClasses && extraClasses.root;

  function getLinkClassNames() {
    return cx({
      Link       : true,
      isDisabled : disabled,
    });
  }

  function getTextClassNames() {
    return cx({
      Text       : true,
      isDisabled : disabled,
    });
  }

  function handleLinkClick() {
    if (match) {
      const userId = getUserIdFromStore();

      if (userId) analytics.identify(userId);

      analytics.track(`Home link clicked on ${match.path}`);
    }

    if (handleClick) handleClick();
  }

  const to = disabled ? null : '/';

  return (
    <div className={ `${styles.Root} ${extraRootClass}` }>
      <Link
        className={ getLinkClassNames() }
        onClick={ handleLinkClick }
        to={ to }
      >
        <HomeIcon />
        <h3 className={ getTextClassNames() }>Home</h3>
      </Link>
    </div>
  );
}

HomeLink.propTypes = {
  disabled     : basePropTypes.disabled,
  extraClasses : basePropTypes.extraClasses,
  handleClick  : basePropTypes.handleClick,
  match        : basePropTypes.match,
};

export default HomeLink;
