import buildUserFriendlyErrorsForMissingValues from 'src/containers/shared/buildUserFriendlyErrorsForMissingValues';
import navigateOrWait from 'src/containers/shared/navigateOrWait';

function handlePostSuccessfulRequest(containerThis) {
  const { errors, history, isFirstSignIn } = containerThis.props;

  // I'm doing a string match here based upon the error message.
  // I do not like this and think it could be better done.
  // But it's thoroughly tested and if something on the backend changes,
  // our tests will catch it. In the future, if you do want
  // to implement it the right way, here's the code:
  //
  // errors.get('otp').find(e => e.get('expired') === true)
  //
  // It's captured here:
  // https://trello.com/c/tJwLNDx9/376-update-how-errors-are-sent-from-the-backend-and-how-they-are-received-on-the-frontend
  if (errors && errors.size > 0 && errors.get('otp').includes('expired')) {
    containerThis.goBackFromVerifyOtp();
    return history.push('/auth/welcome?invalid-otp=true');
  }

  if (isFirstSignIn) {
    return setTimeout(
      () => navigateOrWait(containerThis.props, '/onboarding/new-study-set'),
      1000,
    );
  }

  return navigateOrWait(containerThis.props, '/');
}

export default function (values, containerThis) {
  const { authentication } = containerThis.props.actions;

  const errors = buildUserFriendlyErrorsForMissingValues(
    values,
    { otp  : 'Oops! Looks like you forgot to enter your one time password. This can be found in your email.' }, // eslint-disable-line max-len
  );

  if (errors) return authentication.addErrors(errors);

  const params = {
    email : containerThis.props.emailSentTo,
    ...values,
  };

  return authentication
    .verifyOtp(params)
    .then(() => {
      const { errors : propErrors } = containerThis.props;

      if (propErrors && propErrors.size > 0) return handlePostSuccessfulRequest(containerThis);

      const isRailsEnvTest = process.env.RAILS_ENV === 'test';

      if (isRailsEnvTest) return handlePostSuccessfulRequest(containerThis);

      containerThis.setState({ shouldFireConfetti : true });

      setTimeout(() => {
        handlePostSuccessfulRequest(containerThis);
      }, 1500);
    });
}
