import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.STATS__FETCH_DASHBOARD_REPORT_FAILURE:
    case actionTypes.STATS__FETCH_FULL_REPORT_FAILURE:
      return state.withMutations(map => {
        map.set('isActive', false);
      });

    case actionTypes.STATS__FETCH_DASHBOARD_REPORT_START:
    case actionTypes.STATS__FETCH_FULL_REPORT_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.STATS__FETCH_DASHBOARD_REPORT_SUCCESS:
      return state.withMutations(map => {
        const { data } = payload;

        map.set('dashboardReport', Immutable.fromJS(data));
        map.set('isActive', false);
      });

    case actionTypes.STATS__FETCH_FULL_REPORT_SUCCESS:
      return state.withMutations(map => {
        const { data } = payload;

        map.set('fullReport', Immutable.fromJS(data));
        map.set('isActive', false);
      });

    default:
      return state;
  }
}
