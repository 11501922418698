import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import HomeLink from 'src/components/navigation/HomeLink';

import ExitLink from './ExitLink';
import styles from './styles.module.scss';

function RightSide(props) {
  const {
    disabled,
    exitLinkPath,
    exitOverrideAction,
    isActive,
    isSaving,
    rightSideHasHomeLink,
    saveButtonIsSecondary,
    saveButtonValue,
    withSaveButton,
  } = props;

  return (
    <Choose>
      <When condition={ withSaveButton }>
        <ButtonSubmit
          disabled={ disabled || isActive }
          floatRight
          isActive={ isSaving }
          isInHeader
          isSecondary={ saveButtonIsSecondary }
          type="submit"
          value={ saveButtonValue || 'Save' }
        />
      </When>

      <When condition={ rightSideHasHomeLink }>
        <HomeLink extraClasses={ { root : styles.HomeLink } } />
      </When>

      <Otherwise>
        <ExitLink
          exitOverrideAction={ exitOverrideAction }
          to={ exitLinkPath }
        />
      </Otherwise>
    </Choose>
  );
}

RightSide.propTypes = {
  disabled              : basePropTypes.disabled,
  exitLinkPath          : PropTypes.string,
  exitOverrideAction    : PropTypes.func,
  isActive              : basePropTypes.isActive,
  isSaving              : basePropTypes.isSaving,
  rightSideHasHomeLink  : basePropTypes.rightSideHasHomeLink,
  saveButtonIsSecondary : PropTypes.bool,
  saveButtonValue       : basePropTypes.saveButtonValue,
  withSaveButton        : basePropTypes.withSaveButton,
};

export default RightSide;
