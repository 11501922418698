import React from 'react';

function OpenedLock() {
  return (
    <svg
      height="700pt"
      width="700pt"
      viewBox="0 0 700 700"
    >
      <path d="m560 560h-420c-19.32 0-35-15.645-35-35v-245c0-19.32 15.68-35 35-35h420c19.355 0 35 15.68 35 35v245c0 19.355-15.645 35-35 35zm-385-70h350v-175h-350zm70-210h-70v-105c0-96.496 78.504-175 175-175s175 78.504 175 175h-70c0-57.891-47.109-105-105-105s-105 47.109-105 105z"/>
    </svg>
  );
}

export default OpenedLock;
