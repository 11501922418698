// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___39AWq{margin:0 auto;max-width:464px}.styles-module__Heading___3ZMMr{font-size:32px;font-weight:600;line-height:36px}@media screen and (min-width: 980px){.styles-module__LogoContainer___2A7dH{display:none}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Dashboard/OpeningContent/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,aAAA,CACA,eAAA,CAEF,gCACE,cAAA,CACA,eAAA,CACA,gBAAA,CAEF,qCACE,sCACE,YAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin: 0 auto;\n  max-width: 464px; }\n\n.Heading {\n  font-size: 32px;\n  font-weight: 600;\n  line-height: 36px; }\n\n@media screen and (min-width: 980px) {\n  .LogoContainer {\n    display: none; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___39AWq",
	"Heading": "styles-module__Heading___3ZMMr",
	"LogoContainer": "styles-module__LogoContainer___2A7dH"
};
module.exports = exports;
