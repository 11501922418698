import React from 'react';
import { useForm } from 'react-hook-form';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import classNames from 'src/components/shared/classNames';
import TextField from 'src/components/fields/TextField';

import Shared from './Shared';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);
const shared = new Shared();

function Authentication(props) {
  const {
    errors,
    fullWidth,
    handleSubmit,
    isActive,
  } = props;

  const { handleSubmit: formSubmit, register } = useForm();

  const onSubmit = formSubmit(handleSubmit);

  return (
    <form
      className={ styles.Root }
      onSubmit={ onSubmit }
    >
      <TextField
        disabled={ isActive }
        errorMessages={ errors && errors.get('email') }
        heading="Email"
        name="email"
        register={ register }
        type="text"
      />

      <ButtonSubmit
        disabled={ isActive }
        extraClasses={ { container : cx({ SubmitButton : true, isActive }) } }
        fullWidth={ fullWidth }
        isActive={ isActive }
        isSecondary
        type="submit"
        value="Send me a one time password"
        isBelowInput
      />
    </form>
  );
}

Authentication.propTypes = shared.propTypes;

export default Authentication;
