import actionTypes from 'src/constants/actionTypes';
import authenticationService from 'src/services/authentication';

function handleCommonAuthAction(actionPrefix, serviceAction, credentials = null) {
  return dispatch => {
    dispatch({ type : actionTypes[`AUTH__${actionPrefix}_START`] });

    return authenticationService[serviceAction](credentials)
      .then(userInfo => dispatch({
        type    : actionTypes[`AUTH__${actionPrefix}_SUCCESS`],
        payload : { userInfo },
      }))
      .catch(errors => dispatch({
        type    : actionTypes[`AUTH__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function addErrors(errors) {
  return {
    type    : actionTypes.AUTH__ADD_ERRORS,
    payload : { errors },
  };
}

export function checkLastEmailStatus(values) {
  return handleCommonAuthAction('CHECK_LAST_EMAIL_STATUS', 'checkLastEmailStatus', values);
}

export function clearTempState() {
  return { type : actionTypes.AUTH__CLEAR_TEMP_STATE };
}

export function handleServerError() {
  return { type : actionTypes.AUTH__HANDLE_SERVER_ERROR };
}

export function signOut(headers) {
  return dispatch => {
    dispatch({ type : actionTypes.AUTH__SIGN_OUT });

    return authenticationService.signOut(headers);
  };
}

export function signIn(values) {
  return handleCommonAuthAction('SIGN_IN', 'signIn', values);
}

export function updateToken(userInfo) {
  return {
    type    : actionTypes.AUTH__UPDATE_TOKEN,
    payload : { userInfo },
  };
}

export function updateUser(userInfo) {
  return {
    type    : actionTypes.AUTH__UPDATE_USER,
    payload : { userInfo },
  };
}

export function verifyOtp(values) {
  return handleCommonAuthAction('VERIFY_OTP', 'verifyOtp', values);
}
