import { Link } from 'react-router-dom';
import React from 'react';

import classNames from 'src/components/shared/classNames';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Breadcrumbs(props) {
  const {
    links,
  } = props;

  return (
    <div
      className={ styles.Root }
      data-automated-test="navigation--breadcrumbs"
    >
      <For each="link" of={ links }>
        <Choose>
          <When condition={ link.isCurrent }>
            <p
              className={ cx({ CurrentPathPara : true }) }
              key={ link.label }
            >
              { link.label }
            </p>
          </When>

          <Otherwise>
            <div
              className={ styles.LinkContainer }
              key={ link.label }
            >
              <Link
                className={ cx({ Link : true }) }
                to={ link.path || null }
              >
                { link.label }
              </Link>

              <div className={ styles.Arrow }>
                { '>' }
              </div>
            </div>
          </Otherwise>
        </Choose>
      </For>
    </div>
  );
}

Breadcrumbs.propTypes = {
  links : basePropTypes.breadcrumbLinks.isRequired,
};

export default Breadcrumbs;
