import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import Checkbox from 'src/components/Checkbox';
import classNames from 'src/components/shared/classNames';
import InfoIcon from 'src/components/icons/Info';
import Lever from 'src/components/Lever';
import SegmentedButton from 'src/components/buttons/SegmentedButton';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Options(props) {
  const {
    isOnFormPage,
    isVisibilityStatusDisabled,
    register,
    reversedCopiesAdded,
    reversedCopiesAddedDisabled,
    setReversedQuestionsOptionsSelected,
    visibilityStatus,
  } = props;

  const isUnlisted = visibilityStatus === 'is_unlisted';
  const isPublic = visibilityStatus === 'is_public';

  const leverIsOpenByDefault = isOnFormPage &&
    (reversedCopiesAdded || isPublic || isUnlisted);

  /* eslint-disable max-len */
  const segmentedButtonOptions = [
    {
      hint  : 'Only you can see this study set.',
      title : 'Private',
      value : 'is_private',
    },
    {
      hint  : 'Only those with the shareable link can see this study set. The shareable link will be available after you save the study set.',
      title : 'Unlisted',
      value : 'is_unlisted',
    },
    {
      hint  : 'Anyone can see this study set and it will appear on the ”Public study sets” screen.',
      title : 'Public',
      value : 'is_public',
    },
  ];
  /* eslint-enable max-len */

  return (
    <Lever
      isOpenByDefault={ leverIsOpenByDefault }
      leverTitleClassNames={ styles.LeverTitle }
      title="options"
    >
      <div className={ styles.Container }>
        <div className={ styles.SliderContainer }>
          <div data-automated-test="segmented-button--visibility-status">
            <SegmentedButton
              disabled={ isVisibilityStatusDisabled }
              initialSelectedValue={ visibilityStatus }
              name="visibilityStatus"
              options={ segmentedButtonOptions }
              register={ register }
            />
          </div>

          <p className={ styles.VisibilityStatusPara }>Visibility</p>

          <span
            className={ styles.VisibilityStatusInfoIconSpan }
            data-rh="
              Private: Only you can see this study set.
              Unlisted: Only those with the shareable link can see this study set.
              Public: Anyone can see this study set and it will appear
                on the ”Public study sets” screen.
            "
          >
            <InfoIcon />
          </span>
        </div>

        <div className={ styles.SliderContainer }>
          <div data-automated-test="switch-label--reverse-questions">
            <Checkbox
              defaultChecked={ reversedCopiesAdded }
              disabled={ reversedCopiesAddedDisabled }
              name="reversedCopiesAdded"
              onChecked={ () => setReversedQuestionsOptionsSelected(true) }
              onUnchecked={ () => setReversedQuestionsOptionsSelected(false) }
              register={ register }
            >
              <p
                className={ cx({
                  ReversedQuestionsTitle : true,
                  isDisabled             : reversedCopiesAddedDisabled,
                }) }
              >
                Reversed questions in quizzes

                <span
                  className={ styles.ReverseQuestionsInfoIconSpan }
                  data-automated-test="reverse-questions-form-tooltip"
                  data-rh="
                    If selected, we will add reversed copies (”flipped” copies) of your questions
                    to your quizzes. For each copy,
                    the answer will become the question and the question will become the answer.
                    You can think of it like a flashcard where you are quizzed on the front AND
                    back of it.
                  "
                >
                  <InfoIcon />
                </span>
              </p>
            </Checkbox>
          </div>
        </div>
      </div>
    </Lever>
  );
}

Options.propTypes = {
  isOnFormPage                        : PropTypes.bool,
  isVisibilityStatusDisabled          : PropTypes.bool,
  register                            : basePropTypes.register.isRequired,
  reversedCopiesAdded                 : studySetPropTypes.reversedCopiesAdded,
  reversedCopiesAddedDisabled         : studySetPropTypes.reversedCopiesAddedDisabled,
  setReversedQuestionsOptionsSelected : PropTypes.func.isRequired,
  visibilityStatus                    : PropTypes.string.isRequired,
};

export default Options;
