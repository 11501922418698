import _ from 'lodash';
import React, { memo } from 'react';

import * as searchPropTypes from 'src/constants/propTypes/search';

import ChevronIcon from 'src/components/icons/Chevron';
import getUrlParameter from 'src/shared/getUrlParameter';

import styles from './styles.module.scss';

function SortOptionsOpener(props) {
  const {
    defaultSortedBy,
    openSortOptions,
  } = props;

  let currentSort = _.startCase(getUrlParameter('sort')) || defaultSortedBy;

  if (currentSort === 'Due Date') currentSort = 'Predicted to be Forgotten By';

  return (
    <div className={ styles.Root }>
      <div
        className={ styles.SortOpener }
        onClick={ openSortOptions }
      >
        <p>{ currentSort }</p>
        <ChevronIcon />
      </div>
    </div>
  );
}

SortOptionsOpener.propTypes = {
  defaultSortedBy : searchPropTypes.defaultSortedBy.isRequired,
  openSortOptions : searchPropTypes.openSortOptions.isRequired,
};

export default memo(SortOptionsOpener);
