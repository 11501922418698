import gql from 'graphql-tag';

import studySetFields from 'src/graphql/fragments/studySetFields';

export default gql`
  mutation updateStudySet(
    $description: String
    $id: ID!
    $questions: [QuestionInput!]
    $reversedCopiesAdded: Boolean!
    $title: String
    $visibilityStatus: String!
  ) {
    updateStudySet(
      description: $description
      id: $id
      questions: $questions
      reversedCopiesAdded: $reversedCopiesAdded
      title: $title
      visibilityStatus: $visibilityStatus
    ) {
      ...studySetFields
    }
  }
  ${studySetFields}
`;
