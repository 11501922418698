import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.PAYMENT__ACKNOWLEDGE_GOOGLE_PLAY_PURCHASE_FAILURE:
    case actionTypes.PAYMENT__START_STRIPE_CHECKOUT_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
      });

    case actionTypes.PAYMENT__ACKNOWLEDGE_GOOGLE_PLAY_PURCHASE_START:
    case actionTypes.PAYMENT__START_STRIPE_CHECKOUT_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.PAYMENT__ACKNOWLEDGE_GOOGLE_PLAY_PURCHASE_SUCCESS:
    case actionTypes.PAYMENT__START_STRIPE_CHECKOUT_SUCCESS:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
      });

    default:
      return state;
  }
}
