import { PropTypes } from 'prop-types';
import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class Shared {
  propTypes = {
    autoFocus     : basePropTypes.autoFocus,
    disabled      : basePropTypes.disabled,
    errorMessages : basePropTypes.errorMessages,
    heading       : basePropTypes.heading,
    isScrollable  : PropTypes.bool,
    isTextArea    : basePropTypes.isTextArea,
    type          : basePropTypes.type,
    value         : basePropTypes.value,
  };

  getFieldClassNames() {
    const {
      disabled,
      errorMessages,
      isRemoved,
      isScrollable,
      isTextArea,
      readOnly,
    } = this.props;

    return cx({
      Field      : true,
      hasValue   : this._hasValue(),
      isDisabled : disabled,
      isErroring : errorMessages,
      isFocused  : this.isFocused,
      isReadOnly : readOnly,
      isRemoved,
      isScrollable,
      isTextArea,
      isWeb      : this.isWeb,
    });
  }

  getRootClassNames() {
    return cx({
      Root : true,
    });
  }

  getTitleClassNames() {
    return cx({
      Title      : true,
      hasValue   : this._hasValue(),
      isDisabled : this.props.disabled,
      isFocused  : this.isFocused,
      isTextArea : this.props.isTextArea,
      isWeb      : this.isWeb,
      isReadOnly : this.props.readOnly,
    });
  }

  setClassProperties(classProperties) {
    const {
      props,
      currentValue,
      inputRef,
      isFocused,
      isNative,
      isWeb,
    } = classProperties;

    this.props = props;
    this.currentValue = currentValue;
    this.inputRef = inputRef;
    this.isFocused = isFocused;
    this.isNative = isNative;
    this.isWeb = isWeb;
  }

  // private

  _hasValue() {
    return this.currentValue ||
      this.props.defaultValue ||
      this._inputRefValue();
  }

  _inputRefValue() {
    return this.inputRef && this.inputRef.current && this.inputRef.current.value;
  }
}

export default Shared;
