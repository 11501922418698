import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import analytics from 'src/shared/analytics';

/// ////////////
// Containers
/// ////////////

import SignInViaExternalSource from 'src/containers/authentication/SignInViaExternalSource';
import SignOut from 'src/containers/authentication/SignOut';
import Welcome from 'src/containers/authentication/Welcome';

import PublicStudySets from 'src/containers/publicStudySets/All';
import ShowPublicStudySet from 'src/containers/publicStudySets/Show';
import ShowPublicStudySetQuestion from 'src/containers/publicStudySets/ShowQuestion';

import PublicPublicStudySets from 'src/containers/public/publicStudySets/All';
import ShowPublicPublicStudySet from 'src/containers/public/publicStudySets/Show';
import ShowPublicPublicStudySetQuestion from 'src/containers/public/publicStudySets/ShowQuestion';

import NotFound from 'src/containers/NotFound';

import Shared from './Shared';
import AuthenticatedRoutes from './AuthenticatedRoutes';

const shared = new Shared();

class AllRoutes extends PureComponent {
  static propTypes = shared.propTypes

  state = {
    hasError : false,
  }

  componentDidMount() {
    shared.setClassProperties({ props : this.props });
    shared.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      analytics.page();
    }
  }

  componentDidCatch(error) {
    // Doing extra here because I'm not sure if the stuff inside the setState
    // callback is getting called
    Sentry.captureMessage(`componentDidCatch called ${error.toString()}`);

    this.setState({ hasError : true }, () => Sentry.withScope(scope => {
      scope.setExtra('user.email', this.props.email);
      scope.setExtra('came from', 'componentDidCatch');
      Sentry.captureException(error);
    }));

    shared.handleStrangeErrorsObjectIssue(error, () => window.location.reload());
  }

  isAuthed = () => {
    const { accessToken, email } = this.props;

    return Boolean(accessToken && email);
  }

  renderAuthedRoutes = renderProps => {
    if (!this.isAuthed()) return shared.redirectToSignIn(renderProps, Redirect);

    return <AuthenticatedRoutes { ...renderProps } />;
  }

  shouldRedirect() {
    if (window.location.host === 'localhost:3000') return false;

    return this.state.hasError || this.props.experiencingServerError;
  }

  render() {
    if (this.shouldRedirect()) return <Redirect to="/error" />;

    /* eslint-disable max-len */
    return (
      <Switch>
        <Route path="/auth/sign-out" component={ SignOut } exact />
        <Route path="/auth/sign-in-via-external-source" component={ SignInViaExternalSource } exact />
        <Route path="/auth/welcome" component={ Welcome } exact />

        <Route path="/public-study-sets" component={ PublicStudySets } exact />
        <Route path="/public-study-sets/:id" component={ ShowPublicStudySet } exact />
        <Route path="/public-study-sets/:id/questions/:questionId" component={ ShowPublicStudySetQuestion } exact />

        <Route path="/public/public-study-sets" component={ PublicPublicStudySets } exact />
        <Route path="/public/public-study-sets/:id" component={ ShowPublicPublicStudySet } exact />
        <Route path="/public/public-study-sets/:id/questions/:questionId" component={ ShowPublicPublicStudySetQuestion } exact />

        <Route path="/" render={ this.renderAuthedRoutes } />

        <Route component={ NotFound } />
      </Switch>
    );
    /* eslint-enable max-len */
  }
}

export default connect(shared.mapStateToProps, shared.mapDispatchToProps)(AllRoutes);
