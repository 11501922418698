import React from 'react';

function BarChart() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25">
      <path d="M12.9283 20.4465V10.4465" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.9283 20.4465V4.44653" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.92831 20.4465V16.4465" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default BarChart;
