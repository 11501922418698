// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1sWGd{display:flex;flex-direction:row;justify-content:flex-start;background-color:#edf2f7;border-radius:1000px;padding:6px}.styles-module__Option___25Kmx{border-radius:1000px;color:#2071aa;cursor:pointer;font-weight:600;line-height:16px;padding:6px 18px}.styles-module__Option___25Kmx.styles-module__isSelected___3_yOI{background-color:#2071aa;color:#fff}.styles-module__Option___25Kmx.styles-module__isSelected___3_yOI.styles-module__isDisabled___25gGC{background-color:#bdbdbd;color:#fff}.styles-module__Option___25Kmx.styles-module__isDisabled___25gGC{color:#bdbdbd;cursor:not-allowed}.styles-module__HiddenInput___1tBRn{height:0;opacity:0;width:0}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/toggles/SegmentedToggle/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,wBAAA,CACA,oBAAA,CACA,WAAA,CAEF,+BACE,oBAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,gBAAA,CACA,iEACE,wBAAA,CACA,UAAA,CACA,mGACE,wBAAA,CACA,UAAA,CACJ,iEACE,aAAA,CACA,kBAAA,CAEJ,oCACE,QAAA,CACA,SAAA,CACA,OAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  background-color: #edf2f7;\n  border-radius: 1000px;\n  padding: 6px; }\n\n.Option {\n  border-radius: 1000px;\n  color: #2071aa;\n  cursor: pointer;\n  font-weight: 600;\n  line-height: 16px;\n  padding: 6px 18px; }\n  .Option.isSelected {\n    background-color: #2071aa;\n    color: #ffffff; }\n    .Option.isSelected.isDisabled {\n      background-color: #bdbdbd;\n      color: #ffffff; }\n  .Option.isDisabled {\n    color: #bdbdbd;\n    cursor: not-allowed; }\n\n.HiddenInput {\n  height: 0;\n  opacity: 0;\n  width: 0; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1sWGd",
	"Option": "styles-module__Option___25Kmx",
	"isSelected": "styles-module__isSelected___3_yOI",
	"isDisabled": "styles-module__isDisabled___25gGC",
	"HiddenInput": "styles-module__HiddenInput___1tBRn"
};
module.exports = exports;
