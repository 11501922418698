import classNames from 'classnames/bind';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function CountdownAnimation({ isStopped, seconds, shouldReset }) {
  const getPieClassNames = pieName => cx({
    [pieName] : true,
    isStopped,
  });

  // - 1 because it looks better when it's moving a lil faster
  const pieStyles = { animationDuration : `${seconds - 1}s` };

  return (
    <Choose>
      <When condition={ shouldReset }>
        <div />
      </When>

      <Otherwise>
        <div className={ styles.Root }>
          <div className={ getPieClassNames('LeftSide') } style={ pieStyles } />
          <div className={ getPieClassNames('RightSide') } style={ pieStyles } />
          <div className={ getPieClassNames('Spinner') } style={ pieStyles } />
        </div>
      </Otherwise>
    </Choose>
  );
}

CountdownAnimation.propTypes = {
  isStopped   : basePropTypes.isStopped.isRequired,
  seconds     : basePropTypes.seconds.isRequired,
  shouldReset : basePropTypes.shouldReset.isRequired,
};

export default CountdownAnimation;
