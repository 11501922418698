// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1pe8H{height:76px;overflow:hidden;position:relative;z-index:1}.styles-module__Timer___fpEis{border-radius:6px;height:76px;position:absolute;width:76px}.styles-module__Face___ZXvjk{background:rgba(0,0,0,0);color:#2071aa;font-size:20px;font-weight:bold;line-height:16px;margin-left:1%;position:absolute;text-align:center;top:calc(50% - 10px);width:98%;z-index:500}@keyframes styles-module__rotate-animation___mukpH{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@keyframes styles-module__fill___2Ot5V{0%{opacity:0}50%,100%{opacity:1}}@keyframes styles-module__mask___3u6Am{0%{opacity:1}50%,100%{opacity:0}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/CountdownTimer/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,eAAA,CACA,iBAAA,CACA,SAAA,CAEF,8BACE,iBAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CAEF,6BACE,wBAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,oBAAA,CACA,SAAA,CACA,WAAA,CAEF,mDACE,GACE,sBAAA,CACF,KACE,wBAAA,CAAA,CAEJ,uCACE,GACE,SAAA,CACF,SACE,SAAA,CAAA,CAEJ,uCACE,GACE,SAAA,CACF,SACE,SAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  height: 76px;\n  overflow: hidden;\n  position: relative;\n  z-index: 1; }\n\n.Timer {\n  border-radius: 6px;\n  height: 76px;\n  position: absolute;\n  width: 76px; }\n\n.Face {\n  background: transparent;\n  color: #2071aa;\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 16px;\n  margin-left: 1%;\n  position: absolute;\n  text-align: center;\n  top: calc(50% - 10px);\n  width: 98%;\n  z-index: 500; }\n\n@keyframes rotate-animation {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n\n@keyframes fill {\n  0% {\n    opacity: 0; }\n  50%, 100% {\n    opacity: 1; } }\n\n@keyframes mask {\n  0% {\n    opacity: 1; }\n  50%, 100% {\n    opacity: 0; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1pe8H",
	"Timer": "styles-module__Timer___fpEis",
	"Face": "styles-module__Face___ZXvjk",
	"rotate-animation": "styles-module__rotate-animation___mukpH",
	"fill": "styles-module__fill___2Ot5V",
	"mask": "styles-module__mask___3u6Am"
};
module.exports = exports;
