import gql from 'graphql-tag';

export default gql`
  mutation updateAttempt(
    $id: ID!
    $recallEvaluation: Int!
  ) {
    updateAttempt(
      id: $id
      recallEvaluation: $recallEvaluation
    ) {
      id
      question {
        dueDate
        id
      }
      recallEvaluation
    }
  }
`;
