import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import SegmentedToggle from 'src/components/toggles/SegmentedToggle';

import Percent from './Percent';
import styles from './styles.module.scss';
import Totals from './Totals';

function AnswersMemorized(props) {
  const {
    isEmpty,
    report,
  } = props;

  const [selectedToggleIndex, setSelectedToggleIndex] = useState(0);

  const options = [
    {
      title : 'Totals',
      value : 0,
    },
    {
      title : '%',
      value : 1,
    },
  ];

  function handleClick() {
    const index = selectedToggleIndex === 0 ? 1 : 0;
    setSelectedToggleIndex(index);
  }

  return (
    <div
      className={ styles.Root }
      data-automated-test="charts--answers-memorized"
    >
      <div className={ styles.Toggle }>
        <SegmentedToggle
          handleClick={ handleClick }
          options={ options }
          selectedValue={ selectedToggleIndex }
        />
      </div>

      <Choose>
        <When condition={ selectedToggleIndex === 0 }>
          <Totals isEmpty={ isEmpty } report={ report.get('totals') } />
        </When>

        <Otherwise>
          <Percent isEmpty={ isEmpty } report={ report.get('percent') } />
        </Otherwise>
      </Choose>
    </div>
  );
}

AnswersMemorized.propTypes = {
  isEmpty : PropTypes.bool,
  report  : statsPropTypes.answersMemorized.isRequired,
};

export default AnswersMemorized;
