import React, { useRef } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function MinimalNumberField(props) {
  const {
    defaultNumberValue,
    disabled,
    inputMode,
    name,
    register,
    type,
  } = props;

  const inputRef = useRef(null);

  function setUpRef(el) {
    if (register) register(el);
    inputRef.current = el;
  }

  function getRootClassNames() {
    return cx({
      Root       : true,
      isDisabled : disabled,
    });
  }

  return (
    <input
      className={ getRootClassNames() }
      defaultValue={ defaultNumberValue }
      disabled={ disabled }
      name={ name }
      inputMode={ inputMode }
      ref={ setUpRef }
      type={ type || 'number' }
    />
  );
}

MinimalNumberField.propTypes = {
  defaultNumberValue : basePropTypes.defaultNumberValue,
  disabled           : basePropTypes.disabled,
  inputMode          : basePropTypes.inputMode,
  name               : basePropTypes.name.isRequired,
  register           : basePropTypes.register,
  type               : basePropTypes.type,
};

export default MinimalNumberField;
