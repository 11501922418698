import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import styles from './styles.module.scss';

function EvaluationExplanationOne(props) {
  const {
    hasQuizStarted,
    isFirstQuiz,
    questionNumber,
  } = props;

  if (isFirstQuiz && !hasQuizStarted && questionNumber === '1') {
    return (
      <div className={ styles.Root }>
        Now you’ll assess yourself. Be completely honest. Your questions, answers, and
        self-assessment are all private. The more accurate you are, the better we can
        adjust to you.
        <br />
        <br />
        Did you recall the correct answer?
      </div>
    );
  }

  return (
    <div className={ styles.Root }>
      Did you recall the correct answer?
    </div>
  );
}

EvaluationExplanationOne.propTypes = {
  hasQuizStarted : quizPropTypes.hasQuizStarted,
  isFirstQuiz    : PropTypes.bool,
  questionNumber : basePropTypes.questionNumber.isRequired,
};

export default EvaluationExplanationOne;
