import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorScreen from 'src/containers/ErrorScreen';

import AllRoutes from './AllRoutes';

class Routes extends PureComponent {
  componentDidMount() {
    // https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);

    window.addEventListener('beforeinstallprompt', e => {
      window.deferredPrompt = e;
    });
  }

  render() {
    /* eslint-disable max-len */
    return (
      <Switch>
        <Route path="/error" component={ ErrorScreen } exact />
        <Route path="/" component={ AllRoutes } />
      </Switch>
    );
    /* eslint-enable max-len */
  }
}

export default Routes;
