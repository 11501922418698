// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3cTND{height:15px;margin-top:1px;width:15px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/icons/LinkToPage/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,cAAA,CACA,UAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  height: 15px;\n  margin-top: 1px;\n  width: 15px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3cTND"
};
module.exports = exports;
