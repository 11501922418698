import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as onboardingActions from 'src/actions/onboarding';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as quizzesActions from 'src/actions/quizzes';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';
import * as studySetsActions from 'src/actions/studySets';

import navigateOrWait from 'src/containers/shared/navigateOrWait';
import ShowStudySet from 'src/components/studySets/Show';

class FunctionalShow extends PureComponent {
  static propTypes = {
    actions              : basePropTypes.actions.isRequired,
    autosaved            : studySetPropTypes.autosavedStudySet,
    completedQuizzesSize : PropTypes.number.isRequired,
    history              : basePropTypes.history.isRequired,
    isActive             : basePropTypes.isActive,
    isSuccessful         : basePropTypes.isSuccessful,
    location             : basePropTypes.location.isRequired,
    match                : basePropTypes.match.isRequired,
    newestQuiz           : quizPropTypes.quiz.isRequired,
    studySet             : studySetPropTypes.studySet.isRequired,
  };

  componentDidMount() {
    const {
      actions,
      autosaved,
      completedQuizzesSize,
      location : { state },
      match,
      studySet,
    } = this.props;

    actions.quizzes.clearTempState(); // Not sure why this is here anymore

    if (completedQuizzesSize === 0) {
      actions
        .quizzes
        .fetchNewest()
        .then(data => {
          let id;

          try {
            const { payload : { quiz } } = data;
            id = quiz.id;
          } catch (err) {
            Sentry.captureException(err, scope => {
              const string = JSON.stringify(data);
              scope.setTag('payload data', string);
              return scope;
            });
          }

          actions.quizzes.addToBeAnsweredQuestions(id);
        });
    }

    if (
      state &&
      state.pathFrom &&
      state.pathFrom.includes('/study-sets') &&
      (state.pathFrom.includes('edit') || state.pathFrom.includes('new')) &&
      state.successfullySaved
    ) return;

    return actions
      .studySets
      .show({ id : match.params.id })
      .then(() => {
        // NOTE: this logic here is somewhat temporary. Basically, the user has
        // autosaved data for some already created study sets. And so when we added
        // visibilityStatus and removed is_public, existing study sets that had an
        // autosaved version (for editing), they didn't get an initial
        // visibilityStatus and they had no selection chosen by default. So the fix
        // is to look for those cases and set the visibilityStatus manually.

        if (
          !autosaved ||
          (autosaved.keySeq && autosaved.keySeq().size === 1 && autosaved.get('visibilityStatus'))
        ) {
          return actions.studySets.setAutosavedEdit({ id : match.params.id });
        }

        if (autosaved.get('visibilityStatus')) return;

        actions.studySets.updateValueInAutosaved(
          ['autosaved', match.params.id, 'visibilityStatus'],
          studySet.get('visibilityStatus'),
        );
      });
  }

  deleteStudySet = (values = {}) => {
    const { actions, studySet } = this.props;

    return actions
      .studySets
      .deleteStudySet({ ...values, id : studySet.get('id') })
      .then(data => {
        // A common scenario is a user will first add a basic study set just to
        // test dorothy and then they'll delete it right after. If they do, we
        // want to clear onboarding data so that it doesn't think they still have
        // a study set in the onboarding experience.
        // Study set deletions are rare so I think it's okay to make this call
        // everytime a study set is deleted. It doesn't hurt anything.
        actions.onboarding.clearOnboarding();
        return this.navigateOrWait(data);
      })
      .catch(this.navigateOrWait);
  }

  navigateOrWait = () => navigateOrWait(this.props, '/study-sets')

  startFlashStudySession = () => {
    const { actions : { quizzes }, studySet } = this.props;

    const studySetId = studySet.get('id');

    return quizzes
      .startFlashStudySession({ studySetId })
      .then(({ payload : { quiz : { id } } }) => {
        quizzes.addToBeAnsweredQuestions(id);
        return id;
      })
      .then(id => navigateOrWait(this.props, `/quiz/${id}/explanation`));
  }

  render() {
    return (
      <ShowStudySet
        deleteStudySet={ this.deleteStudySet }
        history={ this.props.history }
        isActive={ this.props.isActive }
        isSuccessful={ this.props.isSuccessful }
        location={ this.props.location }
        newestQuiz={ this.props.newestQuiz }
        startFlashStudySession={ this.startFlashStudySession }
        studySet={ this.props.studySet }
      />
    );
  }
}

function mapStateToProps({ publicStudySets, quizzes, stats, studySets }) {
  const studySet = studySets.getIn(['loaded', 'show']);

  return {
    autosaved            : studySets.getIn(['autosaved', studySet.get('id')]),
    completedQuizzesSize : stats.getIn(['dashboardReport', 'completedQuizzesSize']),
    errors               : studySets.get('errors'),
    isActive             : studySets.get('isActive') || quizzes.get('isActive'),
    isSuccessful         : publicStudySets.get('isSuccessful') || studySets.get('isSuccessful'),
    newestQuiz           : quizzes.getIn(['loaded', 'newest']),
    studySet,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      onboarding      : bindActionCreators(onboardingActions, dispatch),
      publicStudySets : bindActionCreators(publicStudySetsActions, dispatch),
      quizzes         : bindActionCreators(quizzesActions, dispatch),
      studySets       : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalShow);
