import React from 'react';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';

import styles from './styles.module.scss';

function UserDetails({ uid }) {
  return (
    <div className={ styles.Root }>
      <h3>Email</h3>
      <p>{ uid }</p>
    </div>
  );
}

UserDetails.propTypes = {
  uid : authenticationPropTypes.uid.isRequired,
};

export default UserDetails;
