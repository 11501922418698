import { HashRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactHintFactory from 'react-hint';

import * as Sentry from '@sentry/browser';

import Routes from 'src/Routes';
import storeService from 'src/services/store';
import Tooltip from 'src/components/Tooltip';

import 'normalize.css';
import 'src/styles/base/index.scss';
import 'src/shared/serviceWorker/register';

const ReactHint = ReactHintFactory(React);

// Idk why but the blacklisting isn't working on localhost:3000
if (window.location.hostname.includes('my.dorothymemoryapp.com')) {
  /* eslint-disable */
  Sentry.init({
    dsn           : 'https://4e3626e9e2c0441080dae214af472544@sentry.io/5189402',
    blacklistUrls : [
      /http?:\/\/localhost:3000/,
      /http?:\/\/localhost:3000\//,
      /http?:\/\/localhost:5000/,
      /http?:\/\/localhost:5000\//,
      /http?:\/\/127.0.0.1/,
      /http?:\/\/127.0.0.1?/,
      "http://127.0.0.1:5000",
      "http://127.0.0.1:5000/",
      'http://127.0.0.1:5000/',
      /http?:\/\/127.0.0.1:5000/,
      /http?:\/\/127.0.0.1:5001/,
      /http?:\/\/127.0.0.1:5002/,
      /http?:\/\/127.0.0.1:5003/,
      /http?:\/\/127.0.0.1:5004/,
      /http?:\/\/127.0.0.1:5000\//,
      /http?:\/\/127.0.0.1:5001\//,
      /http?:\/\/127.0.0.1:5002\//,
      /https?:\/\/memory-app--staging\.herokuapp\.com/,
      /https?:\/\/memory-app--staging\.herokuapp\.com\//,
    ],
  });
  /* eslint-enable */
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.createElement('div');
  el.setAttribute('id', 'root');

  Sentry.configureScope(scope => {
    const state = storeService.getStore().getState();
    const authentication = state && state.authentication;
    const email = authentication && authentication.get('email');

    scope.setUser({ email });
  });

  ReactDOM.render(
    <Provider store={ storeService.getStore() }>
      <PersistGate loading={ null } persistor={ storeService.getPersistor() }>
        <ReactHint
          autoPosition
          events
          onRenderContent={ target => (<Tooltip target={ target } />) }
        />

        <Router>
          <Routes />
        </Router>
      </PersistGate>
    </Provider>,
    document.body.appendChild(el),
  );
});
