import gql from 'graphql-tag';

import quizFields from 'src/graphql/fragments/quizFields';

export default gql`
  query showQuiz(
    $id: ID!
  ) {
    showQuiz(
      id: $id
    ) {
      ...quizFields
    }
  }
  ${quizFields}
`;
