import { PropTypes } from 'prop-types';
import React from 'react';

import ButtonDiv from 'src/components/buttons/ButtonDiv';
import classNames from 'src/components/shared/classNames';
import GoogleLetterIcon from 'src/components/icons/GoogleLetter';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function GoogleAuth(props) {
  const {
    fullWidth,
    isActive,
    shouldRedirectToThisPage,
  } = props;

  function attachAuthenticityToken(form) {
    const authenticityTokenInput = document.createElement('input');
    authenticityTokenInput.name = 'authenticity_token';
    authenticityTokenInput.type = 'hidden';

    const tokenEl = document.querySelector('meta[name="csrf-token"]');
    const authenticityToken = tokenEl && tokenEl.content;
    authenticityTokenInput.value = authenticityToken;

    form.appendChild(authenticityTokenInput);
  }

  // We have to do this very weird form creation thing because the
  // Google auth experience has to be a "full-page redirect".
  function signInWithGoogle() {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/auth/google_oauth2';

    if (shouldRedirectToThisPage) {
      const redirectInput = document.createElement('input');
      redirectInput.type = 'hidden';
      redirectInput.name = 'origin';

      const [, hashValue] = window.location.hash.split('#');
      redirectInput.value = hashValue;

      form.appendChild(redirectInput);
    }

    attachAuthenticityToken(form);
    document.body.appendChild(form);

    form.submit();
  }

  return (
    <ButtonDiv
      dataAutomatedTest="google-auth-button"
      disabled={ isActive }
      extraClassNames={ cx({ ButtonDiv : true, isActive }) }
      fullWidth={ fullWidth }
      handleClick={ signInWithGoogle }
      isActive={ isActive }
      type="submit"
    >
      <div className={ styles.GoogleLetterIconContainer }>
        <GoogleLetterIcon />
      </div>

      <p className={ styles.Para }>Continue with Google</p>
    </ButtonDiv>
  );
}

GoogleAuth.propTypes = {
  fullWidth                : PropTypes.bool,
  isActive                 : PropTypes.bool,
  shouldRedirectToThisPage : PropTypes.bool,
};

export default GoogleAuth;
