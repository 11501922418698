import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import * as questionPropTypes from 'src/constants/propTypes/question';

import analytics from 'src/shared/analytics';
import AndMoreEllipses from 'src/components/icons/AndMoreEllipses';
import ButtonDiv from 'src/components/buttons/ButtonDiv';
import ButtonLink from 'src/components/buttons/ButtonLink';
import ConfirmationModal from 'src/components/modals/Confirmation';
import classNames from 'src/components/shared/classNames';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';

import DeletionModalAndLinks from '../DeletionModalAndLinks';
import generatePDF from './generatePDF';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function UpdateOptions(props) {
  const {
    deleteStudySet,
    isUndesignated,
    isUpdateOptionsContainerOpen,
    questions,
    setIsDownloadingPDF,
    setIsUpdateOptionsContainerOpen,
    studySetId,
    title,
  } = props;

  const questionsSize = (questions && questions.size) || 0;

  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  function handleAndMoreEllipsesClick() {
    setIsUpdateOptionsContainerOpen(current => !current);
  }

  async function downloadPDF() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Download as PDF confirmed');

    setIsDownloadingPDF(true);
    await generatePDF(questions, title);
    setIsDownloadingPDF(false);
  }

  function handleDeleteClick() { setIsDeleteModalOpen(true); }
  function closeDeleteModal() { setIsDeleteModalOpen(false); }

  return (
    <>
      <div className={ styles.Root }>
        <div
          className={ cx({ AndMoreEllipses : true }) }
          data-automated-test="study-set-show--update-options--handle-and-more-ellipsis-icon"
          onClick={ handleAndMoreEllipsesClick }
        >
          <div className={ styles.EllipsesBackground } />
          <AndMoreEllipses />
        </div>

        <If condition={ isUpdateOptionsContainerOpen }>
          <div className={ styles.OptionsContainer }>
            <div className={ styles.Options }>
              <ButtonLink
                extraClassNames={ styles.ButtonLink }
                isSecondary
                noBorder
                text="Add new question"
                to={ `/study-sets/${studySetId}/edit?purpose=add-new-question` }
                withPlusSign
              />

              <ButtonLink
                extraClassNames={ styles.ButtonLink }
                isSecondary
                noBorder
                text="Edit"
                to={ `/study-sets/${studySetId}/edit` }
                withPencil
              />

              <ButtonLink
                extraClassNames={ styles.ButtonLink }
                href="https://dorothymemoryapp.com/chrome-extension/"
                isSecondary
                linksOut
                noBorder
                text="Generate questions with AI"
                withBrain
              />

              <ButtonDiv
                dataAutomatedTest="study-set-show--update-options--download-as-flashcards-to-pdf"
                extraClassNames={ styles.ButtonLink }
                handleClick={ () => setIsPDFModalOpen(true) }
                iconIsOnLeftSide
                isSecondary
                noBorder
                value="Download as flashcards to PDF"
                withPDFIcon
              />

              <If condition={ !isUndesignated }>
                <ButtonDiv
                  dataAutomatedTest="study-set-show--update-options--delete-button"
                  extraClassNames={ styles.ButtonLink }
                  handleClick={ handleDeleteClick }
                  iconIsOnLeftSide
                  isSecondary
                  noBorder
                  value="Delete"
                  withTrashCan
                />
              </If>
            </div>
          </div>
        </If>
      </div>

      <If condition={ isPDFModalOpen }>
        <ConfirmationModal
          closeModal={ () => setIsPDFModalOpen(false) }
          isPositiveAction
          submitAction={ downloadPDF }
          submitActionName="Yes"
          title="Download all your questions as a PDF?"
        >
          You’ll be able to save or print them as flashcards. If you print them,
          you’ll just need to be sure to print double-sided.
        </ConfirmationModal>
      </If>

      <DeletionModalAndLinks
        closeInitialModal={ closeDeleteModal }
        isInitialModalOpen={ isDeleteModalOpen }
        deleteStudySet={ deleteStudySet }
        questionsSize={ questionsSize }
      />
    </>
  );
}

UpdateOptions.propTypes = {
  deleteStudySet                  : PropTypes.func.isRequired,
  isUpdateOptionsContainerOpen    : PropTypes.bool.isRequired,
  isUndesignated                  : PropTypes.bool,
  setIsDownloadingPDF             : PropTypes.func.isRequired,
  setIsUpdateOptionsContainerOpen : PropTypes.func.isRequired,
  questions                       : questionPropTypes.questions.isRequired,
  studySetId                      : PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  title                           : PropTypes.string.isRequired,
};

export default UpdateOptions;
