import React, { memo, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as searchPropTypes from 'src/constants/propTypes/search';

import analytics from 'src/shared/analytics';
import getUrlParameter from 'src/shared/getUrlParameter';
import SearchIcon from 'src/components/icons/Search';

import styles from './styles.module.scss';

function SearchField(props) {
  const {
    clearSearchResults,
    name,
    placeholder,
    search,
    searchRef,
    setIsLoading,
    shouldOnlySearchWithValue,
  } = props;

  const [searchTimeout, setSearchTimeout] = useState(null);

  const searchAndRecord = searchQuery => {
    analytics.track('Public study set search', { searchQuery });

    return search(searchQuery);
  };

  const runSearchTimeout = searchQuery => {
    const callback = () => setIsLoading(false);
    setSearchTimeout(setTimeout(() => searchAndRecord(searchQuery).then(callback), 1200));
  };

  const handleSearchChange = e => {
    e.preventDefault();

    if (clearSearchResults) clearSearchResults();
    clearTimeout(searchTimeout);

    const { value } = e.target;
    if (shouldOnlySearchWithValue && !value) return setIsLoading(false);

    setIsLoading(true);
    return runSearchTimeout(value);
  };

  const handleKeyDown = e => {
    const { key, target : { value } } = e;

    if (key !== 'Enter') return;

    clearTimeout(searchTimeout);

    return searchAndRecord(value).then(() => setIsLoading(false));
  };

  return (
    <div className={ styles.Root }>
      <div className={ styles.SearchIcon }>
        <SearchIcon />
      </div>

      <input
        className={ `${styles.Input} data-hj-allow` }
        defaultValue={ getUrlParameter('filter') }
        name={ name || 'search' }
        onChange={ handleSearchChange }
        onKeyDown={ handleKeyDown }
        placeholder={ placeholder || 'Search' }
        ref={ searchRef }
        type=""
      />
    </div>
  );
}

SearchField.propTypes = {
  clearSearchResults        : searchPropTypes.clearSearchResults,
  name                      : basePropTypes.name,
  placeholder               : basePropTypes.placeholder,
  search                    : searchPropTypes.search.isRequired,
  searchRef                 : searchPropTypes.searchRef,
  setIsLoading              : searchPropTypes.setIsLoading.isRequired,
  shouldOnlySearchWithValue : searchPropTypes.shouldOnlySearchWithValue,
};

export default memo(SearchField);
