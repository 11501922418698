import { PropTypes } from 'prop-types';
import React from 'react';

import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import isSmallMobile from 'src/components/shared/isSmallMobile';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import StudySetForm from 'src/components/forms/StudySet';

import styles from './styles.module.scss';

function EditStudySet(props) {
  const {
    isOnboarding,
    studySet,
  } = props;

  let heading = 'Edit study set';

  if (isOnboarding) {
    heading = 'Your first study set';
  }

  return (
    <div className={ styles.Root }>
      <Choose>
        <When condition={ studySet }>
          <StudySetForm
            autoFocusOnFirstQuestion={ isSmallMobile() && isOnboarding }
            heading={ heading }
            isOnboarding
            { ...props }
          />
        </When>

        <Otherwise>
          <div className={ styles.PulseLoader }>
            <PulseLoadingIndicator isLoading={ true }/>
          </div>
        </Otherwise>
      </Choose>
    </div>
  );
}

EditStudySet.propTypes = {
  isOnboarding : PropTypes.bool,
  studySet     : studySetPropTypes.studySet,
};

export default EditStudySet;
