import React from 'react';

function DoubleArrowsReversedDirections() {
  return (
    <svg
      height="700pt"
      width="700pt"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m586.48 215.09-85.32 69.977c-1.5859 1.3242-3.9688 1.9844-6.0859 1.9844-4.5625 0-8.5312-3.3086-8.5312-7.0117v-42h-366.94c-4.5625 0-8.5312-3.2422-8.5312-6.9453v-42c0-3.7031 3.9688-7.0117 8.5312-7.0117h366.94v-41.934c0-3.9688 3.7031-7.0117 8.5312-7.0117 2.3828 0 4.4961 0.85938 6.3477 2.1836l85.055 69.711c3.1758 2.6445 3.2422 7.4727 0 10.055z" fillRule="evenodd"/>
        <path d="m588.93 328.98v42c0 3.7031-4.0352 7.0117-8.5312 7.0117h-366.94v41.934c0 6.4805-8.7969 9.1289-14.949 4.8281l-85.055-69.977c-3.1758-2.5781-3.1758-7.2109 0-9.7891l85.387-69.977c5.0938-4.168 14.617-1.2578 14.617 5.0273v42h366.94c4.4961 0 8.5312 3.043 8.5312 6.9453z" fillRule="evenodd"/>
      </g>
    </svg>
  );
}

export default DoubleArrowsReversedDirections;
