import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as authenticationActions from 'src/actions/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildSignInPropsContext from 'src/containers/public/shared/buildSignInPropsContext';
import ShowPublicPublicStudySetQuestion from 'src/components/public/publicStudySets/ShowQuestion';
import SignInPropsContext from 'src/contexts/SignInProps';

class FunctionalShowQuestion extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    isAuthed : PropTypes.bool,
    location : basePropTypes.location.isRequired,
    match    : basePropTypes.match.isRequired,
    studySet : studySetPropTypes.studySet.isRequired,
  };

  state = {
    isVerifyingOtp         : false,
    shouldDisplayVerifyOtp : false,
    shouldFireConfetti     : false,
  }

  componentDidMount() {
    const { actions: { publicStudySets }, match, studySet } = this.props;

    if (
      studySet &&
      studySet.get('questions') &&
      studySet.get('questions').size > 0
    ) return;

    publicStudySets.show({ id : match.params.id });
  }

  render() {
    if (this.props.isAuthed && !this.state.isVerifyingOtp) {
      let { pathname } = this.props.location;

      pathname = pathname.replace('/public/', '/');

      return <Redirect to={ { pathname } } />;
    }

    return (
      <SignInPropsContext.Provider value={ buildSignInPropsContext(this) }>
        <ShowPublicPublicStudySetQuestion { ...this.props } />
      </SignInPropsContext.Provider>
    );
  }
}

function mapStateToProps({ authentication, publicStudySets }, { match : { params } }) {
  return {
    authIsActive : authentication.get('isActive'),
    authErrors   : authentication.get('errors'),
    emailSentTo  : authentication.get('emailSentTo'),
    isAuthed     : !!(authentication.get('email') && authentication.get('accessToken')),
    question     : publicStudySets
      .getIn(['loaded', 'show', 'questions'])
      .find(q => q.get('id') === params.questionId),
    studySet : publicStudySets.getIn(['loaded', 'show']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication  : bindActionCreators(authenticationActions, dispatch),
      publicStudySets : bindActionCreators(publicStudySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalShowQuestion);
