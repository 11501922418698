function autoFocusError(errorKeys) {
  let field;

  // NOTE: order matters here
  const answerErrorKey = errorKeys && errorKeys.find(key => key.includes('[answer][body]'));

  if (answerErrorKey) field = document.querySelector(`[name="${answerErrorKey}"]`);

  const questionErrorKey = errorKeys &&
    errorKeys.find(key => key.includes('question') && key.includes('title'));

  if (questionErrorKey) field = document.querySelector(`[name="${questionErrorKey}"]`);

  const titleKey = errorKeys && errorKeys.find(key => key === 'title');

  if (titleKey) field = document.querySelector('[name="title"]');

  if (!field) return;

  field.focus();
}

export default autoFocusError;
