import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import Lever from 'src/components/Lever';

import styles from './styles.module.scss';

function HowTheyWork({ isSample }) {
  function toggleIsLeverOpenCallback() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('How quizzes work lever opened');
  }

  return (
    <Lever
      leverTitleClassNames={ styles.LeverTitle }
      shouldHideOverflow
      title="how do daily quizzes work?"
      toggleIsLeverOpenCallback={ toggleIsLeverOpenCallback }
    >
      <div className={ styles.Root }>
        <p className={ styles.Para }>
          We will look at <strong>all</strong> of your questions from all of your study sets
          (unless you changed your <Link to="/profile/preferences">quiz settings</Link>),
          and determine which questions you should be quizzed on today.
        </p>

        <p className={ styles.Para }>
          Specifically, we’ll look at answers that we are expecting you to forget
          by <strong>tomorrow</strong>.
          We’re then adding all those questions to the quiz and randomizing the
          order, regardless of study set.
        </p>

        <If condition={ isSample }>
          <p className={ styles.Para }>
            <strong>Since this is a sample quiz,</strong> we’re going to limit the quiz to just
            one or two of the questions you recently added so you can get a feel of
            how quizzes work.
          </p>
        </If>

        <p className={ styles.Para }>
          During the quiz, we’ll use your own recall evaluation, how
          much time you took before revealing the answer, <i>your</i> Dorothy history,
          { ' ' }<i>that question’s</i> specific history, and the entire history of all Dorothy
          users to predict the next day you should be quizzed on each question.
        </p>
      </div>
    </Lever>
  );
}

HowTheyWork.propTypes = {
  isSample : PropTypes.bool,
};

export default HowTheyWork;
