import React, { useState } from 'react';

import analytics from 'src/shared/analytics';
import FeedbackModal from 'src/components/modals/Feedback';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';

import styles from './styles.module.scss';

function ContactUs() {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  function openFeedbackModal() { setIsFeedbackModalOpen(true); }
  function closeFeedbackModal() { setIsFeedbackModalOpen(false); }

  function handleLetUsKnowClick() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Let us know opened in ContactUs');

    openFeedbackModal();
  }

  return (
    <div>
      <If condition={ isFeedbackModalOpen }>
        <FeedbackModal
          closeModal={ closeFeedbackModal }
          thankYouMessage="We've received your message. We'll get back to you very soon!"
        >
          <p className={ styles.LetUsKnowParagraphContent }>
            <strong>We’d love to hear your thoughts!</strong>
            { ' ' }
          </p>

          <p className={ styles.LetUsKnowParagraphContent }>
            What can we do to make Dorothy better for you? What questions can we answer?
            We’ll do our very best to get back to you as soon as we can.
          </p>
        </FeedbackModal>
      </If>

      <p className={ styles.Content }>
        Have any questions, issues, concerns, feedback, or ideas? Please{ ' ' }

        <span
          onClick={ handleLetUsKnowClick }
          className={ styles.Link }
        >let us know</span>.

        We’ll get back to you as soon as we can!
      </p>
    </div>
  );
}

export default ContactUs;
