import { print } from 'graphql/language/printer';

import apiService from 'src/services/api';
import handleError from 'src/services/shared/handleError';

export default function publicPost(values, graphqlQuery) {
  const params = {
    query     : print(graphqlQuery),
    variables : { ...values },
  };

  return apiService
    .post('/graphql/public', { graphql : params })
    .then(({ data : { data } }) => data)
    .catch(handleError);
}
