import actionTypes from 'src/constants/actionTypes';
import quizzesService from 'src/services/quizzes';

function handleCommonQuizAction(actionPrefix, serviceAction, values, payloadKey = 'quiz') {
  return dispatch => {
    dispatch({ type : actionTypes[`QUIZ__${actionPrefix}_START`] });

    return quizzesService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`QUIZ__${actionPrefix}_SUCCESS`],
          payload : { [payloadKey] : { ...data[key] } },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`QUIZ__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function addToBeAnsweredQuestions(quizId) {
  return {
    type    : actionTypes.QUIZ__ADD_QUESTIONS_TO_BE_ANSWERED,
    payload : { quizId },
  };
}

export function clearTempState() {
  return { type : actionTypes.QUIZ__CLEAR_TEMP_STATE };
}

export function fetchNewest() {
  return dispatch => {
    dispatch({ type : actionTypes.QUIZ__FETCH_NEWEST_START });

    return quizzesService
      .fetchNewest()
      .then(({ newestQuiz : quiz }) => {
        if (quiz.id) {
          return dispatch({
            type    : actionTypes.QUIZ__FETCH_NEWEST_SUCCESS,
            payload : { quiz },
          });
        }

        // When a user first signs up, they won't have a quiz created yet. And until
        // they add a question, they won't. So we need to be prepared for that.
        return dispatch({ type : actionTypes.QUIZ__FETCH_NEWEST_SUCCESS__EMPTY });
      })
      .catch(errors => dispatch({
        type    : actionTypes.QUIZ__FETCH_NEWEST_FAILURE,
        payload : { errors },
      }));
  };
}

export function reset() {
  return {
    type : actionTypes.QUIZ__RESET,
  };
}

export function resetFlashStudySession(values) {
  return handleCommonQuizAction('RESET_FLASH_STUDY_SESSION', 'resetFlashStudySession', values);
}

export function show(values) {
  return handleCommonQuizAction('SHOW', 'show', values);
}

export function startFlashStudySession(values) {
  return handleCommonQuizAction('START_FLASH_STUDY_SESSION', 'startFlashStudySession', values);
}

export function updateQuestionToBeAnswered(questionNumber, values) {
  return {
    type    : actionTypes.QUIZ__UPDATE_QUESTION_TO_BE_ANSWERED,
    payload : { questionNumber, values },
  };
}

export function updateIsTransitioningToCongratulations(isTransitioningToCongratulations = false) {
  return {
    type    : actionTypes.QUIZ__UPDATE_IS_TRANSITIONING_TO_CONGRATULATIONS,
    payload : { isTransitioningToCongratulations },
  };
}
