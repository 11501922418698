import gql from 'graphql-tag';

export default gql`
  query userProfile {
    userProfile {
      maxQuestionAmountPerQuiz
      notificationSettings {
        id
        medium
        optedOut
      }
      studySets {
        id
        questionsCount
        title
      }
      user {
        accountPlan {
          planType
          periodEnd
        }
        givenFreeAccess
        id
      }
    }
  }
`;
