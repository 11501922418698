import gql from 'graphql-tag';

import quizFields from 'src/graphql/fragments/quizFields';

export default gql`
  mutation startFlashStudySession(
    $onlyWrongOnes: Boolean
    $studySetId: ID!
  ) {
    startFlashStudySession(
      onlyWrongOnes: $onlyWrongOnes
      studySetId: $studySetId
    ) {
      ...quizFields
    }
  }
  ${quizFields}
`;
