import { PropTypes } from 'prop-types';
import React from 'react';
import Select from 'react-select';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as statsPropTypes from 'src/constants/propTypes/stats';

import AlertBanner from 'src/components/AlertBanner';
import getUrlParameter from 'src/shared/getUrlParameter';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import Charts from './Charts';
import styles from './styles.module.scss';

function Stats(props) {
  const {
    fullReport,
    history,
    isActive,
    location,
  } = props;

  const options = [
    { value : 'yesterday', label : 'Yesterday' },
    { value : 'last_7_days', label : 'Last 7 days' },
    { value : 'last_30_days', label : 'Last 30 days' },
    { value : 'all_time', label : 'All time' },
  ];

  const colorStyles = {
    control : (defaultStyles, { isFocused }) => {
      let { boxShadow } = defaultStyles;

      if (isFocused) boxShadow = '0 0 0 1px #2071aa';

      return {
        ...defaultStyles,
        '&:hover' : {
          borderColor : 'transparent',
        },
        borderColor : 'transparent',
        boxShadow,
      };
    },
    option : (defaultStyles, { isDisabled, isFocused, isSelected }) => {
      let { backgroundColor } = defaultStyles;

      if (isFocused) backgroundColor = '#eaf4fb';
      if (isSelected) backgroundColor = '#2071aa';
      if (isDisabled) backgroundColor = '#bdbdbd';

      return {
        ...defaultStyles,
        backgroundColor,
      };
    },
  };

  function handleChange({ value }) {
    const searchQuery = new URLSearchParams({ time_frame : value });
    const path = `${location.pathname}?${searchQuery.toString()}`;
    return history.push(path);
  }

  let defaultValue = options[1];
  const urlParameter = getUrlParameter('time_frame');

  if (urlParameter) {
    defaultValue = options.find(option => option.value === urlParameter);
  }

  const hasReversedQuestions = fullReport && fullReport.get('hasReversedQuestions');

  return (
    <div
      className={ styles.Root }
      data-automated-test="stats-page"
    >
      <div className={ styles.HeadingSection }>
        <div className={ styles.TopLevel }>
          <h3 className={ styles.Title }>Stats</h3>

          <div
            className={ styles.Select }
            data-automated-test="stats-page--date-range-select"
          >
            <Select
              defaultValue={ defaultValue }
              isSearchable={ false }
              onChange={ handleChange }
              options={ options }
              styles={ colorStyles }
              value={ defaultValue }
            />
          </div>
        </div>
      </div>

      <Choose>
        <When condition={ isActive || !fullReport }>
          <PulseLoadingIndicator />
        </When>

        <Otherwise>
          <Charts fullReport={ fullReport } />

          <If condition={ hasReversedQuestions }>
            <div
              className={ styles.ReversedDataInfoBox }
              data-automated-test="stats-page--reversed-question-data-included-info-box"
            >
              <AlertBanner isInfo>
                <p className={ styles.AlertBannerTitle }>
                  Reversed question data included
                </p>

                <p className={ styles.AlertBannerText }>
                  You have reversed questions from your study sets.
                  The data from those reversed questions is included in your stats.
                  These reversed questions were created when you turned on
                  “Reversed questions in quizzes”.
                </p>
              </AlertBanner>
            </div>
          </If>
        </Otherwise>
      </Choose>

      <SiteWideLinks location={ location } />
    </div>
  );
}

Stats.propTypes = {
  fullReport : statsPropTypes.fullReport,
  history    : basePropTypes.history.isRequired,
  isActive   : PropTypes.bool,
  location   : basePropTypes.location.isRequired,
};

export default Stats;
