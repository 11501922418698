// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2igIf{display:flex;flex-direction:row;justify-content:flex-start;flex-wrap:wrap}.styles-module__Arrow___2_aI7{margin:0 6px}.styles-module__CurrentPathPara___k0-C6{color:rgba(0,0,0,.65);font-weight:600}.styles-module__Link___LWVvj{color:#2071aa;cursor:pointer;text-decoration:none}.styles-module__Link___LWVvj:hover{cursor:pointer}.styles-module__Link___LWVvj *:hover{cursor:pointer}.styles-module__LinkContainer___1Ipxw{display:flex;flex-direction:row;justify-content:flex-start}.styles-module__Link___LWVvj,.styles-module__CurrentPathPara___k0-C6{max-width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/navigation/Breadcrumbs/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,cAAA,CAEF,8BACE,YAAA,CAEF,wCACE,qBAAA,CACA,eAAA,CAEF,6BACE,aAAA,CACA,cAAA,CACA,oBAAA,CACA,mCACE,cAAA,CACF,qCACE,cAAA,CAEJ,sCACE,YAAA,CACA,kBAAA,CACA,0BAAA,CAEF,qEAEE,eAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  flex-wrap: wrap; }\n\n.Arrow {\n  margin: 0 6px; }\n\n.CurrentPathPara {\n  color: rgba(0, 0, 0, 0.65);\n  font-weight: 600; }\n\n.Link {\n  color: #2071aa;\n  cursor: pointer;\n  text-decoration: none; }\n  .Link:hover {\n    cursor: pointer; }\n  .Link *:hover {\n    cursor: pointer; }\n\n.LinkContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start; }\n\n.Link,\n.CurrentPathPara {\n  max-width: 200px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2igIf",
	"Arrow": "styles-module__Arrow___2_aI7",
	"CurrentPathPara": "styles-module__CurrentPathPara___k0-C6",
	"Link": "styles-module__Link___LWVvj",
	"LinkContainer": "styles-module__LinkContainer___1Ipxw"
};
module.exports = exports;
