import ClipLoader from 'react-spinners/ClipLoader';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import Shared from './Shared';

const shared = new Shared();

function ButtonSubmit(props) {
  const {
    children,
    disabled,
    extraClasses,
    handleClick,
    isActive,
    type,
    value,
  } = props;

  shared.setClassProperties({ props });

  const extraContainerClasses = extraClasses && extraClasses.container;

  return (
    <div className={ shared.getRootClassNames() }>
      <div className={ `${shared.getContainerClassNames()} ${extraContainerClasses}` }>
        { children }

        <input
          className={ shared.getButtonClassNames() }
          disabled={ disabled }
          onClick={ handleClick }
          type={ type }
          value={ value }
        />

        <If condition={ isActive }>
          <div className={ shared.getLoaderClassNames() }>
            <ClipLoader
              color="#fff"
              isLoading={ isActive }
              size={ 15 }
            />
          </div>
        </If>
      </div>
    </div>
  );
}

ButtonSubmit.propTypes = {
  type : basePropTypes.type.isRequired,
  ...shared.propTypes,
};

export default ButtonSubmit;
