import { PropTypes } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function Tooltip(props) {
  const {
    payload: payloadArray,
  } = props;

  const data = payloadArray && payloadArray[0];
  const payload = data && data.payload;
  const { xAxisName, yValue, tooltip } = payload || {};
  const { label, value } = tooltip || {};

  return (
    <div className={ styles.Root }>
      <div className={ styles.Label }>
        { label || xAxisName }
      </div>

      <div className={ styles.Value }>
        { value || yValue }
      </div>
    </div>
  );
}

Tooltip.propTypes = {
  payload : PropTypes.arrayOf(PropTypes.shape({
    tooltip : PropTypes.shape({
      label : PropTypes.string.isRequired,
      value : PropTypes.string.isRequired,
    }),
    xAxisName : PropTypes.string,
    yValue    : PropTypes.number,
  })),
};

export default Tooltip;
