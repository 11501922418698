import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as questionsActions from 'src/actions/questions';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';
import * as studySetsActions from 'src/actions/studySets';

import navigateOrWait from 'src/containers/shared/navigateOrWait';
import ShowQuestion from 'src/components/ShowQuestion';

class FunctionalShowQuestion extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    location : basePropTypes.location.isRequired,
    match    : basePropTypes.match.isRequired,
    question : questionPropTypes.question.isRequired,
    studySet : studySetPropTypes.studySet.isRequired,
  };

  componentDidMount() {
    const { actions: { studySets }, match, studySet } = this.props;

    if (
      studySet &&
      studySet.get('questions') &&
      studySet.get('questions').size > 0
    ) return;

    studySets.show({ id : match.params.id });
  }

  deleteQuestion = () => {
    const { actions, question } = this.props;

    return actions
      .questions
      .deleteQuestion({ id : question.get('id') })
      .then(this.navigateOrWait)
      .catch(this.navigateOrWait);
  }

  navigateOrWait = () => navigateOrWait(this.props, `/study-sets/${this.props.studySet.get('id')}`);

  render() {
    return (
      <ShowQuestion
        deleteQuestion={ this.deleteQuestion }
        { ...this.props }
      />
    );
  }
}

function mapStateToProps({ studySets }, { match : { params } }) {
  return {
    question : studySets
      .getIn(['loaded', 'show', 'questions'])
      .find(q => q.get('id') === params.questionId),
    studySet : studySets.getIn(['loaded', 'show']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      questions : bindActionCreators(questionsActions, dispatch),
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalShowQuestion);
