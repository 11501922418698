import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as questionsActions from 'src/actions/questions';
import * as studySetsActions from 'src/actions/studySets';

import buildValuesFromElementsWithFiles from 'src/containers/shared/buildValuesFromElementsWithFiles';
import navigateOrWait from 'src/containers/shared/navigateOrWait';
import QuestionForm from 'src/components/forms/Question';

class FunctionalEdit extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    history  : basePropTypes.history.isRequired,
    match    : basePropTypes.match.isRequired,
    isActive : basePropTypes.isActive,
    question : questionPropTypes.question.isRequired,
  };

  constructor() {
    super();
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { actions, match } = this.props;

    actions.questions.clearTempState();
    actions.questions.show({ id : match.params.id });
    actions.studySets.getAll();
  }

  deleteAnswerAudio = () => {
    const { actions: { questions }, question } = this.props;
    return questions.deleteAnswerAudio({ answerId : question.getIn(['answer', 'id']) });
  }

  deleteAnswerImage = () => {
    const { actions: { questions }, question } = this.props;
    return questions.deleteAnswerImage({ answerId : question.getIn(['answer', 'id']) });
  }

  deleteQuestionAudio = () => {
    const { actions: { questions }, question } = this.props;
    return questions.deleteAudio({ questionId : question.get('id') });
  }

  deleteQuestionImage = () => {
    const { actions: { questions }, question } = this.props;
    return questions.deleteImage({ questionId : question.get('id') });
  }

  async handleSave(values) {
    const { actions: { questions }, question } = this.props;

    const formValues = await buildValuesFromElementsWithFiles(values);

    const params = {
      ...formValues,
      id : question.get('id'),
    };

    return questions
      .update(params)
      .then(this.navigateOrWait);
  }

  navigateOrWait = () => {
    const id = this.props.question.get('id');

    return navigateOrWait(
      this.props,
      `/questions/${id}`,
      { pathFrom : `/questions/${id}/edit` },
    );
  }

  render() {
    return (
      <QuestionForm
        deleteAnswerAudio={ this.deleteAnswerAudio }
        deleteAnswerImage={ this.deleteAnswerImage }
        deleteQuestionAudio={ this.deleteQuestionAudio }
        deleteQuestionImage={ this.deleteQuestionImage }
        fromEdit
        handleSubmit={ this.handleSave }
        heading="Edit Question"
        { ...this.props }
      />
    );
  }
}

function mapStateToProps({ questions, studySets }, { match : { params } }) {
  return {
    errors       : questions.get('errors'),
    isActive     : questions.get('isActive'),
    isSaving     : questions.get('isSaving'),
    isSuccessful : questions.get('isSuccessful'),
    question     : questions.getIn(['loaded', 'questions', params.id]),
    studySets    : studySets.getIn(['loaded', 'all']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      questions : bindActionCreators(questionsActions, dispatch),
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalEdit);
