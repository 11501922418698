import signIn from 'src/containers/shared/signIn';
import verifyOtp from 'src/containers/shared/verifyOtp';

export default function (containerThis, verifyOtpCallback = null) {
  return {
    emailSentTo            : containerThis.props.emailSentTo,
    errors                 : containerThis.props.authErrors,
    isActive               : containerThis.props.authIsActive,
    shouldDisplayVerifyOtp : containerThis.state.shouldDisplayVerifyOtp,
    shouldFireConfetti     : containerThis.state.shouldFireConfetti,
    signIn                 : values => signIn(values, containerThis),
    verifyOtp              : values => verifyOtp(values, containerThis),
    verifyOtpWithCallback  : values => verifyOtp(values, containerThis, verifyOtpCallback),
  };
}
