import { PropTypes } from 'prop-types';
import React from 'react';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

import Blob from 'src/components/images/Blob';
import FaceLogo from 'src/components/images/logos/FaceOnly';

import InfoSection from './InfoSection';
import SignIn from '../SignIn';
import SignInOtpConfirmation from '../SignInOtpConfirmation';
import styles from './styles.module.scss';

function Welcome(props) {
  const {
    emailSentTo,
    errors,
    goBackFromVerifyOtp,
    handleSendOtp,
    handleVerifyOtp,
    isActive,
    justSignedOut,
    location,
    pathFrom,
    shouldDisplayVerifyOtp,
    shouldFireConfetti,
  } = props;

  return (
    <div className={ styles.Root }>
      <div className={ styles.InfoContainer }>
        <InfoSection />
      </div>

      <div className={ styles.LogoContainer }>
        <FaceLogo />
      </div>

      <div className={ styles.AuthContainer }>
        <Choose>
          <When condition={ shouldDisplayVerifyOtp }>
            <SignInOtpConfirmation
              emailSentTo={ emailSentTo }
              errors={ errors }
              goBackFromVerifyOtp={ goBackFromVerifyOtp }
              handleSubmit={ handleVerifyOtp }
              isActive={ isActive }
              shouldFireConfetti={ shouldFireConfetti }
            />
          </When>

          <Otherwise>
            <SignIn
              errors={ errors }
              handleSubmit={ handleSendOtp }
              isActive={ isActive }
              justSignedOut={ justSignedOut }
              location={ location }
              pathFrom={ pathFrom }
            />
          </Otherwise>
        </Choose>
      </div>

      <div className={ styles.BlobContainer }>
        <Blob />
      </div>
    </div>
  );
}

Welcome.propTypes = {
  emailSentTo            : authenticationPropTypes.emailSentTo,
  errors                 : basePropTypes.errors,
  goBackFromVerifyOtp    : PropTypes.func.isRequired,
  isActive               : basePropTypes.isActive.isRequired,
  handleSendOtp          : PropTypes.func.isRequired,
  handleVerifyOtp        : PropTypes.func.isRequired,
  justSignedOut          : authenticationPropTypes.justSignedOut,
  location               : basePropTypes.location.isRequired,
  pathFrom               : authenticationPropTypes.pathFrom,
  shouldDisplayVerifyOtp : PropTypes.bool,
  shouldFireConfetti     : PropTypes.bool,
};

export default Welcome;
