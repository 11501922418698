import gql from 'graphql-tag';

export default gql`
  query getAllStudySets {
    getAllStudySets {
      description
      id
      originalQuestionsCount
      title
    }
  }
`;
