import { PropTypes } from 'prop-types';
import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildRoute from 'src/components/shared/buildRoute';
import Result from 'src/components/ResultsList/Result';
import ResultsList from 'src/components/ResultsList';
import SearchField from 'src/components/fields/SearchField';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';
import trackBackClick from 'src/components/shared/trackBackClick';

import styles from './styles.module.scss';

function AllPublicStudySets(props) {
  const {
    isActive,
    isUnauthed,
    location,
    match,
    studySets,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  async function search(searchQuery) {
    const route = buildRoute(searchQuery, null, '/public-study-sets');
    return history.push(route);
  }

  function getQuestionsSizePara(studySet) {
    const questionsSize = studySet.get('originalQuestionsCount');
    return `${questionsSize} question${questionsSize === 1 ? '' : 's'}`;
  }

  function handleBackClick() {
    trackBackClick(match);
    history.goBack();
  }

  const basePath = isUnauthed ?
    '/public/public-study-sets' :
    '/public-study-sets';

  return (
    <div className={ styles.Root }>
      <div className={ styles.LinkContainer }>
        <p onClick={ handleBackClick }>← back</p>
      </div>

      <div className={ styles.SearchContainer }>
        <SearchField
          placeholder="Spanish, NCLEX, probate law, Arabic, etc."
          search={ search }
          setIsLoading={ setIsLoading }
        />
      </div>

      <div className={ styles.ResultsContainer }>
        <ResultsList
          isLoading={ isActive || isLoading }
          resultName="Public Study Set"
          results={ studySets }
          subTitle="These are study sets created, and made publicly available, by users."
        >
          <ul>
            <For each="studySet" index="i" of={ studySets }>
              <Result
                basePath={ basePath }
                key={ studySet.get('id') }
                result={ studySet }
                rightSidePara={ getQuestionsSizePara(studySet) }
                subTitle={ studySet.get('description') }
                title={ studySet.get('title') }
              />
            </For>
          </ul>
        </ResultsList>
      </div>

      <SiteWideLinks
        isUnauthed={ isUnauthed }
        location={ location }
      />
    </div>
  );
}

AllPublicStudySets.propTypes = {
  isActive   : basePropTypes.isActive,
  isUnauthed : PropTypes.bool,
  location   : basePropTypes.location.isRequired,
  match      : basePropTypes.match.isRequired,
  studySets  : studySetPropTypes.studySets.isRequired,
};

export default memo(AllPublicStudySets);
