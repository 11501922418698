// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2aqud{display:flex;flex-direction:row;justify-content:flex-start;grid-gap:24px;gap:24px;padding:16px 20px 0 20px}.styles-module__Tab___2JUMw{cursor:pointer;font-weight:600;padding-bottom:13px;text-align:center}.styles-module__Tab___2JUMw.styles-module__isActive___1w190{border-bottom:2px solid #2071aa}@media screen and (max-width: 567px){.styles-module__Tab___2JUMw{font-size:14px}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Stats/Charts/Tabs/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,aAAA,CAAA,QAAA,CACA,wBAAA,CAEF,4BACE,cAAA,CACA,eAAA,CACA,mBAAA,CACA,iBAAA,CACA,4DACE,+BAAA,CACF,qCACE,4BACE,cAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  gap: 24px;\n  padding: 16px 20px 0 20px; }\n\n.Tab {\n  cursor: pointer;\n  font-weight: 600;\n  padding-bottom: 13px;\n  text-align: center; }\n  .Tab.isActive {\n    border-bottom: 2px solid #2071aa; }\n  @media screen and (max-width: 567px) {\n    .Tab {\n      font-size: 14px; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2aqud",
	"Tab": "styles-module__Tab___2JUMw",
	"isActive": "styles-module__isActive___1w190"
};
module.exports = exports;
