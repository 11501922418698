import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import ShowPublicStudySetQuestion from 'src/components/publicStudySets/ShowQuestion';

class FunctionalShowQuestion extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    isAuthed : PropTypes.bool,
    location : basePropTypes.location.isRequired,
    match    : basePropTypes.match.isRequired,
    studySet : studySetPropTypes.studySet.isRequired,
  };

  componentDidMount() {
    const { actions: { publicStudySets }, match, studySet } = this.props;

    if (
      studySet &&
      studySet.get('questions') &&
      studySet.get('questions').size > 0
    ) return;

    publicStudySets.show({ id : match.params.id });
  }

  render() {
    if (!this.props.isAuthed) {
      let { pathname } = this.props.location;

      pathname = pathname.replace('/public-study-sets/', '/public/public-study-sets/');

      return <Redirect to={ { pathname } } />;
    }

    return (
      <ShowPublicStudySetQuestion { ...this.props } />
    );
  }
}

function mapStateToProps({ authentication, publicStudySets }, { match : { params } }) {
  return {
    isAuthed : !!(authentication.get('email') && authentication.get('accessToken')),
    question : publicStudySets
      .getIn(['loaded', 'show', 'questions'])
      .find(q => q.get('id') === params.questionId),
    studySet : publicStudySets.getIn(['loaded', 'show']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      publicStudySets : bindActionCreators(publicStudySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalShowQuestion);
