import React, { memo } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import ResultsList from 'src/components/ResultsList';
import Result from 'src/components/ResultsList/Result';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';
// import SortOption from 'src/components/SortOptions/SortOption';
// import SortOptions from 'src/components/SortOptions';
// import SortOptionsOpener from 'src/components/SortOptionsOpener';

import styles from './styles.module.scss';

function AllStudySets(props) {
  const {
    isActive,
    location,
    studySets,
  } = props;

  function getQuestionsSizePara(studySet) {
    const questionsSize = studySet.get('originalQuestionsCount');
    return `${questionsSize} question${questionsSize === 1 ? '' : 's'}`;
  }

  // TODO: Create a Provider for sort options
  // const [isSortOptionsOpen, setIsSortOptionsOpen] = useState(false);
  //
  // const closeSortOptions = useCallback(() => setIsSortOptionsOpen(false), [setIsSortOptionsOpen]);
  // const openSortOptions = useCallback(() => setIsSortOptionsOpen(true), [setIsSortOptionsOpen]);

  return (
    <div className={ styles.Root }>
      {
        // <SortOptionsOpener
        //   defaultSortedBy="Recently Updated"
        //   openSortOptions={openSortOptions}
        // />
        //
        // <If condition={ isSortOptionsOpen }>
        //   <SortOptions closeSortOptions={ closeSortOptions }>
        //     <SortOption
        //       defaultSortedBy="recently-updated"
        //       name="Recently Updated"
        //       sort="recently-updated"
        //     />
        //
        //     <SortOption
        //       defaultSortedBy="recently-updated"
        //       name="Recently Created"
        //       sort="recently-created"
        //     />
        //
        //     <SortOption
        //       defaultSortedBy="recently-updated"
        //       name="Title"
        //       sort="title"
        //     />
        //   </SortOptions>
        // </If>
      }

      <div className={ styles.ResultsContainer }>
        <ResultsList
          basePath="/study-sets"
          isLoading={ isActive }
          resultName="Study Set"
          results={ studySets }
        >
          <ul>
            <For each="result" index="i" of={ studySets }>
              <Result
                basePath="/study-sets"
                key={ result.get('id') }
                result={ result }
                rightSidePara={ getQuestionsSizePara(result) }
                src={ result.get('imageUrl') || result.getIn(['answer', 'imageUrl']) }
                subTitle={ result.get('description') }
              />
            </For>
          </ul>
        </ResultsList>
      </div>

      <SiteWideLinks location={ location } />
    </div>
  );
}

AllStudySets.propTypes = {
  isActive     : basePropTypes.isActive,
  location     : basePropTypes.location.isRequired,
  studySets    : studySetPropTypes.studySets.isRequired,
};

export default memo(AllStudySets);
