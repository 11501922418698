import React from 'react';

import Shared from './Shared';
import styles from './styles.module.scss';

const shared = new Shared();

function FieldErrors({ errorMessages }) {
  return (
    <div
      className={ styles.Root }
      data-automated-test="field-errors"
    >
      <For each="error" index="i" of={ errorMessages }>
        <p className={ styles.Message } key={ i }>{ error }</p>
      </For>
    </div>
  );
}

FieldErrors.propTypes = shared.propTypes;

export default FieldErrors;
