import { PropTypes } from 'prop-types';
import React from 'react';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import Links from '../Links';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Content(props) {
  const {
    children,
    handleSignout,
    isOnAccountPlan,
    location,
  } = props;

  function handleClick(event) {
    event.preventDefault();
    return handleSignout();
  }

  const { pathname } = location;

  return (
    <div className={ styles.Root }>
      <h2 className={ styles.Title }>Profile</h2>

      <div className={ styles.LinksAndActions }>
        <Links
          handleClick={ handleClick }
          pathname={ pathname }
        />

        <div className={ styles.ParentContainer }>
          <div className={ cx({ ChildrenContainer : true, isOnAccountPlan }) }>
            { children }
          </div>
        </div>
      </div>
    </div>
  );
}

Content.propTypes = {
  children        : basePropTypes.children.isRequired,
  handleSignout   : authenticationPropTypes.handleSignout.isRequired,
  isOnAccountPlan : PropTypes.bool,
  location        : basePropTypes.location.isRequired,
};

export default Content;
