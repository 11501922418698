// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3Tn6J{min-width:93px}.styles-module__ShortHorizontalLine___2NJo9{margin-bottom:24px;margin-top:18px}@media screen and (max-width: 979px){.styles-module__ShortHorizontalLine___2NJo9{display:none}}.styles-module__LongHorizontalLine___1pxNz{margin-bottom:39px;margin-top:33px}.styles-module__LongHorizontalLine___1pxNz div{margin:0 auto;width:100%}@media screen and (min-width: 980px){.styles-module__LongHorizontalLine___1pxNz{display:none}}@media screen and (max-width: 979px){.styles-module__SignOutButton___3ZdMa{display:none}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/profile/Links/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAA,CAEF,4CACE,kBAAA,CACA,eAAA,CACA,qCACE,4CACE,YAAA,CAAA,CAEN,2CACE,kBAAA,CACA,eAAA,CACA,+CACE,aAAA,CACA,UAAA,CACF,qCACE,2CACE,YAAA,CAAA,CAEN,qCACE,sCACE,YAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  min-width: 93px; }\n\n.ShortHorizontalLine {\n  margin-bottom: 24px;\n  margin-top: 18px; }\n  @media screen and (max-width: 979px) {\n    .ShortHorizontalLine {\n      display: none; } }\n\n.LongHorizontalLine {\n  margin-bottom: 39px;\n  margin-top: 33px; }\n  .LongHorizontalLine div {\n    margin: 0 auto;\n    width: 100%; }\n  @media screen and (min-width: 980px) {\n    .LongHorizontalLine {\n      display: none; } }\n\n@media screen and (max-width: 979px) {\n  .SignOutButton {\n    display: none; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3Tn6J",
	"ShortHorizontalLine": "styles-module__ShortHorizontalLine___2NJo9",
	"LongHorizontalLine": "styles-module__LongHorizontalLine___1pxNz",
	"SignOutButton": "styles-module__SignOutButton___3ZdMa"
};
module.exports = exports;
