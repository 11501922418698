import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';

import BaseModal from 'src/components/modals/Base';
import FeedbackForm from 'src/components/forms/Feedback';
import FeedbackPropsContext from 'src/contexts/FeedbackProps';

import styles from './styles.module.scss';

function FeedbackModal(props) {
  const {
    children,
    closeModal : propsCloseModal,
    thankYouMessage,
  } = props;

  const {
    errors,
    isActive,
    resetFeedbackModal,
    shouldDisplayThankYouMessage,
    submitFeedback,
  } = useContext(FeedbackPropsContext);

  function closeModal() {
    resetFeedbackModal();
    propsCloseModal();
  }

  // shouldCloseOnOverlayClick is false in case the user fills out the modal
  // and accidentally click outside of it, I don't want them to lose their feedback
  return (
    <BaseModal
      closeModal={ closeModal }
      dataAutomatedTest="feedback-modal"
      extraClasses={ { container : styles.Modal } }
      hasCloseButton
      shouldCloseOnOverlayClick={ false }
    >
      <Choose>
        <When condition={ shouldDisplayThankYouMessage }>
          <p className={ styles.ParagraphContent }>
            <Choose>
              <When condition={ thankYouMessage }>
                <strong>{ thankYouMessage }</strong>
              </When>

              <Otherwise>
                <strong>Thank you so much for your feedback! We seriously appreciate it.</strong>
              </Otherwise>
            </Choose>
          </p>
        </When>

        <Otherwise>
          <div>
            <Choose>
              <When condition={ children }>
                { children }
              </When>

              <Otherwise>
                <p className={ styles.ParagraphContent }>
                  <strong>We’d love to hear your feedback!</strong> is
                  there anything we can do to make your experience better?
                </p>

                <p className={ styles.ParagraphContent }>
                  Perhaps, there is an issue we can fix or a feature we can add?
                </p>
              </Otherwise>
            </Choose>

            <FeedbackForm
              errors={ errors }
              handleSubmit={ submitFeedback }
              isActive={ isActive }
            />
          </div>
        </Otherwise>
      </Choose>
    </BaseModal>
  );
}

FeedbackModal.propTypes = {
  children                     : PropTypes.node,
  closeModal                   : PropTypes.func.isRequired,
  shouldDisplayThankYouMessage : PropTypes.bool,
  thankYouMessage              : PropTypes.string,
};

export default FeedbackModal;
