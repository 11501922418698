import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Footer({ children, shouldDisplayScrollMoreBlur }) {
  return (
    <ul className={ cx({ Root : true, shouldDisplayScrollMoreBlur }) }>
      { children }
    </ul>
  );
}

Footer.propTypes = {
  children                    : basePropTypes.children,
  shouldDisplayScrollMoreBlur : PropTypes.bool,
};

export default Footer;
