import React, { PureComponent } from 'react';

import InstallApp from 'src/components/profile/InstallApp';

class FunctionalInstallApp extends PureComponent {
  render() {
    return (
      <InstallApp />
    );
  }
}

export default FunctionalInstallApp;
