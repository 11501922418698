import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionsActions from 'src/actions/questions';
import * as studySetsActions from 'src/actions/studySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import analytics from 'src/shared/analytics';
import buildQuestionValues from 'src/containers/studySets/shared/buildQuestionValues';
import buildStudySetValues from 'src/containers/studySets/shared/buildStudySetValues';
import getUrlParameter from 'src/shared/getUrlParameter';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import navigateOrWait from 'src/containers/shared/navigateOrWait';
import EditStudySet from 'src/components/studySets/Edit';

class FunctionalEdit extends PureComponent {
  static propTypes = {
    actions      : basePropTypes.actions.isRequired,
    errors       : basePropTypes.errors,
    history      : basePropTypes.history.isRequired,
    match        : basePropTypes.match.isRequired,
    isActive     : basePropTypes.isActive,
    isSaving     : basePropTypes.isSaving,
    location     : basePropTypes.location.isRequired,
    studySet     : studySetPropTypes.autosavedStudySet.isRequired,
  };

  componentDidMount() {
    const { actions: { studySets }, match, studySet } = this.props;

    studySets.clearTempState();

    if (!studySet) studySets.setAutosavedEdit({ id : match.params.id });
  }

  addQuestion = key => this.props.actions.studySets.addQuestionToEdit(
    key,
    this.props.studySet.get('id'),
  )

  bulkAddQuestions = questions => this.props.actions.studySets.bulkAddQuestionsToEdit(
    questions,
    this.props.studySet.get('id'),
  )

  deleteAnswerAudio = (id, key) => {
    const { actions: { questions, studySets }, match } = this.props;

    return questions.deleteAnswerAudio({ answerId : id })
      .then(() => studySets.removeAnswerAudio(key, match.params.id));
  }

  deleteAnswerImage = (id, key) => {
    const { actions: { questions, studySets }, match } = this.props;

    return questions.deleteAnswerImage({ answerId : id })
      .then(() => studySets.removeAnswerImage(key, match.params.id));
  }

  deleteQuestionAudio = (id, key) => {
    const { actions: { questions, studySets }, match } = this.props;

    return questions.deleteAudio({ questionId : id })
      .then(() => studySets.removeQuestionAudio(key, match.params.id));
  }

  deleteQuestionImage = (id, key) => {
    const { actions: { questions, studySets }, match } = this.props;

    return questions.deleteImage({ questionId : id })
      .then(() => studySets.removeQuestionImage(key, match.params.id));
  }

  handleSave = async values => {
    const { actions: { studySets }, studySet } = this.props;

    studySets.startSave();

    const questions = await buildQuestionValues(values, studySet.get('questions'));
    const studySetValues = buildStudySetValues(values, studySet);
    const id = studySet.get('id');

    const params = {
      ...values,
      ...studySetValues,
      id,
      questions,
    };

    return studySets
      .update(params)
      .then(() => {
        const navigate = this.navigateOrWait();
        if (navigate !== null) studySets.clearAutosavedEdit(id);
      })
      .catch(() => null);
  }

  handleNavigatingPastFirstStudySet = () => {
    const { studySet } = this.props;

    if (!studySet) return;

    const { size } = studySet.get('questions');

    const needsQuestionsStill = size === 0 ||
      (
        size === 1 &&
          studySet.get('questions').first().get('isRemoved')
      );

    if (needsQuestionsStill) {
      return navigateOrWait(this.props, '/onboarding/new-study-set');
    }

    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Onboarding -- Study set questions added');
    return navigateOrWait(this.props, '/onboarding/sample-quiz');
  }

  isOnboarding() {
    return getUrlParameter('purpose') === 'onboarding';
  }

  navigateOrWait = () => {
    const id = this.props.studySet.get('id');

    if (this.isOnboarding()) {
      return this.handleNavigatingPastFirstStudySet();
    }

    return navigateOrWait(
      this.props,
      `/study-sets/${id}`,
      {
        pathFrom          : `/study-sets/${id}/edit`,
        successfullySaved : true,
      },
    );
  }

  refreshData = () => {
    const { actions, studySet } = this.props;
    const id = studySet.get('id');

    actions.studySets.clearAutosavedEdit(id);

    return actions.studySets.setAutosavedEdit({ id });
  }

  removeQuestion = key => this.props.actions.studySets.removeQuestionFromEdit(
    key,
    this.props.studySet.get('id'),
  )

  undoRemoveQuestion = key => this.props.actions.studySets.undoRemoveQuestionFromEdit(
    key,
    this.props.studySet.get('id'),
  )

  render() {
    return (
      <EditStudySet
        addQuestion={ this.addQuestion }
        bulkAddQuestions={ this.bulkAddQuestions }
        deleteAnswerAudio={ this.deleteAnswerAudio }
        deleteAnswerImage={ this.deleteAnswerImage }
        deleteQuestionAudio={ this.deleteQuestionAudio }
        deleteQuestionImage={ this.deleteQuestionImage }
        errors={ this.props.errors }
        handleSubmit={ this.handleSave }
        history={ this.props.history }
        isActive={ this.props.isActive }
        isOnboarding={ this.isOnboarding() }
        isSaving={ this.props.isSaving }
        location={ this.props.location }
        match={ this.props.match }
        refreshData={ this.refreshData }
        removeQuestion={ this.removeQuestion }
        saveButtonValue="Save"
        studySet={ this.props.studySet }
        undoRemoveQuestion={ this.undoRemoveQuestion }
        updateValueInAutosaved={ this.props.actions.studySets.updateValueInAutosaved }
      />
    );
  }
}

function mapStateToProps({ studySets }, { match }) {
  return {
    errors   : studySets.get('errors'),
    isActive : studySets.get('isActive'),
    isSaving : studySets.get('isSaving'),
    studySet : studySets.getIn(['autosaved', match.params.id]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      questions : bindActionCreators(questionsActions, dispatch),
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalEdit);
