import { Link } from 'react-router-dom';
import React, { useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import Card from 'src/components/Card';
import ConfirmationModal from 'src/components/modals/Confirmation';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import Navigation from './Navigation';
import Preview from './Preview';
import styles from './styles.module.scss';
import SuccessMessage from './SuccessMessage';

function ShowQuestion(props) {
  const {
    deleteQuestion,
    history,
    isActive,
    isSuccessful,
    location,
    question,
    studySet,
  } = props;

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  function closeDeletionModal() { setIsDeletionModalOpen(false); }
  function openDeletionModal() { setIsDeletionModalOpen(true); }

  // TODO: Change to loading component
  if (!question) return null;

  const { state } = history.location;
  const pathFrom = state && state.pathFrom;
  const fromQuestions = pathFrom && pathFrom.includes('questions');

  const fromEdit = fromQuestions && pathFrom.includes('edit');
  const fromNew = fromQuestions && pathFrom.includes('new');

  const userId = (question && question.get('userId')) ||
    (studySet && studySet.get('userId'));

  return (
    <div>
      <If condition={ isActive }>
        <div className={ styles.PulseLoader }>
          <PulseLoadingIndicator isLoading={ isActive }/>
        </div>
      </If>

      <Card
        isSoloCardOnScreen
        isFullScreenOnDesktop
        isFullScreenOnMobile
        navLocationOnMobile="footer"
      >
        <Navigation
          history={ history }
          location={ location }
          studySet={ studySet }
          title={ question && question.get('title') }
          userId={ userId }
        />

        <Preview question={ question } />

        <div className={ styles.EditQuestionLinkContainer }>
          <Link
            className={ styles.EditQuestionLink }
            to={ `/questions/${question.get('id')}/edit` }
          >
            edit question
          </Link>
        </div>

        <p
          className={ styles.DeleteTitle }
          onClick={ openDeletionModal }
        >delete question</p>

        <If condition={ isDeletionModalOpen }>
          <ConfirmationModal
            closeModal={ closeDeletionModal }
            submitAction={ deleteQuestion }
            submitActionName="Delete question"
          >
            If you delete this question, all of its interval history will be erased.
            The data from this question used by our
            algorithms to help determine your intervals will be lost.
          </ConfirmationModal>
        </If>

        <If condition={ isSuccessful && (fromNew || fromEdit) }>
          <SuccessMessage fromNew={ fromNew } />
        </If>
      </Card>

      <SiteWideLinks location={ location } />
    </div>
  );
}

ShowQuestion.propTypes = {
  deleteQuestion : questionPropTypes.deleteQuestion.isRequired,
  history        : basePropTypes.history.isRequired,
  location       : basePropTypes.location.isRequired,
  isActive       : basePropTypes.isActive,
  isSuccessful   : questionPropTypes.isSuccessful,
  question       : questionPropTypes.question.isRequired,
  studySet       : studySetPropTypes.studySet.isRequired,
};

export default ShowQuestion;
