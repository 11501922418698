import analytics from 'src/shared/analytics';
import buildUserFriendlyErrorsForMissingValues from './buildUserFriendlyErrorsForMissingValues';

export default function (values, containerThis, verifyOtpCallback) {
  if (!containerThis) return;

  containerThis.setState({ isVerifyingOtp : true });

  const { authentication } = containerThis.props.actions;

  const errors = buildUserFriendlyErrorsForMissingValues(
    values,
    { otp  : 'Oops! Looks like you forgot to enter your one time password. This can be found in your email.' }, // eslint-disable-line max-len
  );

  if (errors) return authentication.addErrors(errors);

  const params = {
    email : containerThis.props.emailSentTo,
    ...values,
  };

  return authentication
    .verifyOtp(params)
    .then(({ payload }) => {
      if (payload && payload.errors) return;

      const { userId } = containerThis.props;

      if (userId) analytics.identify(userId);

      // For faster tests
      const isRailsEnvTest = process.env.RAILS_ENV === 'test';

      if (isRailsEnvTest && !verifyOtpCallback) {
        return containerThis.setState({ isVerifyingOtp : false });
      }

      if (!isRailsEnvTest && !verifyOtpCallback) {
        containerThis.setState({ shouldFireConfetti : true });
      }

      if (verifyOtpCallback) return verifyOtpCallback();

      containerThis.setState({ isVerifyingOtp : false });
    });
}
