import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as quizzesActions from 'src/actions/quizzes';
import * as studySetsActions from 'src/actions/studySets';

import OnboardingSampleQuiz from 'src/components/onboarding/SampleQuiz';

class FunctionalSampleQuiz extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    isActive : PropTypes.bool,
    match    : basePropTypes.match.isRequired,
    newest   : quizPropTypes.quiz.isRequired,
  };

  componentDidMount() {
    const { quizzes } = this.props.actions;

    return quizzes
      .fetchNewest()
      .then(data => {
        const { payload : { quiz : { id } } } = data;

        quizzes.addToBeAnsweredQuestions(id);
      });
  }

  render() {
    const { isActive, match, newest } = this.props;

    if (newest && !newest.get('isSampleQuiz')) {
      return (
        <Redirect to={ { pathname : '/' } } />
      );
    }

    return (
      <OnboardingSampleQuiz
        isActive={ isActive }
        match={ match }
        newest={ newest }
      />
    );
  }
}

function mapStateToProps({ quizzes }) {
  return {
    isActive : quizzes.get('isActive'),
    newest   : quizzes.getIn(['loaded', 'newest']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      quizzes   : bindActionCreators(quizzesActions, dispatch),
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalSampleQuiz);
