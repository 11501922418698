import React, { memo, useRef } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as searchPropTypes from 'src/constants/propTypes/search';

import buildRoute from 'src/components/shared/buildRoute';
import SearchField from 'src/components/fields/SearchField';

import styles from './styles.module.scss';

function SearchContainer(props) {
  const {
    history,
    setIsLoading,
  } = props;

  const searchRef = useRef(null);

  async function search(searchQuery) {
    const route = buildRoute(searchQuery);
    return history.push(route);
  }

  return (
    <div className={ styles.Root }>
      <SearchField
        placeholder="cat, gato, chatte, חתול, кот, etc"
        search={ search }
        searchRef={ searchRef }
        setIsLoading={ setIsLoading }
      />
    </div>
  );
}

SearchContainer.propTypes = {
  history      : basePropTypes.history.isRequired,
  setIsLoading : searchPropTypes.setIsLoading.isRequired,
};

export default memo(SearchContainer);
