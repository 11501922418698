import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import HorizontalLine from 'src/components/HorizontalLine';
import StatsCard from 'src/components/Stats/Card';

import AnswersMemorized from '../AnswersMemorized';
import QuestionsStudied from '../QuestionsStudied';
import Tabs from '../Tabs';
import styles from './styles.module.scss';

function GroupOne(props) {
  const {
    answersMemorized,
    isEmpty,
    questionsStudied,
    totals,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = [
    {
      dataAutomatedTest : 'totals--questions-studied',
      label             : 'Questions studied',
      index             : 0,
      value             : totals.get('questionsStudied'),
    },
    {
      dataAutomatedTest : 'totals--answers-memorized',
      label             : 'Answers memorized',
      index             : 1,
      value             : totals.get('answersMemorized'),
    },
  ];

  return (
    <StatsCard>
      <div className={ styles.Root }>
        <Tabs
          activeIndex={ activeIndex }
          setActiveIndex={ setActiveIndex }
          tabs={ tabs }
        />

        <div className={ styles.HorizontalLine }>
          <HorizontalLine />
        </div>

        <Choose>
          <When condition={ activeIndex === 0 }>
            <QuestionsStudied isEmpty={ isEmpty } report={ questionsStudied } />
          </When>

          <Otherwise>
            <AnswersMemorized isEmpty={ isEmpty } report={ answersMemorized } />
          </Otherwise>
        </Choose>
      </div>
    </StatsCard>
  );
}

GroupOne.propTypes = {
  answersMemorized : statsPropTypes.answersMemorized.isRequired,
  isEmpty          : PropTypes.bool,
  questionsStudied : statsPropTypes.questionsStudied.isRequired,
  totals           : statsPropTypes.totals.isRequired,
};

export default GroupOne;
