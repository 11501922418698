// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___WIUqM{display:flex;flex-direction:column;grid-gap:16px;gap:16px;padding-top:16px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Stats/Charts/AnswersMemorized/Percent/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,gBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding-top: 16px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___WIUqM"
};
module.exports = exports;
