import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as paymentPropTypes from 'src/constants/propTypes/payment';
import * as profilePropTypes from 'src/constants/propTypes/profile';

import fireConfetti from 'src/components/shared/fireConfetti';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';

import Plans from './Plans';
import styles from './styles.module.scss';

function Account(props) {
  const {
    accountPlan,
    givenFreeAccess,
    isActive,
    location,
    needsGooglePlayBilling,
    paymentsIsActive,
    upgradeAccount,
    upgradeAccountWithGooglePlayBilling,
    upgradeTo45DaysWithGooglePlayBilling,
    upgradeToYearlyWithGooglePlayBilling,
    upgradeToLifetimeWithGooglePlayBilling,
  } = props;

  function getInitialPlanType() {
    if (givenFreeAccess) return 2;

    switch (accountPlan.get('planType')) {
      case 'basic':
        return 0;
      case 'premium':
        return 1;
      case 'premium_lifetime':
        return 2;
      case 'premium_45_days':
        return 3;
      default:
        return 0;
    }
  }

  const [planType, setPlanType] = useState(getInitialPlanType());
  const [periodEnd, setPeriodEnd] = useState(accountPlan.get('periodEnd'));

  function updatePeriodEndWithDate(urlPlanType) {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    if (urlPlanType === 3) {
      date = new Date();
      date.setDate(date.getDate() + 45);
    }

    const options = { year : 'numeric', month : 'long', day : 'numeric' };
    const formatted = date.toLocaleDateString('en-US', options);
    return setPeriodEnd(formatted);
  }

  function handleSuccessfullPayment(urlParams) {
    fireConfetti(4000);
    const urlPlanType = Number(urlParams.get('plan_type'));
    setPlanType(urlPlanType);

    if (![1, 3].includes(urlPlanType)) return setPeriodEnd(null);

    return updatePeriodEndWithDate(urlPlanType);
  }

  const { search } = location;

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    if (urlParams.get('success') === 'true') {
      handleSuccessfullPayment(urlParams);
    }
  }, [search]);

  const isLoading = isActive;

  function getPlanName() {
    if (givenFreeAccess) return 'Premium (Lifetime)';

    switch (planType) {
      case 0:
        return 'Basic';
      case 1:
        return 'Premium (1 Year)';
      case 2:
        return 'Premium (Lifetime)';
      case 3:
        return 'Premium (45 Days)';
      default:
        return 'Basic';
    }
  }

  return (
    <Choose>
      <When condition={ isLoading }>
        <PulseLoadingIndicator isLoading={ isLoading }/>
      </When>

      <Otherwise>
        <div className={ styles.Root }>
          <h1 className={ styles.Title }>
            You are currently enrolled in the <strong>{ getPlanName() }</strong> plan
          </h1>

          <Choose>
            <When condition={ periodEnd }>
              <p className={ styles.TitleSubText }>
                Your plan will expire on <strong>{ periodEnd }</strong>
              </p>
            </When>

            <When condition={ givenFreeAccess }>
              <p className={ styles.TitleSubText }>
                Thank you <strong>SO MUCH</strong> for being an early user!
              </p>
            </When>

            <When condition={ planType === 2 }>
              <p className={ styles.TitleSubText }>
                Thank you <strong>SO MUCH</strong> for your support!
              </p>
            </When>
          </Choose>

          <div className={ styles.ShortHorizontalSeparator } />

          <Plans
            isActive={ paymentsIsActive }
            needsGooglePlayBilling={ needsGooglePlayBilling }
            planType={ planType }
            upgradeAccount={ upgradeAccount }
            upgradeAccountWithGooglePlayBilling={ upgradeAccountWithGooglePlayBilling }
            upgradeTo45DaysWithGooglePlayBilling={ upgradeTo45DaysWithGooglePlayBilling }
            upgradeToYearlyWithGooglePlayBilling={ upgradeToYearlyWithGooglePlayBilling }
            upgradeToLifetimeWithGooglePlayBilling={ upgradeToLifetimeWithGooglePlayBilling }
          />

          <p className={ styles.MoneyBackGuarantee }>
            All of our Premium plans come with a <strong>30 day money back guarantee</strong>.
            If you are not satisfied with the product, send us an email at{ ' ' }
            <a href="mailto:admin@dorothymemoryapp.com">admin@dorothymemoryapp.com</a>
            { ' ' }and we will refund your payment, no questions asked.
          </p>
        </div>
      </Otherwise>
    </Choose>
  );
}

Account.propTypes = {
  accountPlan      : paymentPropTypes.accountPlan.isRequired,
  currentPeriodEnd : profilePropTypes.currentPeriodEnd,
  givenFreeAccess  : profilePropTypes.givenFreeAccess,
  isActive         : basePropTypes.isActive,
  location         : basePropTypes.location.isRequired,
  paymentsIsActive : PropTypes.bool,
  upgradeAccount   : PropTypes.func.isRequired,
};

export default Account;
