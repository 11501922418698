import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function SwitchSlider(props) {
  const {
    defaultChecked,
    disabled,
    hint,
    name,
    onChecked,
    onUnchecked,
    register,
  } = props;

  const [isChecked, setIsChecked] = useState(defaultChecked);
  const [canDoCssAnimation, setCanDoCssAnimation] = useState(false);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked, setIsChecked]);

  function handleChange({ target : { checked } }) {
    setCanDoCssAnimation(true);
    setIsChecked(checked);

    if (checked && onChecked) return onChecked();
    if (onUnchecked) return onUnchecked();
  }

  const inputRef = useRef(null);

  function setUpRef(el) {
    if (register) {
      register(el);
      inputRef.current = el;
    }
  }

  const checkboxClassNames = cx({
    Checkbox   : true,
    isChecked,
    isDisabled : disabled,
  });

  const sliderClassNames = cx({
    Slider     : true,
    isChecked,
    isDisabled : disabled,
  });

  const circleClassNames = cx({
    Circle     : true,
    canDoCssAnimation,
    isChecked,
    isDisabled : disabled,
  });

  return (
    <label
      data-automated-test="switch-label"
      data-rh={ hint }
      name={ name }
    >
      <input
        checked={ isChecked }
        className={ checkboxClassNames }
        disabled={ disabled }
        name={ name }
        onChange={ handleChange }
        ref={ setUpRef }
        type="checkbox"
      />

      <div className={ sliderClassNames }>
        <div className={ circleClassNames } />
      </div>
    </label>
  );
}

SwitchSlider.propTypes = {
  defaultChecked : basePropTypes.defaultChecked,
  disabled       : basePropTypes.disabled,
  hint           : PropTypes.string,
  name           : basePropTypes.name.isRequired,
  onChecked      : basePropTypes.onChecked,
  onUnchecked    : basePropTypes.onUnchecked,
  register       : basePropTypes.register,
};

export default SwitchSlider;
