import { PropTypes } from 'prop-types';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import Chart from 'src/components/charts/Line';
import StatsPlaceholder from 'src/components/StatsPlaceholder';

import styles from './styles.module.scss';

function LineChart(props) {
  const {
    isEmpty,
    report,
  } = props;

  const dataPoints = report && report.get('dataPoints');
  const data = dataPoints && dataPoints.toJS();

  return (
    <Choose>
      <When condition={ isEmpty }>
        <div className={ styles.StatsPlaceholder }>
          <StatsPlaceholder titleNoun="chart" />
        </div>
      </When>

      <Otherwise>
        <div className={ styles.Chart }>
          <Chart data={ data } />
        </div>
      </Otherwise>
    </Choose>
  );
}

LineChart.propTypes = {
  isEmpty : PropTypes.bool,
  report  : statsPropTypes.dataPointsReport.isRequired,
};

export default LineChart;
