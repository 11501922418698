import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import ButtonDiv from 'src/components/buttons/ButtonDiv';

import styles from './styles.module.scss';

function ExitLink({ exitOverrideAction, to }) {
  if (exitOverrideAction) {
    return (
      <ButtonDiv
        dataAutomatedTest="header--right-side--exit-link"
        extraClassNames={ `${styles.Root} ${styles.RootExtraSpecificity}` }
        handleClick={ exitOverrideAction }
        isTertiary
        value="exit"
      />
    );
  }

  return (
    <Link
      className={ styles.Root }
      data-automated-test="header--right-side--exit-link"
      to={ to || '/' }
    >
      exit
    </Link>
  );
}

ExitLink.propTypes = {
  exitOverrideAction : PropTypes.func,
  to                 : basePropTypes.to,
};

export default ExitLink;
