import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

function SuccessMessage({ fromNew }) {
  return (
    <p className={ styles.Root }>
      <span className={ styles.SuccessEmphasis }>Your question was successfully saved! </span>

      <If condition={ fromNew }>
        We will practice memorizing its answer starting
        <span className={ styles.SuccessEmphasis }> tomorrow</span>.
      </If>
    </p>
  );
}

SuccessMessage.propTypes = {
  fromNew : basePropTypes.fromNew,
};

export default SuccessMessage;
