import { PropTypes } from 'prop-types';
import React, { memo } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';

import Result from './Result';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ResultsList(props) {
  const {
    basePath,
    children,
    currentFilterParam,
    hasQuietTitle,
    hasSortOptions,
    isLoading,
    resultName,
    results,
    subTitle,
    titleLinkNode,
  } = props;

  const { size } = results;

  function buildSizeTitle() {
    return `${size} ${resultName}${size === 1 ? '' : 's'}`;
  }

  function getResultsTitleContainerClassNames() {
    return cx({
      ResultsTitleContainer : true,
      hasQuietTitle,
      hasSortOptions,
    });
  }

  return (
    <Choose>
      <When condition={ isLoading }>
        <div className={ styles.PulseLoader }>
          <PulseLoadingIndicator isLoading={ isLoading }/>
        </div>
      </When>

      <When condition={ currentFilterParam && size === 0 }>
        <div className={ getResultsTitleContainerClassNames() }>
          <h3>0 { resultName }s</h3>
        </div>

        <p className={ styles.NoResultsTitle }>No results found with that filter</p>
      </When>

      <Otherwise>
        <div className={ styles.Root }>
          <div className={ getResultsTitleContainerClassNames() }>
            <h3>{ buildSizeTitle() }</h3>

            <If condition={ titleLinkNode }>
              { titleLinkNode }
            </If>
          </div>

          <If condition={ subTitle }>
            <p className={ styles.ResultsSubTitle }>{ subTitle }</p>
          </If>

          <div className={ styles.ResultsContainer }>
            <Choose>
              <When condition={ children }>
                { children }
              </When>

              <Otherwise>
                <ul>
                  <For each="result" index="i" of={ results }>
                    <Result
                      basePath={ basePath }
                      key={ result.get('id') }
                      result={ result }
                      src={ result.get('imageUrl') || result.getIn(['answer', 'imageUrl']) }
                    />
                  </For>
                </ul>
              </Otherwise>
            </Choose>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
}

ResultsList.propTypes = {
  basePath           : PropTypes.string,
  children           : PropTypes.node,
  currentFilterParam : PropTypes.string,
  hasQuietTitle      : PropTypes.bool,
  hasSortOptions     : PropTypes.bool,
  isLoading          : PropTypes.bool,
  resultName         : PropTypes.string.isRequired,
  results            : basePropTypes.results.isRequired,
  subTitle           : PropTypes.string,
  titleLinkNode      : PropTypes.node,
};

export default memo(ResultsList);
