// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3v2ET{margin-top:36px;text-align:center}.styles-module__EditQuestionLinkContainer___-XueK{margin:45px 0 0;text-align:center}.styles-module__EditQuestionLink___1xAb6{color:rgba(0,0,0,.65);display:inline-block;text-decoration:none}.styles-module__EditQuestionLink___1xAb6 svg{height:21px;margin:-8px -3px -7px -3px;width:21px}.styles-module__EditQuestionLink___1xAb6 svg path{fill:rgba(0,0,0,.65)}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/quiz/Question/AnswerContent/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CACA,iBAAA,CAEF,kDACE,eAAA,CACA,iBAAA,CAEF,yCACE,qBAAA,CACA,oBAAA,CACA,oBAAA,CACA,6CACE,WAAA,CACA,0BAAA,CACA,UAAA,CACA,kDACE,oBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin-top: 36px;\n  text-align: center; }\n\n.EditQuestionLinkContainer {\n  margin: 45px 0 0;\n  text-align: center; }\n\n.EditQuestionLink {\n  color: rgba(0, 0, 0, 0.65);\n  display: inline-block;\n  text-decoration: none; }\n  .EditQuestionLink svg {\n    height: 21px;\n    margin: -8px -3px -7px -3px;\n    width: 21px; }\n    .EditQuestionLink svg path {\n      fill: rgba(0, 0, 0, 0.65); }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3v2ET",
	"EditQuestionLinkContainer": "styles-module__EditQuestionLinkContainer___-XueK",
	"EditQuestionLink": "styles-module__EditQuestionLink___1xAb6"
};
module.exports = exports;
