import React, { PureComponent } from 'react';

import ContactUs from 'src/components/profile/ContactUs';

class FunctionalContactUs extends PureComponent {
  render() {
    return (
      <ContactUs { ...this.props } />
    );
  }
}

export default FunctionalContactUs;
