import gql from 'graphql-tag';

import dataPointFields from 'src/graphql/fragments/dataPointFields';

export default gql`
  query statsFullReport(
    $timeFrame: String
  ) {
    statsFullReport(
      timeFrame: $timeFrame
    ) {
      hasReversedQuestions
      isEmpty
      totals {
        answersMemorized
        avgFutureRetention
        avgStudyTime
        questionsStudied
      }
      questionsStudied {
        total
        today
        last7Days
        last30Days
        quizStreak
        longestQuizStreak
        dataPoints {
          ...dataPointFields
        }
      }
      answersMemorized {
        totals {
          currentlyMemorized
          dataPoints {
            ...dataPointFields
          }
        }
        percent {
          answersMemorized
          currentlyMemorized
          questionsBeingStudied
          dataPoints {
            ...dataPointFields
          }
        }
      }
      avgFutureRetention {
        current
        dataPoints {
          ...dataPointFields
        }
      }
      avgStudyTime {
        allTime
        last7Days
        last30Days
        dataPoints {
          ...dataPointFields
        }
      }
    }
  }
  ${dataPointFields}
`;
