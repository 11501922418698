import createQuestion from 'src/graphql/mutations/questions/createQuestion';
import deleteAnswerAudio from 'src/graphql/mutations/questions/deleteAnswerAudio';
import deleteAnswerImage from 'src/graphql/mutations/questions/deleteAnswerImage';
import deleteQuestion from 'src/graphql/mutations/questions/deleteQuestion';
import deleteQuestionAudio from 'src/graphql/mutations/questions/deleteQuestionAudio';
import deleteQuestionImage from 'src/graphql/mutations/questions/deleteQuestionImage';
import showQuestion from 'src/graphql/queries/questions/showQuestion';
import updateQuestion from 'src/graphql/mutations/questions/updateQuestion';

import post from './shared/post';

export default {
  create(values) {
    return post(values, createQuestion);
  },

  deleteQuestion(values) {
    return post(values, deleteQuestion);
  },

  deleteAnswerAudio(values) {
    return post(values, deleteAnswerAudio);
  },

  deleteAnswerImage(values) {
    return post(values, deleteAnswerImage);
  },

  deleteAudio(values) {
    return post(values, deleteQuestionAudio);
  },

  deleteImage(values) {
    return post(values, deleteQuestionImage);
  },

  show(values) {
    return post(values, showQuestion);
  },

  update(values) {
    return post(values, updateQuestion);
  },
};
