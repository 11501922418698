import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React, { memo, useState } from 'react';

import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as statsPropTypes from 'src/constants/propTypes/stats';

import LetUsKnowModal from 'src/components/Dashboard/OpeningContent/LetUsKnowModal';

import InfoIcon from 'src/components/icons/Info';
import NextQuizDueDate from './NextQuizDueDate';
import styles from './styles.module.scss';

function OpeningPara(props) {
  const {
    completedSampleQuizToday,
    dueDate,
    maxQuestionAmountPerQuiz,
    quiz,
    statsDashboardReport,

    isOnFirstQuizAndNotStarted,
    isSampleQuiz,
    noQuestionsInQuizzes,
    prevQuizWasRecentlyCompleted,
    quizCompleted,
    quizIsDueTodayAndNotStarted,
    quizIsDueTodayAndStarted,
    quizIsDueInFutureAndNotStarted,
    quizIsDueInFutureAndStarted,
  } = props;

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  function openFeedbackModal() { setIsFeedbackModalOpen(true); }
  function closeFeedbackModal() { setIsFeedbackModalOpen(false); }

  function getNextQuizText(key) {
    return <NextQuizDueDate dueDate={ dueDate } key={ key } quiz={ quiz } />;
  }

  const questionsSize = quiz && quiz.get('questions') && quiz.get('questions').size;

  function getQuestionsNotMemorized() {
    if (!statsDashboardReport) return 0;

    const questionsCurrentlyBeingStudied = statsDashboardReport
      .get('questionsCurrentlyBeingStudied');

    if (questionsCurrentlyBeingStudied === 0) return 0;

    const answersBeingStudiedMemorized = statsDashboardReport.get('answersBeingStudiedMemorized');

    return questionsCurrentlyBeingStudied - answersBeingStudiedMemorized;
  }

  const questionsNotMemorized = getQuestionsNotMemorized();
  const questionsMemorized = questionsSize - questionsNotMemorized;

  function getSubMessageForDueAndNotStarted() {
    if (
      questionsSize === maxQuestionAmountPerQuiz ||
      questionsMemorized === 0 ||
      questionsMemorized < 0
    ) {
      return 'to keep memorized or re-memorize their answers.';
    }

    if (questionsNotMemorized > 0 && questionsMemorized > 0) {
      const plural = questionsMemorized === 1 ? '' : 's';

      return (
        `to keep ${questionsMemorized} of your answer${plural} memorized ` +
        `and re-memorize the other ${questionsNotMemorized}.`
      );
    }

    return 'to keep your answers memorized.';
  }

  return (
    <>
      <If condition={ isFeedbackModalOpen }>
        <LetUsKnowModal closeModal={ closeFeedbackModal } />
      </If>

      <p className={ styles.Root }>
        <Choose>
          <When condition={ quizIsDueTodayAndNotStarted }>
            Your quiz for today is ready.

            <If condition={ !!questionsSize }>
              { ' ' }Complete { questionsSize === 1 ? 'your' : 'all' } <strong>
                { questionsSize } question{ questionsSize === 1 ? '' : 's' }
              </strong>{ '  ' }

              { getSubMessageForDueAndNotStarted() }
            </If>
          </When>

          <When condition={ quizIsDueTodayAndStarted }>
            Today’s quiz is ready and in progress.
          </When>

          <When condition={ noQuestionsInQuizzes }>
            You currently do not have any questions being studied.

            <strong>
              { ' ' }This is because you have chosen study set(s) that do not have questions.{ ' ' }
            </strong>

            Visit your{ ' ' }
            <Link className={ styles.Link } to="/profile/preferences">preferences</Link>{ ' ' }
            to change which study sets will have their questions appear in your quizzes.
          </When>

          <When condition={ isOnFirstQuizAndNotStarted }>
            Your very first quiz will be ready in
            { ' ' }{ getNextQuizText(1) }

            <span
              className={ styles.InfoIconSpan }
              data-rh="
                Since you just added questions, we don’t expect you to forget their
                answers for a while. That’s why we’ll quiz you on them for the first time tomorrow.
              "
            >
              <InfoIcon />
            </span>
          </When>

          <When condition={ isSampleQuiz && completedSampleQuizToday }>
            That’s all for today! We’ll notify you when your next quiz is ready.

            <br />
            In the meantime, feel free to add more study sets.

            If you have any questions, please{ ' ' }

            <span
              className={ styles.LetUsKnowLink }
              data-automated-test="dashboard--opening-para--let-us-know-span"
              onClick={ openFeedbackModal }
            >let us know</span>.
          </When>

          <When condition={ completedSampleQuizToday }>
            Your quiz will be ready in{ ' ' }
            { getNextQuizText(1) }{ ' ' }
            We will notify you when it’s time.

            <br />
            In the meantime, feel free to add more study sets!

            If you have any questions, please{ ' ' }

            <span
              className={ styles.LetUsKnowLink }
              data-automated-test="dashboard--opening-para--let-us-know-span"
              onClick={ openFeedbackModal }
            >let us know</span>.
          </When>

          <When condition={ prevQuizWasRecentlyCompleted }>
            Today’s quiz has been completed and you’re all set for now.{ ' ' }
            Your next quiz will be ready in { getNextQuizText(2) }{ ' ' }
          </When>

          <When condition={ quizIsDueInFutureAndStarted || quizIsDueInFutureAndNotStarted }>
            You’re all set for now.{ ' ' }
            Your next quiz will be ready in { getNextQuizText(3) }{ ' ' }
          </When>

          <When condition={ quizCompleted }>
            Today’s quiz has been completed and you’re all set for now.{ ' ' }
            We’ll notify you when your next quiz is ready.
          </When>

          <Otherwise>
            You’re all set for now. We’ll notify you when your next quiz is ready.
          </Otherwise>
        </Choose>
      </p>

      <Choose>
        <When condition={ isOnFirstQuizAndNotStarted }>
          <p className={ styles.Root }>
            We will notify you when the quiz is ready. In the meantime, you can add more study sets
            if you have anything else you want to memorize or you can take the sample quiz.

            If you have any questions, please{ ' ' }

            <span
              className={ styles.LetUsKnowLink }
              data-automated-test="dashboard--opening-para--let-us-know-span"
              onClick={ openFeedbackModal }
            >let us know</span>.
          </p>
        </When>
      </Choose>
    </>
  );
}

OpeningPara.propTypes = {
  completedSampleQuizToday       : PropTypes.bool,
  dueDate                        : quizPropTypes.dueDate.isRequired,
  isOnFirstQuizAndNotStarted     : quizPropTypes.isOnFirstQuizAndNotStarted,
  isSampleQuiz                   : PropTypes.bool,
  maxQuestionAmountPerQuiz       : PropTypes.number,
  quiz                           : quizPropTypes.quiz.isRequired,
  noQuestionsInQuizzes           : quizPropTypes.noQuestionsInQuizzes,
  prevQuizWasRecentlyCompleted   : quizPropTypes.prevQuizWasRecentlyCompleted,
  quizCompleted                  : quizPropTypes.quizCompleted,
  quizIsDueTodayAndNotStarted    : quizPropTypes.quizIsDueTodayAndNotStarted,
  quizIsDueTodayAndStarted       : quizPropTypes.quizIsDueTodayAndStarted,
  quizIsDueInFutureAndNotStarted : quizPropTypes.quizIsDueInFutureAndNotStarted,
  quizIsDueInFutureAndStarted    : quizPropTypes.quizIsDueInFutureAndStarted,
  statsDashboardReport           : statsPropTypes.dashboardReport.isRequired,
};

export default memo(OpeningPara);
