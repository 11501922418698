import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';
import CloseIcon from 'src/components/icons/Close';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function BaseModal(props) {
  const {
    children,
    closeModal,
    dataAutomatedTest,
    extraClasses,
    hasCloseButton,
    shouldCloseOnOverlayClick = true,
  } = props;

  function handleKeyDown(event) {
    if (event.key !== 'Escape') return;

    closeModal();
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  function getContainerClassNames() {
    return cx({
      Container : true,
      hasCloseButton,
    });
  }

  const extraContainerClass = extraClasses && extraClasses.container;

  return (
    <div
      className={ styles.Root }
      data-automated-test={ dataAutomatedTest }
    >
      <div
        className={ styles.Overlay }
        onClick={ shouldCloseOnOverlayClick ? closeModal : undefined }
      />

      <div className={ `${getContainerClassNames()} ${extraContainerClass}` }>
        <If condition={ hasCloseButton }>
          <div
            className={ styles.CloseButton }
            data-automated-test="modal__close-button"
            onClick={ closeModal }
          >
            <CloseIcon />
          </div>
        </If>

        { children }
      </div>
    </div>
  );
}

BaseModal.propTypes = {
  children                  : basePropTypes.children.isRequired,
  closeModal                : basePropTypes.closeModal.isRequired,
  dataAutomatedTest         : PropTypes.string,
  extraClasses              : basePropTypes.extraClasses,
  hasCloseButton            : basePropTypes.hasCloseButton,
  shouldCloseOnOverlayClick : basePropTypes.shouldCloseOnOverlayClick,
};

export default BaseModal;
