// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2a5hh{display:flex;flex-direction:row;justify-content:flex-start;grid-gap:24px;gap:24px}.styles-module__LogoContainer___36coK{display:flex;flex-direction:column;justify-content:center;min-width:79px}.styles-module__LogoContainer___36coK svg{margin:0 auto}.styles-module__LogoContainer___36coK svg path{fill:#2071aa}.styles-module__TextContainer___3mznq{display:flex;flex-direction:column;justify-content:center;grid-gap:8px;gap:8px;line-height:normal;line-height:initial}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/authentication/Welcome/InfoSection/Benefit/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,aAAA,CAAA,QAAA,CAEF,sCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,cAAA,CACA,0CACE,aAAA,CACA,+CACE,YAAA,CAEN,sCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,YAAA,CAAA,OAAA,CACA,kBAAA,CAAA,mBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  gap: 24px; }\n\n.LogoContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-width: 79px; }\n  .LogoContainer svg {\n    margin: 0 auto; }\n    .LogoContainer svg path {\n      fill: #2071aa; }\n\n.TextContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 8px;\n  line-height: initial; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2a5hh",
	"LogoContainer": "styles-module__LogoContainer___36coK",
	"TextContainer": "styles-module__TextContainer___3mznq"
};
module.exports = exports;
