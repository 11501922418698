import { Link } from 'react-router-dom';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as searchPropTypes from 'src/constants/propTypes/search';

import buildRoute from 'src/components/shared/buildRoute';
import classNames from 'src/components/shared/classNames';
import getUrlParameter from 'src/shared/getUrlParameter';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function SortOption({ defaultSortedBy, name, sort }) {
  const currentSortParam = getUrlParameter('sort') || defaultSortedBy;

  function getRootClassNames() {
    return cx({
      Root       : true,
      isSelected : currentSortParam === sort,
    });
  }

  return (
    <li className={ getRootClassNames() }>
      <Link to={ buildRoute(null, sort) }>
        { name }
      </Link>
    </li>
  );
}

SortOption.propTypes = {
  defaultSortedBy : searchPropTypes.defaultSortedBy.isRequired,
  name            : basePropTypes.name.isRequired,
  sort            : searchPropTypes.sort.isRequired,
};

export default SortOption;
