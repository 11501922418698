import { PropTypes } from 'prop-types';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import InfoIcon from 'src/components/icons/Info';

import styles from './styles.module.scss';

function Stat(props) {
  const {
    noHint,
    stat,
  } = props;

  const { hint } = stat;

  return (
    <div
      className={ styles.Root }
      data-automated-test={ stat.dataAutomatedTest }
    >
      <p className={ styles.Title }>
        { stat.title }

        <If condition={ !!hint && !noHint }>
          <span
            className={ styles.InfoIconSpan }
            data-rh={ hint }
          >
            <InfoIcon />
          </span>
        </If>
      </p>

      <div
        className={ styles.ValueContainer }
        data-rh={ hint }
      >
        <p className={ styles.Value }>{ stat.value }</p>
        <p className={ styles.SubValue }>{ stat.subValue }</p>
      </div>
    </div>
  );
}

Stat.propTypes = {
  noHint : PropTypes.bool,
  stat   : statsPropTypes.stat.isRequired,
};

export default Stat;
