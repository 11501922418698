import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Tabs(props) {
  const {
    activeIndex,
    setActiveIndex,
    tabs,
  } = props;

  return (
    <div
      className={ styles.Root }
      data-automated-test="charts-tabs"
    >
      <For each="tab" of={ tabs }>
        <div
          className={ cx({ Tab : true, isActive : activeIndex === tab.index }) }
          data-automated-test={ tab.dataAutomatedTest }
          key={ tab.index }
          onClick={ () => setActiveIndex(tab.index) }
        >
          { tab.label } <span>({ tab.value })</span>
        </div>
      </For>
    </div>
  );
}

Tabs.propTypes = {
  activeIndex    : PropTypes.number.isRequired,
  setActiveIndex : PropTypes.func.isRequired,
  tabs           : basePropTypes.tabs.isRequired,
};

export default Tabs;
