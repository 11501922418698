import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as profileActionCreators from 'src/actions/profile';
import * as studySetsActions from 'src/actions/studySets';

import Preferences from 'src/components/profile/Preferences';

class FunctionalPreferences extends PureComponent {
  static propTypes = {
    actions : basePropTypes.actions.isRequired,
    errors  : basePropTypes.errors,
  };

  componentDidMount() {
    this.props.actions.studySets.getAll();
  }

  handleMaxQuestionAmountSave = values => {
    const params = values;

    try {
      params.maxQuestionAmountPerQuiz = Number(params.maxQuestionAmountPerQuiz);
    } catch (_) {
      // NOTE. This should never happen. But if it does, I don't
      // want everything to break.
      return;
    }

    return this.props.actions.profile
      .update(params)
      .catch(() => null);
  }

  handleChooseStudySetsSave = ({ studySets }) => {
    const params = {
      studySetIds : studySets && studySets.map(ss => ss.value),
    };

    return this.props.actions.profile.update(params);
  }

  render() {
    return (
      <Preferences
        handleChooseStudySetsSave={ this.handleChooseStudySetsSave }
        handleMaxQuestionAmountSave={ this.handleMaxQuestionAmountSave }
        { ...this.props }
      />
    );
  }
}

function mapStateToProps({ profile, studySets }) {
  return {
    allStudySets             : studySets.getIn(['loaded', 'all']),
    chosenStudySets          : profile.get('studySets'),
    errors                   : profile.get('errors'),
    isUpdating               : profile.get('isUpdating'),
    maxQuestionAmountPerQuiz : profile.get('maxQuestionAmountPerQuiz'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      profile   : bindActionCreators(profileActionCreators, dispatch),
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalPreferences);
