import { PropTypes } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function Benefit(props) {
  const {
    children,
    logo,
    title,
  } = props;

  return (
    <div className={ styles.Root }>
      <div className={ styles.LogoContainer }>
        { logo }
      </div>

      <div className={ styles.TextContainer }>
        <h3>{ title }</h3>
        <p>{ children }</p>
      </div>
    </div>
  );
}

Benefit.propTypes = {
  children : PropTypes.node.isRequired,
  logo     : PropTypes.node.isRequired,
  title    : PropTypes.string.isRequired,
};

export default Benefit;
