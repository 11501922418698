import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as authenticationActions from 'src/actions/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildSignInPropsContext from 'src/containers/public/shared/buildSignInPropsContext';
import getUrlParameter from 'src/shared/getUrlParameter';
import PublicPublicStudySetsAll from 'src/components/public/publicStudySets/All';
import SignInPropsContext from 'src/contexts/SignInProps';

class FunctionalAll extends PureComponent {
  static propTypes = {
    actions   : basePropTypes.actions.isRequired,
    isActive  : basePropTypes.isActive,
    isAuthed  : PropTypes.bool,
    location  : basePropTypes.location.isRequired,
    match     : basePropTypes.match.isRequired,
    studySets : studySetPropTypes.studySets.isRequired,
  };

  state = {
    isVerifyingOtp         : false,
    shouldDisplayVerifyOtp : false,
    shouldFireConfetti     : false,
  }

  componentDidMount() {
    return this.searchForPublicStudySets();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.searchForPublicStudySets();
    }
  }

  searchForPublicStudySets = () => {
    const params = { filter : getUrlParameter('filter') };
    return this.props.actions.publicStudySets.getAll(params);
  }

  render() {
    if (this.props.isAuthed && !this.state.isVerifyingOtp) {
      return (
        <Redirect
          to={ {
            pathname : '/public-study-sets',
          } }
        />
      );
    }

    return (
      <SignInPropsContext.Provider value={ buildSignInPropsContext(this) }>
        <PublicPublicStudySetsAll
          isActive={ this.props.isActive }
          location={ this.props.location }
          match={ this.props.match }
          studySets={ this.props.studySets }
        />
      </SignInPropsContext.Provider>
    );
  }
}

function mapStateToProps({ authentication, publicStudySets }) {
  return {
    authIsActive : authentication.get('isActive'),
    authErrors   : authentication.get('errors'),
    emailSentTo  : authentication.get('emailSentTo'),
    errors       : publicStudySets.get('errors'),
    isActive     : publicStudySets.get('isActive'),
    isAuthed     : !!(authentication.get('email') && authentication.get('accessToken')),
    studySets    : publicStudySets.getIn(['loaded', 'all']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication  : bindActionCreators(authenticationActions, dispatch),
      publicStudySets : bindActionCreators(publicStudySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalAll);
