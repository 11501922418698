import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetsActions from 'src/actions/studySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import buildQuestionValues from 'src/containers/studySets/shared/buildQuestionValues';
import navigateOrWait from 'src/containers/shared/navigateOrWait';
import NewStudySet from 'src/components/studySets/New';

class FunctionalNew extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    errors   : basePropTypes.errors,
    history  : basePropTypes.history.isRequired,
    match    : basePropTypes.match.isRequired,
    isActive : basePropTypes.isActive,
    isSaving : basePropTypes.isSaving,
    studySet : studySetPropTypes.autosavedStudySet.isRequired,
  };

  componentDidMount() {
    this.props.actions.studySets.clearTempState();
  }

  addQuestion = key => this.props.actions.studySets.addQuestionToNew(key)

  bulkAddQuestions = questions => this.props.actions.studySets.bulkAddQuestionsToNew(questions)

  handleSave = async values => {
    const {
      actions : { studySets },
      studySet,
    } = this.props;

    studySets.startSave();

    const questions = await buildQuestionValues(values, studySet.get('questions'));
    const params = { ...values, questions };

    return studySets
      .create(params)
      .then(data => {
        if (window.gtag) {
          window.gtag('event', 'conversion_in_product_site', { value : 0 });
        }

        this.navigateOrWait(data);
      })
      .catch(() => null);
  }

  navigateOrWait = ({ payload : { data : { id } } }) => {
    const navigate = navigateOrWait(
      this.props,
      `/study-sets/${id}`,
      {
        pathFrom          : '/study-sets/new',
        successfullySaved : true,
      },
    );

    if (navigate !== null) {
      this.props.actions.studySets.clearAutosavedNew();
    }
  }

  refreshData = () => this.props.actions.studySets.clearAutosavedNew()

  removeQuestion = key => this.props.actions.studySets.removeQuestionFromNew(key)

  render() {
    return (
      <NewStudySet
        addQuestion={ this.addQuestion }
        bulkAddQuestions={ this.bulkAddQuestions }
        errors={ this.props.errors }
        handleSubmit={ this.handleSave }
        history={ this.props.history }
        isActive={ this.props.isActive }
        isSaving={ this.props.isSaving }
        match={ this.props.match }
        removeQuestion={ this.removeQuestion }
        refreshData={ this.refreshData }
        studySet={ this.props.studySet }
        updateValueInAutosaved={ this.props.actions.studySets.updateValueInAutosaved }
      />
    );
  }
}

function mapStateToProps({ studySets }) {
  return {
    errors   : studySets.get('errors'),
    isActive : studySets.get('isActive'),
    isSaving : studySets.get('isSaving'),
    studySet : studySets.getIn(['autosaved', 'new']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      studySets : bindActionCreators(studySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalNew);
