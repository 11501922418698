import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import classNames from 'src/components/shared/classNames';
import DoubleArrowsReversedDirections from 'src/components/icons/DoubleArrowsReversedDirections';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ReversedQuestionSelector(props) {
  const {
    isActive,
    index,
    question,
    register,
    reversedCopiesTurnedOnFromOff,
    reversedQuestionsOptionsSelected,
    unregister,
  } = props;

  const isReversedCopyActive = question && question.get('isReversedCopyActive');

  function getInitialChecked() {
    if (reversedQuestionsOptionsSelected && isReversedCopyActive === undefined) return true;

    return !!(isReversedCopyActive || reversedCopiesTurnedOnFromOff);
  }

  const [isChecked, setIsChecked] = useState(getInitialChecked());

  useEffect(() => {
    if (!reversedQuestionsOptionsSelected) {
      return setIsChecked(false);
    }

    if (isReversedCopyActive || reversedCopiesTurnedOnFromOff) {
      return setIsChecked(true);
    }
  }, [isReversedCopyActive, reversedCopiesTurnedOnFromOff, reversedQuestionsOptionsSelected]);

  function handleChange({ target : { checked } }) {
    setIsChecked(checked);
  }

  const inputRef = useRef(null);

  function setUpRef(el) {
    if (register) {
      register(el);
      inputRef.current = el;
    }
  }

  const name = `questions[${index}][isReversedCopyActive]`;

  // NOTE: we are unregistering on the teardown because of how we currently
  // use keys. If we don't, we could, depending on certain events, end up
  // with `isReversedCopyActive` as an empty array which really throws
  // things off.
  useEffect(() => () => {
    if (!inputRef || !inputRef.current) return;
    if (unregister && name) unregister(name);
  }, []);

  // NOTE: I'm hiding it instead of using a conditional to not show
  // the elements because setting up a ref and registering it then
  // bring it down is an expensive process
  return (
    <label
      className={ cx({
        Root       : true,
        shouldHide : !reversedQuestionsOptionsSelected,
      }) }
      name={ name }
    >
      <input
        checked={ isChecked }
        className={ cx({
          Checkbox   : true,
          isChecked,
          isDisabled : isActive,
        }) }
        disabled={ isActive }
        name={ name }
        onChange={ handleChange }
        ref={ setUpRef }
        type="checkbox"
      />

      <div
        className={ cx({
          Icon              : true,
          isDisabled        : isActive,
          reversedCopiesTurnedOnFromOff,
        }) }
        data-automated-test="study-set-form--specific-reversed-question-icon"
        data-rh={ `
          If the icon is blue, that means a reversed copy of the question
          will appear in your daily quizzes. If it is gray, then a reversed
          copy of the question will NOT appear in your daily quizzes. If you
          do a flash study session, then this reversed copy will appear,
          regardless of color. You can click the icon to change the color.
        ` }
      >
        <DoubleArrowsReversedDirections />
      </div>
    </label>
  );
}

ReversedQuestionSelector.propTypes = {
  index                            : basePropTypes.index.isRequired,
  isActive                         : basePropTypes.isActive,
  question                         : studySetPropTypes.autosavedQuestion.isRequired,
  register                         : basePropTypes.register.isRequired,
  reversedCopiesTurnedOnFromOff    : PropTypes.bool,
  reversedQuestionsOptionsSelected : PropTypes.bool,
  unregister                       : PropTypes.func.isRequired,
};

export default ReversedQuestionSelector;
