import React, { useRef } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';

import styles from './styles.module.scss';

function FileInput(props) {
  const {
    accept,
    children,
    disabled,
    fileInputName,
    handleChange,
    register,
  } = props;

  const fileRef = useRef(null);

  function setUpFileRef(el) {
    if (register) register(el);
    fileRef.current = el;
  }

  const handleUploadClick = () => {
    if (disabled) return;
    fileRef.current.click();
  };

  return (
    <div>
      <label
        className={ styles.FileHtmlLabel }
        htmlFor={ fileInputName }
      >
        <input
          accept={ accept }
          name={ fileInputName }
          onChange={ handleChange }
          ref={ setUpFileRef }
          type="file"
        />
      </label>

      <div onClick={ handleUploadClick }>
        { children }
      </div>
    </div>
  );
}

FileInput.propTypes = {
  accept        : basePropTypes.accept.isRequired,
  children      : basePropTypes.children.isRequired,
  disabled      : basePropTypes.disabled,
  fileInputName : questionPropTypes.fileInputName.isRequired,
  handleChange  : basePropTypes.handleChange.isRequired,
  register      : basePropTypes.register,
};

export default FileInput;
