import buildTotalDaysMonthsText from 'src/components/shared/buildTotalDaysMonthsText';

export default function buildStats(report) {
  if (!report) return null;

  const completedQuizzesSize = report.get('completedQuizzesSize');

  if (!completedQuizzesSize) return null;

  const averageFutureRetention = report.get('avgFutureRetention');
  const quizStreak = report.get('quizStreak');
  const totalAnswersMemorized = report.get('totalAnswersMemorized');

  const retentionText = buildTotalDaysMonthsText(averageFutureRetention);

  /* eslint-disable max-len */
  return {
    averageFutureRetention : {
      dataAutomatedTest : 'stat--average-future-retention',
      hint              : `The average amount of time we expect you to retain each answer (${retentionText}), starting from the last time you were quizzed on it.`,
      title             : 'Average future retention',
      value             : retentionText,
    },
    quizStreak            : {
      dataAutomatedTest : 'stat--quiz-streak',
      hint              : 'This is the number of days in a row you have completed a quiz on the day it was due.',
      title             : 'Quiz streak',
      value             : `${quizStreak} days`,
    },
    totalAnswersMemorized : {
      hint              : 'An answer is considered memorized if 1. the future due date of its question has not passed and 2. You did not select “No” when asked “Did you recall the correct answer?” the last time you were quizzed on it.',
      dataAutomatedTest : 'stat--total-answers-currently-memorized',
      title             : 'Total answers memorized',
      value             : totalAnswersMemorized,
    },
  };
  /* eslint-enable max-len */
}
