import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';

import AudioUploadIcon from 'src/components/icons/AudioUpload';
import classNames from 'src/components/shared/classNames';
import FileInput from 'src/components/fields/FileInput';
import ImageUploadIcon from 'src/components/icons/ImageUpload';
import loadDataUrlAndCall from 'src/shared/loadDataUrlAndCall';
import QuestionThumbnail from 'src/components/QuestionThumbnail';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Field(props) {
  const {
    audioFileInputName,
    autosaveAudioKey,
    autosavedAudio,
    autosavedImage,
    autosaveImageKey,
    children,
    deleteAudio,
    deleteImage,
    handleFieldChange,
    imageFileInputName,
    id,
    isActive,
    register,
    savedAudioUrl,
    savedImageUrl,
    setValue,
  } = props;

  const [hasAudioBeenUploaded, setHasAudioBeenUploaded] = useState(false);
  const [uploadedImageSrc, setUploadedImageSrc] = useState(null);
  // NOTE: uploadedImageFiles is plural because the file input will always
  // give a file _List_ in its result.
  const [uploadedImageFiles, setUploadedImageFiles] = useState([]);

  const imageThumbnailSrc = uploadedImageSrc || savedImageUrl;

  const removeAudio = () => {
    // id will only be passed if coming from `study-sets/edit`
    if (deleteAudio && savedAudioUrl) deleteAudio(id);

    setHasAudioBeenUploaded(false);
    setValue(audioFileInputName, '');

    if (handleFieldChange) handleFieldChange(autosaveAudioKey, null);
  };

  const removeImage = () => {
    // id will only be passed if coming from `study-sets/edit`
    if (deleteImage && savedImageUrl) deleteImage(id);

    setUploadedImageFiles([]);
    setValue(imageFileInputName, '');

    if (handleFieldChange) handleFieldChange(autosaveImageKey, null);
    setUploadedImageSrc(null);
  };

  useEffect(() => {
    loadDataUrlAndCall(uploadedImageFiles)
      .then(({ io }) => setUploadedImageSrc(io));
  }, [
    loadDataUrlAndCall,
    setUploadedImageSrc,
    uploadedImageFiles,
  ]);

  useEffect(() => {
    if (!autosavedAudio && hasAudioBeenUploaded) return setHasAudioBeenUploaded(false);
    if (!autosavedAudio) return;

    setHasAudioBeenUploaded(true);
  }, [autosavedAudio]);

  useEffect(() => {
    if (!autosavedImage && uploadedImageSrc) return setUploadedImageSrc(null);
    if (!autosavedImage) return;

    const io = autosavedImage.get('io');

    if (!io) return;

    setTimeout(() => setUploadedImageSrc(io), 0); // to make sure it's after the render
  }, [autosavedImage]);

  const handleAudioInputChange = ({ target }) => {
    if (target.files.length === 0) setHasAudioBeenUploaded(false);
    setHasAudioBeenUploaded(true);
    removeImage();

    if (handleFieldChange) {
      loadDataUrlAndCall(target.files)
        .then(({ audioDurationInSeconds, io }) => {
          const { name, size, type } = target.files[0];

          handleFieldChange(
            autosaveAudioKey,
            { audioDurationInSeconds, io, name, size, type },
          );
        });
    }
  };

  const handleImageInputChange = useCallback(({ target }) => {
    if (target.files.length === 0) return;

    setUploadedImageFiles(target.files);
    removeAudio();

    if (handleFieldChange) {
      loadDataUrlAndCall(target.files)
        .then(({ io }) => {
          const { name, size, type } = target.files[0];
          handleFieldChange(autosaveImageKey, { io, name, size, type });
          setUploadedImageSrc(io);
        });
    }
  }, [handleFieldChange]);

  const isDisabled = isActive ||
    hasAudioBeenUploaded ||
    Boolean(imageThumbnailSrc) ||
    Boolean(savedAudioUrl);

  return (
    <>
      <div className={ styles.ExtraOptionsContainer }>
        <div className={ cx({ UploadsContainer : true, isDisabled }) }>
          <div className={ styles.AudioFileContainer }>
            <FileInput
              accept=".mp3,.wav,.m4a"
              disabled={ isDisabled }
              fileInputName={ audioFileInputName }
              handleChange={ handleAudioInputChange }
              register={ register }
            >
              <div
                className={ styles.UploadIconContainer }
                data-rh={ window.innerWidth >= 768 ? 'Upload audio' : null }
              >
                <p className={ cx({ UploadText : true, isDisabled }) }>Upload audio</p>
                <AudioUploadIcon disabled={ isDisabled } />
              </div>
            </FileInput>
          </div>

          <FileInput
            accept="image/*"
            disabled={ isDisabled }
            fileInputName={ imageFileInputName }
            handleChange={ handleImageInputChange }
            register={ register }
          >
            <div
              className={ styles.UploadIconContainer }
              data-rh={ window.innerWidth >= 768 ? 'Upload image' : null }
            >
                <p className={ cx({ UploadText : true, isDisabled }) }>Upload image</p>
              <ImageUploadIcon disabled={ isDisabled } />
            </div>
          </FileInput>
        </div>
      </div>

      { children }

      <If condition={ imageThumbnailSrc }>
        <div className={ styles.ImageUploadIconContainer }>
          <QuestionThumbnail
            isActive={ isActive }
            removeFile={ removeImage }
            src={ imageThumbnailSrc }
          />
        </div>
      </If>

      <If condition={ savedAudioUrl || hasAudioBeenUploaded }>
        <div className={ styles.AudioUploadIconContainer }>
          <QuestionThumbnail
            hasOutline
            isActive={ isActive }
            removeFile={ removeAudio }
            src="https://res-2.cloudinary.com/dorothy/image/upload/hBnFAwjF1L4jeaRg6aqhFmsX.png"
          />
        </div>
      </If>
    </>
  );
}

Field.propTypes = {
  autosaveAudioKey   : PropTypes.array.isRequired,
  autosavedAudio     : questionPropTypes.autosavedAudio,
  autosavedImage     : questionPropTypes.autosavedImage,
  autosaveImageKey   : PropTypes.array.isRequired,
  audioFileInputName : questionPropTypes.audioFileInputName.isRequired,
  children           : basePropTypes.children.isRequired,
  deleteAudio        : questionPropTypes.deleteAudio,
  deleteImage        : questionPropTypes.deleteImage,
  handleFieldChange  : PropTypes.func.isRequired,
  imageFileInputName : questionPropTypes.imageFileInputName.isRequired,
  id                 : basePropTypes.id,
  isActive           : basePropTypes.isActive,
  register           : basePropTypes.register.isRequired,
  savedAudioUrl      : questionPropTypes.savedAudioUrl,
  savedImageUrl      : questionPropTypes.savedImageUrl,
  setValue           : basePropTypes.setValue.isRequired,
};

export default Field;
