import { PropTypes } from 'prop-types';
import React from 'react';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ProfileIcon({ isActive }) {
  function getRootClassNames() {
    return cx({
      Root : true,
      isActive,
    });
  }

  return (
    <i
      className={ `${getRootClassNames()} fas fa-user-circle` }
      data-automated-test="profile-desktop-link"
    />
  );
}

ProfileIcon.propTypes = {
  isActive : PropTypes.bool,
};

export default ProfileIcon;
