import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import navigateOrWait from 'src/containers/shared/navigateOrWait';
import ShowPublicStudySet from 'src/components/publicStudySets/Show';

class FunctionalShow extends PureComponent {
  static propTypes = {
    actions  : basePropTypes.actions.isRequired,
    errors   : basePropTypes.errors,
    history  : basePropTypes.history.isRequired,
    isActive : basePropTypes.isActive,
    isAuthed : PropTypes.bool,
    location : basePropTypes.location.isRequired,
    match    : basePropTypes.match.isRequired,
    studySet : studySetPropTypes.studySet.isRequired,
  };

  componentDidMount() {
    const { actions, match } = this.props;

    actions.publicStudySets.show({ id : match.params.id });
  }

  addStudySet = () => {
    const { actions: { publicStudySets }, studySet } = this.props;

    return publicStudySets
      .add({ id : studySet.get('id') })
      .then(data => {
        if (window.gtag) {
          window.gtag('event', 'conversion_in_product_site', { value : 0 });
        }

        return this.navigateOrWait(data);
      })
      .catch(() => null);
  }

  navigateOrWait = ({ payload : { data : { id } } }) => navigateOrWait(
    this.props,
    `/study-sets/${id}`,
    { pathFrom : `/public-study-sets/${this.props.studySet.get('id')}` },
  )

  render() {
    if (!this.props.isAuthed) {
      let { pathname } = this.props.location;

      pathname = pathname.replace('/public-study-sets/', '/public/public-study-sets/');

      return <Redirect to={ { pathname } } />;
    }

    return (
      <ShowPublicStudySet
        addStudySet={ this.addStudySet }
        errors={ this.props.errors }
        history={ this.props.history }
        isActive={ this.props.isActive }
        location={ this.props.location }
        match={ this.props.match }
        studySet={ this.props.studySet }
      />
    );
  }
}

function mapStateToProps({ authentication, publicStudySets }) {
  return {
    errors       : publicStudySets.get('errors'),
    isActive     : publicStudySets.get('isActive'),
    isAuthed     : !!(authentication.get('email') && authentication.get('accessToken')),
    studySet     : publicStudySets.getIn(['loaded', 'show']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      publicStudySets : bindActionCreators(publicStudySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalShow);
