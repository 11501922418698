import * as recharts from 'recharts';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import CustomTooltip from '../Tooltip';

const {
  Line : RechartsLine,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} = recharts;

function Line(props) {
  const {
    data,
  } = props;

  let shouldDisplayDots = true;

  if (data && data.length > 69) shouldDisplayDots = false;
  if (data && data.length > 35 && window.innerWidth < 400) shouldDisplayDots = false;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={ data }
        margin={ { top : 20, right : 5 } }
      >
        <CartesianGrid strokeDasharray="3 3" vertical={ false } />
        <XAxis dataKey="xAxisName" />

        <YAxis
          type="number"
          domain={ [dataMin => Math.floor(Math.abs(dataMin) * 0.95), 'dataMax'] }
        />

        <Tooltip
          content={ <CustomTooltip /> }
          cursor={ false }
          wrapperStyle={ { outline : 'none' } }
        />

        <RechartsLine
          dataKey="yValue"
          dot={ shouldDisplayDots }
          stroke="#2071aa"
          type="linear"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

Line.propTypes = {
  data : statsPropTypes.chartData.isRequired,
};

export default Line;
