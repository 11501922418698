import { Link } from 'react-router-dom';
import React from 'react';

import * as questionPropTypes from 'src/constants/propTypes/question';

import FormattedQuestionContent from 'src/components/FormattedQuestionContent';
import LinkOutIcon from 'src/components/icons/LinkOut';

import styles from './styles.module.scss';

function AnswerContent({ question, shouldShowEditLink }) {
  return (
    <div
      className={ styles.Root }
      data-automated-test="quiz-question--answer-content"
    >
      <FormattedQuestionContent
        audioSrc={ question.getIn(['answer', 'audioUrl']) }
        imageSrc={ question.getIn(['answer', 'imageUrl']) }
        value={ question.getIn(['answer', 'body']) }
      />

      <If condition={ shouldShowEditLink }>
        <div className={ styles.EditQuestionLinkContainer }>
          <Link
            className={ styles.EditQuestionLink }
            target="_blank"
            to={ `/questions/${question.get('id')}/edit` }
          >
            edit <LinkOutIcon />
          </Link>
        </div>
      </If>
    </div>
  );
}

AnswerContent.propTypes = {
  question           : questionPropTypes.question.isRequired,
  shouldShowEditLink : questionPropTypes.shouldShowEditLink,
};

export default AnswerContent;
