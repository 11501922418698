import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from 'src/reducers/quizzes/initialState';

function selectQuestionsToBeAnswered(state, quizId) {
  return state
    .getIn(['loaded', quizId, 'questions'], new Immutable.List())
    .reduce((accumulator, q, i) => {
      const key = (i + 1).toString();
      return accumulator.set(key, q);
    }, Immutable.Map({}));
}

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.QUIZ__ADD_QUESTIONS_TO_BE_ANSWERED:
      return state.set(
        'questionsToBeAnswered',
        selectQuestionsToBeAnswered(state, payload.quizId),
      );

    case actionTypes.QUIZ__CLEAR_TEMP_STATE:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
      });

    case actionTypes.QUIZ__FETCH_NEWEST_FAILURE:
    case actionTypes.QUIZ__RESET_FLASH_STUDY_SESSION_FAILURE:
    case actionTypes.QUIZ__SHOW_FAILURE:
    case actionTypes.QUIZ__START_FLASH_STUDY_SESSION_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
      });

    case actionTypes.QUIZ__FETCH_NEWEST_START:
    case actionTypes.QUIZ__RESET_FLASH_STUDY_SESSION_START:
    case actionTypes.QUIZ__SHOW_START:
    case actionTypes.QUIZ__START_FLASH_STUDY_SESSION_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
        map.set('questionsToBeAnswered', Immutable.fromJS({}));
      });

    case actionTypes.QUIZ__FETCH_NEWEST_SUCCESS:
      return state.withMutations(map => {
        const { quiz } = payload;

        map.set('isActive', false);
        map.setIn(['loaded', 'newest'], Immutable.fromJS(quiz));
        map.setIn(['loaded', quiz.id], Immutable.fromJS(quiz));
      });

    case actionTypes.QUIZ__RESET_FLASH_STUDY_SESSION_SUCCESS:
    case actionTypes.QUIZ__START_FLASH_STUDY_SESSION_SUCCESS:
    case actionTypes.QUIZ__SHOW_SUCCESS:
      return state.withMutations(map => {
        const { quiz } = payload;

        map.set('isActive', false);
        map.setIn(['loaded', quiz.id], Immutable.fromJS(quiz));
      });

    case actionTypes.QUIZ__FETCH_NEWEST_SUCCESS__EMPTY:
      return state.withMutations(map => {
        map.set('isActive', false);
        map.setIn(['loaded', 'newest'], null);
      });

    case actionTypes.QUIZ__RESET:
      return state.withMutations(map => {
        map.set('isTransitioningToCongratulations', false);
        map.set('isActive', false);
      });

    case actionTypes.QUIZ__UPDATE_IS_TRANSITIONING_TO_CONGRATULATIONS:
      return state.set(
        'isTransitioningToCongratulations',
        payload.isTransitioningToCongratulations,
      );

    case actionTypes.QUIZ__UPDATE_QUESTION_TO_BE_ANSWERED:
      return state.updateIn(
        ['questionsToBeAnswered', payload.questionNumber],
        question => question.merge(Immutable.fromJS(payload.values)),
      );

    default:
      return state;
  }
}
