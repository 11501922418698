import * as Sentry from '@sentry/browser';

import createSubscription from './createSubscription';

function rejectPromise(message = 'denied') {
  return Promise.reject(new Error(message));
}

function requestPermissionFromWindow(createPushNotification) {
  return window.Notification.requestPermission()
    .then(permission => {
      if (permission === 'granted') {
        return createSubscription(createPushNotification);
      }
    })
    .catch(err => {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err); // eslint-disable-line no-console
      }

      Sentry.captureException(err, scope => {
        scope.setTag('error caught in requestPermissionFromWindow', true);
        return scope;
      });

      return rejectPromise();
    });
}

export default function requestPushPermission(createPushNotification, shouldAskAnyway = false) {
  if (
    !window.Notification ||
    (
      !shouldAskAnyway && (
        window.Notification.permission === 'granted' ||
        window.Notification.permission === 'denied'
      )
    )
  ) return Promise.resolve('done');

  if (
    window.Notification &&
    window.Notification.permission === 'denied' &&
    shouldAskAnyway
  ) return rejectPromise();

  if (!('PushManager' in window)) return rejectPromise('No Push API Support!');

  return requestPermissionFromWindow(createPushNotification);
}
