import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as paymentPropTypes from 'src/constants/propTypes/payment';
import * as paymentsActionCreators from 'src/actions/payments';
import * as profileActionCreators from 'src/actions/profile';

import { pricesByPlanType } from 'src/constants/prices';

import AccountPlan from 'src/components/profile/AccountPlan';

class FunctionalAccountPlan extends PureComponent {
  static propTypes = {
    accountPlan      : paymentPropTypes.accountPlan.isRequired,
    actions          : basePropTypes.actions.isRequired,
    givenFreeAccess  : PropTypes.bool,
    history          : basePropTypes.history.isRequired,
    isActive         : PropTypes.bool,
    location         : basePropTypes.location.isRequired,
    paymentsIsActive : PropTypes.bool,
  };

  state = {
    digitalGoodsService    : null,
    needsGooglePlayBilling : null,
    skuDetails             : null,
  }

  async componentDidMount() {
    const { actions, location } = this.props;

    actions.profile.show().then(data => {
      const urlParams = new URLSearchParams(location.search);

      try {
        const {
          payload : {
            data : {
              user : {
                accountPlan : {
                  planType,
                },
              },
            },
          },
        } = data;

        if (urlParams.get('success') === 'true' && planType !== 'basic' && window.gtag) {
          window.gtag('event', 'product_purchase', { value : pricesByPlanType[planType] || 1 });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    });

    if ('getDigitalGoodsService' in window) {
      try {
        const digitalGoodsService = await window.getDigitalGoodsService(
          'https://play.google.com/billing',
        );

        const skuDetails = await digitalGoodsService.getDetails([
          'premium',
          'premium_lifetime',
          'premium_45_days',
        ]);

        return this.setState({
          needsGooglePlayBilling : true,
          digitalGoodsService,
          skuDetails,
        });
      } catch (error) {
        Sentry.captureException(error);
        this.setState({ needsGooglePlayBilling : false });
      }
    }

    this.setState({ needsGooglePlayBilling : false });
  }

  upgradeAccount = planType => {
    const { actions, history } = this.props;

    let sessionId;

    return actions.payments.startStripeCheckout({ planType })
      .then(({ payload : { payments : { sessionId : passedSessionId } } }) => {
        sessionId = passedSessionId;

        if (
          sessionId &&
          sessionId.split('test_cs_').length > 1 &&
          sessionId.split('test_cs_')[0] === ''
        ) {
          return history.push(`/profile/account-plan?success=true&plan_type=${planType}`);
        }

        return loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
      })
      .then(stripe => stripe.redirectToCheckout({ sessionId }))
      .catch(error => Sentry.captureException(error));
  }

  // We need to keep everything in this function because Google Play
  // will get mad at us for trying to call things from other functions
  // if we don't.
  upgradeAccountWithGooglePlayBilling = async planType => {
    if (planType === 0) return;

    const {
      actions : {
        payments : { acknowledgeGooglePlayPurchase },
        profile  : { show : showProfile },
      },
      uid,
    } = this.props;

    const {
      digitalGoodsService,
      skuDetails,
    } = this.state;

    let paymentResponse;

    const premiumYearly = skuDetails[0];
    // index of 2 for a reason
    const premiumLifetime = skuDetails[2];
    const premium45Days = skuDetails[1];

    let item = premiumYearly;

    if (planType === 2) item = premiumLifetime;
    if (planType === 3) item = premium45Days;

    const sku = item.itemId;

    const paymentMethods = [{
      supportedMethods : 'https://play.google.com/billing',
      data             : { sku },
    }];

    // The "total" member of the paymentDetails is required by the Payment
    // Request API, but is not used when using Google Play Billing. We can
    // set it up with bogus details.
    const paymentDetails = {
      total : {
        label  : 'Total',
        amount : { currency : 'USD', value : '0' },
      },
    };

    try {
      const request = new PaymentRequest(paymentMethods, paymentDetails);

      paymentResponse = await request.show();

      const { purchaseToken } = paymentResponse.details;

      if (!purchaseToken) {
        await paymentResponse.complete('fail');
        return Sentry.captureMessage(`No purchase token for ${uid}'s purchase`);
      }

      Sentry.captureMessage(`purchaseToken stuff called ${purchaseToken} ${sku}`);
      digitalGoodsService.consume(purchaseToken);

      await acknowledgeGooglePlayPurchase({
        planType,
        purchaseToken,
        skuItemId : sku,
      });

      await showProfile();
      await paymentResponse.complete('success');
      setTimeout(window.location.reload, 1000);
    } catch (error) {
      Sentry.captureException(error);

      if (paymentResponse) {
        await paymentResponse.complete('fail');
      }
    }
  }

  upgradeToYearlyWithGooglePlayBilling = async () => this.upgradeAccountWithGooglePlayBilling(1)

  upgradeToLifetimeWithGooglePlayBilling = async () => this.upgradeAccountWithGooglePlayBilling(2)

  upgradeTo45DaysWithGooglePlayBilling = async () => this.upgradeAccountWithGooglePlayBilling(3)

  render() {
    const {
      accountPlan,
      givenFreeAccess,
      isActive,
      location,
      paymentsIsActive,
    } = this.props;

    const {
      needsGooglePlayBilling,
      digitalGoodsService,
      skuDetails,
    } = this.state;

    if (needsGooglePlayBilling === null) return false;

    return (
      <AccountPlan
        accountPlan={ accountPlan }
        givenFreeAccess={ givenFreeAccess }
        isActive={ isActive }
        location={ location }
        needsGooglePlayBilling={ needsGooglePlayBilling }
        paymentsIsActive={ paymentsIsActive }
        digitalGoodsService={ digitalGoodsService }
        skuDetails={ skuDetails }
        upgradeAccount={ this.upgradeAccount }
        upgradeAccountWithGooglePlayBilling={ this.upgradeAccountWithGooglePlayBilling }
        upgradeTo45DaysWithGooglePlayBilling={ this.upgradeTo45DaysWithGooglePlayBilling }
        upgradeToYearlyWithGooglePlayBilling={ this.upgradeToYearlyWithGooglePlayBilling }
        upgradeToLifetimeWithGooglePlayBilling={ this.upgradeToLifetimeWithGooglePlayBilling }
      />
    );
  }
}

function mapStateToProps({ authentication, payments, profile }) {
  return {
    accountPlan      : profile.get('accountPlan'),
    givenFreeAccess  : profile.get('givenFreeAccess'),
    isActive         : profile.get('isActive'),
    paymentsIsActive : payments.get('isActive'),
    uid              : authentication.get('uid'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      payments : bindActionCreators(paymentsActionCreators, dispatch),
      profile  : bindActionCreators(profileActionCreators, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalAccountPlan);
