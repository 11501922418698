import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from 'src/reducers/attempts/initialState';

export default function (state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.ATTEMPT__CREATE_FAILURE:
    case actionTypes.ATTEMPT__UPDATE_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
      });

    case actionTypes.ATTEMPT__CREATE_START:
    case actionTypes.ATTEMPT__UPDATE_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.ATTEMPT__CREATE_SUCCESS:
    case actionTypes.ATTEMPT__UPDATE_SUCCESS:
      return state.withMutations(map => {
        const { attempt } = payload;

        map.set('isActive', false);
        map.setIn(['loaded', 'byQuestionId', attempt.question.id], Immutable.fromJS(attempt));
      });

    case actionTypes.ATTEMPT__RESET:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
        map.set('isSuccessful', false);
      });

    default:
      return state;
  }
}
