import React from 'react';
import ReactMarkdown from 'react-markdown';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function FormattedQuestionContent({ audioSrc, imageSrc, value }) {
  let source = value;

  const hasThreeBackticks = value && value.includes('```');

  // Even though value is required, it could still get
  // left out on the backend. If so, we don't want the
  // whole quiz to break so that's why we have a null check.
  if (value && !hasThreeBackticks) {
    // If this value has a code sample, we don't want to replace
    // the \n because then it will literally show &nbsp; in
    // the code sample on every line.
    source = value.replace(/\n/gi, '&nbsp;\n');
  }

  return (
    <div className={ cx({ Root : true, hasThreeBackticks }) }>
      <ReactMarkdown
        className={ styles.LineBreak }
        source={ source }
      />

      <If condition={ audioSrc }>
        <div className={ styles.AudioContainer }>
          <audio
            className={ styles.Audio }
            controls
            src={ audioSrc }
          >
            Your browser does not support audio.
          </audio>
        </div>
      </If>

      <If condition={ imageSrc }>
        <img
          className={ styles.Image }
          src={ imageSrc }
        />
      </If>
    </div>
  );
}

FormattedQuestionContent.propTypes = {
  audioSrc : basePropTypes.audioSrc,
  imageSrc : basePropTypes.imageSrc,
  value    : basePropTypes.value.isRequired,
};

export default FormattedQuestionContent;
