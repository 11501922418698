import classNames from 'classnames/bind';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function AudioUpload({ disabled }) {
  const getRootClassNames = () => cx({
    Root : true,
    disabled,
  });

  return (
    <svg
      className={ getRootClassNames() }
      data-automated-test="audio-upload-icon"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 62 82.5"
      xmlSpace="preserve"
    >
      <path
        className={ styles.Path }
        fillRule="evenodd" d="M211,572 C211,578.075533 206.075946,583 200,583 C193.926644,583 189,578.072536 189,572.001964 L189,547.998036 C189,541.927464 193.926644,537 200,537 C206.075946,537 211,541.924467 211,548 L211,572 Z M209,548 C209,543.028999 204.971339,539 200,539 C195.031288,539 191,543.031959 191,547.998036 L191,572.001964 C191,576.968041 195.031288,581 200,581 C204.971339,581 209,576.971001 209,572 L209,548 Z M234,576.414214 L234,587 C234,587.552285 233.552285,588 233,588 C232.447715,588 232,587.552285 232,587 L232,576.414214 L228.707107,579.707107 C228.316582,580.097631 227.683418,580.097631 227.292893,579.707107 C226.902369,579.316582 226.902369,578.683418 227.292893,578.292893 L232.292893,573.292893 C232.683418,572.902369 233.316582,572.902369 233.707107,573.292893 L238.707107,578.292893 C239.097631,578.683418 239.097631,579.316582 238.707107,579.707107 C238.316582,580.097631 237.683418,580.097631 237.292893,579.707107 L234,576.414214 Z M224,595 C223.447715,595 223,594.552285 223,594 C223,593.447715 223.447715,593 224,593 L242,593 C242.552285,593 243,593.447715 243,594 C243,594.552285 242.552285,595 242,595 L224,595 Z M199,601 L199,591 L197.999773,591 C188.612176,591 181,583.391244 181,574.009449 C181,573.457165 181.447715,573.009449 182,573.009449 C182.552285,573.009449 183,573.457165 183,574.009449 C183,582.286473 189.716544,589 197.999773,589 L202.000227,589 C210.28957,589 217,582.291162 217,574.009449 C217,573.457165 217.447715,573.009449 218,573.009449 C218.552285,573.009449 219,573.457165 219,574.009449 C219,583.395838 211.394034,591 202.000227,591 L201,591 L201,601 L210,601 C210.552285,601 211,601.447715 211,602 C211,602.552285 210.552285,603 210,603 L190,603 C189.447715,603 189,602.552285 189,602 C189,601.447715 189.447715,601 190,601 L199,601 Z"
        transform="translate(-181 -537)"
      />
    </svg>
  );
}

AudioUpload.propTypes = {
  disabled : basePropTypes.disabled,
};

export default AudioUpload;
