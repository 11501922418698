import React from 'react';

function Refresh() {
  return (
    <svg width="700pt" height="700pt" viewBox="0 0 700 700">
      <g>
        <path d="m518 280c0.007812-30.762-8.4336-60.938-24.398-87.23-15.965-26.297-38.844-47.703-66.141-61.883-27.301-14.184-57.969-20.602-88.66-18.551-79.742 5.1523-151.87 77.672-156.8 157.41v0.003906c-2.3789 39.457 9.2344 78.488 32.789 110.23 23.559 31.742 57.555 54.16 96.012 63.312 5.8867 1.4336 12.105 0.46875 17.285-2.6797 5.1758-3.1523 8.8906-8.2305 10.32-14.121 1.3438-5.793 0.32422-11.879-2.8281-16.922-3.1484-5.043-8.1758-8.625-13.969-9.957-36.305-8.6289-66.746-33.246-82.773-66.945-16.027-33.699-15.922-72.844 0.29297-106.45 16.219-33.609 46.793-58.059 83.145-66.484 36.352-8.4258 74.566 0.082031 103.91 23.129s46.664 58.156 47.094 95.469c0.43359 37.316-16.074 72.812-44.883 96.531v-37.91c0.11328-5.7031-1.8906-11.246-5.6211-15.559-3.7344-4.3125-8.9336-7.0898-14.594-7.793-6.3086-0.62109-12.586 1.4648-17.27 5.7344-4.6875 4.2695-7.3438 10.324-7.3125 16.664v78.398c0 8.9141 3.5391 17.461 9.8398 23.762 6.3008 6.3008 14.848 9.8398 23.758 9.8398h77.449c5.7031 0.11328 11.246-1.8906 15.559-5.625 4.3125-3.7305 7.0898-8.9297 7.793-14.59 0.61719-6.3086-1.4648-12.586-5.7344-17.273-4.2695-4.6836-10.328-7.3438-16.664-7.3125h-31.699c17.051-15.676 30.664-34.719 39.973-55.926 9.3125-21.203 14.121-44.113 14.125-67.273z"/>
      </g>
    </svg>
  );
}

export default Refresh;
