import React from 'react';

import styles from './styles.module.scss';

function UploadFile() {
  return (
    <svg
      className={ styles.Root }
      height="700pt"
      width="700pt"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m198.33 23.332c-25.648 0-46.664 21.016-46.664 46.668v420c0 25.652 21.016 46.668 46.668 46.668h303.33c25.652 0 46.668-21.016 46.668-46.668l-0.003907-350c0.003907-2.8555-1.043-5.6133-2.9375-7.7461l-93.332-105c-2.2188-2.4961-5.3945-3.9219-8.7305-3.9219zm0 23.332h221.67v70c0 19.188 15.812 35 35 35h70v338.33c0 13.129-10.203 23.332-23.332 23.332l-303.34 0.003906c-13.129 0-23.332-10.203-23.332-23.332v-420c0-13.129 10.203-23.332 23.332-23.332zm245 5.9062 67.359 75.762h-55.691c-6.6641 0-11.668-5-11.668-11.668z"/>
        <path d="m350.23 221.67c-3.1406-0.0625-6.1719 1.1445-8.4102 3.3477l-70 68.723c-4.5938 4.5195-4.6523 11.906-0.13672 16.5 4.5195 4.5938 11.906 4.6523 16.5 0.13672l50.148-49.219v158.21c0 6.4453 5.2227 11.668 11.668 11.668 6.4414 0 11.668-5.2227 11.668-11.668v-158.21l50.152 49.219c4.5938 4.5156 11.98 4.457 16.496-0.13672 4.5195-4.5938 4.457-11.98-0.13672-16.496l-70-68.723v-0.003906c-2.125-2.0898-4.9688-3.2891-7.9531-3.3477z"/>
      </g>
    </svg>
  );
}

export default UploadFile;
