import React, { useEffect, useRef, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import FieldErrors from 'src/components/fields/FieldErrors';

import Shared from './Shared';

const shared = new Shared();

function TextField(props) {
  const {
    autoFocus,
    autoFocusDelayInMs,
    dataAutomatedTest,
    defaultValue,
    disabled,
    errorMessages,
    hasAdjustableHeight = true,
    heading,
    inputMode,
    isTextArea,
    name,
    onChange,
    placeholder,
    readOnly,
    register,
    type,
  } = props;

  const [hasBeenInitiallyAutoFocused, setHasBeenInitiallyAutoFocused] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [textValue, setTextValue] = useState(defaultValue);
  const inputRef = useRef(null);

  useEffect(() => {
    if (
      autoFocusDelayInMs &&
      !isFocused &&
      inputRef &&
      inputRef.current &&
      !hasBeenInitiallyAutoFocused
    ) {
      setHasBeenInitiallyAutoFocused(true);

      setTimeout(() => {
        // Extra conditional needed since we could lose/gain the inputRef
        // depending on how long the timeout takes to fire
        const current = inputRef && inputRef.current;

        if (current) {
          current.focus();

          setTimeout(() => {
            current.scrollTo({ behavior : 'smooth' });
          }, 125);
        }

        setIsFocused(true);
      }, autoFocusDelayInMs);
    }
  }, [autoFocusDelayInMs, isFocused, inputRef]);

  useEffect(() => {
    setTextValue(defaultValue);
  }, [defaultValue]);

  function adjustHeight() {
    if (!hasAdjustableHeight) return;
    if (!isTextArea) return;

    let current = inputRef && inputRef.current;

    if (!current) return;

    current = inputRef && inputRef.current;
    current.style.height = `${current.scrollHeight}px`;
  }

  useEffect(adjustHeight, []);

  function handleBlur() { setIsFocused(false); }
  function handleTitleClick() { inputRef.current.focus(); }

  function handleFocus() {
    adjustHeight();
    setIsFocused(true);
  }

  shared.setClassProperties({
    props,
    inputRef,
    isFocused,
    isWeb : true,
  });

  function setUpRef(el) {
    if (register) register(el);
    inputRef.current = el;
  }

  const fieldClasses = shared.getFieldClassNames();

  function handleChange(e) {
    if (onChange) onChange(e);
    setTextValue(e.target.value);
  }

  function handleKeyDown(e) {
    if (e.key === 'Backspace' || e.key === 'Delete') return;
    adjustHeight();
  }

  function handlePaste() {
    setTimeout(adjustHeight, 50);
  }

  const inputProps = {
    autoFocus             : autoFocus && !autoFocusDelayInMs,
    className             : `${fieldClasses} data-hj-allow`,
    'data-automated-test' : dataAutomatedTest,
    'data-hj-allow'       : true,
    disabled,
    inputMode,
    name,
    onBlur                : handleBlur,
    onChange              : handleChange,
    onFocus               : handleFocus,
    onKeyDown             : handleKeyDown,
    onPaste               : handlePaste,
    placeholder,
    ref                   : setUpRef,
    readOnly,
    rows                  : 5,
    type,
    value                 : textValue || '',
  };

  return (
    <label className={ shared.getRootClassNames() }>
      <div
        className={ shared.getTitleClassNames() }
        onClick={ handleTitleClick }
      >
        { heading }
      </div>

      <Choose>
        <When condition={ isTextArea }>
          <textarea { ...inputProps } />
        </When>

        <Otherwise>
          <input { ...inputProps } />
        </Otherwise>
      </Choose>

      <If condition={ errorMessages }>
        <FieldErrors errorMessages={ errorMessages } />
      </If>
    </label>
  );
}

TextField.propTypes = {
  name : basePropTypes.name.isRequired,
  ...shared.propTypes,
};

export default TextField;
