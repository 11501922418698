import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ButtonLink(props) {
  const {
    dataAutomatedTest,
    extraClassNames,
    floatRight,
    handleClick,
    href,
    isInFooter,
    isInHeader,
    isPulsing,
    isSecondary,
    isTertiary,
    linksOut,
    noBorder,
    text,
    to,
    withArrow,
    withBrain,
    withPencil,
    withPlusSign,
  } = props;

  const isPrimary = !isSecondary && !isTertiary;

  const getButtonClassNames = () => cx({
    Button : true,
    floatRight,
    isInFooter,
    isInHeader,
    isPrimary,
    isPulsing,
    isSecondary,
    isTertiary,
    noBorder,
  });

  const getLinkClassNames = () => cx({
    Link : true,
    floatRight,
    isInFooter,
    isInHeader,
  });

  const getArrowClassNames = () => cx({
    'fas fa-long-arrow-alt-right' : true,
    Icon                          : true,
    isPrimary,
    isSecondary,
  });

  const getBrainClassNames = () => cx({
    'fas fa-brain' : true,
    Icon           : true,
    onLeftSide     : true,
    isPrimary,
    isSecondary,
  });

  const getPencilClassNames = () => cx({
    'fas fa-pen' : true,
    Icon         : true,
    onLeftSide   : true,
    isPrimary,
    isSecondary,
  });

  const getPlusSignClassNames = () => cx({
    'fas fa-plus' : true,
    Icon          : true,
    onLeftSide    : true,
    isPrimary,
    isSecondary,
  });

  const getLinksOutClassNames = () => cx({
    'fas fa-external-link-alt' : true,
    LinkOut                    : true,
    Icon                       : true,
  });

  const getRootClassNames = () => cx({
    Root : true,
    floatRight,
    isInFooter,
  }, extraClassNames);

  const extraLinkProps = {};

  if (linksOut) extraLinkProps.target = '_blank';

  function LinkChildren() {
    return (
      <div className={ getButtonClassNames() }>
        <Choose>
          <When condition={ withBrain }>
            <i className={ getBrainClassNames() } />
          </When>

          <When condition={ withPencil }>
            <i className={ getPencilClassNames() } />
          </When>

          <When condition={ withPlusSign }>
            <i className={ getPlusSignClassNames() } />
          </When>
        </Choose>

        { text }

        <Choose>
          <When condition={ withArrow }>
            <i className={ getArrowClassNames() } />
          </When>

          <When condition={ linksOut }>
            <i className={ getLinksOutClassNames() } />
          </When>
        </Choose>
      </div>
    );
  }

  return (
    <div className={ getRootClassNames() }>
      <Choose>
        <When condition={ to }>
          <Link
            className={ getLinkClassNames() }
            data-automated-test={ dataAutomatedTest }
            onClick={ handleClick }
            to={ to }
            { ...extraLinkProps }
          >
            <LinkChildren />
          </Link>
        </When>

        <Otherwise>
          <a
            className={ getLinkClassNames() }
            data-automated-test={ dataAutomatedTest }
            href={ href }
            onClick={ handleClick }
            { ...extraLinkProps }
          >
            <LinkChildren />
          </a>
        </Otherwise>
      </Choose>
    </div>
  );
}

ButtonLink.propTypes = {
  dataAutomatedTest : basePropTypes.dataAutomatedTest,
  extraClassNames   : PropTypes.string,
  floatRight        : basePropTypes.floatRight,
  handleClick       : basePropTypes.handleClick,
  href              : PropTypes.string,
  isInFooter        : basePropTypes.isInFooter,
  isInHeader        : basePropTypes.isInHeader,
  isPulsing         : PropTypes.bool,
  isSecondary       : basePropTypes.isSecondary,
  isTertiary        : basePropTypes.isTertiary,
  linksOut          : basePropTypes.linksOut,
  noBorder          : PropTypes.bool,
  text              : basePropTypes.text.isRequired,
  to                : basePropTypes.to,
  withArrow         : basePropTypes.withArrow,
  withPencil        : basePropTypes.withPencil,
  withPlusSign      : basePropTypes.withPlusSign,
};

export default ButtonLink;
