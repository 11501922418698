import Immutable from 'immutable';

import actionTypes from 'src/constants/actionTypes';
import INITIAL_STATE from './initialState';

function general(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case actionTypes.GENERAL__ADD_ERRORS:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
      });

    case actionTypes.GENERAL__CLEAR_TEMP_STATE:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', false);
      });

    case actionTypes.GENERAL__SUBMIT_FEEDBACK_FAILURE:
      return state.withMutations(map => {
        map.set('errors', Immutable.fromJS(payload.errors));
        map.set('isActive', false);
      });

    case actionTypes.GENERAL__SUBMIT_FEEDBACK_START:
      return state.withMutations(map => {
        map.set('errors', null);
        map.set('isActive', true);
      });

    case actionTypes.GENERAL__SUBMIT_FEEDBACK_SUCCESS:
      return state.withMutations(map => {
        map.set('isActive', false);
      });

    case actionTypes.GENERAL__CLOSE_BANNER:
      return state.withMutations(map => {
        map.set('isBannerClosed', true);
      });

    default:
      return state;
  }
}

export default general;
