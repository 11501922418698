// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__SampleSubParagraph___1AT7F{margin-top:8px}@media screen and (min-width: 568px){.styles-module__SampleSubParagraph___1AT7F{margin-bottom:0}}.styles-module__SubParagraph___18D9t{color:rgba(0,0,0,.65);line-height:16px;margin-top:8px}.styles-module__SubParagraph___18D9t a{color:#2071aa;opacity:.9}@media screen and (min-width: 568px){.styles-module__SubParagraph___18D9t{margin-bottom:0}}.styles-module__OptionsContainer___2bIyG,.styles-module__KeyboardShortcutsContainer___vJKRu{margin-top:24px}@media screen and (max-width: 568px){.styles-module__KeyboardShortcutsContainer___vJKRu{display:none}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/quiz/Explanation/Content/styles.module.scss"],"names":[],"mappings":"AAAA,2CACE,cAAA,CACA,qCACE,2CACE,eAAA,CAAA,CAEN,qCACE,qBAAA,CACA,gBAAA,CACA,cAAA,CACA,uCACE,aAAA,CACA,UAAA,CACF,qCACE,qCACE,eAAA,CAAA,CAEN,4FAEE,eAAA,CAEF,qCACE,mDACE,YAAA,CAAA","file":"styles.module.scss","sourcesContent":[".SampleSubParagraph {\n  margin-top: 8px; }\n  @media screen and (min-width: 568px) {\n    .SampleSubParagraph {\n      margin-bottom: 0; } }\n\n.SubParagraph {\n  color: rgba(0, 0, 0, 0.65);\n  line-height: 16px;\n  margin-top: 8px; }\n  .SubParagraph a {\n    color: #2071aa;\n    opacity: 0.9; }\n  @media screen and (min-width: 568px) {\n    .SubParagraph {\n      margin-bottom: 0; } }\n\n.OptionsContainer,\n.KeyboardShortcutsContainer {\n  margin-top: 24px; }\n\n@media screen and (max-width: 568px) {\n  .KeyboardShortcutsContainer {\n    display: none; } }\n"]}]);
// Exports
exports.locals = {
	"SampleSubParagraph": "styles-module__SampleSubParagraph___1AT7F",
	"SubParagraph": "styles-module__SubParagraph___18D9t",
	"OptionsContainer": "styles-module__OptionsContainer___2bIyG",
	"KeyboardShortcutsContainer": "styles-module__KeyboardShortcutsContainer___vJKRu"
};
module.exports = exports;
