import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import Stat from 'src/components/Stat';

import styles from './styles.module.scss';

function TextStats(props) {
  const {
    stats,
  } = props;

  return (
    <div className={ styles.Root }>
      <For each="stat" of={ stats }>
        <Stat
          key={ stat.title }
          noHint={ !stat.hint }
          stat={ stat }
        />
      </For>
    </div>
  );
}

TextStats.propTypes = {
  stats : statsPropTypes.stats.isRequired,
};

export default TextStats;
