import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import ChevronIcon from 'src/components/icons/Chevron';
import trackBackClick from 'src/components/shared/trackBackClick';

import styles from './styles.module.scss';

function BackButton({ history, match }) {
  function handleClick() {
    trackBackClick(match);
    history.goBack();
  }

  return (
    <div
      className={ styles.Root }
      data-automated-test="header--back-button"
      onClick={ handleClick }
    >
      <ChevronIcon />
    </div>
  );
}

BackButton.propTypes = {
  history : basePropTypes.history,
  match   : basePropTypes.match.isRequired,
};

export default BackButton;
