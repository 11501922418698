import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';
import TrashCan from 'src/components/icons/TrashCan';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function QuestionThumbnail(props) {
  const {
    dataAutomatedTest,
    hasOutline,
    isActive,
    removeFile,
    src,
  } = props;

  return (
    <div className={ styles.Root }>
      <div className={ styles.CombinedImage }>
        <img
          className={ cx({ Image : true, hasOutline, isActive }) }
          data-automated-test={ dataAutomatedTest || 'add-question-thumbnail' }
          src={ src }
        />

        <If condition={ removeFile && !isActive }>
          <div
            className={ styles.TrashCanContainer }
            onClick={ removeFile }
          >
            <TrashCan />
          </div>
        </If>
      </div>
    </div>
  );
}

QuestionThumbnail.propTypes = {
  dataAutomatedTest : basePropTypes.dataAutomatedTest,
  hasOutline        : basePropTypes.hasOutline,
  isActive          : basePropTypes.isActive,
  removeFile        : basePropTypes.removeFile,
  src               : basePropTypes.src.isRequired,
};

export default QuestionThumbnail;
