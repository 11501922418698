import React from 'react';

function GoogleLetter() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M23.2974 10.0089C23.4353 10.7779 23.5 11.5638 23.5 12.3455C23.5 15.8442 22.224 18.8021 20.004 20.8049H20.0083C18.0641 22.5627 15.3958 23.5811 12.2317 23.5811C7.79594 23.5811 3.73953 21.1303 1.74796 17.2471C0.0840144 13.9977 0.0840111 10.1694 1.74796 6.92003C3.73953 3.0326 7.79594 0.58183 12.2317 0.58183C15.1458 0.548026 17.9607 1.62129 20.0816 3.57346L16.7321 6.85665C15.5208 5.72423 13.9043 5.10731 12.2317 5.13266C9.17107 5.13266 6.57169 7.15666 5.64488 9.88209C5.15345 11.3103 5.15345 12.8568 5.64488 14.285H5.64919C6.58031 17.0062 9.17538 19.0302 12.236 19.0302C13.8181 19.0302 15.1759 18.633 16.2278 17.9316H16.2235C17.4606 17.1288 18.3055 15.8653 18.5685 14.4371H12.2317V10.0089H23.2974Z" />
    </svg>
  );
}

export default GoogleLetter;
