import React, { memo } from 'react';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

import Card from 'src/components/Card';
import classNames from 'src/components/shared/classNames';
import FeedbackWidget from 'src/components/FeedbackWidget';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import Content from './Content';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Profile(props) {
  const {
    children,
    handleSignout,
    location,
  } = props;

  const isOnAccountPlan = location.pathname.includes('account-plan');

  return (
    <div className={ styles.Root }>
      <div className={ styles.DesktopContainer }>
        <Card
          extraClassNames={ cx({
            Card                      : true,
            CardExtraSpecificity      : true,
            CardExtraExtraSpecificity : true,
          }) }
          isFullScreenOnDesktop
          isSoloCardOnScreen
        >
          <Content
            handleSignout={ handleSignout }
            isOnAccountPlan={ isOnAccountPlan }
            location={ location }
          >
            { children }
          </Content>
        </Card>
      </div>

      <div className={ styles.MobileContainer }>
        <Content
          handleSignout={ handleSignout }
          location={ location }
        >
          { children }
        </Content>
      </div>

      <SiteWideLinks location={ location } />
      <FeedbackWidget />
    </div>
  );
}

Profile.propTypes = {
  children      : basePropTypes.children.isRequired,
  handleSignout : authenticationPropTypes.handleSignout.isRequired,
  location      : basePropTypes.location.isRequired,
};

export default memo(Profile);
