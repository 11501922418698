import React from 'react';

import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import classNames from 'src/components/shared/classNames';
import StudySetForm from 'src/components/forms/StudySet';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function NewStudySet(props) {
  if (!props.studySet) return null;

  return (
    <div className={ cx({ Root : true }) }>
      <div className={ cx({ Container : true }) }>
        <StudySetForm
          heading="New study set"
          { ...props }
        />
      </div>
    </div>
  );
}

NewStudySet.propTypes = {
  studySet : studySetPropTypes.newStudySet.isRequired,
};

export default NewStudySet;
