import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import React, { memo } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import Card from 'src/components/Card';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Result(props) {
  const {
    basePath,
    children,
    result,
    rightSidePara,
    src,
    subTitle,
    title,
  } = props;

  const displayTitle = title || result.get('title') || '(no title)';

  function getLeftSideClassNames() {
    return cx({
      LeftSide    : true,
      noRightSide : !rightSidePara,
    });
  }

  return (
    <li className={ styles.Root }>
      <Link
        className={ styles.Link }
        to={ {
          pathname : `${basePath}/${result.get('id')}`,
          state    : { pathFrom : basePath },
        } }
      >
        <Card
          dataAutomatedTest="results-search-result"
          extraClassNames={ styles.Foo }
        >
          <div
            className={ styles.Container }
            data-automated-test="results-search-result"
          >
            <div className={ getLeftSideClassNames() }>
              <Choose>
                <When condition={ src }>
                  <img
                    className={ styles.Image }
                    src={ src }
                  />
                </When>

                <Otherwise>
                </Otherwise>
              </Choose>

              <div className={ styles.InfoContainer }>
                <p className={ styles.Title }>{ displayTitle }</p>

                <If condition={ subTitle }>
                  <p className={ styles.SubTitle }>{ subTitle }</p>
                </If>

                { children }
              </div>
            </div>

            <If condition={ rightSidePara }>
              <div className={ subTitle && styles.RightSideContainer }>
                <p className={ styles.RightSidePara }>{ rightSidePara }</p>
              </div>
            </If>
          </div>
        </Card>
      </Link>
    </li>
  );
}

Result.propTypes = {
  basePath      : basePropTypes.basePath.isRequired,
  children      : basePropTypes.children,
  result        : basePropTypes.result.isRequired,
  rightSidePara : basePropTypes.rightSidePara,
  src           : basePropTypes.src,
  subTitle      : basePropTypes.subTitle,
  title         : basePropTypes.title,
};

export default memo(Result);
