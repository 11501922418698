import buildValuesFromElementsWithFiles from 'src/containers/shared/buildValuesFromElementsWithFiles';

function buildBasicQuestionForSubmission(i, ssQuestions) {
  const ssQuestion = ssQuestions.get(i.toString());

  if (!ssQuestion) return null;

  return {
    answer : {
      autosavedAudio : ssQuestion.getIn(['answer', 'autosavedAudio']),
      autosavedImage : ssQuestion.getIn(['answer', 'autosavedImage']),
      body           : ssQuestion.getIn(['answer', 'body']),
    },
    autosavedAudio       : ssQuestion.get('autosavedAudio'),
    autosavedImage       : ssQuestion.get('autosavedImage'),
    id                   : ssQuestion.get('id'),
    isReversedCopyActive : ssQuestion.get('isReversedCopyActive'),
    title                : ssQuestion.get('title'),
  };
}

function addAutosavedMedia(entity) {
  if (!entity) return {};

  const autosavedAudio = entity.get('autosavedAudio');
  const autosavedImage = entity.get('autosavedImage');

  return {
    autosavedAudio : autosavedAudio && autosavedAudio.toJS(),
    autosavedImage : autosavedImage && autosavedImage.toJS(),
  };
}

function addAutosavedMediaToUpdated(i, ssQuestions, updated) {
  const ssQuestion = ssQuestions.get(i.toString());

  if (
    !ssQuestion ||
    ssQuestion.size === 0 ||
    ssQuestion.get('isRemoved')
  ) return updated;

  return {
    ...updated,
    ...addAutosavedMedia(ssQuestion),

    answer : {
      ...updated.answer,
      ...addAutosavedMedia(ssQuestion.get('answer')),
    },
  };
}

export default async function buildQuestionValues(values, ssQuestions = null) {
  const questions = values.questions || [];
  const questionsMapped = [];

  for (let i = 0; i < questions.length; i += 1) {
    let updated = questions[i];

    if (!updated && ssQuestions) {
      updated = buildBasicQuestionForSubmission(i, ssQuestions);
    }

    if (updated && ssQuestions) {
      updated = addAutosavedMediaToUpdated(i, ssQuestions, updated);
    }

    if (updated && updated.isRemoved === 'true') updated.isRemoved = true;
    if (updated) questionsMapped.push(updated);
  }

  const questionPromises = async () => {
    const promises = questionsMapped.map(question => buildValuesFromElementsWithFiles(question));

    return Promise.all(promises);
  };

  return questionPromises();
}
