import confetti from 'canvas-confetti';

export default function fireConfetti(confettiPixelFromTopStart) {
  let count = 500;
  let spreadMultiplier = 1;
  let startVelocityMultiplier = 1.25;
  let y = 1.0;

  if (window.innerWidth >= 768) {
    y = 0.98;

    if (confettiPixelFromTopStart) {
      y = confettiPixelFromTopStart / window.innerHeight;
    }

    count = 1000;
    spreadMultiplier = 1.35;
    startVelocityMultiplier = 1.85; // This will increase the "height" of the confetti
  }

  const defaults = {
    colors : ['#2071aa', '#b2dfdb', '#f4793e'],
    origin : { y },
    ticks  : 140,
  };

  function fire(particleRatio, opts) {
    confetti({ ...defaults, ...opts, particleCount : Math.floor(count * particleRatio) });
  }

  fire(0.25, {
    spread        : 26 * spreadMultiplier,
    startVelocity : 55 * startVelocityMultiplier,
  });

  fire(0.2, {
    spread        : 60 * spreadMultiplier,
    startVelocity : 45 * startVelocityMultiplier,
  });

  fire(0.35, {
    spread        : 100 * spreadMultiplier,
    decay         : 0.91,
    scalar        : 0.8,
    startVelocity : 45 * startVelocityMultiplier,
  });

  fire(0.1, {
    spread        : 120 * spreadMultiplier,
    startVelocity : 25 * startVelocityMultiplier,
    decay         : 0.92,
    scalar        : 1.2,
  });

  fire(0.1, {
    spread        : 120 * spreadMultiplier,
    startVelocity : 45 * startVelocityMultiplier,
  });
}
