import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import InfoText from 'src/components/Stats/Charts/InfoText';
import LineChart from 'src/components/Stats/Charts/LineChart';
import TextStats from 'src/components/Stats/Charts/TextStats';

import styles from './styles.module.scss';

function Percent(props) {
  const {
    isEmpty,
    report,
  } = props;

  const answersMemorized = report.get('answersMemorized');
  const currentlyMemorized = report.get('currentlyMemorized');
  const questionsBeingStudied = report.get('questionsBeingStudied');

  let value = '100%';

  if (currentlyMemorized !== 1.0) {
    value = `${(currentlyMemorized * 100).toFixed(1)}%`;
  }

  const stats = [
    {
      dataAutomatedTest : 'answers-memorized--percent--percent-currently-memorized',
      title             : 'percent currently memorized',
      value,
    },
  ];

  return (
    <div className={ styles.Root }>
      <InfoText>
        The percentage
        (<strong>{ answersMemorized }</strong> answers memorized{ ' ' }
        / <strong>{ questionsBeingStudied }</strong> questions being studied)
        is relative to the questions you are studying as of
        today (<strong>{ questionsBeingStudied }</strong>)
        or were studying the day the stats were recorded.
        You can modify what you study in your{ ' ' }
        <Link to="/profile/preferences">quiz settings</Link>.
      </InfoText>

      <TextStats stats={ stats } />
      <LineChart isEmpty={ isEmpty } report={ report } />
    </div>
  );
}

Percent.propTypes = {
  isEmpty : PropTypes.bool,
  report  : statsPropTypes.answersMemorizedPercent.isRequired,
};

export default Percent;
