import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

export const audioFileInputName = PropTypes.string;

export const autosavedAudio = ImmutablePropTypes.mapContains({
  audioDurationInSeconds : PropTypes.number.isRequired,
  io                     : PropTypes.string.isRequired,
  name                   : PropTypes.string.isRequired,
  size                   : PropTypes.number.isRequired,
  type                   : PropTypes.string.isRequired,
});

export const autosavedImage = ImmutablePropTypes.mapContains({
  io   : PropTypes.string.isRequired,
  name : PropTypes.string.isRequired,
  size : PropTypes.number.isRequired,
  type : PropTypes.string.isRequired,
});

export const closeDeletionModal = PropTypes.func;
export const closeImageModal = PropTypes.func;
export const deleteAudio = PropTypes.func;
export const deleteImage = PropTypes.func;
export const deleteQuestion = PropTypes.func;
export const fileInputName = PropTypes.string;
export const fromNew = PropTypes.bool;
export const getFileList = PropTypes.func;
export const handleFieldChange = PropTypes.func;
export const heading = PropTypes.string;
export const imageFileInputName = PropTypes.string;
export const isImageModalOpen = PropTypes.bool;
export const isRecallEvaluated = PropTypes.bool;
export const isSuccessful = PropTypes.bool;

export const question = ImmutablePropTypes.mapContains({
  answer : ImmutablePropTypes.mapContains({
    body : PropTypes.string.isRequired,
  }).isRequired,
  id    : PropTypes.string.isRequired,
  title : PropTypes.string.isRequired,
});

export const questions = ImmutablePropTypes.listOf(question);
export const questionsSize = PropTypes.number;
export const questionsMapped = ImmutablePropTypes.mapOf(question);
export const savedAudioUrl = PropTypes.string;
export const savedImageUrl = PropTypes.string;
export const shouldShowEditLink = PropTypes.bool;
export const timeTaken = PropTypes.number;
export const thumbnailSrc = PropTypes.string;
