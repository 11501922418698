import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class Shared {
  propTypes = {
    dataAutomatedTest : basePropTypes.dataAutomatedTest,
    defaultSpacing    : basePropTypes.defaultSpacing,
    isCentered        : basePropTypes.isCentered,
    isLeft            : basePropTypes.isLeft,
  };

  getRootClassNames() {
    const {
      defaultSpacing,
      isCentered,
      isLeft,
    } = this.props;

    return cx({
      Root : true,
      defaultSpacing,
      isCentered,
      isLeft,
    });
  }

  getTitleClassNames() {
    return cx({
      Title      : true,
      hasValue   : this._hasValue(),
      isDisabled : this.props.disabled,
      isFocused  : this.isFocused,
      isWeb      : this.isWeb,
    });
  }

  setClassProperties(classProperties) {
    const { props } = classProperties;

    this.props = props;
  }
}

export default Shared;
