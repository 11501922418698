import { Link } from 'react-router-dom';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import FormattedQuestionContent from 'src/components/FormattedQuestionContent';

import styles from './styles.module.scss';

function Preview({ basePath, question, studySet : passedStudySet }) {
  const forgottenBy = question.get('forgottenByDate') ||
    'Not enough data to make a prediction yet';

  const studySet = passedStudySet || question.get('studySet');
  const studySetLink = studySet && `${basePath || '/study-sets'}/${studySet.get('id')}`;

  return (
    <div className={ styles.Root }>
      <h3 className={ styles.QuestionHeading }>Question</h3>

      <FormattedQuestionContent
        audioSrc={ question.get('audioUrl') }
        imageSrc={ question.get('imageUrl') }
        value={ question.get('title') }
      />

      <h3 className={ styles.AnswerHeading }>Answer</h3>

      <FormattedQuestionContent
        audioSrc={ question.getIn(['answer', 'audioUrl']) }
        imageSrc={ question.getIn(['answer', 'imageUrl']) }
        value={ question.getIn(['answer', 'body']) }
      />

      <If condition={ studySet }>
        <h3 className={ styles.StudySetHeading }>Study set</h3>
        <div className={ styles.StudySetLink }>
          <Link to={ studySetLink }>{ studySet.get('title') }</Link>
        </div>
      </If>

      <If condition={ !basePath || !basePath.includes('/public') }>
        <h3 className={ styles.OtherInfoHeading }>Other information:</h3>
        <p>Predicted to be forgotten by: <i>{ forgottenBy }</i></p>
      </If>
    </div>
  );
}

Preview.propTypes = {
  basePath : basePropTypes.basePath,
  question : questionPropTypes.question.isRequired,
  studySet : studySetPropTypes.studySet,
};

export default Preview;
