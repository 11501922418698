import gql from 'graphql-tag';

export default gql`
  query searchForUserQuestions (
    $filter: String
    $sort: String
  ) {
    searchForUserQuestions (
      filter: $filter
      sort: $sort
    ) {
      results {
        answer {
          body
          imageUrl
        }
        forgottenByDate
        id
        imageUrl
        title
      }
    }
  }
`;
