// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___HS2f1{background:#262322;border-radius:5px;color:#fff;max-width:300px;padding:10px;text-align:center}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Tooltip/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,iBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  background: #262322;\n  border-radius: 5px;\n  color: #ffffff;\n  max-width: 300px;\n  padding: 10px;\n  text-align: center; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___HS2f1"
};
module.exports = exports;
