import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import Field from 'src/components/forms/Question/Field';
import TextField from 'src/components/fields/TextField';

import styles from './styles.module.scss';

function Fields(props) {
  const {
    deleteAnswerAudio,
    deleteAnswerImage,
    deleteQuestionAudio,
    deleteQuestionImage,
    errors,
    handleFieldChange,
    index,
    isActive,
    question,
    questionKey,
    register,
    setValue,
    shouldAutoFocusQuestion,
  } = props;

  const id = question && question.get('id');
  const answerId = question && question.getIn(['answer', 'id']);
  const answer = question && question.get('answer');

  const title = question && question.get('title');
  const answerBody = answer && answer.get('body');

  const autosavedAnswerAudio = answer && answer.get('autosavedAudio');
  const autosavedQuestionAudio = question && question.get('autosavedAudio');
  const autosavedAnswerImage = answer && answer.get('autosavedImage');
  const autosavedQuestionImage = question && question.get('autosavedImage');

  const answerAudioUrl = answer && answer.get('audioUrl');
  const answerImageUrl = answer && answer.get('imageUrl');
  const questionAudioUrl = question && question.get('audioUrl');
  const questionImageUrl = question && question.get('imageUrl');

  const isRemoved = question && question.get('isRemoved');

  function handleQuestionChange({ target : { value } }) {
    handleFieldChange(['questions', questionKey.toString(), 'title'], value);
  }

  function handleAnswerChange({ target : { value } }) {
    handleFieldChange(['questions', questionKey.toString(), 'answer', 'body'], value);
  }

  function deleteAnswerAudioFromStudySet() {
    deleteAnswerAudio(answerId, questionKey.toString());
  }

  function deleteAnswerImageFromStudySet() {
    deleteAnswerImage(answerId, questionKey.toString());
  }

  function deleteQuestionAudioFromStudySet() {
    deleteQuestionAudio(id, questionKey.toString());
  }

  function deleteQuestionImageFromStudySet() {
    deleteQuestionImage(id, questionKey.toString());
  }

  return (
    <div className={ styles.Root }>
      <div
        className={ styles.Question }
        data-automated-test="form-study-set-question-li--question"
      >
        <Field
          audioFileInputName={ `questions[${index}][audioFile]` }
          autosaveAudioKey={ ['questions', index.toString(), 'autosavedAudio'] }
          autosavedAudio={ autosavedQuestionAudio }
          autosavedImage={ autosavedQuestionImage }
          autosaveImageKey={ ['questions', index.toString(), 'autosavedImage'] }
          deleteAudio={ deleteQuestionAudioFromStudySet }
          deleteImage={ deleteQuestionImageFromStudySet }
          handleFieldChange={ handleFieldChange }
          id={ id }
          imageFileInputName={ `questions[${index}][imageFile]` }
          isActive={ isActive }
          register={ register }
          savedAudioUrl={ questionAudioUrl }
          savedImageUrl={ questionImageUrl }
          setValue={ setValue }
        >
          <TextField
            autoFocus={ shouldAutoFocusQuestion }
            autoFocusDelayInMs={ shouldAutoFocusQuestion && 200 }
            defaultValue={ title || '' }
            disabled={ isActive }
            errorMessages={ errors && errors.get(`questions[${index}][title]`) }
            heading="Question"
            htmlFor={ `questions[${index}][title]` }
            isRemoved={ isRemoved }
            isTextArea
            name={ `questions[${index}][title]` }
            onChange={ handleQuestionChange }
            readOnly={ isRemoved }
            register={ register }
          />
        </Field>
      </div>

      <div
        className={ styles.Answer }
        data-automated-test="form-study-set-question-li--answer"
      >
        <Field
          audioFileInputName={ `questions[${index}][answer][audioFile]` }
          autosaveAudioKey={ ['questions', index.toString(), 'answer', 'autosavedAudio'] }
          autosavedAudio={ autosavedAnswerAudio }
          autosavedImage={ autosavedAnswerImage }
          autosaveImageKey={ ['questions', index.toString(), 'answer', 'autosavedImage'] }
          deleteAudio={ deleteAnswerAudioFromStudySet }
          deleteImage={ deleteAnswerImageFromStudySet }
          handleFieldChange={ handleFieldChange }
          id={ answerId }
          imageFileInputName={ `questions[${index}][answer][imageFile]` }
          isActive={ isActive }
          register={ register }
          savedAudioUrl={ answerAudioUrl }
          savedImageUrl={ answerImageUrl }
          setValue={ setValue }
        >
          <TextField
            defaultValue={ answerBody || '' }
            disabled={ isActive }
            errorMessages={ errors && errors.get(`questions[${index}][answer][body]`) }
            heading="Answer"
            htmlFor={ `questions[${index}][answer][body]` }
            isRemoved={ isRemoved }
            isTextArea
            name={ `questions[${index}][answer][body]` }
            onChange={ handleAnswerChange }
            readOnly={ isRemoved }
            register={ register }
          />
        </Field>
      </div>
    </div>
  );
}

Fields.propTypes = {
  deleteAnswerAudio       : questionPropTypes.deleteAudio,
  deleteAnswerImage       : questionPropTypes.deleteImage,
  deleteQuestionAudio     : questionPropTypes.deleteAudio,
  deleteQuestionImage     : questionPropTypes.deleteImage,
  errors                  : basePropTypes.errors,
  handleFieldChange       : questionPropTypes.handleFieldChange.isRequired,
  index                   : basePropTypes.index.isRequired,
  isActive                : basePropTypes.isActive,
  question                : studySetPropTypes.autosavedQuestion.isRequired,
  questionKey             : studySetPropTypes.questionKey.isRequired,
  register                : basePropTypes.register.isRequired,
  setValue                : basePropTypes.setValue.isRequired,
  shouldAutoFocusQuestion : PropTypes.bool,
};

export default Fields;
