import gql from 'graphql-tag';

import studySetFields from 'src/graphql/fragments/studySetFields';

export default gql`
  query showStudySet(
    $id: ID!
  ) {
    showStudySet(
      id: $id
    ) {
      ...studySetFields
    }
  }
  ${studySetFields}
`;
