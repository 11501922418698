// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___20Spp{display:flex;flex-direction:column;grid-gap:16px;gap:16px;padding:16px 20px 20px}.styles-module__StatsPlaceholder___1wl6X{margin-top:12px}.styles-module__Chart___3pTG0{height:200px;width:100%}.styles-module__Chart___3pTG0>div{margin-left:-25px}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/Stats/Charts/QuestionsStudied/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,sBAAA,CAEF,yCACE,eAAA,CAEF,8BACE,YAAA,CACA,UAAA,CACA,kCACE,iBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 16px 20px 20px; }\n\n.StatsPlaceholder {\n  margin-top: 12px; }\n\n.Chart {\n  height: 200px;\n  width: 100%; }\n  .Chart > div {\n    margin-left: -25px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___20Spp",
	"StatsPlaceholder": "styles-module__StatsPlaceholder___1wl6X",
	"Chart": "styles-module__Chart___3pTG0"
};
module.exports = exports;
