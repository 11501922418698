import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import ButtonLink from 'src/components/buttons/ButtonLink';
import CloseIcon from 'src/components/icons/Close';
import Header from 'src/components/navigation/Header';
import Logo from 'src/components/images/logos/FaceOnly';

import LinkItem from './LinkItem';
import ProfileIcon from './ProfileIcon';
import Unauthed from './Unauthed';
import styles from './styles.module.scss';

function Desktop(props) {
  const {
    closeBanner,
    isBannerClosed,
    isCloserToSun,
    isUnauthed,
    pathname,
  } = props;

  return (
    <Header isCloserToSun={ isCloserToSun } noPadding>
      <If condition={ !isBannerClosed && closeBanner }>
        <div className={ styles.Banner }>
          <p>
            Use our new { ' ' }
            { /* eslint-disable-next-line max-len */ }
            <a href="https://chromewebstore.google.com/detail/dorothy-memory-app-questi/jhnpaghcddfjekfkcfaoafjnpipdmbip?hl=en-US&utm_source=ext_sidebar" target="_blank" rel="noreferrer">
              Chrome Extension
            </a>{ ' ' }
            to easily add questions to your study sets with AI.
          </p>

          <div className={ styles.BannerCloseButton } onClick={ closeBanner }>
            <CloseIcon />
          </div>
        </div>
      </If>

      <div className={ styles.Container }>
        <Choose>
          <When condition={ isUnauthed }>
            <Unauthed pathname={ pathname } />
          </When>

          <Otherwise>
            <ul className={ styles.LeftSideContainer }>
              <li className={ styles.Logo }>
                <Link to={ '/' }>
                  <Logo dataAutomatedTest="home-logo-desktop-link" isLeft />
                </Link>
              </li>

              <li>
                <LinkItem
                  isActive={ pathname === '/' }
                  title="Home"
                  to="/"
                />
              </li>

              <li>
                <LinkItem
                  dataAutomatedTest="site-wide-links--stats"
                  isActive={ pathname === '/stats' }
                  title="Your stats"
                  to="/stats"
                />
              </li>

              <li>
                <LinkItem
                  dataAutomatedTest="site-wide-links--questions"
                  isActive={ pathname === '/questions' }
                  title="Your questions"
                  to="/questions"
                />
              </li>

              <li>
                <LinkItem
                  dataAutomatedTest="site-wide-links--study-sets"
                  isActive={ pathname === '/study-sets' }
                  title="Your study sets"
                  to="/study-sets"
                />
              </li>

              <li>
                <LinkItem
                  isActive={ pathname === '/public-study-sets' }
                  title="Public study sets"
                  to="/public-study-sets"
                />
              </li>
            </ul>

            <ul className={ styles.RightSideContainer }>
              <li className={ styles.AddQuestionContainer }>
                <ButtonLink
                  floatRight
                  isInHeader
                  isSecondary
                  text="Add study set"
                  to="/study-sets/new"
                  withPlusSign
                />
              </li>

              <li className={ styles.ProfileIcon }>
                <div className={ styles.Divider }>|</div>

                <Link to={ '/profile' }>
                  <ProfileIcon isActive={ pathname.includes('/profile') } />
                </Link>
              </li>
            </ul>
          </Otherwise>
        </Choose>
      </div>
    </Header>
  );
}

Desktop.propTypes = {
  isCloserToSun : PropTypes.bool,
  isUnauthed    : PropTypes.bool,
  pathname      : basePropTypes.pathname.isRequired,
};

export default Desktop;
