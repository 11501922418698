import Immutable from 'immutable';

export default Immutable.fromJS({
  dashboardReport : {
    averageFutureRetention : 0,
    completedQuizzesSize   : 0,
    lastQuizWasStacked     : false,
    quizStreak             : 0,
    totalAnswersMemorized  : 0,
  },
  fullReport : null,
  isActive   : false,
});
