import React, { PureComponent } from 'react';

import NotFound from 'src/components/NotFound';

class FunctionalNotFound extends PureComponent {
  render() {
    return <NotFound { ...this.props } />;
  }
}

export default FunctionalNotFound;
