import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

export const accept = PropTypes.string;
export const actions = PropTypes.objectOf(PropTypes.objectOf(PropTypes.func));
export const audioSrc = PropTypes.string;
export const autoFocus = PropTypes.bool;
export const basePath = PropTypes.string;

export const breadcrumbLinks = PropTypes.arrayOf(
  PropTypes.shape({
    isCurrent : PropTypes.bool,
    label     : PropTypes.string.isRequired,
    path      : PropTypes.string,
  }),
);

export const buttonText = PropTypes.string;
export const children = PropTypes.node;
export const control = PropTypes.object;
export const className = PropTypes.object;
export const closeModal = PropTypes.func;
export const dataAutomatedTest = PropTypes.string;
export const defaultChecked = PropTypes.bool;
export const defaultSpacing = PropTypes.bool;
export const defaultMultiSelectValue = PropTypes.array;
export const defaultValue = PropTypes.string;
export const defaultNumberValue = PropTypes.number;
export const disabled = PropTypes.bool;
export const errorMessage = PropTypes.string;
export const errorMessages = ImmutablePropTypes.list;
export const errors = ImmutablePropTypes.mapOf(PropTypes.object);
export const extraClasses = PropTypes.objectOf(PropTypes.string);
export const extraProps = PropTypes.object;
export const floatRight = PropTypes.bool;
export const fromNew = PropTypes.bool;
export const h1 = PropTypes.bool;
export const h2 = PropTypes.bool;
export const h3 = PropTypes.bool;
export const h4 = PropTypes.bool;
export const h5 = PropTypes.bool;
export const h6 = PropTypes.bool;
export const handleChange = PropTypes.func;
export const handleClick = PropTypes.func;
export const handleSubmit = PropTypes.func;
export const hasCloseButton = PropTypes.bool;
export const hasSortOptions = PropTypes.bool;
export const hasOutline = PropTypes.bool;
export const hash = PropTypes.string;
export const heading = PropTypes.string;

export const history = PropTypes.shape({
  goBack : PropTypes.func.isRequired,
  push   : PropTypes.func.isRequired,
});

export const htmlFor = PropTypes.string;
export const iconClassName = PropTypes.string;
export const id = PropTypes.string;
export const imageSrc = PropTypes.string;
export const index = PropTypes.number;
export const inputMode = PropTypes.string;
export const isActive = PropTypes.bool;
export const isAnimated = PropTypes.bool;
export const isBelowInput = PropTypes.bool;
export const isBold = PropTypes.bool;
export const isCentered = PropTypes.bool;
export const isDisabled = PropTypes.bool;
export const isEditing = PropTypes.bool;
export const isInFooter = PropTypes.bool;
export const isInHeader = PropTypes.bool;
export const isLeft = PropTypes.bool;
export const isLoading = PropTypes.bool;
export const isPositiveAction = PropTypes.bool;
export const isSaving = PropTypes.bool;
export const isSecondary = PropTypes.bool;
export const isStopped = PropTypes.bool;
export const isSuccessful = PropTypes.bool;
export const isTertiary = PropTypes.bool;
export const isTextArea = PropTypes.bool;
export const isUpdating = PropTypes.bool;
export const leverTitleClassNames = PropTypes.string;
export const linksOut = PropTypes.bool;

export const location = PropTypes.shape({
  pathname : PropTypes.string,
  state    : PropTypes.shape({
    pathFrom : PropTypes.string,
  }),
});

export const match = PropTypes.shape({
  params : PropTypes.shape({
    id             : PropTypes.string,
    questionNumber : PropTypes.string,
  }).isRequired,
});

export const name = PropTypes.string;
export const noBackButton = PropTypes.bool;
export const noPadding = PropTypes.bool;
export const numberedText = PropTypes.string;
export const onChange = PropTypes.func;
export const onChecked = PropTypes.func;
export const onPress = PropTypes.func;
export const options = PropTypes.array;
export const onSubmit = PropTypes.func;
export const onUnchecked = PropTypes.func;
export const pathFrom = PropTypes.string;
export const pathname = PropTypes.string;
export const pathTo = PropTypes.string;
export const placeholder = PropTypes.string;
export const questionNumber = PropTypes.string;
export const register = PropTypes.func;
export const removeFile = PropTypes.func;
export const removeImage = PropTypes.func;
export const result = ImmutablePropTypes.map;
export const resultName = PropTypes.string;
export const results = ImmutablePropTypes.listOf(result);
export const rightSideHasHomeLink = PropTypes.bool;
export const rightSidePara = PropTypes.string;
export const saveButtonValue = PropTypes.string;
export const seconds = PropTypes.number;
export const setIsLoading = PropTypes.func;
export const setValue = PropTypes.func;
export const shouldAnimate = PropTypes.bool;
export const shouldCloseOnOverlayClick = PropTypes.bool;
export const shouldHideOverflow = PropTypes.bool;
export const shouldReset = PropTypes.bool;
export const src = PropTypes.string;
export const style = PropTypes.object;
export const submitAction = PropTypes.func;
export const submitActionName = PropTypes.string;
export const subTitle = PropTypes.string;

export const tabs = PropTypes.arrayOf(
  PropTypes.shape({
    index : PropTypes.number.isRequired,
    label : PropTypes.string.isRequired,
    value : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
);

export const target = PropTypes.object;
export const text = PropTypes.string;
export const title = PropTypes.string;
export const titleAutoFocusDelayInMs = PropTypes.number;
export const to = PropTypes.string;
export const type = PropTypes.string;
export const value = PropTypes.string;
export const withArrow = PropTypes.bool;
export const withDownArrow = PropTypes.bool;
export const withPencil = PropTypes.bool;
export const withPlusSign = PropTypes.bool;
export const withSaveButton = PropTypes.bool;
