import gql from 'graphql-tag';

export default gql`
  mutation addPublicStudySet(
    $id: ID!
  ) {
    addPublicStudySet(
      id: $id
    ) {
      id
    }
  }
`;
