import React from 'react';

function Ellipses() {
  return (
    <svg width="34" height="6" viewBox="0 0 34 6">
      <circle cx="3" cy="3" r="3"/>
      <circle cx="17" cy="3" r="3"/>
      <circle cx="31" cy="3" r="3"/>
    </svg>
  );
}

export default Ellipses;
