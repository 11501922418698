import { PropTypes } from 'prop-types';

export const accessToken = PropTypes.string;
export const client = PropTypes.string;
export const email = PropTypes.string;
export const emailSentTo = PropTypes.string;
export const expiry = PropTypes.string;
export const handleSignout = PropTypes.func;
export const handleSubmit = PropTypes.func;
export const isFirstSignIn = PropTypes.bool;
export const isLocked = PropTypes.bool;
export const name = PropTypes.string;
export const justSignedOut = PropTypes.bool;
export const pathFrom = PropTypes.string;
export const uid = PropTypes.string;
export const userId = PropTypes.string;
