// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__LinkContainer___3qidF,.styles-module__BackLinkContainer___3kyNl{margin-bottom:24px}.styles-module__BackLinkContainer___3kyNl{display:flex;flex-direction:row;justify-content:flex-start}.styles-module__BackLinkContainer___3kyNl p{color:#2071aa;cursor:pointer;text-decoration:none}.styles-module__BackLinkContainer___3kyNl p:hover{cursor:pointer}.styles-module__BackLinkContainer___3kyNl p *:hover{cursor:pointer}@media screen and (max-width: 568px){.styles-module__BackLinkContainer___3kyNl{display:none}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/ShowQuestion/Navigation/styles.module.scss"],"names":[],"mappings":"AAAA,gFAEE,kBAAA,CAEF,0CACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,4CACE,aAAA,CACA,cAAA,CACA,oBAAA,CACA,kDACE,cAAA,CACF,oDACE,cAAA,CACJ,qCACE,0CACE,YAAA,CAAA","file":"styles.module.scss","sourcesContent":[".LinkContainer,\n.BackLinkContainer {\n  margin-bottom: 24px; }\n\n.BackLinkContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start; }\n  .BackLinkContainer p {\n    color: #2071aa;\n    cursor: pointer;\n    text-decoration: none; }\n    .BackLinkContainer p:hover {\n      cursor: pointer; }\n    .BackLinkContainer p *:hover {\n      cursor: pointer; }\n  @media screen and (max-width: 568px) {\n    .BackLinkContainer {\n      display: none; } }\n"]}]);
// Exports
exports.locals = {
	"LinkContainer": "styles-module__LinkContainer___3qidF",
	"BackLinkContainer": "styles-module__BackLinkContainer___3kyNl"
};
module.exports = exports;
