import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as statsPropTypes from 'src/constants/propTypes/stats';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import OpeningContent from './OpeningContent';
import PublicStudySets from './PublicStudySets';
import Stats from './Stats';
import styles from './styles.module.scss';

class Dashboard extends PureComponent {
  static propTypes = {
    actions                  : basePropTypes.actions.isRequired,
    chosenStudySets          : studySetPropTypes.studySets.isRequired,
    expiry                   : authenticationPropTypes.expiry,
    featuredPublicStudySets  : studySetPropTypes.studySets,
    isActive                 : basePropTypes.isActive,
    isLoadingStats           : PropTypes.bool,
    location                 : basePropTypes.location.isRequired,
    maxQuestionAmountPerQuiz : PropTypes.number,
    name                     : authenticationPropTypes.name,
    onboardingStudySetId     : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    statsDashboardReport     : statsPropTypes.dashboardReport.isRequired,
    quiz                     : quizPropTypes.quiz,
  }

  componentDidMount() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);
  }

  render() {
    const {
      actions,
      chosenStudySets,
      expiry,
      featuredPublicStudySets,
      isActive,
      isBannerClosed,
      isLoadingStats,
      maxQuestionAmountPerQuiz,
      name,
      onboardingStudySetId,
      quiz,
      statsDashboardReport,
    } = this.props;

    return (
      <div
        className={ styles.Root }
        data-automated-test="dashboard-page"
      >
        <Choose>
          <When condition={ isActive || isLoadingStats }>
            <div className={ styles.PulseLoader }>
              <PulseLoadingIndicator isLoading={ isActive }/>
            </div>
          </When>

          <Otherwise>
            <div
              className={ styles.Container }
              data-automated-test="dashboard--opening-content"
            >
              <OpeningContent
                chosenStudySets={ chosenStudySets }
                expiry={ expiry }
                maxQuestionAmountPerQuiz={ maxQuestionAmountPerQuiz }
                name={ name }
                onboardingStudySetId={ onboardingStudySetId }
                quiz={ quiz }
                statsDashboardReport={ statsDashboardReport }
              />

              <Choose>
                <When
                  condition={ !quiz &&
                      featuredPublicStudySets &&
                      featuredPublicStudySets.size > 0 }
                >
                  <PublicStudySets publicStudySets={ featuredPublicStudySets } />
                </When>

                <Otherwise>
                  <Stats statsDashboardReport={ statsDashboardReport } />
                </Otherwise>
              </Choose>
            </div>
          </Otherwise>
        </Choose>

        <SiteWideLinks
          closeBanner={ actions.general && actions.general.closeBanner }
          isBannerClosed={ isBannerClosed }
          location={ this.props.location }
        />
      </div>
    );
  }
}

export default Dashboard;
