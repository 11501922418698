import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as profilePropTypes from 'src/constants/propTypes/profile';

import actionTypes from 'src/constants/actionTypes';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import FieldErrors from 'src/components/fields/FieldErrors';
import MinimalNumberField from 'src/components/fields/MinimalNumberField';

import styles from './styles.module.scss';

function MaxQuestionAmount(props) {
  const {
    errors,
    handleSubmit,
    isUpdating,
    maxQuestionAmountPerQuiz,
  } = props;

  const [isEditing, setIsEditing] = useState(false);

  function closeEditing() { setIsEditing(false); }
  function openEditing() { setIsEditing(true); }

  const { handleSubmit: formSubmit, register } = useForm();

  const onSubmit = formSubmit(data => handleSubmit(data)
    .then(({ type }) => {
      if (type === actionTypes.PROFILE__UPDATE_PROFILE_FAILURE) return;

      return closeEditing();
    }));

  const error = errors && errors.get('maxquestionamountperquiz');

  return (
    <div className={ styles.Root }>

      <form onSubmit={ onSubmit }>
        <div className={ styles.QuestionAmountContainer }>
          <div className={ styles.QuestionAmountDescription }>
            <p>Max amount of questions per quiz</p>

            <div className={ styles.DescriptionRightSide }>
              <MinimalNumberField
                defaultNumberValue={ maxQuestionAmountPerQuiz }
                disabled={ isUpdating || !isEditing }
                inputMode="numeric"
                name="maxQuestionAmountPerQuiz"
                register={ register }
                type="number"
              />

              <Choose>
                <When condition={ !isEditing }>
                  <p
                    className={ styles.Link }
                    onClick={ openEditing }
                  >edit</p>
                </When>

                <Otherwise>
                  <p
                    className={ styles.Link }
                    onClick={ closeEditing }
                  >cancel</p>
                </Otherwise>
              </Choose>
            </div>
          </div>

          <p className={ styles.SubText }>
            If you have more than { maxQuestionAmountPerQuiz } questions due,
            we’ll just roll the extra questions over to the next quiz.
            You can set this max as high as you want.
          </p>

          <If condition={ error }>
            <FieldErrors errorMessages={ error } />
          </If>
        </div>

        <If condition={ isEditing }>
          <ButtonSubmit
            disabled={ isUpdating }
            isActive={ isUpdating }
            type="submit"
            value="Save"
            isBelowInput
          />
        </If>
      </form>
    </div>
  );
}

MaxQuestionAmount.propTypes = {
  errors                   : basePropTypes.errors,
  handleSubmit             : basePropTypes.handleSubmit.isRequired,
  isUpdating               : basePropTypes.isUpdating,
  maxQuestionAmountPerQuiz : profilePropTypes.maxQuestionAmountPerQuiz.isRequired,
};

export default MaxQuestionAmount;
