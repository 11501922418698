import React from 'react';

import AllPublicStudySet from 'src/components/publicStudySets/All';

function AllPublicPublicStudySets(props) {
  return (
    <AllPublicStudySet
      isUnauthed
      { ...props }
    />
  );
}

export default AllPublicPublicStudySets;
