import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as searchActions from 'src/actions/search';

import getUrlParameter from 'src/shared/getUrlParameter';
import QuestionsScreen from 'src/components/QuestionsScreen';

class FunctionalAll extends PureComponent {
  static propTypes = {
    actions      : basePropTypes.actions.isRequired,
    isActive     : basePropTypes.isActive,
    isSuccessful : basePropTypes.isSuccessful,
    location     : basePropTypes.location.isRequired,
    questions    : questionPropTypes.questions.isRequired,
  };

  componentDidMount() {
    return this.searchForUserQuestions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.searchForUserQuestions();
    }
  }

  searchForUserQuestions = () => {
    const params = {
      filter : getUrlParameter('filter'),
      sort   : getUrlParameter('sort') || 'recently-answered',
    };

    return this.props.actions.search.searchForUserQuestions(params);
  }

  render() {
    return (
      <QuestionsScreen
        { ...this.props }
      />
    );
  }
}

function mapStateToProps({ search }) {
  return {
    isActive     : search.get('isActive'),
    isSuccessful : search.get('errors') === null,
    questions    : search.get('questions'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      search : bindActionCreators(searchActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalAll);
