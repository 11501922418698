import axios from 'axios';
import { print } from 'graphql/language/printer';
import * as Sentry from '@sentry/browser';

import apiBase from 'src/services/api/base';
import apiService from 'src/services/api';
import checkLastEmailStatus from 'src/graphql/queries/checkLastEmailStatus';
import handleError from 'src/services/shared/handleError';
import signInMutation from 'src/graphql/mutations/authentication/signIn';

import publicPost from './shared/publicPost';

export default {
  checkLastEmailStatus(values) {
    return publicPost(values, checkLastEmailStatus);
  },

  signIn(values) {
    const params = {
      query     : print(signInMutation),
      variables : { ...values },
    };

    // TODO: May not need to do anything with headers
    return apiService
      .post('/graphql/public', { graphql : params })
      .then(({
        data : { data : { signIn } },
        headers : { 'access-token' : accessToken, client },
      }) => ({ ...signIn, accessToken, client }))
      .catch(response => handleError(
        response,
        {
          default : 'signIn',
          401     : ['signIn'],
        },
      ));
  },

  signOut(headers) {
    return axios
      .create({ headers })
      .delete('/auth/sign_out')
      .catch(e => {
        // TODO: remove this in the future
        Sentry.captureException(e);
      });
  },

  verifyOtp(values) {
    // This Devise auth route is not available inside `/api`
    const apiBaseService = apiBase('');

    return apiBaseService
      .post('/auth/sign_in', values)
      .then(data => {
        const {
          headers : { 'access-token' : accessToken, client, expiry, uid },
          data : { is_first_sign_in : isFirstSignIn },
        } = data;

        return { accessToken, isFirstSignIn, client, expiry, uid };
      })
      .catch(response => handleError(
        response,
        {
          default : 'otp',
        },
      ));
  },
};
