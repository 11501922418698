import { PropTypes } from 'prop-types';
import React from 'react';

import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Card(props) {
  const {
    children,
    dataAutomatedTest,
    extraClassNames,
    isFullScreenOnDesktop,
    isFullScreenOnMobile,
    isSoloCardOnScreen,
    navLocationOnMobile,
  } = props;

  function getRootClassNames() {
    return cx({
      Root           : true,
      footerOnMobile : navLocationOnMobile === 'footer',
      headerOnMobile : navLocationOnMobile === 'header',
      isFullScreenOnDesktop,
      isFullScreenOnMobile,
      isSoloCardOnScreen,
    }, extraClassNames);
  }

  function getContainerClassNames() {
    return cx({
      Container      : true,
      footerOnMobile : navLocationOnMobile === 'footer',
      headerOnMobile : navLocationOnMobile === 'header',
      isFullScreenOnDesktop,
      isFullScreenOnMobile,
      isSoloCardOnScreen,
    });
  }

  return (
    <div
      className={ getRootClassNames() }
    >
      <div
        className={ getContainerClassNames() }
        data-automated-test={ dataAutomatedTest }
      >
        { children }
      </div>
    </div>
  );
}

Card.propTypes = {
  children              : PropTypes.node.isRequired,
  dataAutomatedTest     : PropTypes.string,
  extraClassNames       : PropTypes.string,
  isFullScreenOnDesktop : PropTypes.bool,
  isFullScreenOnMobile  : PropTypes.bool,
  isSoloCardOnScreen    : PropTypes.bool,
  navLocationOnMobile   : PropTypes.string,
};

export default Card;
