// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2H-HT{display:flex;flex-direction:column;justify-content:flex-start;margin-bottom:6px;margin-top:-12px}.styles-module__Root___2H-HT.styles-module__hasDescription___1XkeW{margin-top:9px}@media screen and (min-width: 768px){.styles-module__Root___2H-HT{display:flex;flex-direction:row;justify-content:flex-start;margin-bottom:18px;margin-top:-12px}.styles-module__Root___2H-HT.styles-module__hasDescription___1XkeW{margin-top:9px}}.styles-module__TextBadge___2LAzv{margin-top:6px}@media screen and (min-width: 768px){.styles-module__TextBadge___2LAzv{margin-right:9px;margin-top:0;margin-top:initial}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/studySets/Show/TextBadges/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,iBAAA,CACA,gBAAA,CACA,mEACE,cAAA,CACF,qCACE,6BACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CACA,gBAAA,CACA,mEACE,cAAA,CAAA,CAER,kCACE,cAAA,CACA,qCACE,kCACE,gBAAA,CACA,YAAA,CAAA,kBAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  margin-bottom: 6px;\n  margin-top: -12px; }\n  .Root.hasDescription {\n    margin-top: 9px; }\n  @media screen and (min-width: 768px) {\n    .Root {\n      display: flex;\n      flex-direction: row;\n      justify-content: flex-start;\n      margin-bottom: 18px;\n      margin-top: -12px; }\n      .Root.hasDescription {\n        margin-top: 9px; } }\n\n.TextBadge {\n  margin-top: 6px; }\n  @media screen and (min-width: 768px) {\n    .TextBadge {\n      margin-right: 9px;\n      margin-top: initial; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2H-HT",
	"hasDescription": "styles-module__hasDescription___1XkeW",
	"TextBadge": "styles-module__TextBadge___2LAzv"
};
module.exports = exports;
