import * as Sentry from '@sentry/browser';

import createSubscription from 'src/shared/serviceWorker/createSubscription';

function handleError(err) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(err); // eslint-disable-line no-console
  }

  Sentry.captureException(err, scope => {
    scope.setTag('error caught in updateNotificationSettingsOnChange', true);
    return scope;
  });
}

function handlePermissionStatusChange(permissionStatus, props) {
  const {
    actions : {
      profile,
      pushNotifications,
    },
    notificationSettings,
  } = props;

  if (!notificationSettings) return;

  const notificationSetting = notificationSettings
    .find(setting => setting.get('medium') === 'push');

  const optedOut = permissionStatus.state !== 'granted';

  if (optedOut) {
    return profile
      .updateNotificationSetting({
        id     : notificationSetting.get('id'),
        medium : 'push',
        optedOut,
      })
      .catch(handleError);
  }

  return createSubscription(pushNotifications.createPushNotification)
    .then(profile.show)
    .catch(handleError);
}

export default function updateNotificationSettingsOnChange(props) {
  if ('SyncManager' in window && 'permissions' in navigator) {
    navigator
      .permissions
      .query({ name : 'notifications' })
      .then(permissionStatus => {
        permissionStatus.onchange = () => handlePermissionStatusChange(permissionStatus, props);
      });
  }
}
