import actionTypes from 'src/constants/actionTypes';

export function addFirstStudySet(studySet) { // eslint-disable-line import/prefer-default-export
  return {
    type    : actionTypes.ONBOARDING__ADD_FIRST_STUDY_SET,
    payload : { studySet },
  };
}

export function clearOnboarding() { // eslint-disable-line import/prefer-default-export
  return {
    type : actionTypes.ONBOARDING__CLEAR_ONBOARDING,
  };
}
