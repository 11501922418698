import _ from 'lodash';
import Immutable from 'immutable';
import Papa from 'papaparse';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import BaseModal from 'src/components/modals/Base';
import ButtonDiv from 'src/components/buttons/ButtonDiv';
import classNames from 'src/components/shared/classNames';
import FileInput from 'src/components/fields/FileInput';
import LinkOutIcon from 'src/components/icons/LinkOut';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';
import TextField from 'src/components/fields/TextField';
import UploadFileIcon from 'src/components/icons/UploadFile';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function CsvImportModal(props) {
  const {
    addCSVQuestionsToStudySet,
    closeModal,
    savedCsv,
    studySetId,
    updateValueInAutosaved,
  } = props;

  const [csv, setCsv] = useState(savedCsv);
  const [csvData, setCsvData] = useState([]);
  const [csvErrorLines, setCsvErrorLines] = useState([]);
  const [errorMessages, setErrorMessages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function addErrorMessages(errors) {
    const nextErrorMessages = errors ||
      ['Oh no! It looks like something isn’t formatted correctly. Start by taking a look at how many commas you have on each line (there should only be one separating the question and answer). If you need help, click the "Correct format" link to learn more.']; // eslint-disable-line max-len

    setErrorMessages(Immutable.fromJS(nextErrorMessages));
  }

  useEffect(() => {
    const errorLines = [...csvErrorLines];

    if (errorLines.length === 0) return;

    let linesMessage;

    if (errorLines.length === 1) {
      linesMessage = `line ${errorLines[0]}`;
    } else if (errorLines.length === 2) {
      linesMessage = `lines ${errorLines[0]} and ${errorLines[1]}`;
    } else {
      const last = errorLines.pop();
      linesMessage = `lines ${errorLines.join(', ')}, and ${last}`;
    }

    addErrorMessages([
      `Oh no! It looks like there is an issue on ${linesMessage}.`,
      'Start by taking a look at how many commas you have on each line (there should only be one separating the question and answer). If you need help, click the "Correct format" link to learn more.', // eslint-disable-line max-len
    ]);
  }, [csvErrorLines]);

  const handleTextFieldChange = value => {
    const page = studySetId || 'new';
    const keys = ['autosaved', page, 'csv'];
    updateValueInAutosaved(keys, value);
  };

  function handleCSVTextAreaChange({ target : { value } }) {
    handleTextFieldChange(value);
    setCsv(value);
  }

  function buildQuestionsFromData(data) {
    const errorLines = [];

    const questions = data.map(line => {
      if (line.length === 0) return null;

      if (line[0].length === 0) return null;

      if (line.length !== 2) {
        const index = data.indexOf(line) + 1;
        errorLines.push(index);
        return null;
      }

      return {
        title  : line[0],
        answer : { body : line[1] },
      };
    });

    return {
      errorLines,
      questions : _.compact(questions),
    };
  }

  function handleSubmit() {
    setIsLoading(true);

    const parsed = Papa.parse(csv || '');
    const data = parsed && parsed.data;

    setCsvData(data);

    if (!data || data.length === 0) {
      addErrorMessages();
      return setIsLoading(false);
    }

    const { errorLines, questions } = buildQuestionsFromData(data);

    if (errorLines.length > 0) {
      setCsvErrorLines(errorLines);
      return setIsLoading(false);
    }

    addCSVQuestionsToStudySet(questions);
    setIsLoading(false);
    closeModal();
  }

  function trimEndOfCSVString(csvString) {
    let str = csvString;

    for (let i = str.length - 1; i >= 0; i -= 1) {
      const last = str[str.length - 1];

      if (last === '\n' || last === '\r') {
        str = str.substring(0, str.length - 1);
      } else {
        break;
      }
    }

    return str;
  }

  function handleCsvUpload(e) {
    const file = e.target.files[0];

    if (!file) return setIsLoading(false);

    setIsLoading(true);
    const reader = new FileReader();

    reader.onload = ({ target : { result } }) => {
      const str = trimEndOfCSVString(result);
      setCsv(str);
      setIsLoading(false);
    };

    reader.onerror = err => {
      Sentry.captureException(err, {
        extra : { message : 'reader.onerror called during CSV upload' },
      });
      setIsLoading(false);
    };

    reader.readAsText(file);
  }

  return (
    <BaseModal
      closeModal={ closeModal }
      extraClasses={ { container : styles.Root } }
      hasCloseButton={ !isLoading }
      shouldCloseOnOverlayClick={ !isLoading }
    >
      <div
        className={ styles.Container }
      >
        <Choose>
          <When condition={ isLoading }>
            <div className={ styles.PulseLoader }>
              <PulseLoadingIndicator isLoading={ isLoading }/>
            </div>
          </When>

          <Otherwise>
            <h3 className={ styles.Title }>Import CSV</h3>

            <p className={ styles.Para }>
              We will take your CSV and <i>add</i> them in bulk as questions to your
              study set. They will <strong>not</strong> replace any questions already in
              your study set.
            </p>

            <p className={ styles.Para }>
              <a
                href="https://en.wikipedia.org/wiki/Comma-separated_values"
                target="_blank" rel="noreferrer"
              >
                What is a CSV?<LinkOutIcon />
              </a>
            </p>

            <p className={ styles.Para }>
              <a
                href="https://en.wikipedia.org/wiki/Comma-separated_values#Example"
                rel="noreferrer"
                target="_blank"
              >
                Correct format<LinkOutIcon />
              </a>
            </p>

            <div className={ styles.UploadOptions }>
              <FileInput
                accept=".csv"
                fileInputName="csvUpload"
                handleChange={ handleCsvUpload }
              >
                <p className={ styles.UploadCsvFilePara }>Upload .csv file<UploadFileIcon /></p>
              </FileInput>

              <p className={ styles.UploadOptionsOrPara }>
                - or -
              </p>

              <p className={ styles.UploadOptionPara }>
                Copy and paste
              </p>
            </div>

            <div className={ styles.CSVTextAreaContainer }>
              <If condition={ csvErrorLines.length > 0 }>
                <ul className={ styles.CSVErrorLinesNumberList }>
                  <For each="dataPoint" index="i" of={ csvData }>
                    <li
                      className={ cx({ isErrorLine : csvErrorLines.includes(i + 1) }) }
                      key={ i }
                    >{ i + 1 }</li>
                  </For>
                </ul>
              </If>

              <TextField
                dataAutomatedTest="csv-import-modal--textarea"
                defaultValue={ csv }
                disabled={ isLoading }
                errorMessages={ errorMessages }
                isTextArea
                name="csv-upload"
                onChange={ handleCSVTextAreaChange }
                placeholder={ 'question,answer\nquestion,answer\nquestion,answer' }
              />
            </div>
          </Otherwise>
        </Choose>
      </div>

      <div className={ styles.ButtonContainer }>
        <ButtonDiv
          dataAutomatedTest="csv-import-modal--import-questions-button"
          disabled={ isLoading }
          handleClick={ handleSubmit }
          isSecondary
          value="Import questions to study set"
        />
      </div>
    </BaseModal>
  );
}

CsvImportModal.propTypes = {
  addCSVQuestionsToStudySet : studySetPropTypes.addCSVQuestionsToStudySet.isRequired,
  closeModal                : basePropTypes.closeModal.isRequired,
  studySetId                : PropTypes.string,
  savedCsv                  : PropTypes.string,
  updateValueInAutosaved    : PropTypes.func.isRequired,
};

export default CsvImportModal;
