import createAttempt from 'src/graphql/mutations/questions/createAttempt';
import updateAttempt from 'src/graphql/mutations/questions/updateAttempt';

import post from './shared/post';

export default {
  create(values) {
    return post(values, createAttempt);
  },

  update(values) {
    return post(values, updateAttempt);
  },
};
