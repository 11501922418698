import React, { memo, useEffect, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';

import analytics from 'src/shared/analytics';
import ButtonLink from 'src/components/buttons/ButtonLink';
import getUrlParameter from 'src/shared/getUrlParameter'; import ResultsList from 'src/components/ResultsList';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import QuestionResult from 'src/components/ResultsList/QuestionResult';
import SiteWideLinks from 'src/components/navigation/SiteWideLinks';
import SortOption from 'src/components/sorter/SortOption';
import SortOptions from 'src/components/sorter/SortOptions';
import SortOptionsOpener from 'src/components/sorter/SortOptionsOpener';

import SearchContainer from './SearchContainer';
import styles from './styles.module.scss';

function QuestionsScreen(props) {
  const {
    history,
    isActive,
    isSuccessful,
    location,
    questions,
  } = props;

  const currentFilterParam = getUrlParameter('filter');

  const [isSortOptionsOpen, setIsSortOptionsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeSortOptions = () => setIsSortOptionsOpen(false);
  const openSortOptions = () => setIsSortOptionsOpen(true);

  useEffect(closeSortOptions, [location.search]);

  const size = (questions && questions.size) || 0;

  function handleAddFirstQuestionButtonClick() {
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    analytics.track('Add a question clicked from /questions');
  }

  return (
    <div className={ styles.Root }>
      <div className={ styles.Container }>
        <Choose>
          <When condition={ currentFilterParam || size > 0 }>
            <div className={ styles.ButtonContainer }>
              <ButtonLink
                floatRight
                isSecondary
                text="New question"
                to="/questions/new"
                withPlusSign
              />

              <ButtonLink
                extraClassNames={ styles.ButtonLink }
                floatRight
                href="https://dorothymemoryapp.com/chrome-extension/"
                linksOut
                text="Generate questions with AI"
                withBrain
              />
            </div>

            <SearchContainer
              history={ history }
              setIsLoading={ setIsLoading }
            />

            <SortOptionsOpener
              defaultSortedBy="Recently Answered"
              openSortOptions={ openSortOptions }
            />

            <If condition={ isSortOptionsOpen }>
              <SortOptions closeSortOptions={ closeSortOptions }>
                <SortOption
                  defaultSortedBy="recently-answered"
                  name="Recently Answered"
                  sort="recently-answered"
                />

                <SortOption
                  defaultSortedBy="recently-answered"
                  name="Recently Created"
                  sort="recently-created"
                />

                <SortOption
                  defaultSortedBy="recently-answered"
                  name="Title"
                  sort="title"
                />

                <SortOption
                  defaultSortedBy="recently-answered"
                  name="Predicted to be Forgotten By"
                  sort="due-date"
                />
              </SortOptions>
            </If>

            <ResultsList
              currentFilterParam={ currentFilterParam }
              hasSortOptions
              isLoading={ isActive || isLoading }
              resultName="Question"
              results={ questions }
            >
              <ul>
                <For each="question" index="i" of={ questions }>
                  <QuestionResult
                    key={ question.get('id') }
                    question={ question }
                  />
                </For>
              </ul>
            </ResultsList>
          </When>

          <When condition={ !currentFilterParam && size === 0 && isSuccessful && !isActive }>
            <div>
              <h3>0 Questions</h3>

              <div className={ styles.FirstQuestionButtonContainer }>
                <ButtonLink
                  handleClick={ handleAddFirstQuestionButtonClick }
                  text="Add a question"
                  to="/questions/new"
                />

                <ButtonLink
                  extraClassNames={ styles.ButtonLink }
                  href="https://dorothymemoryapp.com/chrome-extension/"
                  linksOut
                  noBorder
                  text="Generate questions with AI"
                  withBrain
                />
              </div>
            </div>
          </When>
        </Choose>

        <SiteWideLinks location={ props.location } />
      </div>
    </div>
  );
}

QuestionsScreen.propTypes = {
  history      : basePropTypes.history.isRequired,
  isActive     : basePropTypes.isActive,
  isSuccessful : basePropTypes.isSuccessful,
  location     : basePropTypes.location.isRequired,
  questions    : questionPropTypes.questions.isRequired,
};

export default memo(QuestionsScreen);
