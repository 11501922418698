import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import CountdownAnimation from './CountdownAnimation';

import styles from './styles.module.scss';

class CountdownTimer extends PureComponent {
  static propTypes = {
    isStopped   : basePropTypes.isStopped.isRequired,
    seconds     : basePropTypes.seconds.isRequired,
    shouldReset : basePropTypes.shouldReset.isRequired,
  }

  state = {
    secondsLeft : -1,
  }

  static getDerivedStateFromProps(props, state) {
    if (state.secondsLeft < 0 && props.seconds > 0) {
      return { secondsLeft : props.seconds };
    }

    return null;
  }

  componentDidMount() {
    this.tick();
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  clearTimeouts() {
    if (this.tickTimeout) clearTimeout(this.tickTimeout);
  }

  tick() {
    this.tickTimeout = setTimeout(this.reduceByOneSecond, 1000);
  }

  reduceByOneSecond = () => {
    const prevSecondsLeft = this.state.secondsLeft;

    if (prevSecondsLeft < 1) return;

    this.setState({ isResetting : false, secondsLeft : prevSecondsLeft - 1 }, this.tick);
  }

  render() {
    const {
      isStopped,
      seconds,
      shouldReset,
    } = this.props;

    return (
      <div className={ styles.Root }>
        <div className={ styles.Timer }>
          <CountdownAnimation
            isStopped={ isStopped }
            seconds={ seconds }
            shouldReset={ shouldReset }
          />

          <div className={ styles.Face }>{ Math.floor(this.state.secondsLeft) }</div>
        </div>
      </div>
    );
  }
}

export default CountdownTimer;
