import { Link } from 'react-router-dom';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';

import Card from 'src/components/Card';
import classNames from 'src/components/shared/classNames';
import FormattedQuestionContent from 'src/components/FormattedQuestionContent';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function QuestionResult(props) {
  const {
    basePath = '/questions',
    question,
  } = props;

  const pathname = `${basePath}/${question.get('id')}`;
  const questionAudio = question.get('audioUrl');

  return (
    <li className={ styles.Root }>
      <Card
        dataAutomatedTest="results-search-result"
        extraClassNames={ styles.Container }
      >
        <div className={ cx({ QuestionContainer : true, hasQuestionAudio : !!questionAudio }) }>
          <FormattedQuestionContent
            audioSrc={ questionAudio }
            imageSrc={ question.get('imageUrl') }
            value={ question.get('title') || '(no title)' }
          />

          <div className={ styles.VerticalLine } />
          <div className={ styles.HorizontalLine } />
        </div>

        <div className={ styles.AnswerContainer }>
          <FormattedQuestionContent
            audioSrc={ question.getIn(['answer', 'audioUrl']) }
            imageSrc={ question.getIn(['answer', 'imageUrl']) }
            value={ question.getIn(['answer', 'body']) }
          />
        </div>

        <div className={ styles.ViewContainer }>
          <p className={ styles.ViewContainerPara }>
            <Link
              className={ styles.Link }
              to={ { pathname, state : { pathFrom : basePath } } }
            >
              view
            </Link>
          </p>
        </div>
      </Card>
    </li>
  );
}

QuestionResult.propTypes = {
  basePath : basePropTypes.basePath,
  question : questionPropTypes.question.isRequired,
};

export default QuestionResult;
