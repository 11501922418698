// NOTE: The reason we're getting the duration on the client side is
// because we don't want to have to load the file server side
// everytime we need the duration. It's much more efficient to create
// an audio element and get the duration from that.
function handleAudio(result, resolve) {
  const audio = document.createElement('audio');

  audio.addEventListener('loadedmetadata', () => {
    const { duration } = audio;
    const hash = { io : result };

    if (duration) hash.audioDurationInSeconds = parseInt(duration, 10);

    return resolve(hash);
  }, false);

  audio.src = result;
}

export default function (fileList) {
  return new Promise(resolve => {
    if (!fileList || fileList.length === 0) resolve({});

    const file = fileList[0];
    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      if (!result.includes('data:audio')) return resolve({ io : result });

      return handleAudio(result, resolve);
    };

    reader.readAsDataURL(file);
  });
}
