/* eslint-disable no-useless-escape */

// https://davidwalsh.name/query-string-javascript
function buildRegex(key) {
  const name = key
    .replace(/[\[]/, '\\[')
    .replace(/[\]]/, '\\]');

  return new RegExp(`[\\?&]${name}=([^&#]*)`);
}

export default function getUrlParameter(key) {
  const regex = buildRegex(key);
  const search = window.location.hash.split('?')[1];

  if (!search) return null;

  const results = regex.exec(`?${search}`);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
/* eslint-enable no-useless-escape */
