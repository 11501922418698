// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___theXo{background:#262322;border-radius:6px;padding:12px;text-align:center}.styles-module__Label___KaZsm{color:#e9e9e9}.styles-module__Value___AvZsN{color:#fff;font-weight:600}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/charts/Tooltip/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,iBAAA,CAEF,8BACE,aAAA,CAEF,8BACE,UAAA,CACA,eAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  background: #262322;\n  border-radius: 6px;\n  padding: 12px;\n  text-align: center; }\n\n.Label {\n  color: #e9e9e9; }\n\n.Value {\n  color: #ffffff;\n  font-weight: 600; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___theXo",
	"Label": "styles-module__Label___KaZsm",
	"Value": "styles-module__Value___AvZsN"
};
module.exports = exports;
