import { PropTypes } from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';

import * as basePropTypes from 'src/constants/propTypes/base';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import TextField from 'src/components/fields/TextField';

import styles from './styles.module.scss';

function Feedback(props) {
  const {
    errors,
    handleSubmit,
    isActive,
  } = props;

  const { handleSubmit: formSubmit, register } = useForm();

  const onSubmit = formSubmit(handleSubmit);

  return (
    <form
      className={ styles.Root }
      onSubmit={ onSubmit }
    >
      <TextField
        autoFocus
        disabled={ isActive }
        errorMessages={ errors && errors.get('feedback') }
        heading={ null }
        isTextArea
        name="feedback"
        register={ register }
      />

      <ButtonSubmit
        disabled={ isActive }
        isActive={ isActive }
        isBelowInput
        type="submit"
        value="Send feedback"
      />
    </form>
  );
}

Feedback.propTypes = {
  errors       : basePropTypes.errors,
  handleSubmit : PropTypes.func.isRequired,
  isActive     : basePropTypes.isActive,
};

export default Feedback;
