import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

class Shared {
  propTypes = {
    errors        : basePropTypes.errors,
    isActive      : basePropTypes.isActive.isRequired,
    handleSubmit  : authenticationPropTypes.handleSubmit.isRequired,
    justSignedOut : authenticationPropTypes.justSignedOut,
    location      : basePropTypes.location.isRequired,
    pathFrom      : authenticationPropTypes.pathFrom,
  };

  /* eslint-disable max-len */
  chooseAlertBannerHeadingText() {
    if (this._hadInvalidOtp()) return 'Your one time password has expired. Enter your email and we\'d be happy to send you a new one.';
    if (this._hasUnlockedAccount()) return 'Your account has been unlocked! Enter your email one more time and we\'ll send you a new one time password to log in with.';

    const { justSignedOut, pathFrom } = this.props;

    if (justSignedOut) return 'You have been logged out. Log in again when you’re ready.';

    if (!['/', undefined].includes(pathFrom)) {
      return 'Oops! You must be logged in to view that page.';
    }
  }
  /* eslint-enable max-len */

  setClassProperties({ props }) {
    this.props = props;
  }

  // private

  _hadInvalidOtp() {
    try {
      return this.props.location.search.includes('invalid-otp=true');
    } catch (_) {
      return false;
    }
  }

  _hasUnlockedAccount() {
    try {
      return this.props.location.search.includes('unlocked=true');
    } catch (_) {
      return false;
    }
  }
}

export default Shared;
