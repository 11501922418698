import { combineReducers } from 'redux';
import attempts from './attempts';
import authentication from './authentication';
import general from './general';
import onboarding from './onboarding';
import payments from './payments';
import profile from './profile';
import publicStudySets from './publicStudySets';
import questions from './questions';
import quizzes from './quizzes';
import search from './search';
import stats from './stats';
import studySets from './studySets';

export default combineReducers({
  attempts,
  authentication,
  general,
  onboarding,
  payments,
  profile,
  publicStudySets,
  questions,
  quizzes,
  search,
  stats,
  studySets,
});
