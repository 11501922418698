import { PropTypes } from 'prop-types';

export const clearSearchResults = PropTypes.func;
export const closeSortOptions = PropTypes.func;
export const defaultSortedBy = PropTypes.string;
export const openSortOptions = PropTypes.func;
export const search = PropTypes.func;
export const searchRef = PropTypes.object;
export const setIsLoading = PropTypes.func;
export const shouldOnlySearchWithValue = PropTypes.bool;
export const size = PropTypes.number;
export const sort = PropTypes.func;
