import { PropTypes } from 'prop-types';
import React from 'react';

import * as profilePropTypes from 'src/constants/propTypes/profile';

import AlertBanner from 'src/components/AlertBanner';
import HorizontalLine from 'src/components/HorizontalLine';
import SwitchSlider from 'src/components/SwitchSlider';

import styles from './styles.module.scss';

function Notification(props) {
  const {
    isOnAppleDevice,
    isOnSafari,
    isServiceWorkerRegistered,
    handleNotificationSettingUpdate,
    notificationSetting,
  } = props;

  // TODO: temp
  if (!notificationSetting) return null;

  const optedOut = notificationSetting.get('optedOut');
  const medium = notificationSetting.get('medium');

  const disabled = medium === 'push' &&
    (isOnAppleDevice || isOnSafari || !isServiceWorkerRegistered);

  const grantedPermission = window.Notification &&
    window.Notification.permission === 'granted';

  function buildAlertBannerText() {
    if (isOnAppleDevice) {
      return 'Unfortunately, we cannot support push notifications on iPhones and iPads just yet. We\'re very sorry about this inconvenience!'; // eslint-disable-line max-len
    }

    if (isOnSafari) {
      return (
        <>
          Unfortunately, we cannot support push notifications on Safari just yet.
          However, if you open Dorothy
          on <strong>Chrome</strong>, <strong>Firefox</strong>, or <strong>Edge</strong>,
          you shouldn’t have any problems.
          We’re very sorry about this inconvenience!
        </>
      );
    }

    if (!isServiceWorkerRegistered) {
      return 'Oh no! It appears your service worker has not been registered in the browser. Please contact us at admin@dorothymemoryapp.com if you don\'t know how to fix this!'; // eslint-disable-line max-len
    }
  }

  return (
    <>
      <If condition={ disabled }>
        <AlertBanner isInfo >
          <p className={ styles.AlertBannerText }>
            { buildAlertBannerText() }
          </p>
        </AlertBanner>
      </If>

      <div
        className={ styles.SliderContainer }
        data-automated-test={ `profile--notifications--${notificationSetting.get('medium')}` }
      >
        <p>{ medium === 'email' ? 'Email' : 'Push notifications' }</p>

        <SwitchSlider
          defaultChecked={ !optedOut }
          disabled={ disabled }
          name={ notificationSetting.get('medium') }
          onChecked={ () => handleNotificationSettingUpdate(notificationSetting, false) }
          onUnchecked={ () => handleNotificationSettingUpdate(notificationSetting, true) }
        />
      </div>

      <If condition={ medium === 'push' && !optedOut && !grantedPermission }>
        <p className={ styles.SubHint }>
          If you are receiving push notifications on another device and would also
          like to receive push notifications on this device, simply turn off push
          notifications here (on this device) and then turn them back on.
        </p>
      </If>

      <div className={ styles.HorizontalLine }>
        <HorizontalLine />
      </div>
    </>
  );
}

Notification.propTypes = {
  handleNotificationSettingUpdate : profilePropTypes.handleNotificationSettingUpdate.isRequired,
  isOnAppleDevice                 : PropTypes.bool,
  isOnSafari                      : PropTypes.bool,
  isServiceWorkerRegistered       : PropTypes.bool,
  notificationSetting             : profilePropTypes.notificationSetting.isRequired,
};

export default Notification;
