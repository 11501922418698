import gql from 'graphql-tag';

export default gql`
  fragment quizFields on QuizType {
    completedAt
    dueDate
    estimatedTimeToCompleteInMinutes
    hasQuestionsWrongFromFssTakenToday
    id
    isFirstQuiz
    isSampleQuiz
    nextQuizDueInWords
    previousQuiz {
      completedAt
      dueDate
      isSampleQuiz
    }
    questions {
      answer {
        audioUrl
        body
        imageUrl
      }
      audioUrl
      estimatedReadingTime
      id
      idealStruggleTime
      imageUrl
      title
    }
    quizQuestionIds {
      questionId
      quizQuestionId
    }
    started
    studySetId
    userId
  }
`;
