import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';

import * as authenticationActions from 'src/actions/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

import ErrorScreen from 'src/components/ErrorScreen';

class FunctionalErrorScreen extends PureComponent {
  static propTypes = {
    actions : basePropTypes.actions.isRequired,
  };

  componentDidMount() {
    Sentry.captureMessage('FunctionalErrorScreen opened');
    this.props.actions.authentication.clearTempState();
  }

  render() {
    return <ErrorScreen { ...this.props } />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      authentication : bindActionCreators(authenticationActions, dispatch),
    },
  };
}

export default connect(null, mapDispatchToProps)(FunctionalErrorScreen);
