import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import * as attemptPropTypes from 'src/constants/propTypes/attempt';
import * as attemptsActions from 'src/actions/attempts';
import * as basePropTypes from 'src/constants/propTypes/base';
import * as profileActionCreators from 'src/actions/profile';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as quizzesActions from 'src/actions/quizzes';
import * as statsActions from 'src/actions/stats';

import QuizQuestion from 'src/components/quiz/Question';
import QuizQuestionContext from 'src/contexts/QuizQuestion';
import shouldRedirectToEnd from 'src/containers/quiz/shared/shouldRedirectToEnd';

class FunctionalQuizQuestion extends PureComponent {
  static propTypes = {
    actions                   : basePropTypes.actions.isRequired,
    history                   : basePropTypes.history.isRequired,
    match                     : basePropTypes.match.isRequired,
    previousAttempt           : attemptPropTypes.attempt,
    question                  : questionPropTypes.question.isRequired,
    questionNumber            : quizPropTypes.questionNumber.isRequired,
    questionsToBeAnsweredSize : quizPropTypes.questionsToBeAnsweredSize.isRequired,
    quiz                      : quizPropTypes.quiz.isRequired,
    shouldRedirectToHome      : PropTypes.bool,
  };

  componentDidMount() {
    const { attempts, quizzes } = this.props.actions;

    attempts.reset();
    quizzes.reset();
  }

  buildQuizQuestionContext() {
    return {
      isOnLastQuestion : this.isOnLastQuestion,
    };
  }

  createAttempt = () => {
    const {
      actions: { attempts, stats },
      question,
      quiz,
    } = this.props;

    const questionId = question.get('id');
    let quizQuestionId = null;

    try {
      quizQuestionId = quiz
        .get('quizQuestionIds')
        .find(idSet => idSet.get('questionId') === questionId.toString())
        .get('quizQuestionId');
    } catch (e) {
      Sentry.captureException(e);
      quizQuestionId = null;
    }

    return attempts
      .create({
        questionId,
        quizId           : quiz.get('id'),
        quizQuestionId,
        recallEvaluation : question.get('recallEvaluation'),
        timeTaken        : question.get('timeTaken'),
      })
      .then(() => {
        if (this.isOnLastQuestion()) stats.fetchDashboardReport();

        this.updateQuestionToBeAnswered({ attempted : true });

        return this.navigate();
      });
  }

  handleNext = () => {
    const { actions, question } = this.props;

    if (this.isOnLastQuestion()) actions.quizzes.updateIsTransitioningToCongratulations(true);
    if (question.get('attempted')) return this.updateAttempt();

    return this.createAttempt();
  }

  isOnLastQuestion = () => {
    const { questionsToBeAnsweredSize } = this.props;

    return this.nextQuestionNumber() > questionsToBeAnsweredSize;
  }

  navigate = () => {
    const isOnLastQuestion = this.isOnLastQuestion();

    if (isOnLastQuestion) return Promise.resolve();

    const { history, quiz } = this.props;

    return history.push(`/quiz/${quiz.get('id')}/questions/${this.nextQuestionNumber()}`);
  }

  navigateToCongratulations = () => {
    const { actions, history, quiz } = this.props;

    return setTimeout(() => {
      actions.quizzes.updateIsTransitioningToCongratulations(false);
      history.push(`/quiz/${quiz.get('id')}/congratulations`);
    }, 1000);
  }

  nextQuestionNumber = () => Number(this.props.match.params.questionNumber) + 1

  updateAttempt = () => {
    const {
      actions: { attempts },
      previousAttempt,
      question,
    } = this.props;

    return attempts
      .update({
        id               : previousAttempt.get('id'),
        recallEvaluation : question.get('recallEvaluation'),
      })
      .then(this.navigate);
  }

  updateQuestionToBeAnswered = values => {
    const { actions, questionNumber } = this.props;

    return actions.quizzes.updateQuestionToBeAnswered(questionNumber, values);
  }

  render() {
    const { quiz, shouldRedirectToHome } = this.props;

    if (shouldRedirectToHome) return <Redirect to="/" />;

    if (shouldRedirectToEnd(quiz.get('questions'), quiz)) {
      return <Redirect to={ `/quiz/${quiz.get('id')}/congratulations` } />;
    }

    return (
      <QuizQuestionContext.Provider value={ this.buildQuizQuestionContext() }>
        <QuizQuestion
          handleNext={ this.handleNext }
          hasQuizStarted={ quiz.get('started') }
          isOnLastQuestion={ this.isOnLastQuestion }
          navigateToCongratulations={ this.navigateToCongratulations }
          updateQuestionToBeAnswered={ this.updateQuestionToBeAnswered }
          { ...this.props }
        />
      </QuizQuestionContext.Provider>
    );
  }
}

function mapStateToProps({ attempts, quizzes }, ownProps) {
  const { match : { params : { questionNumber, id } } } = ownProps;
  const questionsToBeAnswered = quizzes.get('questionsToBeAnswered');
  const question = questionsToBeAnswered.get(questionNumber);

  if (!question) return { shouldRedirectToHome : true };

  return {
    isActive                         : quizzes.get('isActive') || attempts.get('isActive'),
    isTransitioningToCongratulations : quizzes.get('isTransitioningToCongratulations'),
    previousAttempt                  : attempts.getIn(['loaded', 'byQuestionId', question.get('id')]), // eslint-disable-line max-len
    question,
    questionNumber,
    questionsToBeAnsweredSize        : questionsToBeAnswered.size,
    quiz                             : quizzes.getIn(['loaded', id]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      attempts : bindActionCreators(attemptsActions, dispatch),
      profile  : bindActionCreators(profileActionCreators, dispatch),
      quizzes  : bindActionCreators(quizzesActions, dispatch),
      stats    : bindActionCreators(statsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalQuizQuestion);
