import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as searchPropTypes from 'src/constants/propTypes/search';

import CloseIcon from 'src/components/icons/Close';

import styles from './styles.module.scss';

function SortOptions(props) {
  const {
    children,
    closeSortOptions,
  } = props;

  return (
    <div className={ styles.Root }>
      <div className={ styles.Container }>
        <div className={ styles.TitleContainer }>
          <div
            className={ styles.CloseButton }
            onClick={ closeSortOptions }
          >
            <CloseIcon />
          </div>

          <h4>Sort By</h4>
          <div className={ styles.SpaceHolder } />
        </div>

        <ul className={ styles.OptionsList }>
          { children }
        </ul>
      </div>
    </div>
  );
}

SortOptions.propTypes = {
  children         : basePropTypes.children.isRequired,
  closeSortOptions : searchPropTypes.closeSortOptions.isRequired,
};

export default SortOptions;
