import { PropTypes } from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

function CircleSection(props) {
  const {
    index,
    text,
  } = props;

  return (
    <div className={ styles.Root }>
      <div className={ styles.Circle }>
        { index + 1 }
      </div>

      <p className={ styles.Para }>{ text }</p>
    </div>
  );
}

CircleSection.propTypes = {
  index : PropTypes.number.isRequired,
  text  : PropTypes.string.isRequired,
};

export default CircleSection;
