import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import classNames from 'src/components/shared/classNames';
import Logo from 'src/assets/face-only--no-crown.png';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function FaceOnly({ dataAutomatedTest, shouldAnimate }) {
  function getCrownContentContainerClassNames() {
    return cx({
      CrownContentContainer : true,
      shouldAnimate,
    });
  }

  function getCrownPathClassNames() {
    return cx({
      CrownPath : true,
      shouldAnimate,
    });
  }

  function getImageClassNames() {
    return cx({
      Image : true,
      shouldAnimate,
    });
  }

  return (
    <div
      className={ styles.Root }
      data-automated-test={ dataAutomatedTest }
    >
      <div className={ styles.CrownContainer }>
        <div className={ getCrownContentContainerClassNames() }>
          <svg
            className={ styles.Crown }
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 419.000000 158.000000"
          >
            <g transform="translate(0.000000,158.000000) scale(0.100000,-0.100000)" stroke="none">
              <path className={ getCrownPathClassNames() } d="M2021 1508 c-61 -10 -115 -49 -148 -105 l-28 -48 -3 -333 c-4 -400 -1 -424 67 -491 87 -87 204 -94 297 -18 73 60 74 67 74 478 0 364 0 366 -23 410 -43 81 -138 124 -236 107z"/>
              <path className={ getCrownPathClassNames() } d="M308 1126 c-95 -34 -141 -95 -146 -195 -4 -67 -3 -69 126 -377 158 -374 179 -404 297 -421 132 -18 245 83 245 219 0 48 -16 92 -121 344 -146 349 -149 354 -195 392 -58 48 -137 63 -206 38z"/>
              <path className={ getCrownPathClassNames() } d="M3726 1126 c-47 -18 -83 -43 -108 -77 -10 -13 -75 -159 -145 -324 -126 -296 -128 -301 -128 -377 0 -69 3 -80 30 -120 79 -112 234 -128 331 -35 39 38 57 74 175 356 116 278 131 320 131 371 0 87 -40 153 -115 192 -52 26 -123 32 -171 14z"/>
            </g>
          </svg>
        </div>
      </div>

      <img className={ getImageClassNames() } src={ Logo } />
    </div>
  );
}

FaceOnly.propTypes = {
  dataAutomatedTest : basePropTypes.dataAutomatedTest,
  shouldAnimate     : basePropTypes.shouldAnimate,
};

export default FaceOnly;
