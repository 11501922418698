import gql from 'graphql-tag';

export default gql`
  query getAllPublicStudySets(
    $filter: String
    $onlyFeatured: Boolean
  ) {
    getAllPublicStudySets(
      filter: $filter
      onlyFeatured: $onlyFeatured
    ) {
      description
      id
      originalQuestionsCount
      title
    }
  }
`;
