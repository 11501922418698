import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import classNames from 'src/components/shared/classNames';
import TrashCanIcon from 'src/components/icons/TrashCan';
import UndoIcon from 'src/components/icons/Undo';

import Fields from './Fields';
import ReversedQuestionSelector from './ReversedQuestionSelector';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Question(props) {
  const {
    deleteAnswerAudio,
    deleteAnswerImage,
    deleteQuestionAudio,
    deleteQuestionImage,
    errors,
    handleFieldChange,
    index,
    isActive,
    isNewStudySet,
    isUndesignated,
    question,
    questionKey,
    removeQuestion,
    register,
    unregister,
    reversedQuestionsOptionsSelected,
    setValue,
    shouldAutoFocus,
    undoRemoveQuestion,
  } = props;

  // Using this to help track the previous state of reversedQuestionsOptionsSelected
  const [reversedCopiesTurnedOn, setReversedCopiesTurnedOn] = useState(
    reversedQuestionsOptionsSelected,
  );

  const [reversedCopiesTurnedOnFromOff, setReversedCopiesTurnedOnFromOff] = useState(false);

  useEffect(() => {
    if (reversedQuestionsOptionsSelected && reversedCopiesTurnedOn === false) {
      setReversedCopiesTurnedOnFromOff(true);
    } else {
      setReversedCopiesTurnedOnFromOff(false);
    }

    setReversedCopiesTurnedOn(reversedQuestionsOptionsSelected);
  }, [reversedQuestionsOptionsSelected]);

  useEffect(() => {
    setReversedCopiesTurnedOnFromOff(false);
  }, [isActive]);

  useEffect(() => () => {
    setReversedCopiesTurnedOn(reversedQuestionsOptionsSelected);
    setReversedCopiesTurnedOnFromOff(false);
  }, []);

  const inputQuestionIdRef = useRef(null);
  const inputIsRemovedRef = useRef(null);

  const handleRemove = useCallback(() => {
    if (isActive) return;
    removeQuestion(questionKey.toString());
  }, [isActive, removeQuestion, questionKey]);

  const handleUndoRemove = useCallback(() => {
    if (isActive) return;
    undoRemoveQuestion(questionKey.toString());
  }, [isActive, undoRemoveQuestion, questionKey]);

  function setUpQuestionIdRef(el) {
    if (register) register(el);
    inputQuestionIdRef.current = el;
  }

  function setUpIsRemovedRef(el) {
    if (register) register(el);
    inputIsRemovedRef.current = el;
  }

  const isRemoved = question && question.get('isRemoved');
  const questionId = question && question.get('id');

  return (
    <li
      className={ styles.Root }
      data-automated-test="form-study-set-question-li"
    >
      <If condition={ questionId }>
        <input
          defaultValue={ questionId }
          name={ `questions[${index}][id]` }
          ref={ setUpQuestionIdRef }
          type="hidden"
        />
      </If>

      <If condition={ isRemoved }>
        <input
          defaultValue={ true }
          name={ `questions[${index}][isRemoved]` }
          ref={ setUpIsRemovedRef }
          type="hidden"
        />
      </If>

      <Fields
        deleteAnswerAudio={ deleteAnswerAudio }
        deleteAnswerImage={ deleteAnswerImage }
        deleteQuestionAudio={ deleteQuestionAudio }
        deleteQuestionImage={ deleteQuestionImage }
        errors={ errors }
        handleFieldChange={ handleFieldChange }
        index={ index }
        isActive={ isActive }
        questionKey={ questionKey }
        question={ question }
        register={ register }
        setValue={ setValue }
        shouldAutoFocusQuestion={ shouldAutoFocus }
      />

      <div className={ styles.IconContainer }>
        <If condition={ !isUndesignated }>
          <Choose>
            <When condition={ isRemoved }>
              <div
                className={ cx({ Undo : true, isDisabled : isActive }) }
                data-automated-test="delete-question-trash-can-icon"
                onClick={ handleUndoRemove }
              >
                <UndoIcon />
              </div>
            </When>

            <Otherwise>
              <div
                className={ cx({ TrashCan : true, isDisabled : isActive }) }
                data-automated-test="delete-question-trash-can-icon"
                data-rh={ window.innerWidth < 768 || isNewStudySet ?
                  null :
                  `
                      Clicking the trash can icon will not delete the question.
                      That will only remove it from this study set.
                    ` }
                onClick={ handleRemove }
              >
                <TrashCanIcon />
              </div>
            </Otherwise>
          </Choose>
        </If>

        <If condition={ !isRemoved }>
          <ReversedQuestionSelector
            isActive={ isActive }
            index={ index }
            question={ question }
            register={ register }
            reversedCopiesTurnedOnFromOff={ reversedCopiesTurnedOnFromOff }
            reversedQuestionsOptionsSelected={ reversedQuestionsOptionsSelected }
            unregister={ unregister }
          />
        </If>
      </div>
    </li>
  );
}

Question.propTypes = {
  deleteAnswerAudio                : questionPropTypes.deleteAudio,
  deleteAnswerImage                : questionPropTypes.deleteImage,
  deleteQuestionAudio              : questionPropTypes.deleteAudio,
  deleteQuestionImage              : questionPropTypes.deleteImage,
  errors                           : basePropTypes.errors,
  handleFieldChange                : questionPropTypes.handleFieldChange.isRequired,
  index                            : basePropTypes.index.isRequired,
  isActive                         : basePropTypes.isActive,
  isNewStudySet                    : PropTypes.bool,
  isUndesignated                   : studySetPropTypes.isUndesignated,
  question                         : studySetPropTypes.autosavedQuestion.isRequired,
  questionKey                      : studySetPropTypes.questionKey.isRequired,
  register                         : basePropTypes.register.isRequired,
  removeQuestion                   : studySetPropTypes.removeQuestion.isRequired,
  reversedQuestionsOptionsSelected : PropTypes.bool,
  setValue                         : basePropTypes.setValue.isRequired,
  shouldAutoFocus                  : PropTypes.bool,
  undoRemoveQuestion               : studySetPropTypes.undoRemoveQuestion,
  unregister                       : PropTypes.func.isRequired,
};

export default Question;
