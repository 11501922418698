import gql from 'graphql-tag';

export default gql`
  mutation createAttempt(
    $questionId: ID!
    $quizId: ID!
    $quizQuestionId: ID
    $recallEvaluation: Int!
    $timeTaken: Float!
  ) {
    createAttempt(
      questionId: $questionId
      quizId: $quizId
      quizQuestionId: $quizQuestionId
      recallEvaluation: $recallEvaluation
      timeTaken: $timeTaken
    ) {
      id
      question {
        dueDate
        id
      }
      recallEvaluation
    }
  }
`;
