import React from 'react';

import styles from './styles.module.scss';

function Undo() {
  return (
    <svg
      className={ styles.Root }
      x="0px"
      y="0px"
      viewBox="0 0 96 120"
    >
      <path d="M48,16.1242v-9a2.0005,2.0005,0,0,0-3.1992-1.6015l-16,12a2.0019,2.0019,0,0,0,0,3.2031l16,12A2.0005,2.0005,0,0,0,48,31.1242v-9a31,31,0,1,1-31,31v-2a3,3,0,0,0-6,0v2a37,37,0,1,0,37-37Z"/>
    </svg>
  );
}

export default Undo;
