import { PropTypes } from 'prop-types';
import React from 'react';

import AlertBanner from 'src/components/AlertBanner';

import styles from './styles.module.scss';

function EmailIssueAlert(props) {
  const {
    message,
    severity : severityProp,
  } = props;

  let severity = severityProp;

  if (['permanent', 'temporary'].indexOf(severity) === -1) {
    severity = 'permanent';
  }

  let displayMessage = message;

  if (!message) {
    displayMessage = `
      There seems to be an issue with the server that hosts your email.
      Try using a different email such as a Gmail or Yahoo one.
    `;
  }

  return (
    <div className={ styles.Root }>
      <AlertBanner
        isError={ severity === 'permanent' }
        isWarning={ severity === 'temporary' }
      >
        <p className={ styles.Para }>
          { displayMessage }
        </p>

        <p className={ styles.ContactUsPara }>
          If you need help, please contact us at{ ' ' }
          <a href="mailto:admin@dorothymemoryapp.com">admin@dorothymemoryapp.com</a>{ ' ' }
          and we’d be happy to assist.
        </p>
      </AlertBanner>
    </div>
  );
}

EmailIssueAlert.propTypes = {
  message  : PropTypes.string.isRequired,
  severity : PropTypes.string.isRequired,
};

export default EmailIssueAlert;
