import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import HorizontalLine from 'src/components/HorizontalLine';
import SignOutButton from 'src/components/profile/SignOutButton';

import LinkItem from './LinkItem';
import styles from './styles.module.scss';

function Links({ handleClick, pathname }) {
  return (
    <div className={ styles.Root }>
      <ul className={ styles.LinksContainer }>
        <LinkItem
          isActive={ pathname === '/profile/user-details' }
          title="User details"
          to="/profile/user-details"
        />

        <LinkItem
          isActive={ pathname === '/profile/account-plan' }
          title="Account plan"
          to="/profile/account-plan"
        />

        <LinkItem
          isActive={ pathname === '/profile/preferences' }
          title="Quiz settings"
          to="/profile/preferences"
        />

        <LinkItem
          isActive={ pathname === '/profile/notifications' }
          title="Notifications"
          to="/profile/notifications"
        />

        <LinkItem
          isActive={ pathname === '/profile/install-app' }
          title="Install app"
          to="/profile/install-app"
        />

        <LinkItem
          isActive={ pathname === '/profile/contact-us' }
          title="Contact us"
          to="/profile/contact-us"
        />
      </ul>

      <div className={ styles.ShortHorizontalLine }>
        <HorizontalLine />
      </div>

      <div className={ styles.SignOutButton }>
        <SignOutButton handleClick={ handleClick } />
      </div>

      <div className={ styles.LongHorizontalLine }>
        <HorizontalLine />
      </div>
    </div>
  );
}

Links.propTypes = {
  handleClick : basePropTypes.handleClick.isRequired,
  pathname    : basePropTypes.pathname.isRequired,
};

export default Links;
