import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import analytics from 'src/shared/analytics';
import classNames from 'src/components/shared/classNames';
import ShareLinkIcon from 'src/components/icons/ShareLink';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ShareableLink({ isSecondary, link }) {
  const [isCopied, setIsCopied] = useState(false);

  function getCopyButtonClassNames() {
    return cx({
      CopyButton : true,
      isCopied,
      isSecondary,
    });
  }

  function handleCopyButtonClick() {
    setIsCopied(true);
    navigator.clipboard.writeText(link);
    analytics.track('Copy shared link clicked', { link });
  }

  return (
    <div className={ styles.Root }>
      <div className={ styles.ShareLinkIconContainer }>
        <div className={ styles.ShareLinkIcon }>
          <ShareLinkIcon />
        </div>
      </div>

      <p className={ styles.Link }>
        { link }
      </p>

      <div
        className={ getCopyButtonClassNames() }
        data-automated-test="shareable-link--public-study-set"
        data-rh="Copy the shareable link for this study set to your clipboard"
        data-shareablelink={ link }
        onClick={ handleCopyButtonClick }
      >
        { isCopied ? 'Copied' : 'Copy' }
      </div>
    </div>
  );
}

ShareableLink.propTypes = {
  isSecondary : PropTypes.bool,
  link        : PropTypes.string.isRequired,
};

export default ShareableLink;
