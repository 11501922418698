import JsPDF from 'jspdf';

function loadImageFromUrl(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(image);
    image.onerror = () => reject(new Error('Failed to load image'));
  });
}

function createOffScreenContainer({ pageHeight, pageWidth }) {
  const offScreenContainer = document.createElement('div');

  offScreenContainer.style.position = 'fixed';
  offScreenContainer.style.left = '-9999px';
  offScreenContainer.style.top = '-9999px';
  offScreenContainer.style.height = `${pageHeight}px`;
  offScreenContainer.style.width = `${pageWidth}px`;

  // For easy testing
  // offScreenContainer.style.zIndex = 10;
  // offScreenContainer.style.backgroundColor = 'lightblue';
  // offScreenContainer.style.left = `100px`;
  // offScreenContainer.style.top = `100px`;

  return offScreenContainer;
}

function createWrapperDiv({ pageWidth }) {
  const wrapperDiv = document.createElement('div');

  wrapperDiv.style.width = `${pageWidth * 0.6}px`;
  wrapperDiv.style.position = 'absolute';
  wrapperDiv.style.left = `${(pageWidth - pageWidth * 0.6) / 2}px`;
  wrapperDiv.style.transformOrigin = 'center';

  return wrapperDiv;
}

async function createImage(imageUrl, styleProps) {
  const { pageHeight, pageWidth, percentageOfPage } = styleProps;

  const loadedImage = await loadImageFromUrl(imageUrl);
  const aspectRatio = loadedImage.height / loadedImage.width;
  const imageWidth = pageWidth * percentageOfPage;
  const imageHeight = imageWidth * aspectRatio;

  loadedImage.style.width = `${imageWidth}px`;
  loadedImage.style.height = `${imageHeight}px`;
  loadedImage.style.maxHeight = `${pageHeight * 0.8 * (percentageOfPage / 0.6)}px`;
  loadedImage.style.position = 'absolute';

  return {
    imageWidth,
    image : loadedImage,
  };
}

function createTextDiv(text = '(No text)', styleProps) {
  const {
    hasImage = false,
    pageHeight,
    percentageOfPage = 0.6,
  } = styleProps || {};

  const textDiv = document.createElement('div');
  textDiv.style.fontFamily = 'sans-serif';

  const textLength = text.length;

  let fontSize = textLength < 200 ? 14 : 12;

  if (textLength > 400) fontSize = 10;
  if (textLength > 800) fontSize = 8;
  if (textLength > 1200) fontSize = 6;

  if (!hasImage) fontSize *= 1.5;

  textDiv.style.fontSize = `${fontSize}px`;
  textDiv.style.lineHeight = 1.5;
  textDiv.style.color = 'black';
  textDiv.style.maxHeight = `${pageHeight * 0.8 * (percentageOfPage / 0.6)}px`;
  textDiv.style.overflow = 'hidden';
  textDiv.style.textAlign = 'center';

  textDiv.innerHTML = text;

  return textDiv;
}

function positionImage(image, wrapperDiv, { imageWidth }) {
  const marginLeft = (wrapperDiv.offsetWidth - imageWidth) / 2;
  const marginTop = wrapperDiv.clientHeight;

  image.style.left = `${marginLeft}px`;
  image.style.top = `${marginTop}px`;
  image.style.transformOrigin = 'center';
}

async function createTempElements(entity, wrapperDiv, { pageHeight, pageWidth }) {
  const imageUrl = entity.get('imageUrl');
  const text = entity.get('title') || entity.get('body');

  if (imageUrl && text) {
    let imagePercentageOfPage = text.length < 120 ? 0.6 : 0.5;

    if (text.length > 400) imagePercentageOfPage = 0.4;

    const { imageWidth, image } = await createImage(
      imageUrl,
      {
        pageHeight,
        pageWidth,
        percentageOfPage : imagePercentageOfPage,
      },
    );

    const textDiv = createTextDiv(
      text,
      {
        hasImage         : true,
        pageHeight,
        percentageOfPage : 0.8 - imagePercentageOfPage,
      },
    );

    wrapperDiv.style.top = `${(pageHeight - pageHeight * 0.8) / 2}px`;
    wrapperDiv.appendChild(textDiv);
    wrapperDiv.appendChild(image);
    return positionImage(image, wrapperDiv, { imageWidth });
  }

  if (imageUrl) {
    const { imageWidth, image } = await createImage(
      imageUrl,
      {
        pageHeight,
        pageWidth,
        percentageOfPage : 0.6,
      },
    );

    wrapperDiv.style.top = `${(pageHeight - pageHeight * 0.8) / 2}px`;
    wrapperDiv.appendChild(image);
    return positionImage(image, wrapperDiv, { imageWidth });
  }

  const textDiv = createTextDiv(text, { pageHeight });

  wrapperDiv.style.top = `${(pageHeight - pageHeight * 0.6) / 2}px`;
  wrapperDiv.appendChild(textDiv);
}

async function generateWithinRange(questions, title, questionRangeIndex) {
  const pdf = new JsPDF({
    orientation : 'landscape',
    format      : 'a4',
    unit        : 'px',
  });

  const pageWidth = pdf.getPageWidth();
  const pageHeight = pdf.getPageHeight();

  const offScreenContainer = createOffScreenContainer({ pageHeight, pageWidth });
  document.body.appendChild(offScreenContainer);

  async function addPageToPDF(entity, pageIndex) {
    const wrapperDiv = createWrapperDiv({ pageWidth });
    offScreenContainer.appendChild(wrapperDiv);

    await createTempElements(entity, wrapperDiv, { pageHeight, pageWidth });

    await pdf.html(wrapperDiv, {
      html2canvas : { scale : 1 },
      x           : 0,
      y           : (pageIndex * pageHeight),
    });

    offScreenContainer.removeChild(wrapperDiv);
  }

  for (let i = 0; i < questions.size; i += 1) {
    const question = questions.get(i);
    await addPageToPDF(question, i * 2);
    const answer = question.get('answer');
    await addPageToPDF(answer, (i * 2) + 1);
  }

  const titleCardAmount = `(${questionRangeIndex}-${questionRangeIndex + 19})`;

  pdf.save(`${titleCardAmount}${title}.pdf`);
  document.body.removeChild(offScreenContainer);
}

export default async function generatePDF(questions, title) {
  for (let i = 1; i <= questions.size; i += 20) {
    const rangeOfQuestions = questions.slice(i - 1, i + 19);
    await generateWithinRange(rangeOfQuestions, title, i);
  }
}
