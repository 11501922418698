import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import UserDetails from 'src/components/profile/UserDetails';

class FunctionalUserDetails extends PureComponent {
  render() {
    return (
      <UserDetails { ...this.props } />
    );
  }
}

function mapStateToProps({ authentication }) {
  return {
    uid : authentication.get('uid'),
  };
}

export default connect(mapStateToProps, null)(FunctionalUserDetails);
