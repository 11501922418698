import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import CheckmarkIcon from 'src/components/icons/Checkmark';
import classNames from 'src/components/shared/classNames';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Checkbox(props) {
  const {
    children,
    defaultChecked,
    disabled,
    name,
    onChecked,
    onUnchecked,
    register,
  } = props;

  const [isChecked, setIsChecked] = useState(defaultChecked);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked, setIsChecked]);

  function handleChange({ target : { checked } }) {
    setIsChecked(checked);

    if (checked && onChecked) return onChecked();
    if (onUnchecked) return onUnchecked();
  }

  const inputRef = useRef(null);

  function setUpRef(el) {
    if (register) {
      register(el);
      inputRef.current = el;
    }
  }

  const inputClassNames = cx({
    Input      : true,
    isChecked,
    isDisabled : disabled,
  });

  const checkboxClassNames = cx({
    Checkbox   : true,
    isChecked,
    isDisabled : disabled,
  });

  return (
    <label
      className={ styles.Label }
      data-automated-test="switch-label"
      name={ name }
    >
      <input
        checked={ isChecked }
        className={ inputClassNames }
        disabled={ disabled }
        name={ name }
        onChange={ handleChange }
        ref={ setUpRef }
        type="checkbox"
      />

      <div className={ styles.Container }>
        <div className={ checkboxClassNames }>
          <CheckmarkIcon />
        </div>
      </div>

      { children }
    </label>
  );
}

Checkbox.propTypes = {
  children       : PropTypes.node,
  defaultChecked : basePropTypes.defaultChecked,
  disabled       : basePropTypes.disabled,
  name           : basePropTypes.name.isRequired,
  onChecked      : basePropTypes.onChecked,
  onUnchecked    : basePropTypes.onUnchecked,
  register       : basePropTypes.register,
};

export default Checkbox;
