import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';

// Doing it this way because if there is no token, an error will be thrown
let token;

if (window.location.hostname === 'my.dorothymemoryapp.com') {
  token = process.env.MIXPANEL_TOKEN;
}

const analytics = Analytics({
  app     : 'dorothy',
  plugins : token && [
    mixpanelPlugin({ token }),
  ],
});

export default analytics;
