import gql from 'graphql-tag';

import quizFields from 'src/graphql/fragments/quizFields';

export default gql`
  query newestQuiz {
    newestQuiz {
      ...quizFields
    }
  }
  ${quizFields}
`;
