import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import SiteWideLinks from 'src/components/navigation/SiteWideLinks';

import styles from './styles.module.scss';

function ErrorScreen(props) {
  return (
    <div className={ styles.Root }>
      <h3>
        Oh no! Something went wrong. As long as your internet is working,
        then we will have been notified and will aim to get this fixed quickly!
      </h3>

      <SiteWideLinks location={ props.location } />
    </div>
  );
}

ErrorScreen.propTypes = {
  location : basePropTypes.location.isRequired,
};

export default ErrorScreen;
