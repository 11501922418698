import React, { useEffect, useState } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import Header from 'src/components/navigation/Header';

import Finish from './Finish';
import PostTransition from './PostTransition';
import styles from './styles.module.scss';
import Transition from './Transition';

function Congratulations(props) {
  const {
    history,
    questionsToBeAnsweredSize,
  } = props;

  const [isTransitioningFromQuestion, setIsTransitioningFromQuestion] = useState(true);
  const [isAnimatingCongratulations, setIsAnimatingCongratulations] = useState(false);

  let timeout;

  useEffect(() => {
    timeout = setTimeout(() => {
      setIsTransitioningFromQuestion(false);
      setIsAnimatingCongratulations(true);
    }, 8100);
  }, []);

  function handleClick() {
    setIsTransitioningFromQuestion(false);
    setIsAnimatingCongratulations(false);
    clearTimeout(timeout);
  }

  return (
    <div
      className={ styles.Root }
      data-automated-test="quiz-congratulations-root"
      onClick={ handleClick }
    >
      <div className={ styles.Container }>
        <div className={ styles.Header }>
          <Header
            history={ history }
            numberedText={ `${questionsToBeAnsweredSize} of ${questionsToBeAnsweredSize}` }
          />
        </div>

        <div data-automated-test="quiz-congratulations-container">
          <div className={ styles.ContentContainer }>
            <Choose>
              <When condition={ isTransitioningFromQuestion }>
                <div data-automated-test="quiz-congratulations-transition-container">
                  <Transition handleClick={ handleClick } />
                </div>
              </When>

              <Otherwise>
                <PostTransition isAnimatingCongratulations={ isAnimatingCongratulations } />
              </Otherwise>
            </Choose>

            <Finish history={ history } />
          </div>
        </div>
      </div>
    </div>
  );
}

Congratulations.propTypes = {
  history                   : basePropTypes.history.isRequired,
  questionsToBeAnsweredSize : quizPropTypes.questionsToBeAnsweredSize.isRequired,
};

export default Congratulations;
