import actionTypes from 'src/constants/actionTypes';
import attemptsService from 'src/services/attempts';

export function create(values) {
  return dispatch => {
    dispatch({ type : actionTypes.ATTEMPT__CREATE_START });

    return attemptsService
      .create(values)
      .then(({ createAttempt : attempt }) => dispatch({
        type    : actionTypes.ATTEMPT__CREATE_SUCCESS,
        payload : { attempt },
      }))
      .catch(errors => dispatch({
        type    : actionTypes.ATTEMPT__CREATE_FAILURE,
        payload : { errors },
      }));
  };
}

export function update(values) {
  return dispatch => {
    dispatch({ type : actionTypes.ATTEMPT__UPDATE_START });

    return attemptsService
      .update(values)
      .then(({ updateAttempt : attempt }) => dispatch({
        type    : actionTypes.ATTEMPT__UPDATE_SUCCESS,
        payload : { attempt },
      }))
      .catch(errors => dispatch({
        type    : actionTypes.ATTEMPT__UPDATE_FAILURE,
        payload : { errors },
      }));
  };
}

export function reset() {
  return {
    type : actionTypes.ATTEMPT__RESET,
  };
}
