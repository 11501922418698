import createStudySet from 'src/graphql/mutations/studySets/createStudySet';
import deleteStudySet from 'src/graphql/mutations/studySets/deleteStudySet';
import getAllStudySets from 'src/graphql/queries/studySets/getAllStudySets';
import showStudySet from 'src/graphql/queries/studySets/showStudySet';
import updateStudySet from 'src/graphql/mutations/studySets/updateStudySet';

import post from './shared/post';

export default {
  create(values) {
    return post(values, createStudySet);
  },

  deleteStudySet(values) {
    return post(values, deleteStudySet);
  },

  getAll() {
    return post(null, getAllStudySets);
  },

  show(values) {
    return post(values, showStudySet);
  },

  update(values) {
    return post(values, updateStudySet);
  },
};
