import React from 'react';

import classNames from 'src/components/shared/classNames';
import OtpConfirmation from 'src/components/forms/OtpConfirmation';

import EmailIssueAlert from './EmailIssueAlert';
import Shared from './Shared';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);
const shared = new Shared();

function SignInOtpConfirmation(props) {
  const {
    errors,
    emailSentTo,
    goBackFromVerifyOtp,
    handleSubmit,
    isActive,
    shouldFireConfetti,
    sentEmailMessage,
    sentEmailSeverity,
    sentEmailStatus,
  } = props;

  const isLocked = errors && errors.get('otp') && errors.get('otp').first().includes('locked');

  function fillInWithEmailSentTo() {
    if (!emailSentTo) return null;

    return ` to ${emailSentTo}`;
  }

  function getParagraphClassNames() {
    return cx({
      ParagraphContent : true,
      isLocked,
    });
  }

  const isRailsEnvTest = process.env.RAILS_ENV === 'test';

  const shouldShowInput = !(
    sentEmailStatus === 'failed' && sentEmailSeverity === 'permanent'
  );

  return (
    <div className={ styles.Root }>
      <If condition={ shouldFireConfetti && !isRailsEnvTest }>
        <div
          className={ styles.Transitioner }
          data-automated-test="sign-in-otp-confirmation--transitioner"
        />
      </If>

      <div className={ styles.Container }>
        <div className={ styles.BackLinkContainer }>
          <p onClick={ goBackFromVerifyOtp }>← back</p>
        </div>

        <If condition={ !shouldFireConfetti && sentEmailStatus === 'failed' }>
          <EmailIssueAlert
            message={ sentEmailMessage }
            severity={ sentEmailSeverity || 'permanent' }
          />
        </If>

        <If condition={ shouldShowInput }>
          <Choose>
            <When condition={ isLocked }>
              <p className={ getParagraphClassNames() }>
                We’ve noticed an unusually high number of login failures on your account{ ' ' }
                and so we’ve temporarily locked it.
                We’ve sent a link{ fillInWithEmailSentTo() } that will unlock your account.
                Just click it from your email and you’ll be able to log in again.
              </p>

              <p className={ getParagraphClassNames() }>
                If you still have any issues, please feel free to email me at{ ' ' }
                jordan@dorothymemoryapp.com.
              </p>
            </When>

            <Otherwise>
              <p className={ getParagraphClassNames() }>
                Great!{ ' ' }

                <strong>
                  We’ve emailed a one time password{ fillInWithEmailSentTo() }
                </strong>.

                You’re almost there. Just enter what we sent below.
              </p>
            </Otherwise>
          </Choose>

          <OtpConfirmation
            errors={ errors }
            handleSubmit={ handleSubmit }
            isActive={ isActive }
            isLocked={ isLocked }
            shouldFireConfetti={ shouldFireConfetti }
          />
        </If>
      </div>
    </div>
  );
}

SignInOtpConfirmation.propTypes = shared.propTypes;

export default SignInOtpConfirmation;
