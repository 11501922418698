import actionTypes from 'src/constants/actionTypes';
import profileService from 'src/services/profile';

function handleCommonProfileAction(actionPrefix, serviceAction, values) {
  return dispatch => {
    dispatch({ type : actionTypes[`PROFILE__${actionPrefix}_START`] });

    return profileService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`PROFILE__${actionPrefix}_SUCCESS`],
          payload : { data : { ...data[key] } },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`PROFILE__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function show() {
  return handleCommonProfileAction('SHOW', 'show', null);
}

export function update(values) {
  return handleCommonProfileAction(
    'UPDATE_PROFILE',
    'updateProfile',
    values,
  );
}

export function updateNotificationSetting(values) {
  return handleCommonProfileAction(
    'UPDATE_NOTIFICATION_SETTING',
    'updateNotificationSetting',
    values,
  );
}

export function updateProgress(values) {
  return handleCommonProfileAction('UPDATE_PROGRESS', 'updateProgress', values);
}
