import { PropTypes } from 'prop-types';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import BarChart from 'src/components/charts/Bar';
import StatsPlaceholder from 'src/components/StatsPlaceholder';

import InfoText from '../InfoText';
import styles from './styles.module.scss';
import TextStats from '../TextStats';

function QuestionsStudied(props) {
  const {
    isEmpty,
    report,
  } = props;

  const total = report.get('total');

  /* eslint-disable max-len */
  const stats = [
    {
      dataAutomatedTest : 'questions-studied--total',
      title             : 'Total',
      value             : total,
    },
    {
      dataAutomatedTest : 'questions-studied--today',
      title             : 'Today',
      value             : report.get('today'),
    },
    {
      dataAutomatedTest : 'questions-studied--last-7-days',
      title             : 'Last 7 days',
      value             : report.get('last7Days'),
    },
    {
      dataAutomatedTest : 'questions-studied--last-30-days',
      title             : 'Last 30 days',
      value             : report.get('last30Days'),
    },
    {
      dataAutomatedTest : 'questions-studied--quiz-streak',
      title             : 'Quiz streak',
      value             : `${report.get('quizStreak')} days`,
      hint              : 'This is the number of days in a row you have completed a quiz on the day it was due.',
    },
    {
      dataAutomatedTest : 'questions-studied--longest-quiz-streak',
      title             : 'Longest quiz streak',
      value             : `${report.get('longestQuizStreak')} days`,
    },
  ];
  /* eslint-enable max-len */

  const dataPoints = report && report.get('dataPoints');
  const data = dataPoints && dataPoints.toJS();

  return (
    <div
      className={ styles.Root }
      data-automated-test="charts--questions-studied"
    >
      <InfoText>
        You have been quizzed
        <strong> { total } </strong>
        times on your questions since you signed up.
      </InfoText>

      <TextStats stats={ stats } />

      <Choose>
        <When condition={ isEmpty }>
          <div className={ styles.StatsPlaceholder }>
            <StatsPlaceholder titleNoun="chart" />
          </div>
        </When>

        <Otherwise>
          <div className={ styles.Chart }>
            <BarChart data={ data } />
          </div>
        </Otherwise>
      </Choose>
    </div>
  );
}

QuestionsStudied.propTypes = {
  isEmpty : PropTypes.bool,
  report  : statsPropTypes.questionsStudied.isRequired,
};

export default QuestionsStudied;
