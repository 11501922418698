import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import ButtonLink from 'src/components/buttons/ButtonLink';
import getQuizStages from 'src/shared/getQuizStages';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';

import EarlyQuizWarningPara from '../EarlyQuizWarningPara';
import FlashStudySession from '../FlashStudySession';
import HowTheyWork from '../HowTheyWork';
import KeyboardShortcuts from '../KeyboardShortcuts';
import styles from './styles.module.scss';

function Content(props) {
  const {
    estimation,
    isActive,
    isSample,
    questionsSize,
    quiz,
    resetFlashStudySession,
    studyOnlyWrongQuestions,
    studySetIdFromQuiz,
  } = props;

  const [quizStages, setQuizStages] = useState({});

  useEffect(() => {
    const stages = getQuizStages(quiz, null, null);
    setQuizStages(stages);
  }, [quiz]);

  const {
    quizIsDueAtLeastTwoDaysFromNow,
  } = quizStages;

  const quizId = quiz.get('id');
  const isFlashStudySession = Boolean(studySetIdFromQuiz);

  return (
    <>
      <Choose>
        <When condition={ isActive }>
          <div className={ styles.PulseLoader }>
            <PulseLoadingIndicator isLoading={ true }/>
          </div>
        </When>

        <When condition={ isSample }>
          <p>
            Perfect! Since this is a sample quiz, we’re going to choose just one or two of the
            questions you added and quiz you on them.
          </p>

          <p className={ styles.SampleSubParagraph }>
            Click &quot;Begin&quot; when you’re ready.
          </p>

          <ButtonLink
            text="Begin"
            to={ `/quiz/${quizId}/questions/1` }
          />
        </When>

        <When condition={ isFlashStudySession }>
          <FlashStudySession
            estimation={ estimation }
            hasQuestionsWrongFromFssTakenToday={ quiz.get('hasQuestionsWrongFromFssTakenToday') }
            quizId={ quizId }
            resetFlashStudySession={ resetFlashStudySession }
            started={ quiz.get('started') }
            studyOnlyWrongQuestions={ studyOnlyWrongQuestions }
          />
        </When>

        <Otherwise>
          <p>
            We have { questionsSize } question(s) for you and we expect it to take
            about { ' ' }<strong>{ estimation }{ ' ' }
            minute{ estimation > 1 ? 's' : '' }</strong>.
          </p>

          <p className={ styles.SubParagraph }>
            If you need, you can limit the number of
            questions per quiz <Link to="/profile/preferences">here</Link>.
            You can also
            { ' ' }<Link to="/profile/preferences">change</Link>{ ' ' }
            which study sets appear in your quizzes.
          </p>

          <If condition={ quizIsDueAtLeastTwoDaysFromNow }>
            <EarlyQuizWarningPara />
          </If>

          <ButtonLink
            text="Begin"
            to={ `/quiz/${quizId}/questions/1` }
          />
        </Otherwise>
      </Choose>

      <If condition={ !quiz.get('isFirstQuiz') }>
        <div
          className={ styles.KeyboardShortcutsContainer }
          data-automated-test="quiz-explanation--keyboard-shortcuts-options"
        >
          <KeyboardShortcuts />
        </div>
      </If>

      <If condition={ !isFlashStudySession }>
        <div
          className={ styles.OptionsContainer }
          data-automated-test="quiz-explanation--how-they-work-options"
        >
          <HowTheyWork isSample={ isSample } />
        </div>
      </If>
    </>
  );
}

Content.propTypes = {
  estimation              : PropTypes.number.isRequired,
  isActive                : PropTypes.bool,
  isFlashStudySession     : PropTypes.bool,
  isSample                : quizPropTypes.isSample,
  questionsSize           : questionPropTypes.questionsSize.isRequired,
  quiz                    : quizPropTypes.quiz.isRequired,
  resetFlashStudySession  : PropTypes.func,
  studyOnlyWrongQuestions : PropTypes.func,
  studySetIdFromQuiz      : PropTypes.string,
};

export default Content;
