import actionTypes from 'src/constants/actionTypes';
import publicStudySetsService from 'src/services/publicStudySets';

function handleCommonStudySetAction(actionPrefix, serviceAction, values = null) {
  return dispatch => {
    dispatch({ type : actionTypes[`PUBLIC_STUDY_SET__${actionPrefix}_START`] });

    return publicStudySetsService[serviceAction](values)
      .then(data => {
        const key = Object.keys(data)[0];

        return dispatch({
          type    : actionTypes[`PUBLIC_STUDY_SET__${actionPrefix}_SUCCESS`],
          payload : { data : data[key] },
        });
      })
      .catch(errors => dispatch({
        type    : actionTypes[`PUBLIC_STUDY_SET__${actionPrefix}_FAILURE`],
        payload : { errors },
      }));
  };
}

export function add(values) {
  return handleCommonStudySetAction('ADD', 'add', values);
}

export function clearTempState() {
  return { type : actionTypes.PUBLIC_STUDY_SET__CLEAR_TEMP_STATE };
}

export function getAll(values) {
  return handleCommonStudySetAction('GET_ALL', 'getAll', values);
}

export function getAllFeatured() {
  return handleCommonStudySetAction('GET_ALL_FEATURED', 'getAll', { onlyFeatured : true });
}

export function show(values) {
  return handleCommonStudySetAction('SHOW', 'show', values);
}
