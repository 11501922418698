// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___19drB{margin-top:18px}.styles-module__SubPara___3z6eY{margin-top:4px}.styles-module__SubPara___3z6eY a{color:#2071aa;text-decoration:none}.styles-module__SubPara___3z6eY svg{height:25px;margin:-8px -3px -9px -3px;width:25px}.styles-module__SubPara___3z6eY svg path{fill:#2071aa}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/quiz/Explanation/EarlyQuizWarningPara/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CAEF,gCACE,cAAA,CACA,kCACE,aAAA,CACA,oBAAA,CACF,oCACE,WAAA,CACA,0BAAA,CACA,UAAA,CACA,yCACE,YAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin-top: 18px; }\n\n.SubPara {\n  margin-top: 4px; }\n  .SubPara a {\n    color: #2071aa;\n    text-decoration: none; }\n  .SubPara svg {\n    height: 25px;\n    margin: -8px -3px -9px -3px;\n    width: 25px; }\n    .SubPara svg path {\n      fill: #2071aa; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___19drB",
	"SubPara": "styles-module__SubPara___3z6eY"
};
module.exports = exports;
