import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';

import getTotalHeightOfElWithMargin from 'src/components/shared/getTotalHeightOfElWithMargin';
import Header from 'src/components/navigation/Header';
import HorizontalLine from 'src/components/HorizontalLine';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';

import AnswerRevealBox from './AnswerRevealBox';
import AnswerContent from './AnswerContent';
import fireConfetti from './fireConfetti';
import QuestionContent from './QuestionContent';
import RecallEvaluation from './RecallEvaluation';
import styles from './styles.module.scss';

class QuizQuestion extends PureComponent {
  static propTypes = {
    handleNext                       : quizPropTypes.handleNext.isRequired,
    hasQuizStarted                   : quizPropTypes.hasQuizStarted,
    history                          : basePropTypes.history.isRequired,
    isActive                         : basePropTypes.isActive,
    isOnLastQuestion                 : PropTypes.func,
    isTransitioningToCongratulations : quizPropTypes.isTransitioningToCongratulations,
    match                            : basePropTypes.match.isRequired,
    navigateToCongratulations        : quizPropTypes.navigateToCongratulations,
    question                         : questionPropTypes.question,
    quiz                             : quizPropTypes.quiz.isRequired,
    questionNumber                   : quizPropTypes.questionNumber.isRequired,
    questionsToBeAnsweredSize        : quizPropTypes.questionsToBeAnsweredSize.isRequired,
    updateQuestionToBeAnswered       : quizPropTypes.updateQuestionToBeAnswered.isRequired,
  }

  constructor() {
    super();
    this.questionContentRef = React.createRef();
    this.containerRef = React.createRef();
    this.contentContainerRef = React.createRef();
  }

  state = {
    atScrollTop                    : true,
    contentContainerHeight         : 0,
    contentContainerChildrenHeight : 0,
    isResetting                    : false,
    isTransitioning                : false,
  }

  componentDidUpdate(prevProps) {
    const currentQuestionId = this.props.question.get('id');
    const prevQuestionId = prevProps.question.get('id');

    // To ensure we restart our process between questions
    if (prevQuestionId !== currentQuestionId) {
      this.scrollToTopOfContentContainer();
      return this.setState({ isResetting : true, isTransitioning : false });
    }

    return this.setState({ isResetting : false });
  }

  handleNext = () => {
    const {
      handleNext : propsHandleNext,
      isOnLastQuestion,
    } = this.props;

    if (!isOnLastQuestion()) return propsHandleNext();

    let confettiPixelFromTopStart;
    const currentRef = this.contentContainerRef.current;

    if (currentRef) {
      const { height, top } = currentRef.getBoundingClientRect();
      confettiPixelFromTopStart = height + top;
    }

    fireConfetti(confettiPixelFromTopStart);

    return propsHandleNext()
      .then(this.transitionToCongratulations);
  }

  handleScroll = ({ currentTarget: { scrollTop } }) => {
    const { pageYOffset } = window;
    let atScrollTop = false;

    if (scrollTop === 0 && pageYOffset === undefined) atScrollTop = true;
    if (scrollTop === 0 && pageYOffset === 0) atScrollTop = true;

    this.setState({ atScrollTop });
  }

  scrollToAnswerContent = () => {
    const containerEl = this.containerRef.current;
    const contentContainerEl = this.contentContainerRef.current;
    const questionContentEl = this.questionContentRef.current;

    if (
      !contentContainerEl ||
      !questionContentEl ||
      !containerEl
    ) return;

    setTimeout(() => {
      containerEl.scrollTo({
        // 70 is so the user can still see the bottom part of the question
        top      : getTotalHeightOfElWithMargin(questionContentEl) + 79 - 70,
        left     : 0,
        behavior : 'smooth',
      });
    }, 0);
  }

  scrollToTopOfContentContainer() {
    const current = this.containerRef && this.containerRef.current;

    if (current) {
      setTimeout(() => {
        // double scrolls called just to be safe
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'auto',
        });

        current.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'auto',
        });
      }, 0);
    }
  }

  transitionToCongratulations = () => setTimeout(() => {
    this.setState({ isTransitioning : true });
    this.props.navigateToCongratulations();
  }, 2500)

  render() {
    const {
      hasQuizStarted,
      history,
      isActive,
      isOnLastQuestion,
      isTransitioningToCongratulations,
      match,
      question,
      questionNumber,
      questionsToBeAnsweredSize,
      quiz,
      updateQuestionToBeAnswered,
    } = this.props;

    const idealStruggleTimeInSeconds = Number(question.get('idealStruggleTime').toFixed(2));
    const isRecallEvaluated = [0, 1, 2, 3].indexOf(question.get('recallEvaluation')) > -1;
    const readingTimeInSeconds = question.get('estimatedReadingTime');
    const timeTaken = question.get('timeTaken');

    const { isResetting, isTransitioning } = this.state;
    const secondsToReveal = readingTimeInSeconds + idealStruggleTimeInSeconds;

    const studySetIdFromQuiz = quiz.get('studySetId');
    const exitLinkPath = studySetIdFromQuiz ? `/study-sets/${studySetIdFromQuiz}` : '/';

    function determineMiddleSection() {
      if ((isOnLastQuestion && isActive) || isTransitioningToCongratulations) {
        return (
          <div className={ styles.PulseLoader }>
            <PulseLoadingIndicator isLoading={ true }/>
          </div>
        );
      }

      return <div>{ questionNumber } of { questionsToBeAnsweredSize }</div>;
    }

    return (
      <div
        className={ styles.Root }
        data-automated-test="quiz--question"
      >
        <If condition={ isTransitioning }>
          <div className={ styles.Transitioner } />
        </If>

        <div
          className={ styles.Container }
          ref={ this.containerRef }
        >
          <Header
            exitLinkPath={ exitLinkPath }
            history={ history }
            match={ match }
            middleSection={ determineMiddleSection() }
          />

          <Choose>
            <When condition={ question }>
              <div
                className={ styles.ContentContainer }
                onScroll={ this.handleScroll }
                onTouchMove={ this.handleScroll }
                ref={ this.contentContainerRef }
              >
                <div ref={ this.questionContentRef }>
                  <QuestionContent
                    hasQuizStarted={ hasQuizStarted }
                    question={ question }
                    questionNumber={ questionNumber }
                  />
                </div>

                <div className={ styles.HorizontalLine }>
                  <HorizontalLine />
                </div>

                <Choose>
                  <When condition={ timeTaken }>
                    <AnswerContent
                      question={ question }
                      shouldShowEditLink
                    />

                    <RecallEvaluation
                      handleNext={ this.handleNext }
                      hasQuizStarted={ hasQuizStarted }
                      isActive={ isActive }
                      isFirstQuiz={ quiz.get('isFirstQuiz') }
                      isOnLastQuestion={ isOnLastQuestion }
                      isRecallEvaluated={ isRecallEvaluated }
                      isTransitioningToCongratulations={ isTransitioningToCongratulations }
                      question={ question }
                      questionNumber={ questionNumber }
                      updateQuestionToBeAnswered={ updateQuestionToBeAnswered }
                    />
                  </When>

                  <Otherwise>
                    <AnswerRevealBox
                      isResetting={ isResetting }
                      question={ question }
                      scrollToAnswerContent={ this.scrollToAnswerContent }
                      secondsToReveal={ secondsToReveal }
                      updateQuestionToBeAnswered={ updateQuestionToBeAnswered }
                    />
                  </Otherwise>
                </Choose>
              </div>
            </When>

            <Otherwise>
              <h3>Loading...</h3>
            </Otherwise>
          </Choose>
        </div>
      </div>
    );
  }
}

export default QuizQuestion;
