import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import * as authenticationPropTypes from 'src/constants/propTypes/authentication';
import * as basePropTypes from 'src/constants/propTypes/base';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import classNames from 'src/components/shared/classNames';
import fireConfetti from 'src/components/shared/fireConfetti';
import TextField from 'src/components/fields/TextField';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function OtpConfirmation(props) {
  const {
    errors,
    handleSubmit,
    isActive,
    isLocked,
    shouldFireConfetti,
  } = props;

  const { handleSubmit: formSubmit, register } = useForm();

  const onSubmit = formSubmit(handleSubmit);

  useEffect(() => {
    if (shouldFireConfetti && !errors) fireConfetti();
  }, [shouldFireConfetti, errors]);

  function handleChange({ target : { value } }) {
    if (value.length === 6) return onSubmit(value);
  }

  return (
    <form
      className={ styles.Root }
      onSubmit={ onSubmit }
    >
      <TextField
        autoFocus
        disabled={ isActive || isLocked || shouldFireConfetti }
        errorMessages={ errors && errors.get('otp') }
        heading="One Time Password"
        inputMode="numeric"
        name="otp"
        onChange={ handleChange }
        register={ register }
        type="text"
      />

      <ButtonSubmit
        disabled={ isActive || isLocked || shouldFireConfetti }
        extraClasses={ { container : cx({ SubmitButton : true, isActive }) } }
        isActive={ isActive }
        type="submit"
        value="Verify"
        isBelowInput
      />
    </form>
  );
}

OtpConfirmation.propTypes = {
  errors             : basePropTypes.errors,
  handleSubmit       : authenticationPropTypes.handleSubmit.isRequired,
  isActive           : basePropTypes.isActive.isRequired,
  isLocked           : authenticationPropTypes.isLocked,
  shouldFireConfetti : PropTypes.bool,
};

export default OtpConfirmation;
