// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2KnoB{display:flex;flex-direction:column;grid-gap:36px;gap:36px}.styles-module__Root___2KnoB *{color:#0a3959}.styles-module__Title___3aZSo{font-size:30px;line-height:150%;margin-top:-7.5px;text-align:center}.styles-module__Title___3aZSo strong{font-size:30px;font-weight:bold}.styles-module__TitleSubText___k6ded{margin-top:-15px;text-align:center}.styles-module__ShortHorizontalSeparator___2Uz2E{background-color:#0a3959;height:1px;margin:-8px auto 0;width:100px}.styles-module__MoneyBackGuarantee___hFtQb{margin:0 auto;max-width:403px;text-align:center}@media screen and (max-width: 980px){.styles-module__MoneyBackGuarantee___hFtQb{max-width:304px}}", "",{"version":3,"sources":["/tmp/build_53623bcf/app/javascript/src/components/profile/AccountPlan/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,+BACE,aAAA,CAEJ,8BACE,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA,CACA,qCACE,cAAA,CACA,gBAAA,CAEJ,qCACE,gBAAA,CACA,iBAAA,CAEF,iDACE,wBAAA,CACA,UAAA,CACA,kBAAA,CACA,WAAA,CAEF,2CACE,aAAA,CACA,eAAA,CACA,iBAAA,CACA,qCACE,2CACE,eAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: column;\n  gap: 36px; }\n  .Root * {\n    color: #0a3959; }\n\n.Title {\n  font-size: 30px;\n  line-height: 150%;\n  margin-top: -7.5px;\n  text-align: center; }\n  .Title strong {\n    font-size: 30px;\n    font-weight: bold; }\n\n.TitleSubText {\n  margin-top: -15px;\n  text-align: center; }\n\n.ShortHorizontalSeparator {\n  background-color: #0a3959;\n  height: 1px;\n  margin: -8px auto 0;\n  width: 100px; }\n\n.MoneyBackGuarantee {\n  margin: 0 auto;\n  max-width: 403px;\n  text-align: center; }\n  @media screen and (max-width: 980px) {\n    .MoneyBackGuarantee {\n      max-width: 304px; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2KnoB",
	"Title": "styles-module__Title___3aZSo",
	"TitleSubText": "styles-module__TitleSubText___k6ded",
	"ShortHorizontalSeparator": "styles-module__ShortHorizontalSeparator___2Uz2E",
	"MoneyBackGuarantee": "styles-module__MoneyBackGuarantee___hFtQb"
};
module.exports = exports;
