import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

export const answersMemorizedPercent = ImmutablePropTypes.mapContains({
  answersMemorized      : PropTypes.number.isRequired,
  currentlyMemorized    : PropTypes.number.isRequired,
  questionsBeingStudied : PropTypes.number.isRequired,
});

export const answersMemorizedTotals = ImmutablePropTypes.mapContains({
  currentlyMemorized : PropTypes.number.isRequired,
});

// ----- //

export const answersMemorized = ImmutablePropTypes.mapContains({
  percent : answersMemorizedPercent.isRequired,
  totals  : answersMemorizedTotals.isRequired,
});

export const avgFutureRetention = ImmutablePropTypes.mapContains({
  current : PropTypes.number.isRequired,
});

export const avgStudyTime = ImmutablePropTypes.mapContains({
  allTime    : PropTypes.number.isRequired,
  last7Days  : PropTypes.number.isRequired,
  last30Days : PropTypes.number.isRequired,
});

export const questionsStudied = ImmutablePropTypes.mapContains({
  last7Days         : PropTypes.number.isRequired,
  last30Days        : PropTypes.number.isRequired,
  longestQuizStreak : PropTypes.number.isRequired,
  quizStreak        : PropTypes.number.isRequired,
  today             : PropTypes.number.isRequired,
  total             : PropTypes.number.isRequired,
});

export const totals = ImmutablePropTypes.mapContains({
  answersMemorized   : PropTypes.number.isRequired,
  avgFutureRetention : PropTypes.number.isRequired,
  avgStudyTime       : PropTypes.number.isRequired,
  questionsStudied   : PropTypes.number.isRequired,
});

// ----- //

export const chartData = PropTypes.arrayOf(PropTypes.shape({
  tooltip : PropTypes.shape({
    label : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
  }),
  xAxisName : PropTypes.string.isRequired,
  yValue    : PropTypes.number.isRequired,
}));

export const dashboardReport = ImmutablePropTypes.mapContains({
  avgFutureRetention    : PropTypes.number.isRequired,
  completedQuizzesSize  : PropTypes.number.isRequired,
  quizStreak            : PropTypes.number.isRequired,
  totalAnswersMemorized : PropTypes.number.isRequired,
});

export const dataPointsReport = ImmutablePropTypes.mapContains({
  dataPoints : ImmutablePropTypes.listOf(PropTypes.shape({
    tooltip : PropTypes.shape({
      label : PropTypes.string.isRequired,
      value : PropTypes.string.isRequired,
    }),
    xAxisName : PropTypes.string,
    yValue    : PropTypes.number,
  })).isRequired,
});

export const fullReport = ImmutablePropTypes.mapContains({
  answersMemorized   : answersMemorized.isRequired,
  avgFutureRetention : avgFutureRetention.isRequired,
  avgStudyTime       : avgStudyTime.isRequired,
  questionsStudied   : questionsStudied.isRequired,
  totals             : totals.isRequired,
});

export const stat = PropTypes.shape({
  dataAutomatedTest : PropTypes.string,
  hint              : PropTypes.string,
  title             : PropTypes.string.isRequired,
  value             : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export const stats = PropTypes.arrayOf(stat);
