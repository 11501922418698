import React from 'react';

function ErrorIcon() {
  return (
    <svg
      width="700pt"
      height="700pt"
      viewBox="0 0 700 700"
    >
     <g>
       <path
          d="m602 280c0 139.18-112.82 252-252 252s-252-112.82-252-252 112.82-252 252-252 252 112.82 252 252zm-218.4 117.6c0 18.555-15.043 33.598-33.602 33.598s-33.602-15.043-33.602-33.598c0-18.559 15.043-33.602 33.602-33.602s33.602 15.043 33.602 33.602zm-33.602-291.2c-18.559 0-33.602 15.043-33.602 33.602v168c0 18.559 15.043 33.602 33.602 33.602s33.602-15.043 33.602-33.602v-168c0-18.559-15.043-33.602-33.602-33.602z"
          fillRule="evenodd"
        />
     </g>

    </svg>
  );
}

export default ErrorIcon;
