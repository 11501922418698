import keyMirror from 'keymirror';

export default keyMirror({
  ATTEMPT__CREATE_FAILURE : null,
  ATTEMPT__CREATE_START   : null,
  ATTEMPT__CREATE_SUCCESS : null,

  ATTEMPT__RESET : null,

  ATTEMPT__UPDATE_FAILURE : null,
  ATTEMPT__UPDATE_START   : null,
  ATTEMPT__UPDATE_SUCCESS : null,

  AUTH__ADD_ERRORS : null,

  AUTH__CHECK_LAST_EMAIL_STATUS_FAILURE : null,
  AUTH__CHECK_LAST_EMAIL_STATUS_START   : null,
  AUTH__CHECK_LAST_EMAIL_STATUS_SUCCESS : null,

  AUTH__CLEAR_TEMP_STATE : null,

  AUTH__HANDLE_SERVER_ERROR : null,

  AUTH__SIGN_IN_FAILURE : null,
  AUTH__SIGN_IN_START   : null,
  AUTH__SIGN_IN_SUCCESS : null,

  AUTH__SIGN_OUT : null,

  AUTH__UPDATE_TOKEN : null,

  AUTH__UPDATE_USER : null,

  AUTH__VERIFY_OTP_FAILURE : null,
  AUTH__VERIFY_OTP_START   : null,
  AUTH__VERIFY_OTP_SUCCESS : null,

  GENERAL__ADD_ERRORS : null,

  GENERAL__CLEAR_TEMP_STATE : null,

  GENERAL__CLOSE_BANNER : null,

  GENERAL__SUBMIT_FEEDBACK_FAILURE : null,
  GENERAL__SUBMIT_FEEDBACK_START   : null,
  GENERAL__SUBMIT_FEEDBACK_SUCCESS : null,

  ONBOARDING__ADD_FIRST_STUDY_SET : null,

  ONBOARDING__CLEAR_ONBOARDING : null,

  PAYMENT__ACKNOWLEDGE_GOOGLE_PLAY_PURCHASE_FAILURE : null,
  PAYMENT__ACKNOWLEDGE_GOOGLE_PLAY_PURCHASE_START   : null,
  PAYMENT__ACKNOWLEDGE_GOOGLE_PLAY_PURCHASE_SUCCESS : null,

  PAYMENT__START_STRIPE_CHECKOUT_FAILURE : null,
  PAYMENT__START_STRIPE_CHECKOUT_START   : null,
  PAYMENT__START_STRIPE_CHECKOUT_SUCCESS : null,

  PROFILE__FETCH_PROGRESS_REPORT_FAILURE : null,
  PROFILE__FETCH_PROGRESS_REPORT_START   : null,
  PROFILE__FETCH_PROGRESS_REPORT_SUCCESS : null,

  PROFILE__SHOW_FAILURE : null,
  PROFILE__SHOW_START   : null,
  PROFILE__SHOW_SUCCESS : null,

  PROFILE__UPDATE_NOTIFICATION_SETTING_FAILURE : null,
  PROFILE__UPDATE_NOTIFICATION_SETTING_START   : null,
  PROFILE__UPDATE_NOTIFICATION_SETTING_SUCCESS : null,

  PROFILE__UPDATE_PROFILE_FAILURE : null,
  PROFILE__UPDATE_PROFILE_START   : null,
  PROFILE__UPDATE_PROFILE_SUCCESS : null,

  PROFILE__UPDATE_PROGRESS_FAILURE : null,
  PROFILE__UPDATE_PROGRESS_START   : null,
  PROFILE__UPDATE_PROGRESS_SUCCESS : null,

  PUBLIC_STUDY_SET__ADD_FAILURE : null,
  PUBLIC_STUDY_SET__ADD_START   : null,
  PUBLIC_STUDY_SET__ADD_SUCCESS : null,

  PUBLIC_STUDY_SET__CLEAR_TEMP_STATE : null,

  PUBLIC_STUDY_SET__GET_ALL_FEATURED_FAILURE : null,
  PUBLIC_STUDY_SET__GET_ALL_FEATURED_START   : null,
  PUBLIC_STUDY_SET__GET_ALL_FEATURED_SUCCESS : null,

  PUBLIC_STUDY_SET__GET_ALL_FAILURE : null,
  PUBLIC_STUDY_SET__GET_ALL_START   : null,
  PUBLIC_STUDY_SET__GET_ALL_SUCCESS : null,

  PUBLIC_STUDY_SET__SHOW_FAILURE : null,
  PUBLIC_STUDY_SET__SHOW_START   : null,
  PUBLIC_STUDY_SET__SHOW_SUCCESS : null,

  PUSH_NOTIFICATION__CREATE_FAILURE : null,
  PUSH_NOTIFICATION__CREATE_START   : null,
  PUSH_NOTIFICATION__CREATE_SUCCESS : null,

  QUESTION__ADD_ERRORS : null,

  QUESTION__CLEAR_TEMP_STATE : null,

  QUESTION__CREATE_FAILURE : null,
  QUESTION__CREATE_START   : null,
  QUESTION__CREATE_SUCCESS : null,

  QUESTION__DELETE_FAILURE : null,
  QUESTION__DELETE_START   : null,
  QUESTION__DELETE_SUCCESS : null,

  QUESTION__DELETE_ANSWER_AUDIO_FAILURE : null,
  QUESTION__DELETE_ANSWER_AUDIO_START   : null,
  QUESTION__DELETE_ANSWER_AUDIO_SUCCESS : null,

  QUESTION__DELETE_ANSWER_IMAGE_FAILURE : null,
  QUESTION__DELETE_ANSWER_IMAGE_START   : null,
  QUESTION__DELETE_ANSWER_IMAGE_SUCCESS : null,

  QUESTION__DELETE_AUDIO_FAILURE : null,
  QUESTION__DELETE_AUDIO_START   : null,
  QUESTION__DELETE_AUDIO_SUCCESS : null,

  QUESTION__DELETE_IMAGE_FAILURE : null,
  QUESTION__DELETE_IMAGE_START   : null,
  QUESTION__DELETE_IMAGE_SUCCESS : null,

  QUESTION__SHOW_FAILURE : null,
  QUESTION__SHOW_START   : null,
  QUESTION__SHOW_SUCCESS : null,

  QUESTION__UPDATE_FAILURE : null,
  QUESTION__UPDATE_START   : null,
  QUESTION__UPDATE_SUCCESS : null,

  QUIZ__ADD_QUESTIONS_TO_BE_ANSWERED : null,

  QUIZ__CLEAR_TEMP_STATE : null,

  QUIZ__FETCH_NEWEST_FAILURE        : null,
  QUIZ__FETCH_NEWEST_START          : null,
  QUIZ__FETCH_NEWEST_SUCCESS        : null,
  QUIZ__FETCH_NEWEST_SUCCESS__EMPTY : null,

  QUIZ__RESET : null,

  QUIZ__RESET_FLASH_STUDY_SESSION_FAILURE : null,
  QUIZ__RESET_FLASH_STUDY_SESSION_START   : null,
  QUIZ__RESET_FLASH_STUDY_SESSION_SUCCESS : null,

  QUIZ__SHOW_FAILURE : null,
  QUIZ__SHOW_START   : null,
  QUIZ__SHOW_SUCCESS : null,

  QUIZ__START_FLASH_STUDY_SESSION_FAILURE : null,
  QUIZ__START_FLASH_STUDY_SESSION_START   : null,
  QUIZ__START_FLASH_STUDY_SESSION_SUCCESS : null,

  QUIZ__UPDATE_IS_TRANSITIONING_TO_CONGRATULATIONS : null,
  QUIZ__UPDATE_QUESTION_TO_BE_ANSWERED             : null,

  SEARCH__CLEAR_RESULTS : null,

  SEARCH__CLEAR_TEMP_STATE : null,

  SEARCH__USER_QUESTIONS_FAILURE : null,
  SEARCH__USER_QUESTIONS_START   : null,
  SEARCH__USER_QUESTIONS_SUCCESS : null,

  STATS__FETCH_DASHBOARD_REPORT_FAILURE : null,
  STATS__FETCH_DASHBOARD_REPORT_START   : null,
  STATS__FETCH_DASHBOARD_REPORT_SUCCESS : null,

  STATS__FETCH_FULL_REPORT_FAILURE : null,
  STATS__FETCH_FULL_REPORT_START   : null,
  STATS__FETCH_FULL_REPORT_SUCCESS : null,

  STUDY_SET__ADD_QUESTION_TO_EDIT : null,

  STUDY_SET__ADD_QUESTION_TO_NEW : null,

  STUDY_SET__BULK_ADD_QUESTIONS_TO_EDIT : null,

  STUDY_SET__BULK_ADD_QUESTIONS_TO_NEW : null,

  STUDY_SET__CLEAR_AUTOSAVED_EDIT : null,

  STUDY_SET__CLEAR_AUTOSAVED_NEW : null,

  STUDY_SET__CLEAR_TEMP_STATE : null,

  STUDY_SET__CREATE_FAILURE : null,
  STUDY_SET__CREATE_START   : null,
  STUDY_SET__CREATE_SUCCESS : null,

  STUDY_SET__DELETE_FAILURE : null,
  STUDY_SET__DELETE_START   : null,
  STUDY_SET__DELETE_SUCCESS : null,

  STUDY_SET__GET_ALL_FAILURE : null,
  STUDY_SET__GET_ALL_START   : null,
  STUDY_SET__GET_ALL_SUCCESS : null,

  STUDY_SET__REMOVE_ANSWER_AUDIO : null,

  STUDY_SET__REMOVE_ANSWER_IMAGE : null,

  STUDY_SET__REMOVE_QUESTION_AUDIO : null,

  STUDY_SET__REMOVE_QUESTION_FROM_EDIT : null,

  STUDY_SET__REMOVE_QUESTION_FROM_NEW : null,

  STUDY_SET__REMOVE_QUESTION_IMAGE : null,

  STUDY_SET__SET_AUTOSAVED_EDIT_FAILURE : null,
  STUDY_SET__SET_AUTOSAVED_EDIT_START   : null,
  STUDY_SET__SET_AUTOSAVED_EDIT_SUCCESS : null,

  STUDY_SET__SHOW_FAILURE : null,
  STUDY_SET__SHOW_START   : null,
  STUDY_SET__SHOW_SUCCESS : null,

  STUDY_SET__START_SAVE : null,

  STUDY_SET__UNDO_REMOVE_QUESTION_FROM_EDIT : null,

  STUDY_SET__UPDATE_FAILURE : null,
  STUDY_SET__UPDATE_START   : null,
  STUDY_SET__UPDATE_SUCCESS : null,

  STUDY_SET__UPDATE_VALUE_IN_AUTOSAVED : null,
});
