import gql from 'graphql-tag';

import answerFields from 'src/graphql/fragments/answerFields';

export default gql`
  mutation deleteAnswerImage(
    $answerId: ID
  ) {
    deleteAnswerImage(
      answerId: $answerId
    ) {
      ...answerFields
    }
  }
  ${answerFields}
`;
