import classNames from 'classnames/bind';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function ButtonDiv(props) {
  const {
    children,
    dataAutomatedTest,
    disabled,
    extraClassNames,
    floatRight,
    fullWidth,
    handleClick : propsHandleClick,
    hint,
    iconIsOnLeftSide,
    isSecondary,
    isTertiary,
    noBorder,
    shouldNotRebuildClick,
    value,
    withArrow,
    withBolt,
    withBrain,
    withDownArrow,
    withDownload,
    withPDFIcon,
    withPlusSign,
    withTrashCan,
  } = props;

  const isPrimary = !isSecondary && !isTertiary;

  const getButtonClassNames = () => cx({
    Button     : true,
    floatRight,
    fullWidth,
    isDisabled : disabled,
    isPrimary,
    isSecondary,
    isTertiary,
    noBorder,
  });

  const getIconClassNames = () => {
    let fasClass;

    if (withArrow) fasClass = 'fas fa-long-arrow-alt-right';
    if (withBolt) fasClass = 'fas fa-bolt';
    if (withBrain) fasClass = 'fas fa-brain';
    if (withDownArrow) fasClass = 'fas fa-arrow-down';
    if (withDownload) fasClass = 'fas fa-download';
    if (withPlusSign) fasClass = 'fas fa-plus';
    if (withPDFIcon) fasClass = 'fas fa-file-pdf';
    if (withTrashCan) fasClass = 'fas fa-trash';

    return cx({
      [fasClass] : true,
      Icon       : fasClass,
      iconIsOnLeftSide,
      isDisabled : disabled,
      isPrimary,
      isSecondary,
      isTertiary,
    });
  };

  const handleClick = () => {
    if (disabled) return;
    propsHandleClick();
  };

  return (
    <div className={ `${styles.Root} ${extraClassNames}` }>
      <div
        className={ getButtonClassNames() }
        data-automated-test={ dataAutomatedTest }
        data-rh={ window.innerWidth < 768 ? null : hint }
        onClick={ shouldNotRebuildClick ? propsHandleClick : handleClick }
      >
        <Choose>
          <When condition={ children }>
            { children }
          </When>

          <Otherwise>
            <Choose>
              <When condition={ isTertiary }>
                <If condition={ iconIsOnLeftSide }>
                  <div className={ styles.TertiaryIcon }>
                    <i className={ getIconClassNames() } />
                  </div>
                </If>

                <p>{ value }</p>

                <If condition={ !iconIsOnLeftSide }>
                  <div className={ styles.TertiaryIcon }>
                    <i className={ getIconClassNames() } />
                  </div>
                </If>
              </When>

              <Otherwise>
                <If condition={ iconIsOnLeftSide }>
                  <i className={ getIconClassNames() } />
                </If>

                { value }

                <If condition={ !iconIsOnLeftSide }>
                  <i className={ getIconClassNames() } />
                </If>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    </div>
  );
}

ButtonDiv.propTypes = {
  children          : PropTypes.node,
  dataAutomatedTest : basePropTypes.dataAutomatedTest,
  disabled          : basePropTypes.disabled,
  extraClassNames   : PropTypes.string,
  floatRight        : basePropTypes.floatRight,
  fullWidth         : PropTypes.bool,
  handleClick       : basePropTypes.handleClick,
  hint              : PropTypes.string,
  iconIsOnLeftSide  : PropTypes.bool,
  isSecondary       : basePropTypes.isSecondary,
  isTertiary        : basePropTypes.isTertiary,
  noBorder          : PropTypes.bool,
  value             : basePropTypes.value,
  withArrow         : PropTypes.bool,
  withBolt          : PropTypes.bool,
  withDownArrow     : basePropTypes.withDownArrow,
  withDownload      : PropTypes.bool,
  withPDFIcon       : PropTypes.bool,
  withPlusSign      : basePropTypes.withPlusSign,
  withTrashCan      : PropTypes.bool,
};

export default ButtonDiv;
