import ImmutablePropTypes from 'react-immutable-proptypes';
import { PropTypes } from 'prop-types';

export const stripeSubscription = ImmutablePropTypes.mapContains({
  currentPeriodEnd : PropTypes.string.isRequired,
  price            : PropTypes.number,
  status           : PropTypes.string.isRequired,
});

// ----- //

export const completedQuizzesSize = PropTypes.number;
export const currentPeriodEnd = PropTypes.string;
export const formattedDate = PropTypes.string;
export const givenFreeAccess = PropTypes.bool;
export const handleMaxQuestionAmountSave = PropTypes.func;
export const handleNotificationSettingUpdate = PropTypes.func;
export const handleChooseStudySetsSave = PropTypes.func;
export const handlePreferenceUpdate = PropTypes.func;
export const maxQuestionAmountPerQuiz = PropTypes.number;

export const notificationSetting = ImmutablePropTypes.mapContains({
  id       : PropTypes.string.isRequired,
  medium   : PropTypes.string.isRequired,
  optedOut : PropTypes.bool.isRequired,
});

export const notificationSettings = ImmutablePropTypes.listOf(notificationSetting);

export const paymentMethods = ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains({
  brand    : PropTypes.string.isRequired,
  lastFour : PropTypes.string.isRequired,
}));

export const stripeAccount = ImmutablePropTypes.mapContains({
  paymentMethods,
  stripeSubscription,
});
