import isQuizReady from 'src/shared/isQuizReady';

export default function (questions, quiz) {
  if (!quiz) return false;

  const quizIsReady = isQuizReady(quiz);
  if (quizIsReady) return false;

  const previousQuizDueDate = quiz.getIn(['previousQuiz', 'dueDate']);
  if (!previousQuizDueDate) return false;

  return questions && questions.size === 0;
}
