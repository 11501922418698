import classNames from 'classnames/bind';
import { PropTypes } from 'prop-types';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function Button(props) {
  const {
    disabled,
    handleClick : propsHandleClick,
    isSelected,
    text,
  } = props;

  function handleClick() {
    propsHandleClick();
  }

  const getRootClassNames = () => cx({
    Root        : true,
    isSecondary : !isSelected,
    isDisabled  : disabled,
  });

  return (
    <div
      className={ getRootClassNames() }
      data-automated-test="evaluation-button"
      data-automated-test-isselected={ isSelected }
      onClick={ handleClick }
    >
      { text }
    </div>
  );
}

Button.propTypes = {
  disabled    : basePropTypes.disabled,
  handleClick : basePropTypes.handleClick.isRequired,
  isSelected  : PropTypes.bool,
  text        : basePropTypes.text.isRequired,
};

export default Button;
