import { PropTypes } from 'prop-types';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import LineChart from '../LineChart';
import TextStats from '../TextStats';

import styles from './styles.module.scss';

function AvgStudyTime(props) {
  const {
    isEmpty,
    report,
  } = props;

  function buildValue(seconds) {
    return `${(seconds / 60).toFixed(1)} mins/day`;
  }

  const stats = [
    {
      dataAutomatedTest : 'avg-study-time--all-time',
      title             : 'All time',
      value             : buildValue(report.get('allTime')),
    },
    {
      dataAutomatedTest : 'avg-study-time--last-7-days',
      title             : 'Last 7 days',
      value             : buildValue(report.get('last7Days')),
    },
    {
      dataAutomatedTest : 'avg-study-time--last-30-days',
      title             : 'Last 30 days',
      value             : buildValue(report.get('last30Days')),
    },
  ];

  return (
    <div className={ styles.Root }>
      <TextStats stats={ stats } />
      <LineChart isEmpty={ isEmpty } report={ report } />
    </div>
  );
}

AvgStudyTime.propTypes = {
  isEmpty : PropTypes.bool,
  report  : statsPropTypes.avgStudyTime.isRequired,
};

export default AvgStudyTime;
