import { PropTypes } from 'prop-types';
import React from 'react';

import * as statsPropTypes from 'src/constants/propTypes/stats';

import buildTotalDaysMonthsText from 'src/components/shared/buildTotalDaysMonthsText';

import InfoText from '../InfoText';
import LineChart from '../LineChart';
import TextStats from '../TextStats';

import styles from './styles.module.scss';

function AvgFutureRetention(props) {
  const {
    isEmpty,
    report,
  } = props;

  const value = buildTotalDaysMonthsText(report.get('current'));

  const stats = [
    {
      dataAutomatedTest : 'avg-future-retention--current-avg-future-retention',
      title             : 'Current avg future retention',
      value,
    },
  ];

  return (
    <div
      className={ styles.Root }
      data-automated-test="charts--avg-future-retention"
    >
      <InfoText>
        The average amount of time we expect you to retain each answer
        (<strong>{ value }</strong>),
        starting from the last time you were quizzed on it.
      </InfoText>

      <TextStats stats={ stats } />
      <LineChart isEmpty={ isEmpty } report={ report } />
    </div>
  );
}

AvgFutureRetention.propTypes = {
  isEmpty : PropTypes.bool,
  report  : statsPropTypes.avgFutureRetention.isRequired,
};

export default AvgFutureRetention;
