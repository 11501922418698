import progressReport from 'src/graphql/queries/profile/progressReport';
import updateNotificationSetting from 'src/graphql/mutations/profile/updateNotificationSetting';
import updateProfile from 'src/graphql/mutations/profile/updateProfile';
import updateProgress from 'src/graphql/mutations/profile/updateProgress';
import userProfile from 'src/graphql/queries/profile/userProfile';

import post from './shared/post';

export default {
  fetchProgressReport() {
    return post(null, progressReport);
  },

  show() {
    return post(null, userProfile);
  },

  updateProfile(values) {
    return post(values, updateProfile);
  },

  updateProgress(values) {
    return post(values, updateProgress);
  },

  updateNotificationSetting(values) {
    return post(values, updateNotificationSetting);
  },
};
