import React from 'react';

function Checkmark() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path d="M20.2725 6.41992L9.27246 17.4199L4.27246 12.4199" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default Checkmark;
