import Immutable from 'immutable';

export default Immutable.fromJS({
  errors                           : null,
  isActive                         : false,
  isSampleQuiz                     : false,
  isSuccessful                     : null,
  isTransitioningToCongratulations : false,
  loaded                           : { newest : null },
  questionsToBeAnswered            : {},
});
