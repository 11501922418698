import classNames from 'classnames/bind';
import PulseLoader from 'react-spinners/PulseLoader';
import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function PulseLoadingIndicator({ defaultSpacing, isLoading }) {
  const getRootClassNames = () => cx({
    Root : true,
    defaultSpacing,
  });

  return (
    <div
      className={ getRootClassNames() }
      data-automated-test="pulse-loading-indicator"
    >
      <PulseLoader
        color="#b2dfdb"
        loading={ isLoading }
        margin={ 5 }
        size={ 10 }
      />
    </div>
  );
}

PulseLoadingIndicator.propTypes = {
  defaultSpacing : basePropTypes.defaultSpacing,
  isLoading      : basePropTypes.isLoading,
};

export default PulseLoadingIndicator;
