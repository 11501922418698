export default function buildStudySetValues(values, studySet = null) {
  let { description } = values;
  let { visibilityStatus } = values;

  // When saving undesignated study set, visibilityStatus and description input
  // will be disabled and so the values needs to be manually set
  if (studySet.get('isUndesignated')) {
    description = studySet.get('description');
    visibilityStatus = 'is_private';
  }

  return {
    ...values,
    description,
    visibilityStatus,
  };
}
