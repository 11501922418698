import React from 'react';

import styles from './styles.module.scss';

function HorizontalLine() {
  return (
    <div className={ styles.Root } />
  );
}

export default HorizontalLine;
