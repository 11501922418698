import newestQuiz from 'src/graphql/queries/quizzes/newestQuiz';
import resetFlashStudySession from 'src/graphql/mutations/quizzes/resetFlashStudySession';
import showQuiz from 'src/graphql/queries/quizzes/showQuiz';
import startFlashStudySession from 'src/graphql/mutations/quizzes/startFlashStudySession';

import post from './shared/post';

export default {
  fetchNewest() {
    return post(null, newestQuiz);
  },

  resetFlashStudySession(values) {
    return post(values, resetFlashStudySession);
  },

  show(values) {
    return post(values, showQuiz);
  },

  startFlashStudySession(values) {
    return post(values, startFlashStudySession);
  },
};
