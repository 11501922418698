import React from 'react';

function LinkOut() {
  return (
    <svg viewBox="0 0 700 700">
     <path d="m518 448c-0.015625 15.457-12.543 27.992-28 28.016l-307.45 0.17578c-15.457-0.011718-27.996-12.527-28.039-27.984l-0.41406-308.16c-0.023437-15.465 12.496-28.016 27.961-28.039h181.86c7.7344 0 14 6.2695 14 14 0 7.7344-6.2656 14-14 14h-181.85l-0.058594 308.06 307.98-0.070312v-182c0-7.7305 6.2695-14 14-14s14 6.2695 14 14zm30.793-350-0.14062 1.5664-0.085938 110.97c0 6.875-4.9531 12.59-11.484 13.773l-2.5156 0.22656c-7.7344 0-14-6.2656-14-14l0.058594-76.781-168.93 168.93c-5.4688 5.4648-14.332 5.4648-19.801 0-5.4648-5.4688-5.4648-14.332 0-19.801l170.89-170.89-80.535 0.074219c-7.7305 0-14-6.2695-14-14 0-7.7344 6.2695-14 14-14l112.54-0.074219c7.7305 0 14 6.2695 14 14z" fillRule="evenodd"/>
    </svg>
  );
}

LinkOut.propTypes = {};

export default LinkOut;
