import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as questionPropTypes from 'src/constants/propTypes/question';
import * as quizPropTypes from 'src/constants/propTypes/quiz';
import * as quizzesActions from 'src/actions/quizzes';

import analytics from 'src/shared/analytics';
import getUserIdFromStore from 'src/shared/getUserIdFromStore';
import navigateOrWait from 'src/containers/shared/navigateOrWait';
import QuizExplanation from 'src/components/quiz/Explanation';
import shouldRedirectToEnd from 'src/containers/quiz/shared/shouldRedirectToEnd';

class FunctionalQuizExplanation extends PureComponent {
  static propTypes = {
    actions   : basePropTypes.actions.isRequired,
    history   : basePropTypes.history.isRequired,
    isActive  : PropTypes.bool,
    location  : basePropTypes.location.isRequired,
    match     : basePropTypes.match.isRequired,
    newest    : quizPropTypes.quiz,
    questions : questionPropTypes.questions,
    quiz      : quizPropTypes.quiz,
    quizId    : basePropTypes.id.isRequired,
  };

  componentDidMount() {
    const { actions, newest, quiz, quizId } = this.props;
    // Using window.location instead of this.props.location because this
    // page might be opened from a Rails redirect and if so,
    // this.props.location won't be "ready".
    const { hash } = window.location;
    const userId = getUserIdFromStore();

    if (userId) analytics.identify(userId);

    // NOTE: Something similar will get called from the dashboard
    // as well. But if you're starting a quiz from your email,
    // it will begin on the explanation page so we should call
    // it again.
    if (
      !quiz ||
      (
        newest &&
        newest.get('id') !== quiz.get('id') &&
        !quiz.get('studySetId')
      ) ||
      (hash && hash.includes('purpose=email_quiz'))
    ) {
      return actions
        .quizzes
        .show({ id : quizId })
        .then(() => actions.quizzes.addToBeAnsweredQuestions(quizId));
    }
  }

  isSample() {
    const { search } = this.props.location;

    return Boolean(search && search.includes('purpose=sample'));
  }

  resetFlashStudySession = () => {
    const { actions : { quizzes }, quiz } = this.props;

    const studySetId = quiz.get('studySetId');

    if (!studySetId) return;

    const quizId = quiz.get('id');

    return quizzes
      .resetFlashStudySession({ quizId, studySetId })
      .then(() => {
        quizzes.addToBeAnsweredQuestions(quizId);
        return quizId;
      })
      .then(() => navigateOrWait(this.props, `/quiz/${quizId}/questions/1`));
  }

  studyOnlyWrongQuestions = () => {
    const { actions : { quizzes }, quiz } = this.props;

    const studySetId = quiz.get('studySetId');

    if (!studySetId) return;

    const quizId = quiz.get('id');

    return quizzes
      .startFlashStudySession({ onlyWrongOnes : true, studySetId })
      .then(() => {
        quizzes.addToBeAnsweredQuestions(quizId);
        return quizId;
      })
      .then(() => navigateOrWait(this.props, `/quiz/${quizId}/questions/1`));
  }

  render() {
    const { history, match, questions, quiz, quizId } = this.props;

    if (shouldRedirectToEnd(questions, quiz)) {
      return <Redirect to={ `/quiz/${quizId}/congratulations` } />;
    }

    if (!quiz) return null;

    return (
      <QuizExplanation
        estimatedTimeToCompleteInMinutes={ quiz.get('estimatedTimeToCompleteInMinutes') }
        history={ history }
        isActive={ this.props.isActive }
        isSample={ this.isSample() }
        match={ match }
        questions={ questions }
        questionsSize={ questions && questions.size }
        quiz={ quiz }
        resetFlashStudySession={ this.resetFlashStudySession }
        studyOnlyWrongQuestions={ this.studyOnlyWrongQuestions }
      />
    );
  }
}

function mapStateToProps({ quizzes }, { match : { params : { id } } }) {
  return {
    isActive  : quizzes.get('isActive'),
    newest    : quizzes.getIn(['loaded', 'newest']),
    questions : quizzes.getIn(['loaded', id, 'questions']),
    quiz      : quizzes.getIn(['loaded', id]),
    quizId    : id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      quizzes : bindActionCreators(quizzesActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalQuizExplanation);
