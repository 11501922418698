import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import * as basePropTypes from 'src/constants/propTypes/base';
import * as publicStudySetsActions from 'src/actions/publicStudySets';
import * as studySetPropTypes from 'src/constants/propTypes/studySet';

import getUrlParameter from 'src/shared/getUrlParameter';
import PublicStudySetsAll from 'src/components/publicStudySets/All';

class FunctionalAll extends PureComponent {
  static propTypes = {
    actions   : basePropTypes.actions.isRequired,
    isActive  : basePropTypes.isActive,
    isAuthed  : PropTypes.bool,
    location  : basePropTypes.location.isRequired,
    match     : basePropTypes.match.isRequired,
    studySets : studySetPropTypes.studySets.isRequired,
  };

  componentDidMount() {
    return this.searchForPublicStudySets();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.searchForPublicStudySets();
    }
  }

  searchForPublicStudySets = () => {
    const params = { filter : getUrlParameter('filter') };
    return this.props.actions.publicStudySets.getAll(params);
  }

  render() {
    if (!this.props.isAuthed) {
      return (
        <Redirect
          to={ {
            pathname : '/public/public-study-sets',
          } }
        />
      );
    }

    return (
      <PublicStudySetsAll
        isActive={ this.props.isActive }
        location={ this.props.location }
        match={ this.props.match }
        studySets={ this.props.studySets }
      />
    );
  }
}

function mapStateToProps({ authentication, publicStudySets }) {
  return {
    isActive  : publicStudySets.get('isActive'),
    isAuthed  : !!(authentication.get('email') && authentication.get('accessToken')),
    studySets : publicStudySets.getIn(['loaded', 'all']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions : {
      publicStudySets : bindActionCreators(publicStudySetsActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalAll);
