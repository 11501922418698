import React from 'react';

import * as basePropTypes from 'src/constants/propTypes/base';

import LinkToPageIcon from 'src/components/icons/LinkToPage';

import styles from './styles.module.scss';

function SignOutButton({ handleClick }) {
  return (
    <div
      className={ styles.Root }
      onClick={ handleClick }
    >
      <LinkToPageIcon />
      <p>Log out</p>
    </div>
  );
}

SignOutButton.propTypes = {
  handleClick : basePropTypes.handleClick.isRequired,
};

export default SignOutButton;
