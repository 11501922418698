import { PropTypes } from 'prop-types';
import React from 'react';

import Card from 'src/components/Card';
import Header from 'src/components/navigation/Header';
import FaceLogo from 'src/components/images/logos/FaceOnly';
import Logo from 'src/components/images/logos/NameWithFaceAsO';
import PulseLoadingIndicator from 'src/components/PulseLoadingIndicator';

import styles from './styles.module.scss';

function Base(props) {
  const {
    isActive,
    children,
  } = props;

  return (
    <div className={ styles.Root }>
      <div className={ styles.Header }>
        <Header noPadding>
          <div className={ styles.LogoContainer }>
            <FaceLogo dataAutomatedTest="home-logo-desktop-link" isLeft />
          </div>
        </Header>
      </div>

      <div className={ styles.MobileLogo }>
        <Logo />
      </div>

      <Card
        extraClassNames={ styles.Card }
        navLocationOnMobile="header"
      >
        <Choose>
          <When condition={ isActive }>
            <div className={ styles.PulseLoader }>
              <PulseLoadingIndicator isLoading />
            </div>
          </When>

          <Otherwise>
            { children }
          </Otherwise>
        </Choose>
      </Card>
    </div>
  );
}

Base.propTypes = {
  children : PropTypes.node.isRequired,
  isActive : PropTypes.bool,
};

export default Base;
